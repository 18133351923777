import * as React from 'react';

import imgu from '../common/imgu'

export class MapPoint {
    constructor(icon, x, y, {icon_size=32,radius=1}={}) {
        this.icon = icon
        this.icon_size = icon_size
        this.x = x
        this.y = y
        this.radius = radius
    }
}

export function BasicMapView({sz, isz, path, points}) {
    isz = isz == null ? 2048 : isz
    const scale = sz / isz
    const icenter = {x:sz/2,y:sz/2}
    const map_src = `/ui/map2/${path}.webp`
    return (
        <div style={{height:`${sz}px`,width:`${sz}px`}}>
            <div style={{position:'absolute', height:`${sz}px`,width:`${sz}px`, overflow:'hidden', border:'1px solid #888'}}>
                <img src={map_src} style={{width:`${sz}px`, height:`${sz}px`}}></img>
                {mkpoints(points, icenter, scale)}
            </div>
        </div>
    )
}

export function MapView({vwidth, vheight, iwidth, iheight, scale, path, points}) {
    vheight = vheight == null ? 400 : vheight
    vwidth = vwidth == null ? 640 : vwidth
    iheight = iheight == null ? 2048 : iheight
    iwidth = iwidth == null ? 2048 : iwidth
    scale = scale == null ? 0.8 : scale
    const iws = iwidth * scale
    const ihs = iheight * scale
    const icenter = {x:iws/2,y:ihs/2}
    const map_src = `/ui/map2/${path}.webp`

    const center = points == null || points.length !== 1 ? icenter : {
        x: icenter.x + (points[0].x*scale) - 16,
        y: icenter.y + (points[0].y*scale) - 16
    }

    // center-boundaries
    const imin = {x:vwidth/2, y:vheight/2}
    const imax = {x:iws - vwidth/2, y: ihs - vheight/2}
    const [mapCenter, setMapCenter] = React.useState({
        x: clamp(center.x, imin.x, imax.x),
        y: clamp(center.y, imin.y, imax.y)
    })
    console.log(`center: ${mapCenter.x} ${mapCenter.y}`)
    const dragInfo = React.useRef(null)
    const drag = () => {
        const dragd = dragInfo.current
        if (dragd != null) {
            const cx = window.event.clientX;
            const cy = window.event.clientY;
            const dx = cx - dragd.click.x
            const dy = cy - dragd.click.y
            //console.log(`mouse: ${cx} ${cy}`)
            //console.log(`deltas: ${dx} ${dy}`)
            const newCenter = {
                x:clamp(dragd.center.x - dx, imin.x, imax.x), 
                y:clamp(dragd.center.y - dy, imin.y, imax.y)
            }
            //console.log(JSON.stringify(newCenter))
            if (newCenter.x !== dragd.center.x || newCenter.y !== dragd.center.y) {
                setMapCenter(newCenter)
            }
        }
        window.event.stopPropagation()
        window.event.preventDefault()
        //return true
    }
    const mouseup = () => {
        dragInfo.current = null
        //console.log('big-unclick')
        window.removeEventListener("mousemove", drag)
        window.removeEventListener("mouseup", mouseup)
    }
    const mapOff = {
        x: 0 - mapCenter.x + vwidth/2,
        y: 0 - mapCenter.y + vheight/2
    }
    return (
        <div style={{height:`${vheight}px`}}>
            <div style={{position:'absolute', height:`${vheight}px`,width:`${vwidth}px`, overflow:'hidden', border:'2px solid black'}}>
                <div style={{position:'absolute', left:`${mapOff.x}px`, top:`${mapOff.y}px`}}
                    draggable='false'
                    onMouseDown={(e) => {
                        dragInfo.current = {
                            click:{x:e.clientX, y:e.clientY},
                            center:{x:mapCenter.x, y:mapCenter.y}
                        }
                        //console.log(`click: ${dragInfo.current.click.x} ${dragInfo.current.click.y}`)
                        e.stopPropagation()
                        e.preventDefault()
                        window.addEventListener("mousemove", drag)
                        window.addEventListener("mouseup", mouseup)
                        return false
                    }}
                    onDragStart={() => {
                        return false
                    }}
                >
                    <img src={map_src} style={{width:`${iws}px`, height:`${ihs}px`}}></img>
                    {mkpoints(points, icenter, scale)}
                </div>
            </div>
            <div style={{marginLeft:`${vwidth+12}px`}}>
                <div style={{position:'absolute'}}>
                    <img src={map_src} style={{width:'80px', height:'80px', position:'absolute'}}></img>
                    {mapViewToThumb(80, iws, ihs, vwidth, vheight, mapCenter)}
                </div>
            </div>
        </div>
    )
}

function mkpoints(points, icenter, scale) {
    if (points == null || points.length === 0) {
        return null
    }
    const res = []
    points.forEach((p, i) => {
        if (p.radius === 0) { return }
        const radius = p.radius*0.15 * scale
        if (radius > 10) {
            res.push((
                <div
                    key={`poi-radius-${i}`}
                    style={{
                        position:'absolute',
                        left:`${icenter.x + (p.x*scale) - radius}px`,
                        top:`${icenter.y + (p.y*scale) - radius}px`,
                        width:`${radius*2}px`,
                        height:`${radius*2}px`,
                        borderRadius:`${radius}px`,
                        backgroundColor:'#77B4'
                    }}></div>
            ))
        }
        const iconsz = p.icon_size
        const halfsz = iconsz / 2
        const szpx = `${iconsz}px`
        res.push((
            <img
                key={`poi-${i}`}
                style={{
                    position:'absolute', 
                    left:`${icenter.x + (p.x*scale) - halfsz}px`, 
                    top:`${icenter.y + (p.y*scale) - halfsz}px`,
                    width:szpx, height:szpx}}
                src={imgu.to_icon_path(p.icon)}>
            </img>
        ))
    })
    return res
}

function mapViewToThumb(tsize, iws, ihs, vw, vh, center) {
    const scale_x = tsize / iws
    const scale_y = tsize / ihs
    const vws = scale_x * vw
    const vhs = scale_y * vh
    const centers = {x:scale_x*center.x,y:scale_y*center.y}
    //console.log(`centerscaled: ${JSON.stringify(centers)}`)
    //console.log(`left = ${centers.x - vws/2}`)
    //console.log(`top = ${centers.y - vhs/2}`)
    return (
        <div style={{
            position:'absolute', left:`${centers.x - vws/2}px`, top:`${centers.y - vhs/2}px`,
            width:`${vws}px`, height:`${vhs}px`, backgroundColor:'#6765',
            border:'1px solid #0004'
        }}/>
    )
}

function clamp(val, min, max) {
    return (val < min ? min : val > max ? max : val)
}
