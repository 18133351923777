import * as React from 'react';
import {Link} from "react-router-dom";

import api from '../common/api'
import client_util from '../common/util/client_util'
import { useLocalSettings } from '../common/user'
import imgu from '../common/imgu'
import throttle from '../common/util/throttle'
import cbase from '../common/cbase'
import {ItemRef, DebugToggle, vgap} from '../components/viewbase'
import {MapView} from '../components/mapview'
import {XTable, XCol, XTableModel} from '../components/xtable'
import {ItemRead, render_stats} from '../common/item'
import {ItemSearch, item_search_spec, item_colspec_default, item_colspec_mkt} from '../common/item'
import {Page} from '../components/Page'

function RetainerTasksQuick(props) {
    const [settings] = useLocalSettings()

    return (<RetainerTasksQuickCore {...props} settings={settings}/>)
}

class RetainerTasksQuickCore extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            payload: null,
            poke: 0
        }
        this.colspec = this.mk_colspec()
        this.xtm = XTableModel.parseUrl(this.props.qsearch)
        this.data_loader = throttle.loadOnce(() => this._run_query())
    }

    componentDidMount() {
        this.data_loader.load()
        client_util.setTitle('Quick Exploration')
    }

    componentDidUpdate(prevProps) {
        if (this.props.qsearch !== prevProps.qsearch) {
            this.xtm = XTableModel.parseUrl(this.props.qsearch)
            this.setState((prev) => ({poke:prev.poke === 0 ? 1 : 0}))
        }
        if (this.props.settings.homeWorld().id !== prevProps.settings.homeWorld().id) {
            this.colspec.update_world(this.props.settings.homeWorld().name)
            this._run_query()
        }
    }

    _table_updated(e) {
        console.log(`table_update: ${JSON.stringify(e, 2)}`)
        this.xtm = e
        this._update_history()
    }

    _update_history() {
        const qparams = this.xtm.toUrlParams()
        if (window.history.state != qparams) {
            window.history.replaceState(qparams, '', qparams == null ? '/retainer-tasks/quick' : `/retainer-tasks/quick?${qparams}`)
        }
    }

    _run_query() {
        readPagePayload(this.props.settings, (x) => this.setState({payload:x}))
    }

    render() {
        const {payload} = this.state
        return (
            <Page canon='retainer-tasks/quick'>
                <div className='page-body'>
                    <h1>Quick Exploration</h1>
                    <div className='x-titled-section'>
                        <div className='x-title'>Retainer Task</div>
                        <table className='basic-t'><tbody>
                            <tr><td>Retainer Level</td><td>10</td></tr>
                            <tr><td>Duration</td><td>1 hour</td></tr>
                            <tr>
                                <td>Cost</td>
                                <td><ItemRef id={21072} icon={65049} quantity={2}/></td>
                            </tr>
                        </tbody></table>
                    </div>
                    <div style={{margin:'1rem 0', maxWidth:'800px'}}>
                        <p>Quick Exploration provides a random reward from a very large pool of items. Typical rewards include
                        dungeon gear, prior tier raid and tomestone gear, materials, and venture coffers. While rare, it is also
                        possible to receive items that can no longer be acquired by any other method (such as Pre-Calamity items)</p>
                    </div>
                    {payload == null ? null : this.render_rewards(payload)}
                </div>
            </Page>
        )
    }

    render_rewards(payload) {
        const items = payload == null ? [] : payload.items
        const rpp = items.length > 50 ? 50 : null
        const msg = `${items.length} items found`
        return (
            <div>
                <h2>Notable Rewards</h2>
                <p></p>
                <XTable tableClass='data-t' cols={this.colspec.cols} data={items} 
                    xtmodel={this.xtm} handler={(e) => this._table_updated(e)}
                    message={msg} rpp={rpp} width='1020px'/>
            </div>
        )
    }

    mk_colspec() {
        if (this.props.settings.homeWorld() == null) {
            return item_colspec_mkt('Faerie')
        }
        return item_colspec_mkt(this.props.settings.homeWorld().name)
    }
}

function renderItems(settings, payload) {
    const worldName = settings.homeWorld().name
    const colspec = item_colspec_mkt(worldName)
    const items = payload == null ? [] : payload.items
    const rpp = items.length > 50 ? 50 : null
    const msg = `${items.length} items found`
    //xtmodel={this.xtm} handler={(e) => this._table_updated(e)}
    return (
        <div>
            <h2>Notable Rewards</h2>
            <p></p>
            <XTable tableClass='data-t' cols={colspec.cols} data={items} 
                message={msg} rpp={rpp} width='1020px'/>
        </div>
    )
}

function readPagePayload(settings,callback) {
    const worldId = settings.homeWorld().id
    api.call({
        path:`retainertask/quick?world=${worldId}`, method:'GET',
        handler:(result, err) => processPayload(result, callback)
    })
}

function processPayload(payload, callback) {
    const item_rewards = []
    if (payload != null && payload.tasks != null && payload.tasks.length !== 0) {
        const t = payload.tasks[0]
        if (t.rand_reward != null) {
            t.rand_reward.forEach((r) => {
                if (payload.market != null) {
                    const mkt = payload.market[r.id]
                    if (mkt != null) {
                        r.market = mkt
                    }
                }
                item_rewards.push(r)
            })
        }
    }
    callback({items: item_rewards})
}

export default client_util.withQSearch(RetainerTasksQuick)
