import * as React from 'react';

import cbase from '../common/cbase'
import gathrare from '../common/gathrare'
import { GathSpotRef, loc_str, to_simple_loc } from '../components/viewbase'
import {XCol} from '../components/xtable'
import {MicroNodeTimer} from '../components/gnode_timer'

export function render_gspot_loc(gspot, inc_region=true) {
    return loc_str(to_simple_loc(gspot.location), inc_region)
}

export function gspot_regcmp(i, j) {
    const ir = cbase.loc_access.region_name(i.location)
    const jr = cbase.loc_access.region_name(j.location)
    return cbase.strcmp(ir, jr)
}

export function gspot_zonecmp(i, j) {
    const iz = cbase.loc_access.zone_name(i.location)
    const jz = cbase.loc_access.zone_name(j.location)
    return cbase.strcmp(iz,jz)
}

export const gather_colspec = ({
    extra_cols=null, gspot_fn=null, excludeLinks=false
}={}) => {
    const base_cols = [
        new XCol({
            label:'Name', hstyle:{},
            compare: (i,j) => cbase.strcmp(
                (gspot_fn == null ? i : gspot_fn(i)).name,
                (gspot_fn == null ? j : gspot_fn(j)).name
            ),
            render: (i) => {
                const gspot = (gspot_fn == null ? i : gspot_fn(i))
                return (
                    <div style={{display:'flex'}}>
                        <GathSpotRef id={gspot.id} name={gspot.name} icon={gspot.icon} stype={gspot.stype} nolink={excludeLinks}/>
                        <div style={{flexGrow:'1'}}></div>
                        {gspot.transient == null ? null
                            : <MicroNodeTimer schedule={new gathrare.RareNodeSchedule(gspot.transient)} 
                                jobabbrev={gspot.job_abbrev} active_icon={gspot.icon}/>
                        }
                    </div>
                )
            }
        }),
        new XCol({
            label:'Job', hstyle:{},
            compare: (i,j) => cbase.strcmp(
                (gspot_fn == null ? i : gspot_fn(i)).job_abbrev, 
                (gspot_fn == null ? j : gspot_fn(j)).job_abbrev
            ),
            render: (i) => (gspot_fn == null ? i : gspot_fn(i)).job_abbrev
        }),
        new XCol({
            label:'Lvl', hstyle:{},
            compare: (i,j) => cbase.numcmp(
                (gspot_fn == null ? i : gspot_fn(i)).level, 
                (gspot_fn == null ? j : gspot_fn(j)).level
            ),
            render: (i) => (gspot_fn == null ? i : gspot_fn(i)).level
        }),
        new XCol({
            label:'Region', hstyle:{},
            compare: (i,j) => gspot_regcmp(
                (gspot_fn == null ? i : gspot_fn(i)),
                (gspot_fn == null ? j : gspot_fn(j))
            ),
            render: (i) => {
                const gspot = (gspot_fn == null ? i : gspot_fn(i))
                return gspot.location.region == null ? null : gspot.location.region.name
            }
        }),
        new XCol({
            label:'Zone', hstyle:{},
            compare: (i,j) => gspot_zonecmp(
                (gspot_fn == null ? i : gspot_fn(i)),
                (gspot_fn == null ? j : gspot_fn(j))
            ),
            render: (i) => render_gspot_loc((gspot_fn == null ? i : gspot_fn(i)), false)
        }),
    ]

    return extra_cols == null ? base_cols : [...base_cols, ...extra_cols]
}

export const gather_colspec_default = gather_colspec()
