import * as React from 'react';

function buildSearchArgs(xsdata, prefix=null) {
    const params = []
    if (xsdata.sort != null) {
        const pn = prefix == null ? 'sort' : `${prefix}sort`
        const val = `${xsdata.sort.col}${xsdata.sort.asc?'':',d'}`
        params.push(`${pn}=${val}`)
    }
    if (xsdata.page !== 1) {
        const pn = prefix == null ? 'page' : `${prefix}page`
        params.push(`${pn}=${xsdata.page}`)
    }
    return params.length === 0 ? null : params.join('&')
}

function parseSearchArgs(qsearch, prefix=null) {
    const res = {
        sort: null,
        page: 1
    }
    if (qsearch == null) {
        return res
    }
    let pn = prefix == null ? 'sort' : `${prefix}sort`
    const sort = qsearch.get(pn)
    if (sort != null) {
        const parts = sort.split(',')
        res.sort = {col:parts[0],asc:parts.length===1}
    }

    pn = prefix == null ? 'page' : `${prefix}page`
    const page = qsearch.get(pn)
    if (page != null) {
        const pint = +page
        if (pint != NaN) {
            res.page = pint
        }
    }
    return res
}

export class XTableModel {
    constructor(page=null, sort=null) {
        this.page = page == null ? 1 : page
        this.sort = sort
    }

    toUrlParams(prefix=null) {
        return buildSearchArgs(this, prefix)
    }

    resolveSort(cols) {
        if (this.sort == null) {
            return null
        }
        const res = {col:null,asc:this.sort.asc}
        for (let i = 0; i < cols.length; i++) {
            const c = cols[i]
            if (c.name === this.sort.col) {
                res.col = c
                break
            }
        }
        return res.col == null ? null : res
    }

    externSort(sort) {
        this.sort = sort == null ? null : {col:sort.col.name,asc:sort.asc}
    }

    static parseUrl(qsearch, prefix=null) {
        const x = parseSearchArgs(qsearch, prefix)
        return new XTableModel(x.page, x.sort)
    }
}

let _next_table_id = 0
/*
 * Props:
 *  - cols: array of Xcol
 *  - data: array of data records
 *  - rpp: # results per page (if undefined, no paging)
 *  - width: sets width of wrapper (defaults to fit-content)
 *  - tableClass
 *  - message
 *  - idResolver: function receives row object, returns key for row (optional)
 *  - xtmodel: XTableModel - restore prior view state
 *  - handler: function receives XTableModel when it is updated
 *  - jumpTargetClass
 *  - simple: (true) for no-sort/no-paging/no-extra-headspace
 *  - rowRender: row based rendering: return <tr/> (disables column-based render / idResolver)
 */
export class XTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: props.data.slice(),
            rpp: props.rpp,
            page: null,
            sort: null,
        }
        //console.log('MAKE TABLE')
        this.table_id = `xtable_${_next_table_id++}`
        this.checkColNames()

        this.xtm = null
        this.loadXtModel()
    }

    checkColNames() {
        for (let i = 0; i < this.props.cols.length; i++) {
            if (this.props.cols[i].name == null) {
                this.props.cols[i].name = `col${i}`
            }
        }
    }

    loadXtModel() {
        const xtmodel = this.props.xtmodel
        this.xtm = xtmodel == null ? new XTableModel() : xtmodel
        const isort = this.xtm.resolveSort(this.props.cols)
        if (isort != null) {
            this._do_sort(isort)
            this.state.sort = isort
        }
        this.state.page = this.xtm_tgtPage()
    }

    xtm_tgtPage() {
        const maxp = max_page_index(this.props.rpp, this.props.data.length)
        const pmod = this.xtm.page-1
        return pmod < 0 ? 0
                : pmod > maxp ? maxp
                : pmod
    }

    update_pulse(xtmodel=null, data=null) {
        let doSort = false
        let slice = this.state.data
        if (xtmodel != null) {
            this.xtm = xtmodel
            doSort = true
            const tgtPage = this.xtm_tgtPage()
            this.setState({page:tgtPage})
        }
        if (data != null) {
            slice = data.slice()
            doSort = true
        }
        if (doSort) {
            const isort = this.xtm.resolveSort(this.props.cols)
            if (isort == null) {
                this.setState({data:slice,sort:null})
            }
            else {
                this._do_sort(isort, slice)
                this.setState({data:slice,sort:isort})
            }
        }
    }

    componentDidUpdate(prevProps) {
        //console.log('xtable-DID-UPDATE')
        let newData = prevProps.data !== this.props.data ? this.props.data : null
        let newModel = prevProps.xtmodel !== this.props.xtmodel ? this.props.xtmodel : null
        if (newData != null || newModel != null) {
            this.update_pulse(newModel, newData)
        }
        /*if (prevProps.data !== this.props.data) {
            //console.log('  data-change')
            this.setState({data:this.props.data.slice(),page:0,sort:null})
        }*/
        if (prevProps.rpp !== this.props.rpp) {
            //console.log('  rpp-change')
            this.setState({rpp:this.props.rpp})
        }
        /*if (prevProps.xtmodel !== this.props.xtmodel) {
            //console.log('  xt-model-change')
            this.loadXtModel()
        }*/
    }

    _notify() {
        if (this.props.handler != null) {
            this.props.handler(this.xtm)
        }
    }

    _page_updated(e) {
        this.xtm.page = e+1
        this.setState({page: e})
        this._notify()
    }

    _hclick(col) {
        if (col.compare != null) {
            const {sort} = this.state
            const usort = (sort == null || sort.col !== col)
                ? {col:col,asc:true}
                : {col:col,asc:!sort.asc}
            this._sort_updated(usort)
        }
    }

    _sort_updated(e) {
        this.xtm.externSort(e)
        this._apply_sort(e)
        this._notify()
    }

    _apply_sort(sort, initialLoad=false) {
        if (sort == null) {
            if (this.state.sort != null) {
                // this should never occur when initialLoad is true 
                // (because this.state.sort will be null on the initial call as we are trying to apply a provided sort)
                this.setState({data:this.props.data.slice(),sort:null})
            }
        }
        else {
            this._do_sort(sort)
            if (initialLoad)
                this.state.sort = sort
            else
                this.setState({sort: sort})
        }
    }

    _do_sort(sort, data=null) {
        data = data != null ? data : this.state.data
        const fn = sort.asc ? sort.col.compare : (i,j) => sort.col.compare(j,i)
        data.sort(fn)
    }

    render() {
        const {data, rpp, page} = this.state
        const {cols, idResolver, rowRender} = this.props
        const header = this._render_header(cols)
        const rows = []
        const datalen = data.length
        //console.log(`datalen = ${datalen}`)
        const ibounds = page_indices(page, rpp, datalen)
        //console.log(JSON.stringify(ibounds))
        for (let i = ibounds.start; i < ibounds.end; i++) {
            const rec = data[i]
            if (rowRender != null) {
                rows.push(rowRender(rec))
            }
            else {
                const cells = cols.filter((c) => c.render != null)
                                .map((c, j) => (<td key={j}>{c.render(rec)}</td>))
                const row_id = idResolver == null ? i : idResolver(rec)
                rows.push((<tr key={row_id}>{cells}</tr>))
            }
        }
        const jumpTargetCls = this.props.jumpTargetClass != null ? this.props.jumpTargetClass : 'jump-target'
        const width = this.props.width != null ? this.props.width : 'fit-content'
        return (
            <div>
                <span style={{position:'absolute'}}><span id={this.table_id} className={jumpTargetCls}></span></span>
                <div style={{display:'flex',flexDirection:'column',width:width}}>
                    {this.props.simple === true ? null : (
                        <div style={{display:'flex',flexDirection:'row'/*,borderTop:'1px solid #14181c'*/}}>
                            <div style={{margin:'2px 4px',fontSize:'14px',fontStyle:'italic'}}>
                                {this.props.message}
                            </div>
                            <div style={{flexGrow:1}}></div>
                            <div style={{margin:'2px 60px 2px 4px',fontSize:'14px'}}>
                                {this.state.sort == null ? '' :
                                    <span className='micro-btn' onClick={(e) => this._sort_updated(null)}>Reset Sort</span>
                                }
                            </div>
                            <div style={{margin:'2px 4px',fontSize:'14px'}}>
                                {this.mkpager(datalen, (<div style={{height:'17px'}}></div>))}
                            </div>
                        </div>
                    )}
                    <table className={this.props.tableClass} style={this.props.tableStyle}>
                        {header}
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                    <div style={{display:'flex',flexDirection:'row'}}>
                        <div style={{flexGrow:1}}></div>
                        <div style={{margin:'4px',fontSize:'14px'}}>
                            {this.mkpager(datalen, (<></>), true)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    _render_header(cols) {
        const {sort} = this.state
        const heads = []
        for (let i = 0; i < cols.length;) {
            const c = cols[i]
            const span_size = c.span_size()
            if (span_size == 1) {
                const sep = c.span_leadingSep() ? c.span_sep() : null
                heads.push((
                    <Xh key={c.name} col={c} sep={sep} header={c.span_header()} footer={c.span_footer()} sort={sort}
                        onClick={this.props.simple === true || c.compare == null ? null : (col) => this._hclick(col)}/>
                ))
                i++
                continue
            }
            const span_cols = cols.slice(i, i+span_size)
            heads.push((
                <Xmh key={c.name} cols={span_cols} sep={c.span_sep()} leadingSep={c.span_leadingSep()}
                    justify={c.span_justify()} header={c.span_header()} footer={c.span_footer()} sort={sort}
                    isLastCol={i+span_size >= cols.length}
                    onClick={this.props.simple === true ? null : (col) => this._hclick(col)}/>
            ))
            i+= span_size
        }
        return (
            <thead><tr>{heads}</tr></thead>
        )
    }

    mkpager(rcount, alt, jump=false) {
        return this.props.rpp == null || this.state.data == null || this.state.data.length === 0
            ? alt
            : (<XPager page={this.state.page} rcount={rcount} rpp={this.props.rpp}
                    onPage={(p) => {
                        if (jump === true) {
                            document.getElementById(this.table_id).scrollIntoView()
                        }
                        this._page_updated(p)
                    }}/>)
    }
}

export class XCol {
    constructor({name, label, hstyle, render, compare, span}) {
        this.name = name
        this.label = label
        this.hstyle = hstyle
        this.render = render
        this.compare = compare
        this.span = span
    }

    span_size() {
        return this.span == null || this.span.size == null ? 1 : this.span.size
    }

    span_sep() {
        if (this.span == null || this.span.sep == null) {
            return default_sep
        }
        if (this.span.sep === 'none') {
            return null
        }
        return this.span.sep
    }

    span_justify() {
        if (this.span == null || this.span.justify == null) {
            return null
        }
        return this.span.justify
    }

    span_header() {
        if (this.span == null || this.span.header == null) {
            return null
        }
        return this.span.header
    }

    span_footer() {
        if (this.span == null || this.span.footer == null) {
            return null
        }
        return this.span.footer
    }

    span_leadingSep() {
        if (this.span == null || this.span.leadingSep == null) {
            return false
        }
        return this.span.leadingSep === true
    }
}

function default_sep(key=null) {
    // className='sep-bg'
    return (
        <div key={key} className='' style={{width:'1px', margin:'2px 8px 2px 2px'}}></div>
    )
}

function Xh({col, sep, header, footer, sort, onClick}={}) {
    const fn = onClick != null ? (e) => onClick(col) : null
    const hstyle = col.hstyle != null && onClick != null ? {...col.hstyle, cursor:'pointer'}
            : col.hstyle != null ? col.hstyle
            : onClick != null ? {cursor:'pointer'}
            : null
    const label = (<XhLabel col={col} sort={sort}/>)
    const content = footer == null && header == null ? label : (
        <div style={{display:'flex',flexDirection:'column'}}>
            {header == null ? null : header()}
            {sep == null ? label : (
                <div style={{display:'flex',marginLeft:'-10px'}}>
                    {sep()}
                    {label}
                </div>
            )}
            {footer == null ? null : footer()}
        </div>
    )
    return (
        <th style={hstyle} onClick={fn}>{content}</th>
    )
}

function Xmh({cols, sep, justify, leadingSep, header, footer, isLastCol=true, sort, onClick}={}) {
    const col = cols[0]
    const labels = []
    for (let i = 0; i < cols.length; i++) {
        if (sep != null && (i !== 0 || leadingSep === true)) {
            labels.push(sep(`sep_${i}`))
        }
        const colx = cols[i]
        const hasClick = onClick != null && colx.compare != null
        const lstyle = hasClick ? {cursor:'pointer'} : null
        labels.push((
            <div key={i} style={lstyle} onClick={hasClick ? () => onClick(colx) : null}>
                <XhLabel col={colx} sort={sort}/>
                {sep != null ? null : (<span style={{width:'8px'}}></span>)}
            </div>
        ))
    }
    const mgrt = justify !== 'right' ? '0px'
        : isLastCol === true ? '-6px' : '2px'
    const label_div = (
        <div style={{display:'flex',justifyContent:justify,marginRight:mgrt}}>
            {labels}
        </div>
    )
    const content = footer == null && header == null ? label_div : (
        <div style={{display:'flex',flexDirection:'column'}}>
            {header == null ? null : header()}
            {label_div}
            {footer == null ? null : footer()}
        </div>
    )
    return (
        <th style={col.hstyle}>{content}</th>
    )
}

function XhLabel({col, sort}) {
    const sort_ind = sort == null || sort.col.name !== col.name ?
        (<span style={{width:'14px'}}></span>)
        : (
            <span className='material-icon icon14' style={{color:'#668'}}>
                {sort.asc === true ? 'expand_less' : 'expand_more'}
            </span>
        )
    return (
            <div style={{display:'flex',alignItems:'center'}}>
                <div style={{display:'flex',alignItems:'center'}}>{col.label}</div>
                {sort_ind}
            </div>
        )
}

/*
 * Props
 *  page - page to show (0-based index)
 *  rcount - total # of records
 *  rpp - page size
 *  onPage - callback receives page # (0-based index)
 */
export class XPager extends React.Component {
    constructor(props) {
        super(props)
        this.state = this.buildState()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.page !== this.props.page
            || prevProps.rcount !== this.props.rcount
            || prevProps.rpp !== this.props.rpp) {
            this.setState(this.buildState())
        }
    }

    buildState() {
        const {rcount, rpp, page} = this.props
        return {
            bounds:page_bounds(page, rpp, rcount)
        }
    }

    render() {
        const {bounds} = this.state
        const info = `${bounds.start} - ${bounds.end} of ${this.props.rcount}`
        const prev_enabled = bounds.start !== 1
        const next_enabled = bounds.end !== this.props.rcount
        return (
            <div style={{display:'flex', gap: '6px'}}>
                <div style={{marginRight:'4px'}}>
                    <button className='micro-btn' disabled={!prev_enabled} style={{padding:'0px 4px'}}
                        onClick={(e) => this._notify(this.props.page-1)}>Previous</button>
                </div>
                <div style={{/*margin:'0px 4px'*/}}>
                    {info}
                </div>
                <div style={{marginLeft:'4px'}}>
                    <button className='micro-btn' disabled={!next_enabled} style={{padding:'0px 4px'}}
                    onClick={(e) => this._notify(this.props.page+1)}>Next</button>
                </div>
            </div>
        )
    }

    _notify(page) {
        if (this.props.onPage != null) {
            this.props.onPage(page)
        }
    }
}

// start/end inclusive - user index (1-N)
function page_bounds(page, rpp, rcount) {
    const calcEnd = (page+1)*rpp
    return {
        start: page*rpp + 1,
        end: calcEnd > rcount ? rcount : calcEnd
    }
}

// start inclusive, end exclusive
function page_indices(page, rpp, rcount) {
    if (rpp == null) {
        return {start:0,end:rcount}
    }
    const calcEnd = (page+1)*rpp
    return {
        start: page*rpp,
        end: calcEnd > rcount ? rcount : calcEnd
    }
}

function max_page_index(rpp, rcount) {
    if (rpp == null) {
        return 0
    }
    const hasPartial = rcount % rpp !== 0
    const nPage = Math.floor(rcount / rpp) + (hasPartial ? 1 : 0)
    return nPage === 0 ? 0 : nPage - 1
}
