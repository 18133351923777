import * as React from 'react';
//import {Link} from "react-router-dom";

import api from '../common/api'
import cbase from '../common/cbase'
import client_util from '../common/util/client_util'
import imgu from '../common/imgu'
import throttle from '../common/util/throttle'
import {render_npc_roles} from '../common/npc'
import {qkind_map} from '../common/quest'
import {XTable, XTableModel, XCol} from '../components/xtable'
import {XStrFilter, XSelectFilter, XBooleanFilter, XIntRangeFilter, XSearch, XSearchModel} from '../components/xsearch'
import { DebugToggle, NpcRef, QuestRef, render_jobcat, render_locations } from '../components/viewbase'
import {Page} from '../components/Page'

class QuestSearchPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            payload: null,
            options: null,
            debug_mode: false,
        }
        this.rpp = 50
        this.xsm = XSearchModel.parseUrl(_quest_spec, this.props.qsearch)
        this.xtm = XTableModel.parseUrl(this.props.qsearch)

        this.data_loader = throttle.loadOnce(() => this._run_query())
    }

    componentDidMount() {
        console.log('componentDidMount')
        this.data_loader.load()
        client_util.setTitle('Quest Database')
    }

    componentDidUpdate(prevProps) {
        if (this.props.qsearch !== prevProps.qsearch) {
            this.xsm = XSearchModel.parseUrl(_quest_spec, this.props.qsearch)
            this.xtm = XTableModel.parseUrl(this.props.qsearch)
            this._run_query()
        }
    }

    render() {
        const {payload} = this.state
        return payload == null ? null : (
            <Page>
            <div className='page-body'>
                {this.render_header()}
                {this.render_search_controls()}
                {this.render_data(payload)}
                {this.render_debug_data(payload)}
            </div>
            </Page>
        )
    }

    render_header() {
        return (
            <div>
                <h1>Quest Database</h1>
                <DebugToggle stateFn={(dbg) => this.setState({debug_mode: dbg})}/>
            </div>
        )
    }

    render_search_controls() {
        return (
            <QuestSearch 
                handler={(e) => this._search_updated(e)} 
                optionsLoader={load_quest_options}
                xsmodel={this.xsm}/>
        )
    }

    _search_updated(e) {
        this.xsm = e
        this.xtm = new XTableModel()
        this._update_history()
        this._run_query()
    }

    _table_updated(e) {
        //console.log(`table_update: ${JSON.stringify(e, 2)}`)
        this.xtm = e
        this._update_history()
    }

    _update_history() {
        const xsmParams = this.xsm.toUrlParams(_quest_spec)
        const xtmParams = this.xtm.toUrlParams()
        const qparams = (xsmParams != null && xtmParams != null)
            ? `${xsmParams}&${xtmParams}`
            : xsmParams != null ? xsmParams : xtmParams
        console.log(qparams)
        if (window.history.state != qparams) {
            window.history.replaceState(qparams, '', qparams == null ? '/quests' : `/quests?${qparams}`)
        }
    }

    _run_query() {
        api.call({
            path:'quest', method:'POST',
            params:{
                search: this.xsm.search,
                search_method: 'search'
            },
            handler:(result, err) => this.setState({payload: result})
        })
    }

    render_data(payload) {
        const {debug_mode} = this.state
        const reslen = payload.results.length
        const msg = payload.ttl_count > reslen
            ? `${reslen} of ${payload.ttl_count} quests loaded`
            : `${reslen} quests found`

        return debug_mode === true ? (<></>) : (
            <div>
                <div style={{paddingTop: '8px'}}>
                    {
                        payload == null ? ''
                            : <XTable tableClass='data-t' cols={quest_colspec_default} data={payload.results}
                                width='1000px' rpp={this.rpp} message={msg}
                                handler={(e) => this._table_updated(e)} xtmodel={this.xtm}/>
                    }
                </div>
            </div>
        )
    }

    render_debug_data(payload) {
        const {debug_mode} = this.state
        return debug_mode === false ? (<></>) : (
            <div style={{justifyContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                {client_util.renderInput(payload)}
            </div>
        )
    }
}

async function load_quest_options() {
    const data = await api.get_doc('core/opt/quest.json')
    const options = {
        region: { choices: data.regions.map((r) => {
            return {value: r.id, content: r.name}
        })},
        zone: { choices: data.zones.map((z) => {
            return {value: z.id, content: z.name}
        })},
        qkind: { choices: Object.keys(qkind_map).map((qk) => {
            return {value: qk, content: qkind_map[qk]}
        })}
    }
    const sortFn = (a,b) => a.content.localeCompare(b.content)
    options.region.choices.sort(sortFn)
    options.zone.choices.sort(sortFn)
    //console.log(JSON.stringify(options))
    return options
}

const _quest_spec = {
    standard: [
        new XStrFilter('search', null, 'qstr', {placeholder:'Search for Quests'}),
        new XIntRangeFilter('level', 'Level', 'min_level', 'max_level'),
        new XSelectFilter('qkind', 'Quest Type', 'qkind')
    ],
    other: [
        new XSelectFilter('region', 'Region', 'region_id'),
        new XSelectFilter('zone', 'Zone', 'zone_id')
    ]
}

function QuestSearch({handler, options, optionsLoader, xsmodel}) {
    return (<XSearch handler={handler} spec={_quest_spec} options={options} optionsLoader={optionsLoader} xsmodel={xsmodel}/>)
}

const quest_colspec_default = [
    new XCol({
        label:'Name', hstyle:{},
        compare: (i,j) => cbase.strcmp(i.name, j.name),
        render: (i) => {
            return (
                <QuestRef id={i.id} name={i.name} icon={i.icon}/>
            )
        }
    }),
    /*new XCol({
        label:'Type', hstyle:{},
        compare: (i,j) => cbase.strcmp(i.qkind_nm, j.qkind_nm),
        render: (i) => i.qkind_nm
    }),*/
    new XCol({
        label:'Level', hstyle:{minWidth:'80px',maxWidth:'80px'},
        compare: (i,j) => cbase.numcmp(i.req.level, j.req.level),
        render: (i) => i.req.level
    }),
    new XCol({
        label:'Reqs', hstyle:{},
        compare: (i,j) => cbase.strcmp(i.req.jobcat_nm,j.req.jobcat_nm),
        render: (i) => render_jobcat(i.req.jobcat_nm)
    }),
    /*new XCol({
        label:'Region', hstyle:{minWidth:'100px',maxWidth:'100px'},
        compare: (i,j) => cbase.loccmp_region(i.starter.loc, j.starter.loc),
        render: (i) => {
            const loc = i.starter.loc
            return loc == null ? null : cbase.loc_access.region_name(loc)
        }
    }),*/
    new XCol({
        label:'Start Location', hstyle:{minWidth:'260px',maxWidth:'260px'},
        compare: (i,j) => cbase.loccmp(i.starter.loc, j.starter.loc),
        render: (i) => render_starter(i.starter)
    }),
]

function render_starter(starter) {
    return (
        <div>
            {/*<div style={{marginBottom:'0.25rem'}}>
                {starter.type !== 'npc' ? starter.name : (
                    <NpcRef id={starter.id} name={starter.name}/>
                )}
            </div>*/}
            {starter.loc == null ? null : 
                render_locations([starter.loc], {simple_loc:false})
            }
        </div>
    )
}

export default client_util.withQSearch(QuestSearchPage)
