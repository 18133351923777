import * as React from 'react';
import {Link} from 'react-router-dom';

import imgu from '../common/imgu'
import api from '../common/api'
import throttle from '../common/util/throttle'
import client_util from '../common/util/client_util'
import {NewsReader} from '../components/newsview'
import {Page} from '../components/Page'

export default class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            patch: null
        }

        this.data_loader = throttle.loadOnce(() => this._run_query())
    }

    componentDidMount() {
        client_util.setTitle()
        this.data_loader.load()
    }

    _run_query() {
        api.promise({path: '/core/patch_current'})
        .then((result) => {
            this.setState({patch: result.payload})
        })
    }

    render() {
        const {patch} = this.state
        return patch == null ? (<></>) : (
            <Page>
                <div className='page-body resp nobg'>
                    {/*<h1>Final Fantasy 14 Database & Tools</h1>
                    <h2>Current Patch</h2>*/}
                    <h2 style={{marginTop:'0px'}}>Current Patch</h2>
                    <div style={{margin:'12px 0px 12px 0px'}}>
                        <PatchView patch={patch}/>
                    </div>
                    <div style={{margin:'16px 0px'}}>
                        <NewsReader/>
                    </div>
                </div>
            </Page>
        )
    }
}

function section_ref(path, icon, label) {
    return (
        <div>
            <Link className="Game-link" to={path}>
                <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                    <img src={imgu.to_icon_path(icon)} style={{height:'40px',width:'40px'}}></img>
                    <div style={{margin:'6px 4px'}}>{label}</div>
                </div>
            </Link>
        </div>
    )
}

function PatchView({patch}) {
    return (
        <div style={{display:'flex'}}>
        <div>
            <Link className='Clean-link' to={`/patch/${patch.id}`}>
            <div style={{display:'flex',flexDirection:'column'}}>
                <img src={patch.image} style={{maxWidth:'480px',width:'100%',height:'auto',
                    borderTopLeftRadius:'6px',borderTopRightRadius:'6px'
                }} ></img>
                <div style={{backgroundColor:'#181818',padding:'4px 8px',borderBottomLeftRadius:'6px',borderBottomRightRadius:'6px'}}>
                    <div style={{display:'flex',flexWrap:'wrap',margin:'4px',alignItems:'center',justifyContent:'space-between'}}>
                        <div style={{fontSize:'1.125rem',fontWeight:'bold',paddingRight:'1rem'}}>Patch {patch.patch}</div>
                        <div style={{fontSize:'0.875rem'}}>{patch.name}</div>
                    </div>
                    <div style={{display:'flex',flexWrap:'wrap',margin:'4px',alignItems:'center',justifyContent:'space-between'}}>
                        <div style={{fontSize:'14px',color:'#888',paddingRight:'1rem'}}>Release {patch.release}</div>
                        <div className='Game-link micro-btn' style={{fontSize:'0.875rem'}}
                            >View Changeset</div>
                    </div>
                </div>
            </div>
            </Link>
        </div>
        <div style={{flexGrow:1}}/>
        </div>
    )
}

function PatchView2({patch}) {
    return (
        <div style={{backgroundColor:'#181818',borderRadius:'8px',width:'fit-content'}}>
            <div style={{display:'flex',gap:'10px'}}>
                <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                    <img style={{/*marginTop:'4px'*/borderTopLeftRadius:'8px',borderBottomLeftRadius:'8px'}} src={patch.image} width='195px' height='53px'></img>
                </div>
                <div style={{display:'flex',flexDirection:'column',alignItems:'left',margin:'6px 8px 6px 0px'}}>
                    <div style={{color:'inherit',fontSize:'18px'}}>Patch {patch.patch}</div>
                    <div style={{fontSize:'16px'}}>{/*patch.name*/}</div>
                    <div><Link className='Game-link micro-btn' to={`/patch_detail/${patch.id}`}>View Details</Link></div>
                </div>
            </div>
        </div>
    )
}

function PatchView3({patch}) {
    return (
        <div style={{backgroundColor:'#181818',borderRadius:'8px',width:'fit-content'}}>
            <div style={{display:'flex',flexDirection:'column'}}>
                <div style={{margin:'4px 8px 4px 8px'}}>{patch.patch} - {patch.name}</div>
            </div>
            <div style={{display:'flex'}}>
                <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                    <img style={{/*marginTop:'4px'*/borderBottomLeftRadius:'8px'}} src={patch.image} width='195px' height='53px'></img>
                </div>
                <div style={{borderTop:'1px solid #7080a040'}}>
                    <div style={{display:'flex',flexDirection:'column',alignItems:'left',margin:'6px 8px 6px 8px'}}>
                        <div style={{fontSize:'14px',color:'#888'}}>Release {patch.release}</div>
                        <div><Link className='Game-link micro-btn' to={`/patch_detail/${patch.id}`}>View Details</Link></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function PatchView4({patch}) {
    return (
        <div style={{backgroundColor:'#181818',borderRadius:'8px',width:'fit-content'}}>
            <Link className='Clean-link' to={`/patch/current`}>
            <div style={{display:'flex'}}>
                <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                    <img src={patch.image} height='68px' style={{
                        marginTop:'0px',borderTopLeftRadius:'8px',borderBottomLeftRadius:'8px'
                    }} ></img>
                </div>
                <div>
                    <div style={{display:'flex',flexDirection:'column',alignItems:'left',margin:'4px 10px'}}>
                        <div>Patch {patch.patch}</div>
                        <div style={{fontSize:'14px',color:'#888'}}>Release {patch.release}</div>
                        <div className='Game-link micro-btn' style={{fontSize:'12px',marginTop:'2px'}}
                            to={`/patch/current`}>View Details & Changeset</div>
                        {/*<div style={{fontSize:'14px'}}>{patch.name}</div>*/}
                    </div>
                </div>
            </div>
            </Link>
        </div>
    )
}
