import * as React from 'react'

import cbase from './cbase'
import api from './api'
import config from './client_config'

const _default_home_world = {
    id: 54, name: 'Faerie', datacenter: 4, dc_name: 'Aether'
}

export class WorldRepo {
    constructor(dcWorld) {
        this.dcMap = {}
        this.worldMap = {}
        Object.values(dcWorld.dc).forEach((dc) => this.dcMap[dc.id] = dc)
        Object.values(dcWorld.world).forEach((w) => {
            this.worldMap[w.id] = w
            w.dc_name = this.dcMap[w.datacenter].name
        })
    }

    world(id) { return this.worldMap[id] }
    dc(id) { return this.dcMap[id] }
}

export class UserInfo {
    constructor(data=null) {
        this.user_id = data == null ? 0 : data.user_id
        this.settings = data == null ? null : data.settings
    }

    diff(other) {
        if ((other === this) ||
            (other != null
            && other.user_id === this.user_id
            && other.settings.diff(this.settings) === other.settings)
        ) {
            return this
        }
        return other
    }
}

export class ClientSettings {
    constructor(data=null) {
        this.data = data != null ? data : {
            homeWorld: _default_home_world
        }
    }

    homeWorld() { return this.data.homeWorld }
    setHomeWorldId(world_id, worldRepo) {
        this.data.homeWorld = {
            ...worldRepo.world(world_id)
        }
    }

    json() { return JSON.stringify(this.data) }

    diff(other) {
        if ((other === this) || 
            (other != null
            && other.homeWorld().id === this.homeWorld().id)
        ) {
            return this
        }
        return other
    }

    static from_json(json) { return new ClientSettings(JSON.parse(json)) }
}

export class SyncBundle {
    constructor(data=null) {
        if (data == null) {
            this.remote_client_version = '0'
            this.refresh_at = 0
        }
        else {
            this.remote_client_version = data.remote_client_version
            this.refresh_at = data.refresh_at
        }
    }

    json() { return JSON.stringify(this) }

    diff(other) {
        if ((other === this) ||
            (other != null
            && other.remote_client_version === this.remote_client_version
            && other.refresh_at === this.refresh_at)
        ) {
            return this
        }
        return other
    }

    static from_json(json) { return new SyncBundle(JSON.parse(json)) }
}

export const TaskPriority = {
    FIRST: 0,
    HIGH: 3,
    LOW: 10
}

class VisExecutor {
    constructor() {
        this.taskMap = {}
        this.anonTask = []
        this._setup()
    }

    _setup() {
        const cb = (e) => {
            if (document.visibilityState === 'visible') {
                this._on_visible()
            }
        }
        window.addEventListener('visibilitychange', cb)
    }

    async _on_visible() {
        const tasks = [...Object.values(this.taskMap), ...this.anonTask]
        if (tasks.length !== 0) {
            tasks.sort((i, j) => cbase.numcmp(i.priority, j.priority))
            for (let i = 0; i < tasks.length; i++) {
                const x = await tasks[i].taskFn()
                if (x === false) {
                    break
                }
            }
            this.taskMap = {}
            this.anonTask = []
        }
    }

    runWhenVisible(name, priority, taskFn) {
        if (document.visibilityState !== 'hidden') {
            taskFn()
        }
        else if (name != null) {
            let entry = this.taskMap[name]
            if (entry == null) {
                entry = {
                    name: name,
                    priority: priority,
                    taskFn: taskFn
                }
                this.taskMap[name] = entry
            } 
            else {
                entry.priority = priority
                entry.taskFn = taskFn
            }
        }
        else {
            this.anonTask.push({priority: priority, taskFn: taskFn})
        }
    }
    runIfVisible(taskFn) {
        if (document.visibilityState !== 'hidden') {
            taskFn()
        }
    }
}
export const visExecutor = new VisExecutor()

class LocalDataManager {
    constructor() {
        console.log('LDM CONSTRUCTOR')
        this.settings = null
        this.sync = null
        this._listeners = {settings: [], sync: []}
        this._setup()
    }
    _setup() {
        this._read_settings()
        this._read_sync()
    }
    _storage_event(e) {
        if (e.key === 'local.settings') {
            if (this._read_settings()) {
                this._notify_settings()
            }
        }
        else if (e.key === 'local.sync') {
            if (this._read_sync()) {
                this._notify_sync()
            }
        }
    }
    _read_settings() {
        const localUserDat = localStorage.getItem('local.settings')
        const newSettings = localUserDat == null ? new ClientSettings() : ClientSettings.from_json(localUserDat)
        this.settings = this.settings == null ? newSettings : this.settings.diff(newSettings)
        return this.settings === newSettings
    }
    _notify_settings() {
        this._listeners.settings.forEach((l) => l(this.settings))
    }
    get_settings() { return this.settings }
    set_settings(settings) {
        if (settings !== this.settings) {
            this.settings = this.settings.diff(settings)
            if (this.settings === settings) {
                localStorage.setItem('local.settings', this.settings.json())
                this._notify_settings()
                return true
            }
        }
        return false
    }
    add_settings_listener(l) {
        this._listeners.settings.push(l)
    }
    remove_settings_listener(l) {
        this._listeners.settings = this._listeners.settings.filter((x) => x !== l)
    }
    _read_sync() {
        const syncDat = localStorage.getItem('local.sync')
        const newSync = syncDat == null ? new SyncBundle() : SyncBundle.from_json(syncDat)
        this.sync = this.sync == null ? newSync : this.sync.diff(newSync)
        return this.sync === newSync
    }
    _notify_sync() {
        this._listeners.sync.forEach((l) => l(this.sync))
    }
    get_sync() { return this.sync }
    set_sync(sync, notify=true) {
        if (sync !== this.sync) {
            this.sync = this.sync.diff(sync)
            if (this.sync === sync) {
                localStorage.setItem('local.sync', this.sync.json())
                if (notify===true) {this._notify_sync()}
                return true
            }
        }
        return false
    }
    add_sync_listener(l) {
        this._listeners.sync.push(l)
    }
    remove_sync_listener(l) {
        this._listeners.sync = this._listeners.sync.filter((x) => x !== l)
    }
}
export const localDataManager = new LocalDataManager()

const ldm_storage_event_forward = (e) => {
    localDataManager._storage_event(e)
}

class UserService {
    constructor() {
        this.sync = localDataManager.get_sync()
        console.log(this.sync.json())
        this.user = new UserInfo({user_id:0,settings:localDataManager.get_settings()})
        this.active_call = false
        this.next_call = null
        this.next_call_at = 0
        this.is_reloading = false
        this.listeners = []
        this.check_listeners = []
        this._settings_listener = (s) => this._on_settings_update(s)
        this._sync_listener = (s) => this._on_sync_update(s)
    }

    setup() {
        localDataManager.add_settings_listener(this._settings_listener)
        localDataManager.add_sync_listener(this._sync_listener)
        window.addEventListener('storage', ldm_storage_event_forward)
    }
    teardown() {
        localDataManager.remove_settings_listener(this._settings_listener)
        localDataManager.remove_sync_listener(this._sync_listener)
        window.removeEventListener('storage', ldm_storage_event_forward)
        if (this.next_call != null) {
            clearTimeout(this.next_call)
            this.next_call = null
            this.next_call_at = 0
        }
    }

    async server_sync({version_check=true, _reentrant=false} = {}) {
        if (this.active_call === true && _reentrant === false) {
            console.log('Entering active-call wait')
            await new Promise(resolve => {
                this.check_listeners.push(resolve)
            })
            console.log('Promise resolved')
            return this.user
        }
        if (this.is_reloading) {
            return this.user
        }
        this.active_call = true
        console.log('### server_sync')
        const until_ready = (this.sync.refresh_at*1000) - Date.now()
        if (until_ready < 30000) {
            console.log(`  refresh: until_ready=${until_ready}`)
            await this._do_sync()
        }
        else {
            console.log('  refresh-not-needed')
        }
        if (version_check === true && this._version_check() === true) {
            // we're reloading
            //this.active_call = false
            return this.user
        }
        this.schedule_next()
        this.active_call = false
        this.check_listeners.forEach((l) => l())
        this.check_listeners = []
        return this.user
    }

    async _do_sync() {
        //await window.navigator.locks.request('server-sync-interact', async () => {
            const until_ready = (this.sync.refresh_at*1000) - Date.now()
            if (until_ready < 30000) {
                let newSync = null
                try {
                    const res = await api.promise({path: '/wcc/sync'})
                    newSync = new SyncBundle(res.sync)
                    console.log(`NEW SYNC FROM SERVER:\n${newSync.json()}`)
                }
                catch (e) {
                    if (!this.is_reloading) {
                        // try again in ~1 minute
                        console.log(e)
                        newSync = SyncBundle.from_json(localDataManager.get_sync().json())
                        newSync.refresh_at = Math.floor(Date.now()/1000) + 90
                        console.log(`ERROR RESYNC:\n${newSync.json()}`)
                    }
                }
                finally {
                    if (newSync != null && localDataManager.set_sync(newSync, false)) {
                        this.sync = newSync
                        localDataManager._notify_sync()     // we're effectively allowing the event but ignoring it
                    }
                }
            }
            else {
                console.log('  refresh-skip')
            }
        //})
    }

    _version_check(reload_delay=50) {
        if (this.sync.remote_client_version !== config.ui.version) {
            this.is_reloading = true
            visExecutor.runWhenVisible('reload', TaskPriority.FIRST, () => {
                console.log(`sync_version: ${this.sync.remote_client_version}  ui_version: ${config.ui.version}`)
                console.log(`RELOAD`)
                setTimeout(() => window.location.reload(), reload_delay < 5 ? 5 : reload_delay)
                return false
            })
            /*console.log(`sync_version: ${this.sync.remote_client_version}  ui_version: ${config.ui.version}`)
            console.log(`RELOAD`)
            setTimeout(() => window.location.reload(), reload_delay < 5 ? 5 : reload_delay)*/
        }
        return this.is_reloading
    }

    _on_sync_update(sync) {
        if (this.sync !== sync) {
            console.log(`NEW SYNC FROM STORAGE:\n${sync.json()}`)
            this.sync = sync
            //visExecutor.runWhenVisible('server-sync', TaskPriority.FIRST, () => this.server_sync())
            this.cancel_next()
            this.server_sync()
        }
    }
    _on_settings_update(s) {
        // if the current user is anonymous...
        this.user = new UserInfo({user_id:0,settings:s})
        this._notify_user_change()
    }

    add_user_listener(l) {
        this.listeners.push(l)
    }
    remove_user_listener(l) {
        this.listeners = this.listeners.filter((x) => x !== l)
    }
    _notify_user_change() {
        this.listeners.forEach((l) => l(this.user))
    }

    schedule_next() {
        if (this.sync !== null) {
            if (this.next_call_at === this.sync.refresh_at) {
                console.log('refresh_at matches, no reschedule')
                return
            }
            const offset_sec = 10 + Math.floor(Math.random() * 20)
            const next_at = (this.sync.refresh_at-offset_sec)*1000 + Math.floor(Math.random() * 990)
            if (this.next_call != null) {
                console.log('cancel previous check')
                clearTimeout(this.next_call)
            }
            const now = Date.now()
            this.next_call_at = this.sync.refresh_at
            const ms_timeout = Math.max(next_at - now, 0)
            console.log(`schedule check at ${next_at}: ${ms_timeout}ms`)
            this.next_call = setTimeout(() => {
                //this.next_call = null
                //this.next_call_at = 0
                //visExecutor.runWhenVisible('server-sync', TaskPriority.FIRST, () => {
                    this.server_sync()
                //})
            }, ms_timeout)
            //console.log(`scheduled: at=${this.next_call_at} call=${this.next_call}`)
        }
    }

    cancel_next() {
        if (this.next_call != null) {
            console.log('cancel previous check')
            clearTimeout(this.next_call)
            this.next_call_at = 0
            this.next_call = null
        }
    }
}
export const userService = new UserService()

/*class SimpleLocker {
    constructor() {
        this.locks = {}
        this._listeners = []
        this._setup()
    }
    _setup() {
        window.addEventListener('storage', (e) => {
            if (!e.key.startsWith('lock.')) {
                return
            }
            const lstate = localStorage.getItem(e.key)
            if (lstate == null) {
                // lock released
                const entry = this.locks[e.key]
                if (entry != null && entry.length !== 0) {
                    // run each fn
                }
                this.locks[e.key] = null
            }
            else {
                // lock applied
                this.locks[e.key] = []
            }
        })
    }
    request(name, fn) {
        const fname = `lock.${name}`
        const entry = this.locks[fname]
        if (entry == null) {
            // set lock, run fn, release lock
        }
        else {
            // queue fn to run later
        }
    }
}*/
//export const localSettingsManager = new LocalSettingsManager()

export function useLocalSettings() {
    const [localSettings, setLocalSettings] = React.useState(localDataManager.get_settings())
    React.useEffect(() => {
        const cb = (s) => {
            visExecutor.runWhenVisible(null, TaskPriority.HIGH, () => {
                // Always apply curent state
                // - if we get multiple state changes while hidden, we dont want to apply them all
                setLocalSettings(localDataManager.get_settings())
            })
        }
        localDataManager.add_settings_listener(cb)
        return () => localDataManager.remove_settings_listener(cb)
    })
    return [localSettings, (s) => localDataManager.set_settings(s)]
}

export function useUserAdapter() {
    const [userInfo, setUserInfo] = React.useState(userService.user)
    React.useEffect(() => {
        const cb = (u) => {
            visExecutor.runWhenVisible(null, TaskPriority.HIGH, () => {
                // Always apply current state
                setUserInfo(userService.user)
            })
        }
        userService.add_user_listener(cb)
        return () => userService.remove_user_listener(cb)
    })
    return {user:userInfo, saveSettings:(s) => localDataManager.set_settings(s)}
}

// Bootstrap – 576px, 768px, 992px, 1200px, and 1400px.
export const DeviceLayout = {
    mobile: {id:0, minWidth:0},
    tablet: {id:1, minWidth:600},
    desktop: {id:2, minWidth:920},
    wide: {id:3, minWidth:1200},
    xwide: {id:4, minWidth:1400}
}

// smallest -> largest
const _device_layouts = [
    DeviceLayout.mobile,
    DeviceLayout.desktop
]

export function useDeviceLayout() {
    const width = useScreenWidth()
    const [layout, setLayout] = React.useState(computeLayout(width))

    React.useEffect(() => {
        const computed = computeLayout(width)
        if (computed.id !== layout.id) {
            setLayout(computed)
        }
    }, [width])
    return layout
}

function computeLayout(width) {
    for (let i = _device_layouts.length-1; i > -1; i--) {
        if (width >= _device_layouts[i].minWidth) {
            return _device_layouts[i]
        }
    }
    return DeviceLayout.mobile
}

export function getScreenWidth() {
    return window !== 'undefined' ? window.innerWidth : 0
}

export function useScreenWidth() {
    const [width, setWidth] = React.useState(getScreenWidth());

    React.useEffect(() => {
        function handleResize() {
            setWidth(getScreenWidth());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return width;
}

