
import EorzeaTime from './EorzeaTime'

const is_active_node = function(spawns, {eorzea_time} = {}) {
    const et_now = eorzea_time == null ? new EorzeaTime() : eorzea_time
    for (const spawn in spawns) {
        const start_bits = EorzeaTime.parse_eis_time(spawn.start)
        const et_start = EorzeaTime.from_parts({
            year:et_now.year, moon:et_now.moon, sun:et_now.sun,
            bell:start_bits.bells, minute:start_bits.minutes
        })
        const et_end = et_start.offset_time({mod_minute:spawn.duration})
        if (et_now.eorzea_epoch >= et_start.eorzea_epoch && et_now.eorzea_epoch <= et_end.eorzea_epoch)
            return true
    }
    return false
}

const normalize_spawns = function(transient_data) {
    if (transient_data == null) {
        return []
    }
    let nspawns = transient_data.spawns
    if (nspawns == null || nspawns.length === 0) {
        const start = transient_data.ephemeral_start
        const end = transient_data.ephemeral_end
        const dur = (end > start) ? end - start : 2400 - end - start
        nspawns = [{start:start, duration:dur}]
    }
    return nspawns
}

const get_view_spawns = function(transient_data) {
    const nspawns = normalize_spawns(transient_data)
    if (nspawns.length === 0) {
        return []
    }
    const etime = new EorzeaTime()
    const result = []
    for (let i = 0; i < nspawns.length; i++) {
        const spawn = nspawns[i]
        const start_bits = EorzeaTime.parse_eis_time(spawn.start)
        const estart = EorzeaTime.from_parts({
            year:etime.year, moon:etime.moon, sun:etime.sun, bell:start_bits.bells, minute:start_bits.minutes
        })
        const dur_bits = EorzeaTime.parse_eis_time(spawn.duration)
        const eend = estart.offset_time({mod_bell:dur_bits.bells, mod_minute:dur_bits.minutes})
        result.push({
            start: {bells: start_bits.bells, minutes: start_bits.minutes},
            end: {bells: eend.bell, minutes: eend.minute},
            raw_dur: spawn.duration
        })
    }
    return result
}

class RareNodeSchedule {
    constructor(transient_data) {
        this.transient = transient_data
        this.spawns = null
        this._build_spawns()
    }

    _build_spawns() {
        const nspawns = normalize_spawns(this.transient)
        this.spawns = []
        const etimes = []
        if (nspawns.length === 0) {
            return
        }
        const etime = new EorzeaTime()
        for (let i = 0; i < nspawns.length; i++) {
            const spawn = nspawns[i]
            const start_bits = EorzeaTime.parse_eis_time(spawn.start)
            const estart = EorzeaTime.from_parts({
                year:etime.year, moon:etime.moon, sun:etime.sun, bell:start_bits.bells, minute:start_bits.minutes
            })
            const dur_bits = EorzeaTime.parse_eis_time(spawn.duration)
            const eend = estart.offset_time({mod_bell:dur_bits.bells, mod_minute:dur_bits.minutes})
            this.spawns.push({
                start: {bell: start_bits.bells, minute: start_bits.minutes},
                end: {bell: eend.bell, minute: eend.minute},
                dur: {bells: dur_bits.bells, minutes: dur_bits.minutes},
                real_dur: estart.real_delta(eend)
            })
            etimes.push({
                start: estart,
                end: eend
            })
        }
        for (let i = 0; i < this.spawns.length; i++) {
            const j = i === this.spawns.length-1 ? 0 : i+1
            const cur = this.spawns[i]
            const next = this.spawns[j]
            cur.next_start = next.start
            const next_etime = i === this.spawns.length-1 ?
                EorzeaTime.from_parts({
                    year: etimes[j].start.year, moon: etimes[j].start.moon,
                    sun: etimes[j].start.sun+1, bell: etimes[j].start.bell, minute: etimes[j].start.minute
                })
                : etimes[j].start
            cur.real_dur_tonext = etimes[i].end.real_delta(next_etime)
        }
    }

    status(etime) {
        etime = etime == null ? new EorzeaTime() : etime
        //console.log(`eorzea_time: ${eorzea_time.format()}`)
        //console.log(`spawns: ${JSON.stringify(spawns)}`)
        let cur = etime
        let chance = 0
        const hist = []
        while(chance < 2) {
            for (let s = 0; s < this.spawns.length; s++) {
                const spawn = this.spawns[s]
                const et_start = EorzeaTime.from_parts({
                    year:cur.year, moon:cur.moon, sun:cur.sun, bell:spawn.start.bell, minute:spawn.start.minute
                })
                const et_end = et_start.offset_time({mod_bell:spawn.dur.bells, mod_minute:spawn.dur.minutes})
                if (etime.eorzea_epoch > et_end.eorzea_epoch) {
                    hist.push({start: et_start, end: et_end})
                    continue
                }
                let is_active = false
                if (etime.eorzea_epoch >= et_start.eorzea_epoch)
                    is_active = true
                const next_sun = spawn.next_start.bell <= spawn.start.bell ? cur.sun+1 : cur.sun
                const et_next = EorzeaTime.from_parts({
                    year:cur.year, moon:cur.moon, sun:next_sun, bell:spawn.next_start.bell, minute:spawn.next_start.minute
                })
                let prev_end = hist.length !== 0 ? hist[hist.length-1].end : null
                if (prev_end == null) {
                    const last = this.spawns[this.spawns.length-1]
                    const prev_sun = last.end.bell < et_start.bell ? cur.sun : cur.sun - 1
                    prev_end = EorzeaTime.from_parts({
                        year:cur.year, moon:cur.moon, sun:prev_sun, bell:last.end.bell, minute: last.end.minute
                    })
                    //console.log(`null case: ${JSON.stringify(prev_end)}`)
                }
                //console.log('??')
                return {start: et_start, end: et_end, is_active: is_active, prev_end: prev_end, next_start:et_next}
            }
            cur = EorzeaTime.from_parts({
                year:cur.year, moon:cur.moon, sun:cur.sun+1, bell:0, minute:0
            })
            chance++
        }
        return null
    }
}

const get_spawn_sig = function(transient_data) {
    const nspawns = normalize_spawns(transient_data)
    if (nspawns.length === 0) {
        return null
    }
    const part_list = [nspawns.map(v => `${v.start}:${v.duration}`)]
    return part_list.join()
}

const get_spawn_info = function(transient_data, {eorzea_time, num_results=5} = {}) {
    const nspawns = normalize_spawns(transient_data)
    if (nspawns.length === 0) {
        return []
    }
    return _do_get_spawn_info(nspawns, {eorzea_time:eorzea_time, num_results:num_results})
}

const _do_get_spawn_info = function(spawns, {eorzea_time, num_results=5} = {}) {
    const results = []
    eorzea_time = eorzea_time == null ? new EorzeaTime() : eorzea_time
    //console.log(`eorzea_time: ${eorzea_time.format()}`)
    //console.log(`spawns: ${JSON.stringify(spawns)}`)
    let cur = eorzea_time
    let done = false
    num_results = num_results < 1 ? 1 : num_results
    while(!done) {
        let prev_end = null
        for (let s = 0; s < spawns.length; s++) {
            const spawn = spawns[s]
            //console.log(`spawn: ${JSON.stringify(spawn)}`)
            const start_bits = EorzeaTime.parse_eis_time(spawn.start)
            //console.log(`spawnstart: ${spawn.start}`)
            //console.log(`start_bits: ${JSON.stringify(start_bits)}`)
            const et_start = EorzeaTime.from_parts({
                year:cur.year, moon:cur.moon, sun:cur.sun, bell:start_bits.bells, minute:start_bits.minutes
            })
            //console.log(`et_start ${et_start.format()}`)
            const dur_bits = EorzeaTime.parse_eis_time(spawn.duration)
            const et_end = et_start.offset_time({mod_bell:dur_bits.bells, mod_minute:dur_bits.minutes})
            if (eorzea_time.eorzea_epoch > et_end.eorzea_epoch)
                continue
            let is_active = false
            if (eorzea_time.eorzea_epoch >= et_start.eorzea_epoch)
                is_active = true
            results.push({start: et_start, end: et_end, is_active: is_active})
            prev_end = et_end
            if (results.length === num_results) {
                done = true
                break
            }
        }
        /*
        if (prev_end != null) {
            console.log(`prev_end is ${prev_end.format()}`)
            cur = prev_end.offset_time({mod_minute:1})
            console.log(`new cur is ${cur.format()}`)
        }
        else */
        cur = EorzeaTime.from_parts({
            year:cur.year, moon:cur.moon, sun:cur.sun+1, bell:0, minute:0
        })
    }
    return results
}

const gathnode_rare = {
    is_active_node: is_active_node,
    get_spawn_info: get_spawn_info,
    get_spawn_sig: get_spawn_sig,
    get_view_spawns: get_view_spawns,
    RareNodeSchedule: RareNodeSchedule
}

export default gathnode_rare
