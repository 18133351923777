
const loadOnce = (loadFn) => {
    return new OnceLoader(loadFn)
}

class OnceLoader {
    constructor(fn) {
        this.fn = fn
        this.fn_called = false
    }

    load() {
        if (!this.fn_called) {
            this.fn_called = true
            return this.fn()
        }
    }
}

const exp = {
    loadOnce: loadOnce
}

export default exp
