import * as React from 'react';
import {Link} from "react-router-dom";

import api from '../common/api'
import client_util from '../common/util/client_util'
import throttle from '../common/util/throttle'
import { DebugToggle } from '../components/viewbase'
import { item_colspec_default, item_colspec } from '../common/item'
import { gather_colspec_default, gather_colspec } from '../common/gathnode'
import { recipe_colspec_default, recipe_colspec } from '../common/recipe'
import { zone_colspec_default, zone_colspec } from '../common/zone'
import { quest_ref_colspec, quest_ref_colspec_nolinks } from '../common/common_colspec'
import { XTable } from '../components/xtable'
import {Page} from '../components/Page'

class PatchBaseEntities extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            payload: null,
            _poke: 0,
            debug_mode: false
        }

        let { id } = props.params
        console.log(`id = ${id}`)
        this.patch_id = id
        this.data_loader = throttle.loadOnce(() => this.loadData())
    }

    componentDidMount() {
        console.log('componentDidMount')
        this.data_loader.load()
    }

    componentDidUpdate(prevProps) {
        let rlreq = false
        if (prevProps.params.id !== this.props.params.id) {
            this.patch_id = this.props.params.id
            this.setState({payload:null})
            this.loadData()
            rlreq = true
        }
        if (prevProps.qsearch !== this.props.qsearch) {
            if (!rlreq) {
                this.setState({_poke:this.state._poke === 0 ? 1 : 0})
            }
        }
    }

    loadData() {
        api.call({path:this.getApiUrl(), handler:(result, err) => {
            if (result != null && result.payload != null) {
                this.setState({payload: result.payload})
            }
        }})
    }

    getApiUrl() {
        return null
    }

    getEntityDetail() {
        return {
            proper: 'New Unknowns',
            sub_path:'new-unknowns'
        }
    }

    getColspec() {
        return null
    }

    getTableData(entries) {
        return []
    }

    renderTable(payload) {
        const tdata = this.getTableData(payload.entries)
        const rpp = tdata.length < 50 ? null : 50
        return (
            <div style={{overflowX:'auto',maxWidth:'calc(100vw - 20px)'}}>
            <XTable tableClass='data-t' cols={this.getColspec()} data={tdata} 
                rpp={rpp} width='980px'/>
            </div>
        )
    }

    getPatch() {
        return this.state.payload == null ? null : this.state.payload.patch
    }

    set_title() {
        document.title = `${this.getEntityDetail().proper}: Patch ${this.getPatch().patch}`
    }
    render() {
        const {payload} = this.state
        if (payload == null) {
            return (<></>)
        }
        this.set_title()
        return (
            <Page canon={`patch/${this.patch_id}/${this.getEntityDetail().sub_path}`}>
            <div className='page-body'>
                {this.render_header(payload.patch)}
                {this.render_content(payload)}
                {this.render_debug(payload)}
            </div>
            </Page>
        )
    }

    render_header(patch) {
        return (
            <div>
                <h1>
                    <Link className='Clean-link' to={`/patch/${this.patch_id}`}>
                        Patch {patch.patch}
                    </Link> 
                    <span style={{marginLeft:'6px'}}>&gt;&gt; {this.getEntityDetail().proper}</span>
                </h1>
                <DebugToggle stateFn={(dbg) => this.setState({debug_mode: dbg})}/>
            </div>
        )
    }

    render_content(payload) {
        const {debug_mode} = this.state
        return debug_mode === true ? (<></>)
            : this.render_new_entities(payload)
    }

    render_new_entities(payload) {
        return payload.entries == null || payload.entries.length === 0 ? (<></>) : (
            <div style={{backgroundColor:'#141414',borderRadius:'8px',padding:'10px',margin:'10px 0px',width:'1000px'}}>
                {this.renderTable(payload)}
            </div>
        )
    }

    render_debug(payload) {
        const {debug_mode} = this.state
        return debug_mode === false ? (<></>) : (
            <div style={{justifyContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                {client_util.renderInput(payload)}
            </div>
        )
    }
}

class PatchItemsNewInt extends PatchBaseEntities {
    constructor(props) {
        super(props)
    }
    getApiUrl() {
        return `hist/patch_new_items/${this.patch_id}`
    }
    getEntityDetail() {
        return {
            proper: 'New Items',
            sub_path:'new-items'
        }
    }
    getColspec() {
        return item_colspec_default
    }
    getTableData(entries) {
        return entries.map((x) => x.item)
    }
}

class PatchItemsRemovedInt extends PatchBaseEntities {
    constructor(props) {
        super(props)
    }
    getApiUrl() {
        return `hist/patch_removed_items/${this.patch_id}`
    }
    getEntityDetail() {
        return {
            proper: 'Removed Items',
            sub_path:'removed-items'
        }
    }
    getColspec() {
        return item_colspec(true)
    }
    getTableData(entries) {
        return entries.map((x) => x.item)
    }
}

class PatchGnodesNewInt extends PatchBaseEntities {
    constructor(props) {
        super(props)
    }
    getApiUrl() {
        return `hist/patch_new_gnodes/${this.patch_id}`
    }
    getEntityDetail() {
        return {
            proper: 'New Gathering Nodes',
            sub_path:'new-gnodes'
        }
    }
    getColspec() {
        return gather_colspec_default
    }
    getTableData(entries) {
        return entries.map((x) => x.gnode)
    }
}

class PatchGnodesRemovedInt extends PatchBaseEntities {
    constructor(props) {
        super(props)
    }
    getApiUrl() {
        return `hist/patch_removed_gnodes/${this.patch_id}`
    }
    getEntityDetail() {
        return {
            proper: 'Removed Gathering Nodes',
            sub_path:'removed-gnodes'
        }
    }
    getColspec() {
        return gather_colspec({excludeLinks:true})
    }
    getTableData(entries) {
        return entries.map((x) => x.gnode)
    }
}

class PatchFspotsNewInt extends PatchBaseEntities {
    constructor(props) {
        super(props)
    }
    getApiUrl() {
        return `hist/patch_new_fspots/${this.patch_id}`
    }
    getEntityDetail() {
        return {
            proper: 'New Fishing Spots',
            sub_path:'new-fspots'
        }
    }
    getColspec() {
        return gather_colspec_default
    }
    getTableData(entries) {
        return entries.map((x) => x.fspot)
    }
}

class PatchFspotsRemovedInt extends PatchBaseEntities {
    constructor(props) {
        super(props)
    }
    getApiUrl() {
        return `hist/patch_removed_fspots/${this.patch_id}`
    }
    getEntityDetail() {
        return {
            proper: 'Removed Fishing Spots',
            sub_path:'removed-fspots'
        }
    }
    getColspec() {
        return gather_colspec({excludeLinks:true})
    }
    getTableData(entries) {
        return entries.map((x) => x.fspot)
    }
}

class PatchRecipesNewInt extends PatchBaseEntities {
    constructor(props) {
        super(props)
    }
    getApiUrl() {
        return `hist/patch_new_recipes/${this.patch_id}`
    }
    getEntityDetail() {
        return {
            proper: 'New Recipes',
            sub_path:'new-recipes'
        }
    }
    getColspec() {
        return recipe_colspec_default
    }
    getTableData(entries) {
        return entries.map((x) => x.recipe)
    }
}

class PatchRecipesRemovedInt extends PatchBaseEntities {
    constructor(props) {
        super(props)
    }
    getApiUrl() {
        return `hist/patch_removed_recipes/${this.patch_id}`
    }
    getEntityDetail() {
        return {
            proper: 'Removed Recipes',
            sub_path:'removed-recipes'
        }
    }
    getColspec() {
        return recipe_colspec({excludeLinks:true})
    }
    getTableData(entries) {
        return entries.map((x) => x.recipe)
    }
}

class PatchZonesNewInt extends PatchBaseEntities {
    constructor(props) {
        super(props)
    }
    getApiUrl() {
        return `hist/patch_new_zones/${this.patch_id}`
    }
    getEntityDetail() {
        return {
            proper: 'New Zones',
            sub_path:'new-zones'
        }
    }
    getColspec() {
        return zone_colspec_default
    }
    getTableData(entries) {
        return entries.map((x) => x.zone)
    }
}

class PatchZonesRemovedInt extends PatchBaseEntities {
    constructor(props) {
        super(props)
    }
    getApiUrl() {
        return `hist/patch_removed_zones/${this.patch_id}`
    }
    getEntityDetail() {
        return {
            proper: 'Removed Zones',
            sub_path:'removed-zones'
        }
    }
    getColspec() {
        return zone_colspec({excludeLinks:true})
    }
    getTableData(entries) {
        return entries.map((x) => x.detail)
    }
}

class PatchQuestsNewInt extends PatchBaseEntities {
    constructor(props) {
        super(props)
    }
    getApiUrl() {
        return `hist/patch_new_quests/${this.patch_id}`
    }
    getEntityDetail() {
        return {
            proper: 'New Quests',
            sub_path:'new-quests'
        }
    }
    getColspec() {
        return quest_ref_colspec
    }
    getTableData(entries) {
        return entries.map((x) => x.quest)
    }
}

class PatchQuestsRemovedInt extends PatchBaseEntities {
    constructor(props) {
        super(props)
    }
    getApiUrl() {
        return `hist/patch_removed_quests/${this.patch_id}`
    }
    getEntityDetail() {
        return {
            proper: 'Removed Quests',
            sub_path:'removed-quests'
        }
    }
    getColspec() {
        return quest_ref_colspec_nolinks
    }
    getTableData(entries) {
        return entries.map((x) => x.detail)
    }
}

export const PatchItemsNew = client_util.withUrlFeatures(PatchItemsNewInt)
export const PatchItemsRemoved = client_util.withUrlFeatures(PatchItemsRemovedInt)

export const PatchGnodesNew = client_util.withUrlFeatures(PatchGnodesNewInt)
export const PatchGnodesRemoved = client_util.withUrlFeatures(PatchGnodesRemovedInt)

export const PatchFspotsNew = client_util.withUrlFeatures(PatchFspotsNewInt)
export const PatchFspotsRemoved = client_util.withUrlFeatures(PatchFspotsRemovedInt)

export const PatchRecipesNew = client_util.withUrlFeatures(PatchRecipesNewInt)
export const PatchRecipesRemoved = client_util.withUrlFeatures(PatchRecipesRemovedInt)

export const PatchZonesNew = client_util.withUrlFeatures(PatchZonesNewInt)
export const PatchZonesRemoved = client_util.withUrlFeatures(PatchZonesRemovedInt)

export const PatchQuestsNew = client_util.withUrlFeatures(PatchQuestsNewInt)
export const PatchQuestsRemoved = client_util.withUrlFeatures(PatchQuestsRemovedInt)
