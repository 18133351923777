import * as React from 'react';
//import {Link} from "react-router-dom";

import api from '../common/api'
import client_util from '../common/util/client_util'
import imgu from '../common/imgu'
import throttle from '../common/util/throttle'
import cbase from '../common/cbase'
import {ItemRef, DebugToggle, vgap} from '../components/viewbase'
import {MapView, BasicMapView} from '../components/mapview'
import {XTable, XCol} from '../components/xtable'
import {ItemRead, render_stats} from '../common/item'
import {Page} from '../components/Page'

class ZonePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            payload: null,
            active: null,
            debug_mode: false
        }

        let { id } = props.params
        console.log(`id = ${id}`)
        this.zone_id = id

        this.info = { defMap: 0, subs: null }
        this.data_loader = throttle.loadOnce(() => this.loadData())
    }

    componentDidMount() {
        console.log('componentDidMount')
        this.data_loader.load()
    }

    componentDidUpdate(prevProps) {
        let rlreq = false
        if (prevProps.params.id !== this.props.params.id) {
            this.zone_id = this.props.params.id
            this.info = { defMap: 0 }
            this.setState({payload:null,active:null})
            this.loadData()
            rlreq = true
        }
        if (prevProps.qsearch !== this.props.qsearch) {
            if (!rlreq) {
                this._load_params()
            }
        }
    }

    loadData() {
        api.call({path:`geo/zone/${this.zone_id}`, handler:(result, err) => {
            if (result == null) {
                this.info.defMap = 0
                this.info.subs = {}
            }
            else {
                this.info.defMap = result.zone.isvirtual === false ? result.zone.id
                        : result.zone.zsubs == null || result.zone.zsubs.length === 0 ? result.zone.id
                        : result.zone.zsubs[0].id
                this.info.subs = {}
                if (result.zone.zsubs != null && result.zone.zsubs.length !== 0) {
                    result.zone.zsubs.forEach((zs) => this.info.subs[`${zs.id}`] = zs)
                }
                if (result.zone.duties != null) {
                    for (let i = 0; i < result.zone.duties.length; i++) {
                        const d = result.zone.duties[i]
                        _build_drops(d)
                    }
                }
            }
            this.setState({payload: result})
            this._load_params()
        }})
    }

    _load_params(initialLoad=false) {
        if (this.props.qsearch != null) {
            //let target = null
            const tab = this.props.qsearch.get('sub')
            if (tab != null && this.info.subs[tab] != null) {
                if (initialLoad) {
                    // eslint-disable-next-line
                    this.state.active = tab
                }
                else this.setState({active:+tab})
                //target = +tab
            }
            /*else target = this.deftab
            if (this.tabinfo[target] != null) {
                this.tabinfo[target].loadUrlParams(this.props.qsearch)
            }*/
        }
    }

    _settab(x) {
        this._update_history(x)
        this.setState({active:x})
    }

    _update_history(active=null) {
        if (active == null) {
            active = this.state.active
        }
        const target = active != null ? active : this.info.defMap
        const tabParams = target === this.info.defMap ? null : `sub=${encodeURIComponent(target)}`
        const conParams = null //this.tabinfo[target].toUrlParams()
        const params = tabParams != null && conParams != null 
            ? `${tabParams}&${conParams}`
            : tabParams != null ? tabParams : conParams
        const bpath = `/zone/${this.state.payload.zone.id}`
        const path = params == null ? bpath : `${bpath}?${params}`
        if (window.history.state !== params) {
            window.history.replaceState(params, '', path)
        }
    }

    render() {
        const {payload} = this.state
        if (payload == null) {
            return (<></>)
        }
        document.title = payload.zone.cfdname != null ? payload.zone.cfdname : payload.zone.name
        return payload == null ? (<></>) : (
            <Page canon={`zone/${this.zone_id}`}>
            <div className='page-body'>
                {this.render_header(payload.zone)}
                {this.render_body(payload)}
                {this.render_debug(payload)}
            </div>
            </Page>
        )
    }

    render_header(zone) {
        return (
            <div>
                <h1>{zone.cfdname != null ? zone.cfdname : zone.name}</h1>
                <DebugToggle stateFn={(dbg) => this.setState({debug_mode: dbg})}/>
            </div>
        )
    }

    render_body(payload) {
        const {debug_mode} = this.state
        return debug_mode === true ? (<></>) : this.render_zone(payload.zone)
    }

    render_zone(z) {
        const icon_url = z.map_path == null ? imgu.to_icon_path(imgu.static.zones)
            : `/ui/map2/${z.map_path}.webp`
        return (
            <div style={{paddingTop: '8px'}}>
                <div style={{display:'flex',marginBottom:'10px'}}>
                    <img src={icon_url} alt="" style={{width: '60px', height: '60px'}}></img>
                    <div style={{margin:'0px 20px'}}>
                        <div>{z.cfdname != null ? z.cfdname : z.name}</div>
                        {z.cfdname == null || z.cfdname === z.name ? null : <div>{z.name}</div>}
                        <div>{z.cfdlevel === 0 ? z.role : `Level ${z.cfdlevel} ${z.role}`}</div>
                    </div>
                </div>
                {/*item.stacksize > 1 ? <div style={{fontSize:'14px'}}>Stack: {item.stacksize}</div> : ''*/}
                {this.render_region(z)}
                {this.render_pduty_header(z)}
                {this.render_maps(z)}
                {this.render_pduty_drops(z)}
                {this.render_mduty_list(z)}
            </div>
        )
    }

    render_region(z) {
        return z.parent_path == null ? null : (
            <div>
                <div style={{fontWeight:'bold', margin:'6px 0px'}}>Location</div>
                <div style={{display:'flex', gap:'8px', fontSize:'14px'}}>
                    {z.parent_path.map((r) => (
                        <div key={r.id} style={{backgroundColor:'#202226',borderRadius:'4px',padding:'2px 6px'}}>
                            {r.name}
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    render_location(z) {
        let indent = 0
        return z.parent_path == null ? null : (
            <div>
                <div style={{fontWeight:'bold', margin:'6px 0px'}}>Location</div>
                <div style={{/*display:'flex', gap:'8px'*/}}>
                    {z.parent_path.map((r) => (
                        <div key={r.id} style={{/*backgroundColor:'#204270',borderRadius:'4px',padding:'2px 6px'*/marginLeft:`${16 * indent++}px`}}>
                            {r.name}
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    get_rflags(d) {
        const flags = []
        const fconf = {
            'level': 'Leveling',
            'highlevel': 'High Level',
            'msq': 'Main Scenario Quest',
            'guildhest': 'Guildhest',
            'expert': 'Expert',
            'trial': 'Trial',
            'frontline': 'Frontline',
            'levelcap': 'Level Cap',
            'mentor': 'Mentor',
            'alliance': 'Alliance Raid',
            'raid': 'Raid',
        }
        for (let i = 0; i < d.rflags.length; i++) {
            const n = fconf[d.rflags[i]]
            if (n == null) {
                console.log(`unrecognized rflag: ${d.rflags[i]}`)
                continue
            }
            flags.push((
                <div key={i} style={{fontSize:'14px',backgroundColor:'#2a3037',borderRadius:'4px',padding:'2px 6px'}}>
                    {n}
                </div>
            ))
        }
        return flags
    }

    render_roulette_flags(flags) {
        return (<div style={{display:'flex',flexWrap:'wrap',gap:'6px', maxWidth:'500px'}}>{flags}</div>)
    }

    render_roulettes(z) {
        const flags = this.get_roulette_flags(z)
        return flags.length === 0 ? (<></>) : (
                <div style={{marginTop:'6px'}}>
                    <div style={{fontWeight:'bold',marginBottom:'6px'}}>Roulettes</div>
                    {this.render_roulette_flags(flags)}
                </div>
            )
    }

    render_mduty_list(z) {
        return z.duties == null || z.duties.length < 2 ? null : (
            <div style={{margin:'18px 0px 18px 0px'}}>
                <div style={{marginBottom:'12px',fontSize:'16px',fontWeight:'bold'}}>Duties</div>
                <div style={{marginLeft:'16px'}}>
                    <table className='data-t'>
                        <thead><tr>
                            <td style={{minWidth:'140px'}}>Name</td>
                            <td style={{minWidth:'140px'}}>Job</td>
                            <td style={{minWidth:'120px'}}>Level</td>
                            <td style={{minWidth:'120px'}}>ILvl</td>
                        </tr></thead>
                        <tbody>
                            {z.duties.map((d) => (
                                <tr>
                                    <td>{d.name}</td>
                                    <td>{d.jobcat_nm}</td>
                                    <td>{d.level}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    render_pduty_header(z) {
        return z.duties == null || z.duties.length !== 1 ? null
            : this.render_duty_header(z.duties[0])
    }

    render_duty_header(d, gen_title=false) {
        /*const level = d.levelsync === 0 ? d.level
            : (<span><span style={{marginRight:'18px'}}>{d.level}</span>{`Sync: ${d.levelsync}`}</span>)
        const itemlevel = d.itemlevelsync === 0 ? d.itemlevel
            : (<span><span style={{marginRight:'18px'}}>{d.itemlevel}</span>{`Sync: ${d.itemlevelsync}`}</span>)
        */
        const level = _level_sync(d.level, d.levelsync)
        const itemlevel = _level_sync(d.itemlevel, d.itemlevelsync)
        const rflags =  this.get_rflags(d)
        const title = gen_title === false ? 'Duty'
            : d.name == null || d.name.length === 0 ? `Duty #${d.id}` : d.name
        return (
            <div key={d.id} className='x-titled-section' style={{/*width:'unset'*/}}>
                <div className='x-title' style={{fontWeight: 'bold', fontSize: '16px'}}>{title}</div>
                <table className='basic-t'><tbody>
                    <tr><td>Job</td><td>{d.jobcat_nm}</td></tr>
                    <tr><td>Level</td><td>{level}</td></tr>
                    {d.ilvl === 0 ? null : (
                        <tr><td>Item Level</td><td>{itemlevel}</td></tr>
                    )}
                    {rflags.length === 0 ? null : (
                        <tr><td>Roulettes</td><td>{this.render_roulette_flags(rflags)}</td></tr>
                    )}
                </tbody></table>
            </div>
        )
    }

    render_pduty_drops(z) {
        return z.duties == null || z.duties.length !== 1 ? null
            : _render_drops(z.duties[0].drops)
    }

    render_maps(z) {
        const maps = z.isvirtual ? z.zsubs : [z, ...z.zsubs]
        const active = this.state.active != null ? this.state.active : this.info.defMap
        const cb = (x) => this._settab(x)

        const tabs = maps.filter((m) => m.map_path != null).map((m) => _mktab(m.id, m.name != null ? m.name : z.name, active, cb))
        return tabs.length === 0 ? null : (
            <div style={{marginTop:'12px'}}>
                <h2>Maps</h2>
                <div style={{margin:'10px 0px 4px 0px'}}>
                    {tabs}
                </div>
                {active === null ? '' : _mkcontent(active, z /*, () => this._tabmodel_update()*/)}
            </div>
        )
    }

    render_debug(payload) {
        const {debug_mode} = this.state
        return debug_mode === false ? (<></>) : (
            <div style={{justifyContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                {client_util.renderInput(payload)}
            </div>
        )
    }
}

function _mktab(id, label, active, handler) {
    const tabClass = active === id ? 'tabbtnactive' : 'tabbtn'
    const displayText = label
    return (
        <span 
            className={tabClass} 
            style={{fontWeight:'bold',fontSize:'14px',borderRadius:'4px', padding:'2px 8px'}} 
            onClick={() => handler(id)}>{displayText}</span>
    )
}

function _mkcontent(id, z, handler=null) {
    const map = _find_map(z, id)

    return map == null ? null : _render_map(map)
}

function _level_sync(level, sync) {
    const lbase = (<span style={{fontSize:'16px', fontWeight:'bold'}}>{level}</span>)
    return sync === 0 || sync == null ? lbase
            : (
                <span style={{display:'flex',width:'110px'}}>
                    {lbase}
                    <span style={{flexGrow:'1'}}></span>
                    <span style={{color:'#777'}}>{`${sync} (sync)`}</span>
                </span>
            )
}

function _render_map(map) {
    return (
        <div key={map.id} style={{margin:'8px 0px 8px 0px'}}>
            {/*<div>{map.name}</div>*/}
            <div style={{marginTop:'8px'}}>
                {/*<MapView path={map.map_path} vwidth={500} vheight={500} scale={0.5}/>*/}
                <BasicMapView sz={340} path={map.map_path}/>
            </div>
        </div>
    )
}

function _find_map(z, id) {
    if (z == null) { return null }
    if (z.id === id) { return z }
    if (z.zsubs == null) { return null }
    for (let i = 0; i < z.zsubs.length; i++) {
        if (z.zsubs[i].id === id) { return z.zsubs[i] }
    }
    return null
}

const _drop_cols = [
    new XCol({
        label:'Name', hstyle:{minWidth:'140px',maxWidth:'300px'},
        compare: (i,j) => cbase.strcmp(i.name, j.name),
        render: (i) => {
            return (<ItemRef id={i.id} name={i.name} icon={i.icon} rarity={i.rarity}/>)
        }
    }),
    new XCol({
        label:'ILvl', hstyle:{},
        compare: (i,j) => cbase.numcmp(ItemRead.ilvl(i), ItemRead.ilvl(j)),
        render: (i) => vgap(4, ItemRead.ilvl(i))
    }),
    new XCol({
        label:'Req', hstyle:{},
        compare: (i,j) => cbase.numcmp(ItemRead.ulvl(i), ItemRead.ulvl(j)),
        render: (i) => vgap(4, ItemRead.ulvl(i))
    }),
    new XCol({
        label:'Type', hstyle:{},
        compare: (i,j) => cbase.strcmp(i.uicat_nm, j.uicat_nm),
        //render: (i) => i.uicat_nm
        render: (i) => vgap(3, (
            <div>
                <div style={{marginBottom:'2px'}}>{i.uicat_nm}</div>
                <div style={{marginBottom:'2px'}}>{render_stats(i)}</div>
            </div>
        ))
    }),
    new XCol({
        label:'Equip By', hstyle:{},
        compare: (i,j) => cbase.strcmp(i.jobcat_nm, j.jobcat_nm),
        //render: (i) => i.jobcat_nm
        render: (i) => vgap(4, (<span style={{fontSize:'12px'}}>{i.jobcat_nm}</span>))
    }),
    new XCol({
        label:'Source', hstyle:{},
        compare: (i,j) => _src_cmp(i, j),
        render: (i) => vgap(4, _src_render(i))
    })
]

function _src_cmp(i, j) {
    const ic = i.sources == null || i.sources.length === 0 ? null : i.sources[0]
    const jc = j.sources == null || j.sources.length === 0 ? null : j.sources[0]
    return cbase.strcmp(ic, jc)
}

function _src_render(i) {
    return (
        <div style={{color:'inherit'}}>
            {i.sources == null ? null : i.sources.map((x, i) => (<div key={i}>{x}</div>))}
        </div>
    )
}

function _render_drops(drops) {
    const nsources = drops == null ? 0 : drops.length
    return nsources === 0 ? null : (
        <div style={{marginTop:'24px'}}>
            <h2 style={{marginBottom:'0.25rem'}}>Drops</h2>
            <XTable tableClass='data-t vtop-t' cols={_drop_cols} data={drops} width={900}
                rpp={nsources > 50 ? 50 : undefined}
                idResolver={(x) => x.id}/>
        </div>
    )
}

function _build_drops(d) {
    const dmap = {}
    const drops = []
    if (d.drop_sources != null && d.drop_sources.length !== 0) {
        for (let i = 0; i < d.drop_sources.length; i++) {
            const ds = d.drop_sources[i]
            for (let j = 0; j < ds.bins.length; j++) {
                const bin = ds.bins[j]
                const bname = bin.name === '(Fixed Rate Drop)' || bin.name === '(Blue Mage Spell Acquisition)' ? null : bin.name
                const source_e = ds.type === 'coffer' ? bname : bname == null ? `${ds.name}` : `${ds.name} - ${bname}`
                for (let k = 0; k < bin.items.length; k++) {
                    const item = bin.items[k]
                    
                    if (dmap[item.id] == null) {
                        const drop = { ...item, sources: [source_e]}
                        dmap[item.id] = drop
                        drops.push(drop)
                    }
                    else {
                        dmap[item.id].sources.push(source_e)
                    }
                }
            }
        }
    }
    d.drops = drops
    delete d.drop_sources
}

const sep = () => {
    return (
        <div style={{/*width:'300px',height:'1px',backgroundColor:'#151515',*/margin:'4px 0px'}}>
        </div>
    )
}

export default client_util.withUrlFeatures(ZonePage)
