import * as React from 'react';
import {Link} from "react-router-dom";

import client_util from '../common/util/client_util'
import {Page} from '../components/Page'

function RetainerTasksPage(props) {
    React.useEffect(() => client_util.setTitle('Retainer Tasks'), [])
    return (
        <Page key='retainer-tasks' canon='retainer-tasks'>
            <div className='page-body resp'>
                <h1>Retainer Tasks</h1>
                <div style={{maxWidth:'1000px'}}>
                    <h2 style={{borderBottom:'2px solid #222427',padding:'4px 0'}}>Targeted</h2>
                    <p>
                        Targeted ventures are available at Retainer Level 1. These are short duration tasks (40m - 1h)
                        and allow you to target specific items for your retainer to collect. Combat retainers are able to target
                        materials dropped from monsters in the world, while gathering retainers are able to target materials that
                        are gatherable by their job. Note that <strong>you must have gathered an item yourself in order to
                        allow your retainer to target that item</strong>.
                    </p>
                    <div style={{display:'flex',flexWrap:'wrap',gap:'4px'}}>
                        <Link className='xbtn' to='/retainer-tasks/targeted/combat'>Combat Tasks</Link>
                        <Link className='xbtn' to='/retainer-tasks/targeted/botanist'>Botanist Tasks</Link>
                        <Link className='xbtn' to='/retainer-tasks/targeted/fisher'>Fisher Tasks</Link>
                        <Link className='xbtn' to='/retainer-tasks/targeted/miner'>Miner Tasks</Link>
                    </div>
                    <h2 style={{borderBottom:'2px solid #222427',padding:'4px 0'}}>Exploration</h2>
                    <p>
                        Exploration ventures are available at Retainer Level 1. These are long duration tasks (18h)
                        and provide rewards from a small pool of items that varies for each task. In
                        addition to the random portion of the reward, a baseline amount of gil will be rewarded in the
                        form of Allagain Pieces.
                    </p>
                    <div style={{display:'flex',flexWrap:'wrap',gap:'4px'}}>
                        <Link className='xbtn' to='/retainer-tasks/exploration/combat'>Combat Tasks</Link>
                        <Link className='xbtn' to='/retainer-tasks/exploration/botanist'>Botanist Tasks</Link>
                        <Link className='xbtn' to='/retainer-tasks/exploration/fisher'>Fisher Tasks</Link>
                        <Link className='xbtn' to='/retainer-tasks/exploration/miner'>Miner Tasks</Link>
                    </div>
                    <h2 style={{borderBottom:'2px solid #222427',padding:'4px 0'}}>Quick Exploration</h2>
                    <p>
                        Quick exploration ventures are available at Retainer Level 10. These are short duration tasks (1h)
                        and provide a random reward from a very large pool of items. Typical rewards include dungeon gear, prior tier 
                        raid and tomestone gear, materials, and venture coffers. While rare, it is also possible to receive items 
                        that can no longer be acquired by any other method (such as Pre-Calamity items).
                    </p>
                    <div>
                        <Link className='xbtn' to='/retainer-tasks/quick'>Quick Exploration Details & Rewards</Link>
                    </div>
                </div>
            </div>
        </Page>
    )
}

export default RetainerTasksPage
