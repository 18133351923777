import * as React from 'react';

import api from '../common/api'
import client_util from '../common/util/client_util'
import throttle from '../common/util/throttle'
import imgu from '../common/imgu'
import cbase from '../common/cbase'
import itemsource from '../common/itemsource'
import {ItemRef, render_gath_level, render_mini_source, DebugToggle} from '../components/viewbase'
import {Page} from '../components/Page'

const SELECT_SOURCE = false

class RecipePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            payload: null,
            debug_mode: false
        }
        let { id } = props.params
        console.log(`id = ${id}`)
        this.recipe_id = id
        this.data_loader = throttle.loadOnce(() => this.loadData())
    }

    componentDidMount() {
        console.log('componentDidMount')
        this.data_loader.load()
    }

    loadData() {
        api.call({path:`recipe/${this.recipe_id}`, handler:(result, err) => {
            if (result != null) {
                this.setState({payload:result.payload})
            }
        }})
    }

    render() {
        const {payload} = this.state
        if (payload == null) {
            return (<></>)
        }
        document.title = payload.recipe.name
        return (
            <Page canon={`recipe/${this.recipe_id}`}>
            <div className='page-body'>
                {this.render_header(payload)}
                {this.render_recipe(payload)}
                {this.render_debug_recipe(payload)}
            </div>
            </Page>
        )
    }

    render_header(payload) {
        const recipe = payload.recipe
        return (
            <div>
                <h1>{recipe.name}</h1>
                <DebugToggle stateFn={(dbg) => this.setState({debug_mode: dbg})}/>
            </div>
        )
    }

    render_recipe(payload) {
        const {debug_mode} = this.state
        const recipe = payload.recipe
        return debug_mode === true ? (<></>) : (
            <div style={{paddingTop: '8px'}}>
                <div style={{display:'flex',marginBottom:'10px'}}>
                    <img src={imgu.to_icon_path(imgu.job_icon(recipe.job))} alt="" style={{width: '60px', height: '60px'}}></img>
                    <div style={{margin:'0px 20px'}}>
                        {/*<div>{recipe.job_nm}</div>
                        <div>Level {render_gath_level(recipe.recipe_lt.joblevel, recipe.recipe_lt.stars)}</div>
                        <div>Crafting Recipe</div>*/}
                        <div>{recipe.name}</div>
                        <div>Level {render_gath_level(recipe.recipe_lt.joblevel, recipe.recipe_lt.stars)}</div>
                        <div>{recipe.job_nm} Recipe</div>
                    </div>
                </div>
                {this.render_result(payload)}
                {this.render_materials(payload)}
                {render_craft_plan(payload.plan)}
            </div>
        )
    }

    render_result(payload) {
        const result = payload.recipe.result
        return (
            <div>
                <h2>Result</h2>
                <div style={{marginLeft: '24px'}}>
                    <ItemRef id={result.id} icon={result.icon} name={result.name} 
                        rarity={result.rarity} quantity={result.amount}/>
                </div>
            </div>
        )
    }

    render_materials(payload) {
        const items = payload.recipe.materials.map(i => this.render_material(i))
        return (
            <div>
                <h2>Materials</h2>
                <table className="data-t">
                    <thead>
                        <tr><th>Item</th><th>ILvl</th><th>Type</th></tr>
                    </thead>
                    <tbody>{items}</tbody>
                </table>
            </div>
        )
    }

    render_material(item) {
        return (
            <tr key={item.id}>
                <td><ItemRef id={item.id} name={item.name} icon={item.icon} rarity={item.rarity} quantity={item.amount}/></td>
                <td>{item.ilvl}</td>
                <td>{item.uicat_nm}</td>
            </tr>
        )
    }

    render_debug_recipe(payload) {
        const {debug_mode} = this.state
        return debug_mode === false ? (<></>) : (
            <div style={{justifyContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                {client_util.renderInput(payload)}
            </div>
        )
    }
}

function render_craft_plan(plan) {
    return (
        <div style={{paddingTop: '8px'}}>
            <h2>Crafting Breakdown</h2>
            {/*<div style={{marginLeft:'16px', display:'flex', gap:'14px'}}>
                {render_rmats_section(plan)}
                {render_craft_section(plan)}
            </div>*/}
            <div style={{display:'flex', backgroundColor:'#222', borderRadius:'8px', width:'fit-content'}}>
                {render_craft_section(plan)}
                <div style={{width:'1px',backgroundColor:'#7080a040',margin:'10px 6px'}}></div>
                {render_rmats_section(plan)}
            </div>
            {/*<div style={{height:'60px'}}></div>*/}
            {/*<div style={{marginLeft:'16px',display:'flex',flexDirection:'column',alignItems:'left',gap:'10px'}}>
                <div style={{width:'fit-content'}}>{render_craft_section(plan)}</div>
                <div style={{width:'fit-content'}}>{render_rmats_section(plan)}</div>
            </div>*/}
            {/*<div style={{marginLeft:'16px', display:'flex', flexDirection:'column',backgroundColor:'#222', borderRadius:'8px', width:'fit-content'}}>
                {render_craft_section(plan)}
                <div style={{height:'1px',backgroundColor:'#7080a030',margin:'6px 10px'}}></div>
                {render_rmats_section(plan)} 
            </div>*/}
        </div>
    )
}

function render_rmats_section(plan) {
    const mats = []
    const crystals = []
    Object.values(plan.rmats).filter((m) => m.rqty !== 0).forEach((m) => {
        (m.drole === 2 ? crystals : mats).push(m)
    })
    const source_info = {sources:{},zones:{}}
    return (
        <div style={{backgroundColor:'#222', padding:'4px', borderRadius:'8px'}}>
            <table className="data-t noborder-t vtop-t balance-t">
                <thead>
                    <tr><th style={{backgroundColor:'#222',paddingBottom:'6px'}}>Raw Materials</th></tr>
                </thead>
                <tbody>
                    {render_crystals_row(crystals, 2)}
                    {mats.map((m) => render_raw_material(m, source_info))}
                </tbody>
            </table>
        </div>
    )
}

function render_craft_section(plan) {
    let craft_ct = 0
    Object.values(plan.craft).forEach((c) => craft_ct += c.mkct)
    const jnode_renders = []
    plan.craft_order.forEach((jnode) => {
        jnode_renders.push(render_jnode(plan, jnode))
    })
    return (
        <div style={{backgroundColor:'#222', padding:'4px', borderRadius:'8px'}}>
            <table className="data-t noborder-t vtop-t balance-t">
                <thead>
                    <tr><th colspan='2' style={{backgroundColor:'#222',paddingBottom:'6px'}}>
                        <div style={{display:'flex',alignItems:'center'}}>
                            <span>Craft Order</span>
                            <div style={{flexGrow:'1',minWidth:'12px'}}></div>
                            <span style={{color:'#9ac',fontWeight:'normal',fontSize:'12px'}}>{
                                craft_ct === 1 ? '' : `${craft_ct} makes`
                            }</span>
                        </div>
                    </th></tr>
                </thead>
                <tbody>
                    {jnode_renders}
                    {/*<tr><td colspan='2' style={{textAlign:'right',paddingTop:'12px'}}>
                        <span style={{color:'#89b'}}>{craft_ct} {craft_ct === 1 ? 'make' : 'makes'}</span>
                    </td></tr>*/}
                </tbody>
            </table>
        </div>
    )
}

function render_crystals_row(crystals, colspan=1) {
    return (
        <tr key='crystals'>
            <td colspan={colspan} style={{paddingBottom:'6px'}}>
                {render_crystals(crystals)}
            </td>
        </tr>
    )
}

function render_crystals(crystals) {
    return (
        <div style={{display:'flex', gap:'4px'}}>
            {crystals.map((m) => (
                <div key={m.item.id} style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                    <ItemRef id={m.item.id} icon={m.item.icon}/>
                    <div>{m.rqty}</div>
                </div>
            ))}
        </div>
    )
}

function render_raw_material(m, source_info) {
    const xs = inspect_sources(m, source_info)
    const locs = xs.source == null  ? null : xs.source.data.locations
    const loc = locs == null || locs.length === 0 ? null : locs[0]
    return (
        <tr key={m.item.id}>
            <td>
                <div>
                    <ItemRef id={m.item.id} name={m.item.name} icon={m.item.icon}
                        rarity={m.item.rarity} quantity={m.rqty}/>
                </div>
            </td>
            <td>{SELECT_SOURCE ? render_mini_source(xs.source) : null}</td>
            <td>{render_source_icons(xs)}</td>
        </tr>
    )
}

function render_source_icons(xs) {
    if (xs.icons == null) {
        return null
    }
    const src_icons = xs.icons.map((t) => (
        <img key={t} src={imgu.to_icon_path(t)} width='24px' height='24px'></img>
    ))
    return (
        <div>
            <div style={{display:'flex', gap:'2px'}}>
                {src_icons}
            </div>
        </div>
    )
}

function inspect_sources(node, source_info) {
    const sources = {}
    let types = null
    let icons = null
    let source = null
    if (node.sources != null) {
        node.sources.forEach((s) => {
            const e = sources[s.source_type]
            if (e == null) {
                sources[s.source_type] = [s]
            }
            else {
                e.push(s)
            }
            s.zkey = s.data.locations == null || s.data.locations.length === 0 ? 0 : s.data.locations[0].zone.id
            s.skey = `${s.data[s.source_type].id}-${s.zkey}`
        })

        types = Object.keys(sources)
        if (types.length !== 0) {
            types.sort((i,j) => cbase.numcmp(source_prefs[i],source_prefs[j]))
            if (SELECT_SOURCE) {
                const t = types[0]
                console.log(`select-source: ${node.item.name}`)
                source = select_source(t, sources[t], source_info)
                if (source != null) {
                    source_info.sources[source.skey] = 1
                    if (source.zkey !== 0) {
                        source_info.zones[source.zkey] = 1
                    }
                }
            }
            icons = build_source_icons(types, sources)
        }
    }
    return {types:types, icons:icons, source:source}
}

function build_source_icons(types, sources) {
    const icons = []
    types.forEach((t) => {
        if (t === 'gath_node' || t === 'fish_spot') {
            build_gath_icons(icons, sources, t)
        }
        //else if (t === 'quest') {
        //    build_quest_icons(icons, sources)
        //}
        else {
            icons.push(itemsource.icons.from_string(t))
        }
    })
    return icons
}

function build_gath_icons(icons, sources, t) {
    const iconMap = {}
    sources[t].forEach((s) => {
        const icon = s.data[t].icon
        if (iconMap[icon] == null) {
            iconMap[icon] = 1
            icons.push(icon)
        }
    })
}

/*function build_quest_icons(icons, sources) {
    const iconMap = {}
    sources['quest'].forEach((s) => {
        const icon = s.data['quest'].icon
        if (iconMap[icon] == null) {
            iconMap[icon] = 1
            icons.push(icon)
        }
    })
}*/

function select_source(type, sources, source_info) {
    if (sources.length === 1) {
        return sources[0]
    }
    if (Object.keys(source_info).length !== 0) {
        for (let i = 0; i < sources.length; i++) {
            const s = sources[i]
            if (source_info.sources[s.skey] != null) {
                console.log(`over1 ${s.data[s.source_type].name}`)
                return s
            }
            if (source_info.zones[s.zkey] != null) {
                console.log('over2')
                return s
            }
        }
    }
    if (type === 'gath_node') {
        sources.sort((i,j) => cbase.numcmp(
            i.data.gath_node.level + (i.data.gath_node.transient != null ? 10000 : 0),
            j.data.gath_node.level + (j.data.gath_node.transient != null ? 10000 : 0)
        ))
        return sources[0]
    }
    if (type == 'fish_spot') {
        sources.sort((i,j) => cbase.numcmp(
            i.data.fish_spot.level,
            j.data.fish_spot.level
        ))
        return sources[0]
    }
    // TODO - something for merchants -- ideally i get the merchant req added to item sources
    //  then i can look at that and the req on the listing -- preferring no reqs
    // for monsters, prefer those not inside of a duty
    return sources[0]
}

function render_jnode(plan, jnode) {
    return jnode.crafts.map((jc) => render_craft(plan, jc))
}

function render_craft(plan, jcraft) {
    const c = plan.craft[jcraft.item.id]
    return (
        <tr key={jcraft.item.id}>
            <td>
                <div>
                    <ItemRef id={c.item.id} name={c.item.name} icon={c.item.icon}
                        rarity={c.item.rarity} quantity={c.rqty}/>
                </div>
            </td>
            <td><div>{render_craft_hints(c, jcraft)}</div></td>
        </tr>
    )
}

function render_craft_hints(c, jcraft) {
    const r = c.make.length === 1 ? c.make[0] : c.make.filter((r) => r.id === jcraft.target)[0]
    console.log(`r.job: ${r.job}`)
    const tgt_icon = (<CraftImg key={r.job} job={r.job}/>)
    const craft_icons = c.make.length === 1 ? [tgt_icon] : [
        tgt_icon,
        ...(c.make.filter((xr) => xr.id !== jcraft.target).map(
                (xr) => (<CraftImg key={xr.job} job={xr.job}/>)
            ))
    ]
    return (
        <div>
            <div style={{display:'flex', gap:'2px'}}>{craft_icons}</div>
            <div style={{marginLeft:'8px', fontSize:'12px'}}>{c.mkct} {c.mkct === 1 ? 'make' : 'makes'}</div>
        </div>
    )
}

function CraftImg({job}) {
    return (<img key={job} src={imgu.to_icon_path(imgu.job_icon(job))} width='24px' height='24px'></img>)
}

const source_prefs = {
    gath_node: 0,
    fish_spot: 1,
    enemy: 2,
    duty: 3,
    merchant: 4,
    reduction: 5,
    coffer: 6,
    quest: 7,
    achieve: 8
}

export default client_util.withParams(RecipePage)
