import * as React from 'react';

import api from './api'
import cbase from './cbase'
import imgu from './imgu'
import {
    XStrFilter, XIntRangeFilter, XSelectFilter, 
    XBooleanFilter, XSearch,
    cleanSearch
} from '../components/xsearch'
import {
    ItemRef, vgap, render_gath_level
} from '../components/viewbase'
import {
    XCol
} from '../components/xtable'

export async function load_item_search_options() {
    const data = await Promise.all([
        api.core.get_jobs(),
        api.core.get_uicats()
    ]);
    return {
        job: { choices: data[0].payload.map((x) => { return { value: x.id, content: x.name } }) },
        uicat: { choices: data[1].payload.map((x) => { return {value: x.id, content: x.name} })},
    };
}

export const item_search_spec = {
    standard: [
        new XStrFilter('search', null, 'qstr', {placeholder:'Search for items'}),
        new XIntRangeFilter('ilvl', 'Item Level', 'min_ilvl', 'max_ilvl'),
        new XIntRangeFilter('ulvl', 'Req Level', 'min_ulvl', 'max_ulvl')
    ],
    other: [
        new XSelectFilter('uicat', 'Type', 'uicat'),
        new XSelectFilter('job', 'Job', 'job'),
        new XIntRangeFilter('mval', 'Market Value', 'mval_hmin', 'mval_hmax'),
        new XIntRangeFilter('mact', 'Market Activity', 'mact_hmin', 'mact_hmax')
    ]
}

export function ItemSearch({handler, options, optionsLoader, xsmodel}) {
    if (options == null && optionsLoader == null) {
        optionsLoader = load_item_search_options
    }
    return (
        <XSearch handler={handler} spec={item_search_spec} 
            options={options} optionsLoader={optionsLoader}
            xsmodel={xsmodel}/>
    )
}

export function render_stats(item) {
    if (item.qstat == null) {
        return null
    }
    const parts = item.qstat.split(' ')
    return (
        <div style={{display:'flex',flexWrap:'true',gap:'8px',fontSize:'12px'}}>
            {parts.map((p) => {
                const x = p.split('=')
                return (
                    <div key={x[0]}><span style={{marginRight:'3px',color:'#888'}}>{x[0]}</span>{x[1]}</div>
                )
            })}
        </div>
    )
}

export class ItemRead {
    static ilvl(item) { return item.ilvl == null ? 1 : item.ilvl }
    static ulvl(item) { return item.ulvl == null ? 1 : item.ulvl }
    static eqcat(item) { return item.equip == null ? item.jobcat_nm : item.equip.jobcat_nm }
}

export const item_colspec = (excludeLinks=false) => {
    return [
        new XCol({
            label:'Name', hstyle:{minWidth:'120px',maxWidth:'300px'},
            compare: (i,j) => cbase.strcmp(i.name, j.name),
            render: (i) => {
                return (<ItemRef id={i.id} name={i.name} icon={i.icon} rarity={i.rarity} nolink={excludeLinks}/>)
            }
        }),
        new XCol({
            label:'ILvl', hstyle:{},
            compare: (i,j) => cbase.numcmp(ItemRead.ilvl(i), ItemRead.ilvl(j)),
            render: (i) => ItemRead.ilvl(i)
        }),
        new XCol({
            label:'Req', hstyle:{},
            compare: (i,j) => cbase.numcmp(ItemRead.ulvl(i), ItemRead.ulvl(j)),
            render: (i) => ItemRead.ulvl(i)
        }),
        new XCol({
            label:'Stats', hstyle:{},
            compare: null,
            render: (i) => render_stats(i)
        }),
        new XCol({
            label:'Type', hstyle:{},
            compare: (i,j) => cbase.strcmp(i.uicat_nm, j.uicat_nm),
            render: (i) => (<div style={{fontSize:'12px'}}>{i.uicat_nm}</div>)
        }),
        new XCol({
            label:'Equip By', hstyle:{},
            compare: (i,j) => cbase.strcmp(ItemRead.eqcat(i), ItemRead.eqcat(j)),
            render: (i) => (<div style={{fontSize:'12px'}}>{ItemRead.eqcat(i)}</div>)
        }),
    ]    
}

export const item_colspec_mkt = (homeWorldName='Faerie') => {
    const mvcols = market_value_columns({homeWorldName:homeWorldName})
    const cols = [
        new XCol({
            label:'Name', hstyle:{minWidth:'120px'},
            compare: (i,j) => cbase.strcmp(
                i.name, 
                j.name
            ),
            render: (i) => {
                return (<ItemRef id={i.id} name={i.name} icon={i.icon} rarity={i.rarity}/>)
            }
        }),
        new XCol({
            label:'ILvl', hstyle:{},
            compare: (i,j) => cbase.numcmp(
                ItemRead.ilvl(i),
                ItemRead.ilvl(j)
            ),
            render: (i) => (
                <div style={{display:'flex',justifyContent:'start'}}>
                    {ItemRead.ilvl(i)}
                </div>
            )
        }),
        new XCol({
            label:'Req', hstyle:{},
            compare: (i,j) => cbase.numcmp(
                ItemRead.ulvl(i),
                ItemRead.ulvl(j)
            ),
            render: (i) => (
                <div style={{display:'flex',justifyContent:'start'}}>
                    {ItemRead.ulvl(i)}
                </div>
            )
        }),
        new XCol({
            label:'Type', hstyle:{minWidth:'80px'},
            compare: (i,j) => cbase.strcmp(
                i.uicat_nm,
                j.uicat_nm
            ),
            render: (i) => (
                <div style={{fontSize:'12px'}}>
                    <div>{i.uicat_nm}</div>
                    {render_stats(i)}
                </div>
            )
        }),
        new XCol({
            label:'Equip By', hstyle:{minWidth:'80px'},
            compare: (i,j) => cbase.strcmp(ItemRead.eqcat(i), ItemRead.eqcat(j)),
            render: (i) => (<div style={{fontSize:'12px'}}>{ItemRead.eqcat(i)}</div>)
        }),
        ...mvcols.cols
    ]
    return {
        cols: cols,
        update_world: (name) => mvcols.update_world(name)
    }
}

export const gather_item_colspec = (homeWorldName='Faerie') => {
    const mvcols = market_value_columns({homeWorldName:homeWorldName})
    const cols = [
        new XCol({
            label:'Item',
            compare: (i,j) => cbase.strcmp(
                i.name, 
                j.name
            ),
            render: (i) => {
                return (<ItemRef id={i.id} name={i.name} icon={i.icon} rarity={i.rarity}/>)
            }
        }),
        new XCol({
            label:'ILvl',
            compare: (i,j) => cbase.numcmp(
                ItemRead.ilvl(i),
                ItemRead.ilvl(j)
            ),
            render: (i) => ItemRead.ilvl(i)
        }),
        new XCol({
            label:'GLvl',
            compare: (i,j) => cbase.gathlvlcmp(i.glevel, i.stars, j.glevel, j.stars),
            render: (i) => render_gath_level(i.glevel, i.stars)
        }),
        new XCol({
            label:'',
            compare: null,
            render: (i) => !i.ishidden ? '' : (
                <div className='tag-min-fadeblue'>Hidden</div>
            )
        }),
        ...mvcols.cols
    ]
    return {
        cols: cols,
        update_world: (name) => mvcols.update_world(name)
    }
}

export function market_value_columns({homeWorldName, item_fn}) {
    item_fn = item_fn != null ? item_fn : (x) => x
    const cols = [
        new XCol({
            label:'Value', 
            hstyle:{minWidth:'142px',width:'144px',maxWidth:'158px'},
            compare: (i,j) => cmp_agg_mprice(item_fn(i), item_fn(j)),
            render: (i) => render_home_prices(item_fn(i)),
            span: {
                size:2,
                /*sep:'default',*/
                justify:'right',
                header: () => (<MarketHomeBanner homeWorldName={homeWorldName}/>)
            }
        }),
        new XCol({
            label:(<span className="material-icon icon18">acute</span>)/*'Act'*/,
            compare: (i,j) => cmp_agg_velo(item_fn(i), item_fn(j)),
        }),
    ]
    const mvcol = cols[0]
    return {
        cols: cols,
        update_world: (name) => {
            mvcol.span.header = () => (<MarketHomeBanner homeWorldName={name}/>)
        }
    }
}

export function market_value_currency_columns({homeWorldName}={}) {
    const cols = [
        new XCol({
            label:(<div style={{paddingBottom:'1px'}}>Value</div>), 
            hstyle:{minWidth:'142px',width:'144px',maxWidth:'158px'},
            compare: (i,j) => cmp_mprice(i.item, i.market_qual, j.item, j.market_qual),
            render: (i) => vgap(6, render_home_price(i.item, i.market_qual)),
            span: {
                size:2,
                /*sep:'default',*/
                justify:'right',
                header: () => (<MarketHomeBanner homeWorldName={homeWorldName}/>)
            }
        }),
        new XCol({
            label:(<span className="material-icon icon18">acute</span>),
            compare: (i,j) => cmp_velo(i.item, i.market_qual, j.item, j.market_qual),
        }),
        new XCol({
            label:(<div style={{paddingBottom:'1px'}}>Each</div>), 
            hstyle:{minWidth:'0px'},
            compare: (i,j) => cbase.numcmp(i.per_cur, j.per_cur), //cmp_mprice(i.item,j.item, i.market_qual),
            render: (i) => vgap(4, render_per_cur(i.per_cur)),
            span: {
                justify:'right',
                leadingSep: true,
                /*sep: (key) => (
                    <div key={key} className={'sep-bg'} style={{width:'1px', margin:'2px 10px 2px 0px'}}></div>
                ),*/
                header: () => (
                    <div className='th-unpad-top bg-dark-violet' style={{fontSize:'12px'}}>
                        <span>&nbsp;</span>
                    </div>
                )
            }
        }),
    ]
    const mvcol = cols[0]
    return {
        cols: cols,
        update_world: (name) => {
            mvcol.span.header = () => (<MarketHomeBanner homeWorldName={name}/>)
        }
    }
}

export function reward_market_value_columns({homeWorldName, item_fn, qt_fn}) {
    item_fn = item_fn != null ? item_fn : (x) => x
    qt_fn = qt_fn != null ? qt_fn : (x) => x.qt
    
    const cols = [
        new XCol({
            label:'Value', 
            hstyle:{minWidth:'142px',width:'144px',maxWidth:'158px'},
            compare: (i,j) => cbase.numcmp(item_fn(i)._hrv, item_fn(j)._hrv),
            render: (i) => render_home_reward_value(item_fn(i), qt_fn(i)),
            span: {
                size:2,
                /*sep:'default',*/
                justify:'right',
                header: () => (<MarketHomeBanner homeWorldName={homeWorldName}/>)
            }
        }),
        new XCol({
            label:(<span className="material-icon icon18">acute</span>)/*'Act'*/,
            compare: (i,j) => {
                const item_i = item_fn(i)
                const item_j = item_fn(j)
                return cmp_velo(
                    item_i, item_quality(item_i),
                    item_j, item_quality(item_j)
                )
            },
        }),
    ]
    const mvcol = cols[0]
    return {
        cols: cols,
        update_world: (name) => {
            mvcol.span.header = () => (<MarketHomeBanner homeWorldName={name}/>)
        }
    }
}

export function simple_reward_market_value_columns({homeWorldName}) {
    const cols = [
        new XCol({
            label:null, 
            hstyle:{minWidth:'142px',width:'144px',maxWidth:'158px',fontSize:'0.75rem'},
            render: (i) => render_home_reward_value(i),
            span: {
                size:1,
                justify:'right',
                header: () => (<MarketHomeHeader homeWorldName={homeWorldName}/>)
            }
        }),
    ]
    const mvcol = cols[0]
    return {
        cols: cols,
        update_world: (name) => {
            mvcol.span.header = () => (<MarketHomeHeader homeWorldName={name}/>)
        }
    }
}

export function MarketHomeLabel({homeWorldName='Faerie'}) {
    return (
        <span style={{display:'flex',alignItems:'end',gap:'0px',padding:'0px',margin:'0px'}}>
            <span style={{flexGrow:'0'}}></span>
            <span>Market</span>
            {homeWorldName == null ? null : (
                <React.Fragment>
                <span style={{width:'6px'}}></span>
                <span style={{fontSize:'12px',fontWeight:'normal',color:'#999'}}>{homeWorldName}</span>
                </React.Fragment>
            )}
            
        </span>
    )
}

export function MarketHomeBanner({homeWorldName='Faerie', backgroundColor, fontSize='12px'}) {
    const extra = homeWorldName == null ? '' : ` - ${homeWorldName}`
    return (
        <div className='th-unpad-top-right bg-dark-violet' style={{
            padding: '0px 6px', backgroundColor:backgroundColor, fontSize:fontSize
        }}>
            <span style={{color:'#999'}}>Market{extra}</span>
        </div>
    )
}

export function MarketHomeHeader({homeWorldName='Faerie'}) {
    const extra = homeWorldName == null ? '' : ` - ${homeWorldName}`
    return (
        <div className='' style={{
            padding:'0px 6px', fontSize:'0.75rem',margin:'-4px -8px -4px 0px'
        }}>
            <div>Market{extra}</div>
        </div>
    )
}

// i / j are items
export function cmp_agg_mprice(i, j, quality=Quality.both) {
    const p_i = agg_mprice(i, quality)
    const p_j = agg_mprice(j, quality)
    return cbase.numcmp(p_i == null ? 0 : p_i, p_j == null ? 0 : p_j)
}

function agg_mprice(i, quality=Quality.both) {
    if (i.market == null) {
        return null
    }
    if (quality === Quality.nq) {
        return i.market.mprice_nq
    }
    if (quality === Quality.hq) {
        return i.market.mprice_hq
    }
    const has_nq = i.market.mprice_nq != null && i.market.mprice_nq !== 0
    const has_hq = i.market.mprice_hq != null && i.market.mprice_hq !== 0
    if (has_nq && has_hq) {
        if (i.qstat != null) {
            return i.market.mprice_hq
        }
        return (i.market.mprice_nq + i.market.mprice_hq) / 2
    }
    if (has_nq) {
        return i.market.mprice_nq
    }
    if (has_hq) {
        return i.market.mprice_hq
    }
    return null
}

export function cmp_mprice(i, i_qual, j, j_qual) {
    const i_val = market_prop(i, i_qual === Quality.hq ? 'mprice_hq' : 'mprice_nq')
    const j_val = market_prop(j, j_qual === Quality.hq ? 'mprice_hq' : 'mprice_nq')
    return cbase.numcmp(i_val, j_val)
}

export function cmp_agg_velo(i, j, quality=Quality.both) {
    const i_velo = agg_velo(i)
    const j_velo = agg_velo(j)
    return cbase.numcmp(i_velo, j_velo)
}

function agg_velo(i) {
    if (i.market == null) {
        return null
    }
    const nq = market_prop(i, 'vel_nq')
    const hq = market_prop(i, 'vel_hq')
    return (nq == null ? 0 : nq) + (hq == null ? 0 : hq)
}

export function cmp_velo(i, i_qual, j, j_qual) {
    const i_val = market_prop(i, i_qual === Quality.hq ? 'vel_hq' : 'vel_nq')
    const j_val = market_prop(j, j_qual === Quality.hq ? 'vel_hq' : 'vel_nq')
    return cbase.numcmp(i_val, j_val)
}

export function market_prop(i, prop) {
    if (i.market == null || i.market[prop] == null || i.market[prop] === 0) {
        return null
    }
    return i.market[prop]
}

export const Quality = {
    both: 0,
    nq: 1,
    hq: 2,
}

export function item_quality(i) {
    return i.hq === true ? Quality.hq : Quality.nq
}

export function render_home_price(i, quality) {
    if (i.market == null || (quality !== Quality.nq && quality !== Quality.hq)) {
        return null
    }
    const ind = quality === Quality.nq ? (<div style={{width:'12px',height:'12px'}}></div>)
        : (<img src={imgu.to_icon_path(imgu.static.hq16)} alt='hq' height='12px'></img>)
    const mprice = market_prop(i, quality === Quality.nq ? 'mprice_nq' : 'mprice_hq')
    if (mprice == null) {
        return null
    }
    const vel = market_prop(i, quality === Quality.nq ? 'vel_nq' : 'vel_hq')
    return (
        <div style={{}}>
            <div style={{display:'flex',flexDirection:'column',alignItems:'stretch',backgroundColor:'#202226',padding:'0px 4px',borderRadius:'4px'}}>
                <div style={{display:'flex',alignItems:'center'}}>
                    {ind}
                    <div style={{flexGrow:'1',minWidth:'10px'}}></div>
                    <div style={{paddingRight:'10px'}}>{mprice.toLocaleString()}</div>
                    {render_velocity(vel)}
                </div>
            </div>
        </div>
    )
}

function render_per_cur(per_cur) {
    if (per_cur == null) {
        return null
    }
    return (
        <div style={{display:'flex'}}>
            <div style={{flexGrow:'1'}}></div>
            <div>{float_quantity(per_cur)}</div>
        </div>
    )
}

export function render_home_prices(i, quality=Quality.both) {
    if (i.market == null) {
        return null
    }
    const mprice_nq = market_prop(i, 'mprice_nq')
    const mprice_hq = market_prop(i, 'mprice_hq')
    const vel_nq = market_prop(i, 'vel_nq')
    const vel_hq = market_prop(i, 'vel_hq')
    if (mprice_nq == null && mprice_hq == null) {
        return null
    }
    return (
        <div style={{}}>
            <div style={{display:'flex',flexDirection:'column',alignItems:'stretch',backgroundColor:'#202226',padding:'0px 4px',borderRadius:'4px'}}>
                {mprice_hq == null || quality === Quality.nq ? null : (
                    <div style={{display:'flex',alignItems:'center'}}>
                        <img src={imgu.to_icon_path(imgu.static.hq16)} alt='hq' height='12px'></img>
                        <div style={{flexGrow:'1',minWidth:'10px'}}></div>
                        <div style={{paddingRight:'10px',alignItems:'center'}}>{mprice_hq.toLocaleString()}</div>
                        {render_velocity(vel_hq)}
                    </div>
                )}
                {mprice_nq == null || quality === Quality.hq ? null : (
                    <div style={{display:'flex',alignItems:'center'}}>
                        <div style={{flexGrow:'1'}}></div>
                        <div style={{color:mprice_hq == null || quality === Quality.nq ? 'inherit' : '#686868',paddingRight:'10px'}}>
                            {mprice_nq.toLocaleString()}
                        </div>
                        {render_velocity(vel_nq)}
                    </div>
                )}
            </div>
        </div>
    )
}

export function set_home_reward_value(i, quantity) {
    const mprice = market_prop(i, i.hq === true ? 'mprice_hq' : 'mprice_nq')
    if (mprice != null) {
        i._hrv = mprice * quantity
    }
}

export function render_home_reward_value(i, quantity, {minWidth=null}={}) {
    if (i.market == null) {
        return null
    }
    const ind = i.hq === true ? (<img src={imgu.to_icon_path(imgu.static.hq16)} alt='hq' height='12px'></img>)
        : (<div style={{width:'12px',height:'12px'}}></div>)
    const mprice = market_prop(i, i.hq === true ? 'mprice_hq' : 'mprice_nq')
    const vel = market_prop(i, i.hq === true ? 'vel_hq' : 'vel_nq')
    if (mprice == null) {
        return null
    }
    const qt = quantity != null ? quantity : 1
    const tprice = qt * mprice
    return (
        <div style={{display:'flex',flexDirection:'column',alignItems:'stretch',
            backgroundColor:'#202226',padding:'0px 4px',borderRadius:'4px',minWidth:minWidth}}>
            <div style={{display:'flex',alignItems:'center'}}>
                {ind}
                <div style={{flexGrow:'1',minWidth:'10px'}}></div>
                <div style={{paddingRight:'10px',alignItems:'center'}}>{tprice.toLocaleString()}</div>
                {render_velocity(vel)}
            </div>
            {qt === 1 ? null : (
                <div style={{display:'flex',alignItems:'center'}}>
                    <div style={{flexGrow:'1'}}></div>
                    <div style={{color:'#686868',paddingRight:'10px'}}>
                        {mprice.toLocaleString()}
                    </div>
                    <div style={{color:'#686868',width:'32px',minWidth:'32px',fontSize:'0.75rem'}}>
                        each
                    </div>
                </div>
            )}
        </div>
    )
}

// Unused
/*export function render_home_cur_prices(i, per_cur, quality) {
    if (i.market == null || per_cur == null || per_cur === 0 || (quality !== Quality.nq && quality !== Quality.hq)) {
        return null
    }
    const ind = quality === Quality.nq ? (<div style={{width:'12px',height:'12px'}}></div>)
        : (<img src={imgu.to_icon_path(imgu.static.hq16)} alt='hq' height='12px'></img>)
    const vel = market_prop(i, quality === Quality.nq ? 'vel_nq' : 'vel_hq')
    return (
        <div style={{}}>
            <div style={{display:'flex',flexDirection:'column',alignItems:'stretch',backgroundColor:'#202226',padding:'0px 4px',borderRadius:'4px'}}>
                <div style={{display:'flex',alignItems:'center'}}>
                    {ind}
                    <div style={{flexGrow:'1',minWidth:'10px'}}></div>
                    <div style={{paddingRight:'8px'}}>{float_quantity(per_cur)}</div>
                    {render_velocity(vel)}
                </div>
            </div>
        </div>
    )
}*/

export function render_velocity(velo) {
    if (velo == null) {
        velo = 0
    }
    const width = 30
    const pct = velo >= 10 ? 1.0 : velo / 10.0
    const xwidth = Math.floor(width * pct)
    const small =  velo < 0.5
    return (
        <div className={small ? 'border-violet-dark' : 'border-violet1'} style={{
            width:`${width}px`,height:'6px',backgroundColor:'inherit',borderRadius:'2px',display:'flex'
        }}>
            <div className={small ? 'bg-violet-dark' : 'bg-violet1'} style={{width:`${xwidth}px`,height:'100%'}}></div>
            <div></div>
        </div>
    )
}

export function float_quantity(value) {
    let as_str = `${value.toLocaleString('en-US')}`
    const dot = as_str.lastIndexOf('.')
    if (dot == -1) {
        return `${as_str}.00`
    }
    else if (as_str.length - dot > 3) {
        return as_str.slice(0, dot+3)
    }
    else {
        const cur_dec_length = as_str.length - dot - 1
        const zc = 2 - cur_dec_length
        for (let i = 0; i < zc; i++) {
            as_str = `${as_str}0`
        }
        return as_str
    }
}

export function calc_per_currency_price(i, quantity, quality, cur_ct) {
    if (i.market == null) {
        return null
    }
    //console.log(`i: ${JSON.stringify(i, null, 2)}`)
    const mprice = market_prop(i, `mprice_${quality === Quality.hq ? 'hq' : 'nq'}`)
    //console.log(`mprice: ${mprice}`)
    //console.log(`cur_ct: ${cur_ct}`)
    return Math.floor((mprice*quantity / cur_ct) * 100) / 100
}

function _render_price(i, prop) {
    const val = market_prop(i, prop)
    if (val == null) {
        return null
    }
    //return val.toLocaleString()
    return (
        <div style={{display:'flex',justifyContent:'start'}}>
            {val.toLocaleString()}
        </div>
    )
}

export const item_colspec_compact1 = ({
    extra_cols=null, item_fn=null, excludeLinks=false, excludeStats=false
}={}) => {
    const base_cols = [
        new XCol({
            label:'Name', hstyle:{},
            compare: (i,j) => cbase.strcmp(
                (item_fn == null ? i : item_fn(i)).name, 
                (item_fn == null ? j : item_fn(j)).name
            ),
            render: (i) => {
                const item = item_fn == null ? i : item_fn(i)
                return (
                    <ItemRef id={item.id} name={item.name} icon={item.icon} rarity={item.rarity} nolink={excludeLinks}/>
                )
            }
        }),
        new XCol({
            label:'ILvl', hstyle:{},
            compare: (i,j) => cbase.numcmp(
                ItemRead.ilvl(item_fn == null ? i : item_fn(i)), 
                ItemRead.ilvl(item_fn == null ? j : item_fn(j))
            ),
            render: (i) => (
                <div style={{marginTop:'3px'}}>{ItemRead.ilvl(item_fn == null ? i : item_fn(i))}</div>
            )
        }),
        new XCol({
            label:'Req', hstyle:{},
            compare: (i,j) => cbase.numcmp(
                ItemRead.ulvl(item_fn == null ? i : item_fn(i)), 
                ItemRead.ulvl(item_fn == null ? j : item_fn(j))
            ),
            render: (i) => (
                <div style={{marginTop:'3px'}}>{ItemRead.ulvl(item_fn == null ? i : item_fn(i))}</div>
            )
        }),
        new XCol({
            label:'Detail', hstyle:{},
            compare: (i,j) => cbase.strcmp(
                (item_fn == null ? i : item_fn(i)).uicat_nm,
                (item_fn == null ? j : item_fn(j)).uicat_nm
            ),
            render: (i) => {
                const item = item_fn == null ? i : item_fn(i)
                return (
                    <div style={{marginTop:'4px', fontSize:'12px'}}>
                        <div style={{color:'#888'}}>{item.uicat_nm}</div>
                        <div style={{color:'#888'}}>{ItemRead.eqcat(item)}</div>
                        {excludeStats ? null : (<div>{render_stats(item)}</div>)}
                    </div>
                )
            }
        }),
        /*new XCol({
            label:'Equip By', hstyle:{},
            compare: (i,j) => cbase.strcmp(
                ItemRead.eqcat(item_fn == null ? i : item_fn(i)),
                ItemRead.eqcat(item_fn == null ? j : item_fn(j))
            ),
            render: (i) => null
        }),*/

    ]
    return extra_cols == null ? base_cols : [...base_cols, ...extra_cols]
}

export const item_colspec_compact2 = ({
    extra_cols=null, item_fn=null, excludeLinks=false, excludeStats=false
}={}) => {
    const base_cols = [
        new XCol({
            label:'Name', hstyle:{},
            compare: (i,j) => cbase.strcmp(
                (item_fn == null ? i : item_fn(i)).name, 
                (item_fn == null ? j : item_fn(j)).name
            ),
            render: (i) => {
                const item = item_fn == null ? i : item_fn(i)
                return (
                    <ItemRef id={item.id} name={item.name} icon={item.icon} rarity={item.rarity} nolink={excludeLinks}/>
                )
            }
        }),
        new XCol({
            label:'Detail', hstyle:{},
            compare: (i,j) => cbase.strcmp(
                (item_fn == null ? i : item_fn(i)).uicat_nm, 
                (item_fn == null ? j : item_fn(j)).uicat_nm
            ),
            render: (i) => {
                const item = item_fn == null ? i : item_fn(i)
                const ilvl = ItemRead.ilvl(item)
                const ulvl = ItemRead.ulvl(item)
                return (
                    <div style={{marginTop:'6px', fontSize:'12px'}}>
                        <div style={{color:'#888'}}>{item.uicat_nm}</div>
                        <div style={{display:'flex',gap:'8px',color:'#888'}}>
                            {ilvl === 1 ? null : (
                                <span>ILvl {ilvl}</span>
                            )}
                            {ulvl === 1 ? null : (
                                <span>Req {ulvl}</span>
                            )}
                        </div>
                        <div style={{color:'#888'}}>{ItemRead.eqcat(item)}</div>
                        {excludeStats ? null : (<div>{render_stats(item)}</div>)}
                    </div>
                )
            }
        }),
    ]
    return extra_cols == null ? base_cols : [...base_cols, ...extra_cols]
}

export const item_colspec_default = item_colspec()

export const reward_colspec_base = [
    new XCol({
        label:'Reward', hstyle:{},
        render: (i) => {
            return (
                <ItemRef id={i.id} name={i.name} icon={i.icon} rarity={i.rarity} quantity={i.qt}/>
            )
        }
    }),
/*    new XCol({
        label:'ILvl', hstyle:{},
        render: (i) => (
            <div style={{marginTop:'3px'}}>{ItemRead.ilvl(i)}</div>
        )
    }),
    new XCol({
        label:'Type', hstyle:{},
        render: (i) => {
            return (
                <div style={{marginTop:'4px', fontSize:'0.75rem',color:'#888'}}>
                    {i.uicat_nm}
                </div>
            )
        }
    }),*/
]

export const reward_colspec = (homeWorldName='Faerie') => {
    const mvcols = simple_reward_market_value_columns({homeWorldName:homeWorldName})
    const cols = [
        ...reward_colspec_base,
        ...mvcols.cols
    ]
    return {
        cols: cols,
        update_world: (name) => mvcols.update_world(name)
    }
}

export const leve_reward_colspec_base = [
    new XCol({
        label:'Reward',hstyle:{},
    }),
    new XCol({
        label:'Chance',hstyle:{},
    })
]

export const leve_reward_colspec = (homeWorldName='Faerie') => {
    const mvcols = simple_reward_market_value_columns({homeWorldName:homeWorldName})
    const cols = [
        ...leve_reward_colspec_base,
        ...mvcols.cols
    ]
    return {
        cols: cols,
        update_world: (name) => mvcols.update_world(name)
    }
}

export const job_reward_colspec_base = [
    new XCol({
        label:'Job',hstyle:{},
    }),
    new XCol({
        label:'Reward'
    })
]

export const job_reward_colspec = (homeWorldName='Faerie') => {
    const mvcols = simple_reward_market_value_columns({homeWorldName:homeWorldName})
    const cols = [
        ...job_reward_colspec_base,
        ...mvcols.cols
    ]
    return {
        cols: cols,
        update_world: (name) => mvcols.update_world(name)
    }
}
