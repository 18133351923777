import * as React from 'react';
import {useLocation} from "react-router-dom";

import api from '../common/api'
import client_util from '../common/util/client_util'
import imgu from '../common/imgu'
import throttle from '../common/util/throttle'
import cbase from '../common/cbase'
import itemsource from '../common/itemsource'
import { ItemRef, NpcRef, DebugToggle, render_source_header, render_source_locations, ListingCost, vgap } 
from '../components/viewbase'
import { item_colspec_default, item_colspec_compact1, item_colspec_compact2 } from '../common/item'
import { 
    gather_source_colspec, enemy_source_colspec, duty_source_colspec, 
    reduction_source_colspec, achieve_source_colspec, coffer_source_colspec, quest_source_colspec
} from '../common/common_colspec'
import { XTable, XCol } from '../components/xtable'
import {PatchUpdEntities} from './PatchUpdEntities'

class PatchItemsUpdInt extends PatchUpdEntities {
    constructor(props) {
        super(props)
    }
    getApiUrl() {
        return `hist/patch_updated_items/${this.patch_id}`
    }
    getEntityDetail() {
        return {
            proper: 'Updated Items',
            sub_path:'updated-items'
        }
    }
    renderEntity(entry) {
        return (<ItemRef id={entry.item.id} name={entry.item.name} icon={entry.item.icon} rarity={entry.item.rarity}/>)
    }
    getPropChangeBaseColspec() {
        return item_colspec_compact2({item_fn: (x) => x.entry.item})
    }
    onPayload(payload) {
        console.log('onPayload')
        build_source_updates(payload.entries)
    }
    renderExtra(payload) {
        // we want:
        // level1 mapped by unique source id (variant-id in case of enemy)
        // level2 mapped by NEW/DEL
        // level3 mapped by item id, containing list of detail_entries
        //
        //  eg: {
        //      '1033456': {
        //          source_type: merchant,
        //          data: merchant: {...without the item-specific details...},
        //          change_types: {
        //              NEW: {
        //                  35460: [{item:snippet, source:source-like}]
        //              }
        //          }
        //      }  
        //  }
        return (<SourceDetail entries={payload.entries}/>)
    }
}

class SourceDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            src_entries: props.entries == null ? [] : props.entries.filter((e) => e.srcup != null),
            vd: null
        }
        this.colspec = [
            ...item_colspec_compact1({item_fn:(x)=>x.item}),
            new XCol({
                label: 'Sources', hstyle:{},
                compare: null,
                render: (i) => _render_source_col(i, (vd) => this.setState({vd: vd}))
            })
        ]
    }
    componentDidUpdate(prevProps) {
        if (this.props.entries !== prevProps.entries) {
            this.setState({src_entries: this.props.entries == null ? [] : this.props.entries.filter((e) => e.srcup != null)})
        }
    }
    render() {
        const {src_entries, vd} = this.state
        return src_entries == null || src_entries.length === 0 ? null : (
            <div style={{backgroundColor:'#141414',borderRadius:'8px',padding:'10px',margin:'10px 0px',width:'1000px'}}>
                <div style={{fontSize:'18px',marginBottom:'10px'}}>Source Changes</div>
                {this.render_items(src_entries)}
                <ItemSourceDetail 
                    target={vd == null ? null : vd.target} 
                    ientry={vd == null ? null : vd.ientry} 
                    onClose={() => this.setState({vd:null})}/>
            </div>
        )
    }
    render_items(entries) {
        const rpp = entries.length < 50 ? null : 50
        return (
            <XTable tableClass='data-t vtop-t' cols={this.colspec} data={entries} 
                rpp={rpp} width='980px'/>
        )
    }
}

// '#6a7' : '#a67'
const _source_colspec = [
    ...item_colspec_compact1({item_fn:(x)=>x.item}),
    new XCol({
        label: 'Sources', hstyle:{},
        compare: null,
        render: (i) => _render_source_col(i)
    })
]
function _render_source_col(i, onViewDetail) {
    const types = i.srcup.types
    const srcs = Object.keys(types).map((t) => {
        const new_ct = Object.keys(types[t].new).length
        const rem_ct = Object.keys(types[t].rem).length
        const infos = []
        if (new_ct > 0) {
            infos.push((<span key='new'>+{new_ct}</span>))
        }
        if (rem_ct > 0) {
            infos.push((<span key='rem' style={{color:'#a67'}}>-{rem_ct}</span>))
        }
        const info = (<div style={{display:'flex',gap:'6px'}}>{infos}</div>)
        return (
            <div key={t} className='srcbtn' style={{display:'flex',gap:'4px',alignItems:'center'}}
                onClick={() => onViewDetail({target:t, ientry:i})}
            >
                <img src={imgu.to_icon_path(types[t].icon)} width='28px' height='28px'></img>
                <div>{info}</div>
            </div>
        )
    })
    return (<div style={{display:'flex',gap:'10px'}}>{srcs}</div>)
}

function build_source_updates(entries) {
    let count = 0
    entries.forEach((e) => {
        e.detail.forEach((d) => {
            if (d.facet === 'source') {
                if (e.srcup == null) {
                    e.srcup = {
                        types: {},
                        listing_merch: {}
                    }
                }
                const stype_id = get_source_typeid(d)
                let tentry = e.srcup.types[stype_id]
                if (tentry == null) {
                    tentry = {
                        new: {},
                        rem: {},
                        icon: itemsource.get_source_icon(d),
                        source_type: d.source_type,
                        entries: []
                    }
                    e.srcup.types[stype_id] = tentry
                }
                if (d.source_type !== 'merchant') {
                    tentry.entries.push(d)
                }
                mk_source_id(d)
                const src_id = d.source_id
                if (d.type === 'NEW') {
                    tentry.new[src_id] = 1
                }
                else {
                    tentry.rem[src_id] = 1
                }
                if (d.source_type === 'merchant') {
                    const listing_sig = `${d.data.sig.split('-')[1]}-${d.type}`
                    let list_entry = e.srcup.listing_merch[listing_sig]
                    if (list_entry == null) {
                        list_entry = {
                            type: d.type,
                            sig: listing_sig,
                            merchants: []
                        }
                        e.srcup.listing_merch[listing_sig] = list_entry
                    }
                    list_entry.merchants.push(d.data)
                }
            }
        })
        count++
    })
    console.log(JSON.stringify(entries[1], null, 2))
    //console.log(`SOURCE_CHECK: ${count} items`)
}

function get_source_typeid(d) {
    if (d.source_type === 'gath_node') {
        return `gath_node-${d.transient != null ? 'rare' : 'cmn'}`
    }
    if (d.source_type === 'fish_spot') {
        return `fish_spot-${d.is_rare === true ? 'rare' : 'cmn'}`
    }
    return d.source_type
}

function mk_source_id(entry) {
    if (entry.source_id == null) {
        const vid = entry.data[entry.source_type].vid
        const id = vid != null ? vid : entry.data[entry.source_type].id
        entry.source_id = `${entry.source_type}-${id}`
    }
}

function ItemSourceDetail({target, ientry, onClose}) {
    React.useEffect(() => {
        document.body.style.overflow = target != null ? 'hidden' : 'unset'
        return () => document.body.style.overflow = 'unset'
    }, [target])

    return target == null || ientry == null ? null : (
        <div style={{
            position:'fixed',top:'0px',left:'0px',width:'100%',height:'100vh',
            backgroundColor:'#00000070',zIndex:'2000'
        }} onClick={onClose}>
            <div style={{marginTop:'120px'}}>
                <ItemSourceCore target={target} ientry={ientry} onClose={() => onClose()}/>
            </div>
        </div>
    )
}

function ItemSourceCore({target, ientry, onClose}) {
    return (
        <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <div style={{width:'850px',backgroundColor:'#282c34',padding:'20px',borderRadius:'6px'}}
                onClick={(e) => e.stopPropagation()}
            >
                <div style={{marginBottom: '14px'}}>
                    <span style={{fontSize: '18px', fontWeight: 'bold'}}>
                        Source Changes for {ientry.item.name}
                    </span>
                </div>
                {render_item_sourcechgs(target, ientry, onClose)}
            </div>
        </div>
    )
}

// target is a srcup.types entry name
// ientry is the entire entry for the item (x.item, x.srcup, etc)
function render_item_sourcechgs(target, ientry, onClose) {
    const xtar = ientry.srcup.types[target]
    const sc_body = target === 'merchant' ? render_shop_sourcechgs(ientry, xtar) : render_other_sourcechgs(target, ientry, xtar)
    return (
        <div>
            <div style={{margin:'12px 0px'}}>
                <div style={{maxHeight:'60vh',overflowY:'auto',backgroundColor:'#181818',padding:'6px'}}>
                    {sc_body}
                </div>
                <div style={{display:'flex',gap:'10px', marginTop:'14px'}}>
                    <div style={{flexGrow:'1'}}></div>
                    <button className='xbtn' onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    )
}

function render_shop_sourcechgs(ientry, xtar) {
    const new_listings = []
    const rem_listings = []
    Object.values(ientry.srcup.listing_merch).forEach((lmerch) => {
        if (lmerch.type === 'NEW') { new_listings.push(lmerch) }
        else { rem_listings.push(lmerch) }
    })
    const new_sections = new_listings.map((lmerch) => (
        <ItemSourceListingSection key={lmerch.sig} lmerch={lmerch} available={true}/>
    ))
    const rem_sections = rem_listings.map((lmerch) => (
        <ItemSourceListingSection key={lmerch.sig} lmerch={lmerch} available={false}/>
    ))
    return (
        <div>
            <div>{new_sections}</div>
            <div>{rem_sections}</div>
        </div>
    )
}

function ItemSourceListingSection({lmerch, available}) {
    const entries = lmerch.merchants
    const listing = entries[0].merchant.listing
    const shead = (
        <div style={{padding:'6px 0px 0px 8px',fontSize:'14px'}}>
            <ListingCost listings={[listing]} suppressShopCat={true}/>
        </div>
    )
    const sinfo = (
        <div style={{margin:'4px 0px 0px 40px',padding:'0px 0px 4px 0px', fontSize:'14px',
            color:available ? '#6a7' : '#a67'
        }}>
            {available ? `Now available (${entries.length})` : `No longer available (${entries.length})`}
        </div>
    )
    const rpp = entries.length < 20 ? null : 20
    return (
        <React.Fragment>
            <div style={{backgroundColor:'#34384450',display:'flex',alignItems:'center'}}>
                {shead}
                {sinfo}
            </div>
            <div style={{paddingBottom:'40px'}}>
                <XTable tableClass='data-t vtop-t clean-t' cols={_colspecs.merchant} data={entries} 
                    rpp={rpp} width='820px'/>
            </div>
        </React.Fragment>
    )
}

function render_other_sourcechgs(target, ientry, xtar) {
    const new_entries = []
    const rem_entries = []
    for (let i = 0; i < xtar.entries.length; i++) {
        const e = xtar.entries[i]
        if (e.type === 'NEW') { new_entries.push(e) }
        else { rem_entries.push(e) }
    }
    return (
        <React.Fragment>
            {new_entries.length === 0 ? null : (
                <ItemSourceBasicSection target={target} xtar={xtar} available={true} isExpandable={false}/>
            )}
            {rem_entries.length === 0 ? null : (
                <ItemSourceBasicSection target={target} xtar={xtar} available={false} isExpandable={false}/>
            )}
        </React.Fragment>
    )
}

function ItemSourceBasicSection({target, xtar, available, isExpandable=true, initExpanded=false}={}) {
    const [expanded, setExpanded] = React.useState(initExpanded === true || isExpandable === false ? true : false)
    
    const entries = xtar.entries.filter(available ? (d) => d.type === 'NEW' : (d) => d.type !== 'NEW')
    const shead = (<img src={imgu.to_icon_path(xtar.icon)} width='40px' height='40px'></img>)
    const sinfo = (
        <div style={{margin:'4px 0px 0px 40px',padding:'0px 0px 4px 0px', fontSize:'14px',
            color:available ? '#6a7' : '#a67'
        }}>
            {available ? `Now available (${entries.length})` : `No longer available (${entries.length})`}
        </div>
    )
    const colspec = get_source_colspec(target)
    const rpp = entries.length < 20 ? null : 20
    return (
        <React.Fragment>
            {isExpandable !== true ? (
                <div style={{backgroundColor:'#34384450',display:'flex',alignItems:'center'}}>
                    {shead}
                    {sinfo}
                </div>
            ) : (
                <div style={{backgroundColor:'#34384450',display:'flex',alignItems:'center'}}>
                    {shead}
                    {sinfo}
                    <div style={{flexGrow:'1'}} onClick={(e) => setExpanded(!expanded)}></div>
                    <button className='cleanbtn' onClick={(e) => setExpanded(!expanded)}>
                        <span className='material-icon icon20'>
                            {expanded === true ? 'expand_less' : 'expand_more'}
                        </span>
                    </button>
                </div>
            )}
            {expanded === false ? (
                <div style={{minHeight:'20px'}}></div>
            ) : (
                <div style={{paddingBottom:'40px'}}>
                    <XTable tableClass='data-t vtop-t clean-t' cols={colspec} data={entries} 
                        rpp={rpp} width='820px'/>
                </div>
            )}
        </React.Fragment>
    )
}

const _merchant_source_colspec = [
    new XCol({
        label:'Name', hstyle:{width:'280px'},
        compare: (i,j) => cbase.strcmp(i.merchant.name, j.merchant.name),
        render: (i) => {
            return (
                <NpcRef id={i.merchant.id} name={i.merchant.name} icon={imgu.static.shop}/>
            )
        }
    }),
    new XCol({
        label:'Location', hstyle:{width:'320px'},
        compare: loccmp_source_special,
        render: (i) => vgap(4, render_source_locations(i.locations))
    }),
    /*new XCol({
        label:'Shop', hstyle:{},
        //compare: (i,j) => cbase.strcmp(i.d.source_type, j.d.source_type),
        render: (i) => vgap(4, (<div style={{fontSize:'12px',color:'#888'}}>{i.merchant.listing.shop_cat}</div>))
    })*/
]
function loccmp_source_special(i, j) {
    const ix = i.locations == null || i.locations.length == 0 ? null : i.locations[0]
    const jx = j.locations == null || j.locations.length == 0 ? null : j.locations[0]
    return cbase.loccmp(ix, jx)
}

const _colspecs = {
    gather: gather_source_colspec,
    enemy: enemy_source_colspec,
    duty: duty_source_colspec,
    merchant: _merchant_source_colspec,
    reduction: reduction_source_colspec,
    coffer: coffer_source_colspec,
    achieve: achieve_source_colspec,
    quest: quest_source_colspec
}
function get_source_colspec(target) {
    if (_colspecs[target] != null) {
        return _colspecs[target]
    }
    if (target.startsWith('gath_node') || target.startsWith('fish_spot')) {
        return _colspecs.gather
    }
    return null
}

/*
function render_old_sources(payload) {
    const nsources = build_normal_sources(payload.entries)
    const source_list = [...Object.values(nsources)]
    source_list.sort((i,j) => cbase.strcmp(
        i.data[i.source_type].name, j.data[j.source_type].name
    ))
    const section_models = []
    source_list.forEach((x) => source_section_models(x).forEach((m) => section_models.push(m)))
    const controls = []
    for (let i = 0; i < section_models.length; i++) {
        const m = section_models[i]
        controls.push((<SourceSection x={m.source} available={m.available} initExpanded={i === 0 || section_models.length < 4}/>))
    }
    return (
        <div>
            <div style={{backgroundColor:'#181818',borderRadius:'8px',padding:'10px',margin:'10px 0px',width:'1000px'}}>
                <div style={{fontSize:'18px',marginBottom:'10px'}}>Source Changes</div>
                {controls}
            </div>
        </div>
    )
}

function source_section_models(x) {
    const models = []
    const has_new = x.change_types.NEW != null && Object.keys(x.change_types.NEW).length !== 0
    const has_del = x.change_types.DEL != null && Object.keys(x.change_types.DEL).length !== 0
    if (has_new) {
        models.push({source:x, available:true})
    }
    if (has_del) {
        models.push({source:x, available:false})
    }
    return models
}

function SourceSection({x, available, isExpandable=true, initExpanded=false}={}) {
    const [expanded, setExpanded] = React.useState(initExpanded === true || isExpandable === false ? true : false)
    const entries = available ? x.change_types.NEW : x.change_types.DEL
    const tdata = []
    Object.values(entries).forEach((elements) => elements.forEach((elem) => {
        tdata.push(elem)
    }))
    const shead = render_source_header(x)
    const sinfo = (
        <div style={{margin:'4px 0px 0px 30px',paddingBottom:'4px', fontSize:'14px'}}>
            <span style={{marginLeft:'4px', color:available ? '#6a7' : '#a67'}}>
                {available ? `Now available (${tdata.length})` : `No longer available (${tdata.length})`}
            </span>
        </div>
    )
    return (
        <React.Fragment>
            {isExpandable !== true ? (
                <div style={{backgroundColor:'#34384450',display:'flex'}}>
                    {shead}
                    {sinfo}
                </div>
            ) : (
                <div style={{backgroundColor:'#34384450',display:'flex'}}>
                    {shead}
                    {sinfo}
                    <div style={{flexGrow:'1'}} onClick={(e) => setExpanded(!expanded)}></div>
                    <button className='cleanbtn' onClick={(e) => setExpanded(!expanded)}>
                        <span className='material-icon icon20'>
                            {expanded === true ? 'expand_less' : 'expand_more'}
                        </span>
                    </button>
                </div>
            )}
            {expanded === false ? (
                <div style={{minHeight:'20px'}}></div>
            ) : (
                <div style={{paddingBottom:'40px'}}>
                    {render_source_items(x, tdata)}
                </div>
            )}
        </React.Fragment>
    )
}

function render_source_items(x, tdata) {
    const rpp = tdata.length < 30 ? null : 30
    const colspec = colspec_by_sourcetype(x)
    return (
        <XTable tableClass='data-t vtop-t clean-t' cols={colspec} data={tdata} 
            rpp={rpp} width='980px'/>
    )
}

function colspec_by_sourcetype(x) {
    const stype = itemsource.sourcet[x.source_type]
    if (stype === itemsource.sourcet.merchant) {
        return item_colspec_compact1({item_fn:(x)=>x.item,extra_cols:_merchant_colspec_extra})
    }
    //if (stype === itemsource.sourcet.duty) {
    //}
    return item_colspec_compact1({item_fn:(x)=>x.item})
}

const _merchant_colspec_extra = [
    new XCol({
        label: 'Listing', hstyle:{},
        compare: null,
        render: (i) => {
            const s = i.source
            return (<ListingCost listings={[s.data.merchant.listing]}/>)
        }
    })
]

function build_normal_sources(entries) {
    const root = {}
    entries.forEach((e) => {
        e.detail.forEach((d) => {
            if (d.facet === 'source') {
                mk_source_id(d)
                let src_entry = root[d.source_id]
                if (src_entry == null) {
                    src_entry = {
                        source_type: d.source_type,
                        data: d.data,
                        change_types: {}
                    }
                    root[d.source_id] = src_entry
                }
                let chgt_entry = src_entry.change_types[d.type]
                if (chgt_entry == null) {
                    chgt_entry = {}
                    src_entry.change_types[d.type] = chgt_entry
                }
                let item_entry = chgt_entry[e.item_id]
                if (item_entry == null) {
                    chgt_entry[e.item_id] = [{item:e.item, source:d}]
                }
                else {
                    item_entry.push({item:e.item, source:d})
                }
            }
        })
    })
    return root
}
*/

const PatchItemsUpdated = client_util.withUrlFeatures(PatchItemsUpdInt)
export default PatchItemsUpdated
