import * as React from 'react';

import cbase from './cbase'
import imgu from './imgu'
import { GameRef } from '../components/viewbase'
import { XCol } from '../components/xtable'

const task_collect = 0
const task_explore = 1
const task_quick = 2

const jc_combat = 34

export const retainertask_source_colspec = [
    new XCol({
        label:'Name',hstyle:{},
        compare: (i,j) => cbase.strcmp(i.data.rtask.name, j.data.rtask.name),
        render: (i) => (<GameRef icon={imgu.static.retainer} name={i.data.rtask.name} linkTo={taskLinkPath(i.data.rtask)}/>)
    }),
    new XCol({
        label:'Job',hstyle:{},
        compare: (i,j) => cbase.strcmp(i.data.rtask.jobret, j.data.rtask.jobret),
        render: (i) => i.data.rtask.jobret
    }),
    new XCol({
        label:'Level',hstyle:{},
        compare: (i,j) => cbase.numcmp(i.data.rtask.level, j.data.rtask.level),
        render: (i) => i.data.rtask.level
    }),
    new XCol({
        label:'Req',hstyle:{},
        render: (i) => render_task_req(i.data.rtask)
    }),
    new XCol({
        label:'Duration',hstyle:{},
        compare: (i,j) => cbase.numcmp(i.data.rtask.maxtimemin, j.data.rtask.maxtimemin),
        render: (i) => render_task_time(i.data.rtask)
    })
]

function render_task_req(task) {
    let content = null
    if (task.jobcat === jc_combat) {
        if (task.req_ilvl != null && task.req_ilvl > 0) {
            content = `ILvl ${task.req_ilvl}`
        }
    }
    else if (task.req_gather != null && task.req_gather > 0) {
        content = `Gather ${task.req_gather}`
    }
    return (content == null) ? null : (
        <div style={{color:'#888'}}>{content}</div>
    )
}

function render_task_time(task) {
    const hours = Math.floor(task.maxtimemin / 60)
    const mins = task.maxtimemin % 60
    const parts = []
    if (hours > 0) {
        parts.push(`${hours}h`)
    }
    if (mins > 0) {
        parts.push(`${mins}m`)
    }
    return parts.join(' ')
}

function taskLinkPath(task) {
    switch(task.task_type) {
        case task_collect:
            return `/retainer-tasks/targeted/${task.jobret.toLowerCase()}`
        case task_explore:
            return `/retainer-tasks/exploration/${task.jobret.toLowerCase()}`
        case task_quick:
            return '/retainer-tasks/quick'
        default:
            return null
    }
}
