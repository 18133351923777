import * as React from 'react';

import api from '../common/api'
import client_util from '../common/util/client_util'
import throttle from '../common/util/throttle'
import cbase from '../common/cbase'
import {zone_colspec_default} from '../common/zone'
import {XTable, XTableModel} from '../components/xtable'
import {XStrFilter, XSelectFilter, XBooleanFilter, XSearch, XSearchModel} from '../components/xsearch'
import {DebugToggle} from '../components/viewbase'
import {Page} from '../components/Page'

class ZoneSearchPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            payload: null,
            records: null,
            options: null,
            debug_mode: false,
        }
        this.rpp = 50
        this.xsm = XSearchModel.parseUrl(_zs_spec, this.props.qsearch)
        this.xtm = XTableModel.parseUrl(this.props.qsearch)

        this.data_loader = throttle.loadOnce(() => this._run_query())
    }

    componentDidMount() {
        console.log('componentDidMount')
        this.data_loader.load()
        client_util.setTitle('Zone Database')
    }

    componentDidUpdate(prevProps) {
        if (this.props.qsearch !== prevProps.qsearch) {
            this.xsm = XSearchModel.parseUrl(_zs_spec, this.props.qsearch)
            this.xtm = XTableModel.parseUrl(this.props.qsearch)
            this._run_search()
        }
    }

    render() {
        const {payload} = this.state
        return (
            <Page>
            <div className='page-body'>
                {this.render_header()}
                {this.render_search_controls()}
                {this.render_data(payload)}
                {this.render_debug_data(payload)}
            </div>
            </Page>
        )
    }

    render_header() {
        return (
            <div>
                <h1>Zone Database</h1>
                <DebugToggle stateFn={(dbg) => this.setState({debug_mode: dbg})}/>
            </div>
        )
    }

    render_search_controls() {
        return (
            <ZoneSearch 
                handler={(e) => this._search_updated(e)}
                options={this.state.options}
                xsmodel={this.xsm}/>
        )
    }

    _search_updated(e) {
        this.xsm = e
        this.xtm = new XTableModel()
        this._update_history()
        this._run_search()
    }

    _table_updated(e) {
        this.xtm = e
        this._update_history()
    }

    _update_history() {
        const xsmParams = this.xsm.toUrlParams(_zs_spec)
        const xtmParams = this.xtm.toUrlParams()
        const qparams = (xsmParams != null && xtmParams != null)
            ? `${xsmParams}&${xtmParams}`
            : xsmParams != null ? xsmParams : xtmParams
        console.log(qparams)
        if (window.history.state != qparams) {
            window.history.replaceState(qparams, '', qparams == null ? '/zones' : `/zones?${qparams}`)
        }
    }

    _run_query() {
        api.call({
            path:'geo/index', method:'GET',
            handler:(result, err) => {
                const geod = result == null || result.geo == null ? [] : result.geo
                if (geod != null) {
                    geod.forEach((r) => {
                        r.sname = r.cfdname != null ? r.cfdname.toLowerCase()
                            : r.name.toLowerCase()
                    })
                }
                this.setState({
                    payload: result,
                    options: _build_search_options(result),
                    records: _do_search(result, this.xsm)
                })
            }
        })
    }

    _run_search() {
        const {payload} = this.state
        const recs = _do_search(payload, this.xsm)
        this.setState({records:recs})
    }

    render_data(payload) {
        const {debug_mode} = this.state
        return debug_mode === true ? (<></>) : (
            <div>
                <div style={{paddingTop: '8px'}}>
                    {
                        payload == null ? ''
                            : <XTable tableClass='data-t' cols={zone_colspec_default} data={this.state.records} 
                                rpp={this.rpp} width='900px'
                                handler={(e) => this._table_updated(e)} xtmodel={this.xtm}/>
                    }
                </div>
            </div>
        )
    }

    render_debug_data(payload) {
        const {debug_mode} = this.state
        return debug_mode === false ? (<></>) : (
            <div style={{justifyContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                {client_util.renderInput(payload)}
            </div>
        )
    }
}

function _do_search(payload, xsm) {
    if (payload == null || payload.geo == null) {
        return []
    }
    const checks = []
    // loop through xsm.search properties to create checks
    for (const prop in xsm.search) {
        const val = xsm.search[prop]
        if (val != null) {
            if (prop === 'qstr') {
                const vlow = val.toLowerCase()
                checks.push((z) => z.sname.indexOf(vlow) !== -1)
            }
            else if (prop === 'region_id') {
                const val_int = parseInt(val)
                console.log(val_int)
                checks.push((z) => _region_check(z, val_int))
            }
            else if (prop === 'is_duty') {
                const val_int = parseInt(val)
                checks.push((z) => val_int === 0 ? z.cfdid === 0 : z.cfdid !== 0)
            }
        }
    }
    if (checks.length === 0) {
        //console.log('no checks')
        return payload.geo
    }
    //console.log(`${checks.length} checks`)
    return payload.geo.filter((z) => checks.filter((c) => !c(z)).length === 0)
}

function _region_check(z, region_id) {
    if (z.parent_path != null) {
        for (let i = 0; i < z.parent_path.length; i++) {
            const r = z.parent_path[i]
            if (r.id === region_id) {
                return true
            }
        }
    }
    return false
}

function _build_search_options(payload) {
    if (payload != null && payload.geo != null) {
        console.log('notnull')
        const regionMap = {}
        const regions = []
        payload.geo.forEach((z) => {
            if (z.parent_path != null) {
                z.parent_path.forEach((r) => {
                    if (regionMap[r.id] == null) {
                        regionMap[r.id] = true
                        regions.push({value:r.id, content:r.name})
                        console.log(`pushed ${r.name}`)
                    }
                })
            }
        })
        regions.sort((i,j) => cbase.strcmp(i.content, j.content))

        return {
            region: { choices: regions }
        }
    }
    return null
}

const _zs_spec = {
    standard: [
        new XStrFilter('search', null, 'qstr', {placeholder:'Search for zones'}),
        new XSelectFilter('region', 'Region', 'region_id'),
    ],
    other: [
        new XBooleanFilter('duty', 'Duty', 'is_duty')
    ]
}
function ZoneSearch({handler, options, optionsLoader, xsmodel}) {
    return (<XSearch handler={handler} spec={_zs_spec} options={options} optionsLoader={optionsLoader} xsmodel={xsmodel}/>)
}

export default client_util.withQSearch(ZoneSearchPage)
