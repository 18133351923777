import * as React from 'react';
import {useLocation} from 'react-router-dom';

export const Dropdown = ({id, button, content}) => {
    const location = useLocation()
    const [open, setOpen] = React.useState(false)
    const docClick = (e) => {
        if (!e.target.closest(`#${id}`) && open) {
            setOpen(false)
        }
    }
    React.useEffect(() => {
        document.addEventListener('click', docClick)
        return () => document.removeEventListener('click', docClick)
    })
    React.useEffect(() => {
        setOpen(false)
    }, [location])
    return (
        <div id={id} style={{position:'relative',display:'inline-block'}}>
            {React.cloneElement(button, {onClick:() => setOpen(!open)})}
            {!open ? (<></>) : (
                <div style={{position:'absolute',width:'max-content',boxShadow:'0px 8px 16px 0px rgba(0,0,0,0.2)',zIndex:'10'}}>
                    {content}
                </div>
            )}
        </div>
    )
}
