import * as React from 'react';

import api from '../common/api'
import client_util from '../common/util/client_util'
import throttle from '../common/util/throttle'
import cbase from '../common/cbase'
import imgu from '../common/imgu'
import {
    reward_colspec, reward_colspec_base, 
    leve_reward_colspec, leve_reward_colspec_base,
    job_reward_colspec, job_reward_colspec_base,
    render_home_reward_value
} from '../common/item'
import {qkind_display} from '../common/quest'
import {XTable, XCol} from '../components/xtable'
import {to_simple_loc, render_location, render_jobcat_lg, ItemRef, NpcRef, QuestRef, vgap, DebugToggle} from '../components/viewbase'
import {MapPoint, MapView, BasicMapView} from '../components/mapview'
import { useLocalSettings } from '../common/user'
import {Page} from '../components/Page'

function QuestPage(props) {
    const [settings] = useLocalSettings()

    return (<QuestPageCore {...props} settings={settings}/>)
}

class QuestPageCore extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            quest: null,
            debug_mode: false
        }
        let { id } = props.params
        console.log(`id = ${id}`)
        this.quest_id = id
        //this.colspec = _mk_colspec()
        this.data_loader = throttle.loadOnce(() => this.loadData())
    }

    componentDidMount() {
        console.log('componentDidMount')
        this.data_loader.load()
    }

    componentDidUpdate(prevProps) {
        let rlreq = false
        if (this.props.params.id !== prevProps.params.id) {
            this.quest_id = this.props.params.id
            this.loadData()
            rlreq = true
        }
        if (prevProps.settings.homeWorld().id !== this.props.settings.homeWorld().id) {
            if (!rlreq) {
                //this.setState({payload:null})
                this.loadData()
                rlreq = true
            }
        }
    }

    loadData() {
        const homeWorld = this.props.settings.homeWorld()
        api.call({path:`quest/${this.quest_id}?world=${homeWorld.id}`, handler:(result, err) => {
            if (err) {
                console.log(err)
                return
            }
            const quest = _process_quest(result.payload)
            this.setState({quest: quest})
        }})
    }

    render() {
        const {quest} = this.state
        if (quest == null) {
            return (<></>)
        }
        document.title = quest.name
        return (
            <Page canon={`quest/${this.quest_id}`}>
            <div className='page-body'>
                {this.render_header(quest)}
                {this.render_body(quest)}
                {this.render_debug(quest)}
            </div>
            </Page>
        )
    }

    render_header(quest) {
        return (
            <div>
                <h1>{quest.name}</h1>
                <DebugToggle stateFn={(dbg) => this.setState({debug_mode: dbg})}/>
            </div>
        )
    }

    // #0078d4
    render_body(quest) {
        const {debug_mode} = this.state
        if (debug_mode === true) {
            return (<></>)
        }
        return (
            <div>
                <div style={{display:'flex',marginBottom:'10px',alignItems:'center'}}>
                    <img src={imgu.to_icon_path(quest.icon)} alt="" style={{width: '60px', height: '60px'}}></img>
                    <div style={{margin:'0px 20px'}}>
                        <div>{quest.name}</div>
                        {/*<div>Level {quest.req.level}</div>*/}
                        <div>{qkind_display(quest.qkind)}</div>
                    </div>
                </div>
                {this.render_info(quest)}
                {this.render_reqs(quest)}
                {this.render_starter(quest.starter)}
                {/*this.render_quest_flow(quest)*/}
                {this.render_rewards(quest)}
            </div>
        )
    }

    render_info(quest) {
        const rpt = quest.repeat === 1 ? 'daily'
            : quest.repeat === 2 ? 'weekly'
            : quest.qkind === 4 ? 'when offered'
            : null
        const has_info = rpt != null
        return !has_info ? null : (
            <div style={{marginTop:'10px',fontSize:'0.88rem'}}>
                <div style={{fontWeight:'bold'}}>Repeatable {rpt}</div>
            </div>
        )
    }

    render_reqs(quest) {
        const spreq = gcreq_info(quest) || tribereq_info(quest)
        console.log(`spreq = ${spreq}`)
        return (
            <div style={{marginTop:'10px',fontSize:'0.88rem'}}>
                <div style={{fontSize:'1.125rem'}}>Level {quest.req.level}</div>
                <div style={{color:'#999'}}>{render_jobcat_lg(quest.req.jobcat_nm)}</div>
                {spreq == null ? null : (
                    <div style={{marginTop:'0.25rem'}}>
                        <SpreqView d={spreq}/>
                    </div>
                )}
                {quest.prev == null || quest.prev.length === 0 ? null : (
                    <div style={{marginTop:'1rem',width:'fit-content',minWidth:'180px'}}>
                        {render_link_quests(quest.prev, 'Completed Quests')}
                    </div>
                )}
            </div>
        )
    }

    render_starter(starter) {
        const sname = (
            <div style={{fontSize:'1.0625rem'}}>{starter.type !== 'npc' ? starter.name : (
                <NpcRef id={starter.id} name={starter.name}/>
            )}</div>
        )
        const starter_text = (
            <div className='x-section-mini' style={{marginBottom:'0.5rem',display:'flex',flexDirection:'column',gap:'0.75rem 1.5rem'}}>
                {sname}
                <div style={{fontSize:'0.9375rem'}}>
                    {starter.loc == null ? null : render_location(to_simple_loc(starter.loc))}
                </div>
            </div>
        )
        let starter_map = null
        if (starter.loc != null) {
            const loc = starter.loc
            const zd = starter.loc.zd
            if (zd != null) {
                const vcoords = loc.coords == null ? null : cbase.map_view_coords(loc.coords, zd.sizef)
                /*<MapView iheight={2048} iwidth={2048} vheight={204} vwidth={204} scale={.1} path={zd.map}
                    points={[new MapPoint(
                        imgu.static.map_marker.circle_green, vcoords.x, vcoords.y,
                        {icon_size:imgu.static.map_marker.circle_iconsz}
                    )]}
                />*/
                starter_map = zd.map == null ? null : (
                    <BasicMapView sz={340} path={zd.map} points={[
                        new MapPoint(
                            imgu.static.map_marker.circle_green, vcoords.x, vcoords.y,
                            {icon_size:imgu.static.map_marker.circle_iconsz}
                        )
                    ]}/>
                )
            }
        }
        return (
            <div style={{marginTop:'1.12rem'}}>
                <h2>Quest Starter</h2>
                {starter_text}
                {starter_map}
                {/*<div style={{marginLeft:'1rem',display:'flex',flexDirection:'row',gap:'2rem'}}>
                    {starter_map}
                    {starter_text}
                </div>*/}
            </div>
        )
    }

    render_quest_flow(quest) {
        const has_prev = quest.prev != null && quest.prev.length !== 0
        const has_next = quest.next != null && quest.next.length !== 0
        if (!has_prev && !has_next) {
            return null
        }
        return (
            <div style={{marginTop:'1.125rem'}}>
                <div style={{fontSize:'1.125rem',fontWeight:'bold',paddingBottom:'0.5rem'}}>Quest Flow</div>
                <div style={{display:'flex',flexDirection:'column',justifyContent:'stretch',gap:'1rem',marginLeft:'1rem',width:'fit-content'}}>
                    {has_prev === false ? null : render_link_quests(quest.prev, 'Required')}
                    {/*!has_prev || !has_next ? null : (<div style={{minHeight:'100%',background:'#222',minWidth:'1px'}}></div>)*/}
                    {has_next === false ? null : render_link_quests(quest.next, 'Unlocked')}
                </div>
            </div>
        )
    }

    render_rewards(quest) {
        const homeWorld = this.props.settings.homeWorld()
        const r = quest.reward
        const main = render_main_rewards(quest, homeWorld)
        const opt = render_opt_rewards(r, homeWorld)
        const job = render_job_rewards(r, homeWorld)
        const leve = render_leve_rewards(r, homeWorld)
        const questunl = quest.next == null || quest.next.length === 0 ? null : (
            <div style={{marginTop:'1rem',width:'fit-content',minWidth:'180px'}}>
                {render_link_quests(quest.next, 'Unlocked Quests')}
            </div>
        )
        if (main == null && opt == null && job == null && leve == null && questunl == null) {
            return null
        }
        return (
            <div style={{marginTop:'24px'}}>
                <h2>Rewards</h2>
                <div style={{margin:'8px 0px 0px 0px',fontSize:'0.88rem'}}>
                    {main}
                    {opt}
                    {job}
                    {leve}
                    {questunl}
                </div>
            </div>
        )
    }

    render_debug(quest) {
        const {debug_mode} = this.state
        return debug_mode === false ? (<></>) : (
            <div style={{justifyContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                {client_util.renderInput(quest)}
            </div>
        )
    }
}

function render_link_quests(qlist, label) {
    const rows = qlist.map((q) => (
        <tr key={q.id}>
            <td><QuestRef id={q.id} name={q.name} icon={q.icon}/></td>
        </tr>
    ))
    return (
        <div>
            <div style={{background:'#222',fontSize:'0.875rem',fontWeight:'bold',padding:'0.25rem 0.4rem'}}>{label}</div>
            <div style={{/*maxHeight:'10rem',overflowY:'scroll'*/}}>
                <table className='data-t clean-t'>
                    <tbody>{rows}</tbody>
                </table>
            </div>
        </div>
    )
}

function render_main_rewards(quest, homeWorld) {
    // exp, gil, rep (tribe or gc)
    // items
    const r = quest.reward
    const exp = r.exp == null || r.exp === 0 ? null : (
        <ItemRef icon={imgu.static.exp} name='Exp' quantity={r.exp}/>
    )
    const rep_info = r.rep == null || r.rep === 0 ? null : gcrep_reward(quest) || triberep_reward(quest)
    const rep = rep_info == null ? null : (
        <RepReward info={rep_info}/>
    )
    const gil = r.gil == null || r.gil === 0 ? null : (
        <ItemRef id={1} icon={imgu.static.gil} name='Gil' quantity={r.gil}/>
    )
    /*const mitems = r.items == null || r.items.length === 0 ? null : (
        <div style={{display:'flex',flexDirection:'column',gap:'2px'}}>
            {r.items.map((i) => (
                <ItemRef id={i.id} icon={i.icon} name={i.name} rarity={i.rarity} hq={i.hq} quantity={i.qt}/>
            ))}
        </div>
    )*/
    const mitems = render_main_reward_items(r, homeWorld)
    if (exp == null && rep == null && gil == null && mitems == null) {
        return null
    }
    return (
        <div style={{display:'flex',flexDirection:'column',gap:'2px',marginTop:'2px'}}>
            {rep}
            {exp}
            {gil}
            {mitems}
        </div>
    )
}

function render_main_reward_items(r, homeWorld) {
    if (r._profile.items === 0) {
        return null
    }
    const colspec = r._profile.items === 1 ? reward_colspec_base
        : reward_colspec(homeWorld.name).cols
    return (
        <div style={{margin:'0.5rem 0px'}}>
            <div style={{marginBottom:'0.5rem',fontSize:'1rem',fontWeight:'bold'}}>Item Rewards</div>
            <XTable tableClass='data-t noborder-t'
                cols={colspec} data={r.items} simple={true}
                idResolver={(i) => i.id}
            />
        </div>
    )
}

function render_opt_rewards(r, homeWorld) {
    // optitems
    if (r._profile.optitems === 0) {
        return null
    }
    const colspec = r._profile.optitems === 1 ? reward_colspec_base
        : reward_colspec(homeWorld.name).cols
    return (
        <div style={{margin:'0.5rem 0px'}}>
            <div style={{display:'flex',gap:'1rem'}}>
                <h3>Optional Rewards</h3>
                <span style={{color:'#999',fontSize:'0.75rem'}}>choose one</span>
            </div>
            <XTable tableClass='data-t noborder-t'
                cols={colspec} data={r.optitems} simple={true}
                idResolver={(i) => i.id}
            />
        </div>
    )
}

function render_job_rewards(r, homeWorld) {
    // jobitems
    if (r._profile.jobitems === 0) {
        return null
    }
    const colspec = r._profile.jobitems === 1 ? job_reward_colspec_base
        : job_reward_colspec(homeWorld.name).cols
    const jobgroups = Object.values(r.jobitems)
    return (
        <div style={{margin:'0.5rem 0px'}}>
            <h3>Job Based Rewards</h3>
            <XTable tableClass='data-t' cols={colspec} data={jobgroups}
                simple={true} rowRender={job_reward_renderRow}/>
        </div>
    )
}

function job_reward_renderRow(row, rwstyle) {
    const cells = [
        (<td key='jobcat'>{render_jobcat_lg(row.jobcat_nm)}</td>),
        (<td key='rewards' colSpan={rwstyle === 1 ? 1 : 2}>
            <div style={{display:'flex',flexDirection:'column',gap:'2px',justifyContent:'stretch'}}>
                {row.items.map((i) => (
                    <div key={i.id} style={{display:'flex'}}>
                        <ItemRef id={i.id} icon={i.icon} name={i.name} rarity={i.rarity} hq={i.hq} quantity={i.qt}/>
                        <div style={{flexGrow:'1',minWidth:'0px'}}></div>
                        {i.market == null ? null : (
                            <div style={{paddingLeft:'1.25rem'}}>
                                {render_home_reward_value(i, i.qt, {minWidth:'142px'})}
                            </div>
                        )}
                    </div>
                ))}

            </div>
        </td>)
    ]
    return (
        <tr key={row.jobcat}>{cells}</tr>
    )
}

function render_leve_rewards(r, homeWorld) {
    // leveitems
    if (r._profile.leveitems === 0) {
        return null
    }
    const objs = []
    r.leveitems.forEach((g) => {
        const len = g.items.length
        const chance = Math.floor((g.pct / len)*100) / 100
        g.items.forEach((i) => {
            objs.push({item:i, chance:chance})
        })
    })
    objs.sort((i,j) => cbase.numcmp(j.chance, i.chance))
    const colspec = r._profile.leveitems === 1 ? leve_reward_colspec_base
        : leve_reward_colspec(homeWorld.name).cols
    return (
        <div style={{margin:'0.5rem 0px'}}>
            <h3>Random Reward</h3>
            <XTable tableClass='data-t noborder-t' cols={colspec} data={objs}
                simple={true} rowRender={leve_reward_renderRow}/>
        </div>
    )
}

function leve_reward_renderRow(row) {
    const cells = [
        (<td key='leveitem'>
            <ItemRef id={row.item.id} icon={row.item.icon} name={row.item.name} 
                rarity={row.item.rarity} hq={row.item.hq} quantity={row.item.qt}/>
        </td>),
        (<td key='chance' style={{color:'#999'}}>
            {`${row.chance}%`}
        </td>)
    ]
    if (row.item.market != null) {
        cells.push((
            <td key='market'>
                {render_home_reward_value(row.item, row.item.qt)}
            </td>
        ))
    }
    return (
        <tr key={`${row.item.id}-${row.item.qt}-${row.chance}`}>{cells}</tr>
    )
}

function SpreqView({d}) {
    if (d == null) {
        return null
    }
    const text = d.rank === 0 ? d.text : `${d.text} Rank ${d.rank}`
    return (<ItemRef name={text} icon={d.icon}/>)
}

function gcreq_info(quest) {
    const gc = quest.req.gc
    if (gc != null && gc !== 0) {
        return {
            icon: imgu.gc_icon(gc),
            text: quest.req.gc_nm,
            rank: quest.req.gcrank
        }
    }
    return null
}

function tribereq_info(quest) {
    const tribe = quest.req.tribe
    if (tribe != null && tribe !== 0) {
        return {
            icon: quest.req.tribe_icon,
            text: quest.req.tribe_nm,
            rank: quest.req.triberank
        }
    }
    return null
}

function RepReward({info}) {
    return (info == null) ? null : (
        <ItemRef icon={info.icon} name={info.text} quantity={info.rep}/>
    )
}

function gcrep_reward(quest) {
    const gc = quest.req.gc
    if (gc != null && gc !== 0) {
        return {
            icon: imgu.gc_icon(gc),
            text: `${quest.req.gc_nm} Reputation`,
            rep: quest.reward.rep
        }
    }
    return null
}

function triberep_reward(quest) {
    const tribe = quest.req.tribe
    if (tribe != null && tribe !== 0) {
        return {
            icon: quest.req.tribe_icon,
            text: `${quest.req.tribe_nm} Reputation`,
            rep: quest.reward.rep
        }
    }
    return null
}

// rwstyle
// 0 = No rewards
// 1 = Item rewards (no market info)
// 2 = Item rewards (with market info)
function _process_quest(q) {
    const r = q.reward
    if (r != null) {
        r._profile = {
            items: _rwstyle(r.items),
            optitems: _rwstyle(r.optitems),
            jobitems: _jobitem_rwstyle(r),
            leveitems: _leveitem_rwstyle(r)
        }
    }
    return q
}

function _jobitem_rwstyle(r) {
    if (r.jobitems == null) {
        return 0
    }
    const joblist = Object.values(r.jobitems)
    if (joblist.length === 0) {
        return 0
    }
    let rwstyle = 1
    for (let i = 0; i < joblist.length; i++) {
        const jstyle = _rwstyle(joblist[i].items)
        if (jstyle === 2) {
            rwstyle = 2
            break
        }
    }
    return rwstyle
}

function _leveitem_rwstyle(r) {
    if (r.leveitems == null || r.leveitems.length === 0) {
        return 0
    }
    let rwstyle = 1
    for (let i = 0; i < r.leveitems.length; i++) {
        const gstyle = _rwstyle(r.leveitems[i].items)
        if (gstyle === 2) {
            rwstyle = 2
            break
        }
    }
    return rwstyle
}

function _rwstyle(items) {
    if (items == null || items.length === 0) {
        return 0
    }
    for (let i = 0; i < items.length; i++) {
        if (items[i].market != null) {
            return 2
        }
    }
    return 1
}

export default client_util.withParams(QuestPage)
