import * as React from 'react';

import api from '../common/api'
import client_util from '../common/util/client_util'
import cbase from '../common/cbase'
import throttle from '../common/util/throttle'
import imgu from '../common/imgu'
import {DebugToggle} from './viewbase'

export class NewsReader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            payload: null,
            feed: null,
            debug_mode: false,
        }
        this.data_loader = throttle.loadOnce(() => this._run_query())
    }

    componentDidMount() {
        console.log('componentDidMount')
        this.data_loader.load()
    }

    _run_query() {
        api.call({
            path:'news/current',
            handler:(result, err) => this.setState({
                payload: result,
                feed: result == null ? null : new NewsFeed(result)
            })
        })
    }

    render() {
        const {feed, payload} = this.state
        return (
            <div>
                {this.render_header()}
                {this.render_feed(feed)}
                {this.render_debug_data(payload)}
            </div>
        )
    }

    render_header() {
        return (
            <div style={{margin: '12px 0px'}}>
                <h2>Latest News</h2>
                <DebugToggle stateFn={(dbg) => this.setState({debug_mode: dbg})}/>
            </div>
        )
    }

    render_feed(feed) {
        const {debug_mode} = this.state

        return debug_mode === true ? (<></>) : (
            <NewsView feed={feed}/>
        )
    }

    render_debug_data(payload) {
        const {debug_mode} = this.state
        return debug_mode === false ? (<></>) : (
            <div style={{justifyContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                {client_util.renderInput(payload)}
            </div>
        )
    }
}

export class NewsFeed {
    constructor(payload) {
        this.news_type = payload.news_type
        this.entries = payload.news != null ? payload.news : []
    }
}

export function NewsView({feed}) {
    const [viewCount, setViewCount] = React.useState(25)
    const vitems = feed == null ? [] 
        : feed.entries.length <= viewCount ? feed.entries 
        : feed.entries.slice(0, viewCount)
    return (
        <div>
            {vitems.map((e) => (
                <div key={e.id} style={{margin:'10px 0px'}}>
                    <NewsEntry entry={e} news_type={feed.news_type}/>
                </div>
            ))}
        </div>
    )
}

export function NewsEntry({entry, news_type}) {
    const nt = news_type[entry.type]
    const icon = nt == null ? null : nt.icon
    return (
        <div style={{
            display:'flex',flexDirection:'column',
            backgroundColor:'#181818',borderRadius:'6px',padding:'12px',
            maxWidth:'800px',width:'100%'
        }}>
            <div style={{margin:'8px 0px'}}>
                <NewsHeader title={entry.title} icon={icon} linkTo={entry.url}/>
            </div>
            <div style={{fontSize:'14px',color:'#79b'}}>{cbase.format_utc_timestamp(entry.ptime)}</div>
            {entry.image == null ? null : (
                <div style={{margin:'8px 0px'}}>
                    <a className='Game-link' href={entry.url} target='_blank' rel='noopener noreferrer'>
                        <img src={entry.image} style={{
                            /* 720x188 natural */
                            /*width:'648px',height:'170px'    // 90%*/
                            maxWidth: '640px', width:'100%', height:'auto'
                        }}></img>
                    </a>
                </div>
            )}
            {entry.description == null ? null : (
                <div style={{margin:'8px 0px'}}>
                    {entry.description}
                </div>
            )}
        </div>
    )
}

export function NewsHeader({title, icon, textColor, linkTo}) {
    const nameSpacing = icon == null ? '0px' : '6px'
    const content = (
        <div style={{display:'flex',alignItems:'center'}}>
            {icon == null ? null : (
                <img src={imgu.to_icon_path(icon)} style={{width:'auto',height:'48px'}}></img>
            )}
            <div style={{
                marginLeft:nameSpacing,color:textColor == null ? 'inherit' : textColor,
                fontSize:'16px'
            }}>
                {title == null ? null : title}
            </div>
        </div>
    )
    return linkTo == null ? (
        <span style={{display:'inline-flex',alignItems:'center'}}>{content}</span>
    ) : (
        <span style={{display:'inline-flex',alignItems:'center'}}>
            <a className='Game-link' href={linkTo} target='_blank' rel='noopener noreferrer'>{content}</a>
        </span>
    )
}
