import * as React from 'react';
//import {Link} from "react-router-dom";

import api from '../common/api'
import cbase from '../common/cbase'
import client_util from '../common/util/client_util'
import { ItemRef } from '../components/viewbase'
import {Page} from '../components/Page'

function CurrencyPage() {
    const [data, setData] = React.useState(null)

    React.useEffect(() => {
        client_util.setTitle('Currency')
        api.get_doc('core/xcur_structure.json').then((result) => setData(result))
    }, [])

    return (
        <Page canon='items/currency'>
        <div className='page-body'>
            <h1>Currency</h1>
            {data == null ? null : (
                <div style={{display:'flex', alignItems:'start', gap:'0px 40px', flexWrap:'wrap',
                    backgroundColor:'#181818', padding:'8px 8px',borderRadius:'6px',width:'fit-content'}}>
                    <CurrencyFlow groups={[data.battle,data.crafting,data.gathering]}/>
                    <div>
                        <CurrentEvents groups={data.current_event_groups}/>
                        <CurrencyFlow groups={[data.common,data.other]}/>
                    </div>
                    <CurrencyFlow groups={[data.tribal]}/>
                    
                </div>
            )}
        </div>
        </Page>
    )
}

function CurrencyFlow({groups}) {
    const group_views = groups.map((g) => _group_view(g))
    return (
        <div>
            {group_views}
        </div>
    )
}

function CurrentEvents({groups}) {
    const active_groups = groups.filter((g) => g.active === true).map((g) => _group_view(g))
    return active_groups.length === 0 ? null : (
        <div>
            {active_groups}
        </div>
    )
}

function _group_view(g) {
    const is_ext = g.extended === true
    const rows = g.entries.map((item) => (
        <tr key={item.id}>
            <td><ItemRef id={item.id} icon={item.icon} name={item.name} rarity={item.rarity}/></td>
            {is_ext === false ? null : (
                <td><div style={{paddingLeft:'20px'}}>{item.ext}</div></td>
            )}
        </tr>
    ))
    return (
        <div key={g.name}>
            <div style={{backgroundColor:'#34384450',display:'flex',padding:'4px 8px',margin:'0px -4px',width:'320px'}}>
                <div style={{}}>{g.name}</div>
            </div>
            <div style={{margin:'8px 0px'}}>
                <table className='data-t noborder-t'>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default CurrencyPage
