import imgu from './imgu'

export const chcodes = {
    NEW: {id: 1, key: 'NEW', proper: 'New'},
    UPD: {id: 2, key: 'UPD', proper: 'Updated'},
    DEL: {id: 3, key: 'DEL', proper: 'Removed'},
    from_code: (chcode) => {
        return chcode_bycode[chcode]
    }
}
chcodes._all = [chcodes.NEW, chcodes.UPD, chcodes.DEL]

const chcode_bycode = {}
chcodes._all.forEach((x) => chcode_bycode[x.id] = x)

export const diff_types = [
    {id: 0, rfield: 'item', name: 'Items', path_suffix:'items', icon: imgu.static.items},
    {id: 1, rfield: 'gnode', name: 'Gathering Nodes', path_suffix:'gnodes', icon: imgu.static.gathering},
    {id: 2, rfield: 'fspot', name: 'Fishing Spots', path_suffix:'fspots', icon: imgu.static.fishing},
    {id: 3, rfield: 'recipe', name: 'Recipes', path_suffix:'recipes', icon: imgu.static.crafting},
    {id: 4, rfield: 'zone', name: 'Zones', path_suffix:'zones', icon: imgu.static.zones},
    {id: 5, rfield: 'quest', name: 'Quests', path_suffix:'quests', icon: imgu.static.quest}
]
