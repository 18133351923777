import React from 'react';
import {Link, useLocation} from 'react-router-dom'

import client_config from '../common/client_config'
import imgu from '../common/imgu'
import { useLocalSettings, DeviceLayout, useDeviceLayout } from '../common/user'
import {Dropdown} from './dropdown'
import {SettingsEdit} from './settings'

export default function Header(props) {
    return (
        <React.Fragment>
            <HeaderMobile {...props}/>
            {/*<HeaderMain {...props}/>*/}
        </React.Fragment>
    )
}

export function HeaderMobile({}) {
    //const layout = useDeviceLayout()
    //console.log(`layout = ${JSON.stringify(layout)}`)
    //const icon = '/site-icon.png'
    //const [showSearch, setShowSearch] = React.useState(false)
    const logo = '/site-logo-mini-beta.png'
    return (
        <div className='header-mobile'>
            {/*<SearchHolder show={showSearch} setShow={setShowSearch}/>*/}
            <div className="not-header-contents" style={{display:'flex',flexDirection:'column',alignItems:'stretch',paddingBottom:'2px'}}>
                <div style={{display:'flex',alignItems:'center',paddingRight:'4px'}}>
                    <Mobinav/>
                    {/*<span className='material-icon'
                            style={{cursor:'pointer',padding:'4px 4px', fontSize:'32px'}}
                            onClick={null}>menu</span>*/}
                    <div style={{flexGrow:1,paddingLeft:'16px',display:'flex',alignItems:'center',justifyContent:'left'}}>
                        <Link to='/'>
                            <div style={{display:'flex',gap:'6px',alignItems:'center',justifyContent:'left',paddingBottom:'2px'}}>
                            <img src='/site-icon.png' style={{height:'28px',width:'auto'}} alt='icon'/>
                            <img src={logo} style={{height:'20px',width:'auto'}} alt='logo'/>
                            </div>
                        </Link>
                    </div>
                    {/*<span id='search-trigger' className='material-icon'
                        style={{cursor:'pointer',padding:'6px 6px',fontSize:'28px',marginRight:'8px'}}
                        onClick={() => setShowSearch(!showSearch)}>search</span>*/}
                    <UserHeaderMobile/>
                </div>
            </div>
        </div>
    )
}

function UserHeaderMobile({}) {
    const [settings, saveSettings] = useLocalSettings()
    const [editSettings, setEditSettings] = React.useState(false)
    return (
        <div>
            <div style={{display:'flex',alignItems:'center'}}>
                <div className='header-server-label'>{settings.homeWorld().name}</div>
                <span className='material-icon'
                    style={{/*color:'#79b',*/cursor:'pointer',padding:'6px 6px',fontSize:'28px',marginRight:'4px'}}
                    onClick={() => setEditSettings(true)}>settings</span>
            </div>
            <SettingsEdit settings={settings} saveSettings={saveSettings} 
                editSettings={editSettings} setEditSettings={setEditSettings}/>
        </div>
    )
}

function Mobinav() {
    const [show, setShow] = React.useState(false)
    const showFlyoutNav = () => {
        document.getElementById('mobinav_panel').scrollTo(0,0)
        setShow(true)
    }
    return (
        <div>
            <span className='material-icon'
                style={{cursor:'pointer',padding:'4px 4px', marginLeft:'4px', fontSize:'32px'}}
                onClick={showFlyoutNav}>menu</span>
            {show === false ? null : (
                <div style={{position:'absolute',inset:'0px 0px',width:'100vw',height:'100vh'}} onClick={() => setShow(false)}></div>
            )}
            <div id='mobinav_panel' className={`mobinav${show ? ' shownav' : ''}`}>
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',gap:'10px'}}>
                    <Link to='/' onClick={() => setShow(false)}>
                        <img src='/site-logo.png' style={{height:'40px',margin:'8px 20px'}} alt='logo'/>
                    </Link>
                    <span className='material-icon'
                        style={{/*color:'#79b',*/cursor:'pointer',padding:'6px 6px',fontSize:'28px',marginRight:'4px'}}
                        onClick={() => setShow(false)}>close</span>
                </div>
                {mobinav_entries(setShow)}
            </div>
        </div>
    )
}

function mobinav_entries(setShow) {
    const entries = []
    for (let i = 0; i < tbmodel_mobi.length; i++) {
        const tbe = tbmodel_mobi[i]
        if (tbe.entries == null || tbe.entries.length === 0) {
            // regular top-level entry
            entries.push((
                <Link key={tbe.id} to={tbe.linkTo} onClick={() => setShow(false)}>
                    <div style={{padding:'4px 12px',margin:'2px',backgroundColor:'#141414'}}>
                        {tbe.name}
                    </div>
                </Link>
            ))
        }
        else {
            entries.push((
                <div key={tbe.id} style={{padding:'4px 12px',margin:'2px'}}>
                    {tbe.name}
                </div>
            ))
            tbe.entries.forEach((nest) => entries.push((
                <Link key={nest.id} to={nest.linkTo} onClick={() => setShow(false)}>
                    <div style={{padding:'4px 12px 4px 24px', margin:'2px',backgroundColor:'#141414'}}>
                        {nest.name}
                    </div>
                </Link>
            )))
        }
    }
    entries.push((
        <div key='tbpad-bottom' style={{height:'24px'}}></div>
    ))
    return entries
}

const tbmodel_mobi = [
    {id:'tb-patch', name:`Patch ${client_config.ui.game_version}`, linkTo:`/patch/${client_config.ui.patch_id}`},
    {id:'tb-items-cat', name:'Items', entries:[
        {id:'tb-items', name:'Items', icon:imgu.static.items, linkTo:'/items'},
        {id:'tb-currency', name:'Currency', icon:null, linkTo:'/items/currency'},
        {id:'tb-gather', name:'Gathering', icon:imgu.static.gathering, linkTo:'/gatherspots'},
        {id:'tb-craft', name:'Crafting', icon:imgu.static.crafting, linkTo:'/recipes'},
    ]},
    {id:'tb-char', name:'Character', entries:[
        {id:'tb-quest', name:'Quests', linkTo:'/quests'},
        {id:'tb-retainertask', name:'Retainer Tasks', linkTo:'/retainer-tasks'},
        {id:'tb-housing', name:'Housing', linkTo:'/housing'}
    ]},
    {id:'tb-world', name:'World', entries:[
        {id:'tb-npc', name:'NPCs', linkTo:'/npcs'},
        //{id:'tb-quest', name:'Quests', linkTo:'/quests'},
        {id:'tb-zone', name:'Zones', linkTo:'/zones'},
        //{id:'tb-housing', name:'Housing', linkTo:'/housing'}
    ]},
]

export function HeaderMain(props) {
    const logo = '/site-logo-beta.png'
    return (
        <div className="header-contents header-main">
            <div style={{display: 'flex', alignItems: 'top', gap:'20px'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Link to="/" style={{marginTop:'6px'}}>
                        <img src={logo} className="header-logo" alt="logo"/>
                    </Link>
                </div>
                <div style={{flexGrow:'1'}}></div>
                <UserHeader/>
            </div>
            <div style={{height:'8px'}}></div>
            <div style={{display:'flex', alignItems:'center'}}>
                <Toolbar2/>
            </div>
        </div>
    )
}

function UserHeader(props) {
    const [settings, saveSettings] = useLocalSettings()
    const [editSettings, setEditSettings] = React.useState(false)
    return (
        <div>
            <div style={{display:'flex', alignItems:'center', margin:'18px 18px 0px 0px'}}>
                <div style={{fontWeight:'bold',fontSize:'1rem',color:'#9ab',marginRight:'1rem'}}>{settings.homeWorld().name}</div>
                {/*<img src={imgu.to_icon_path(imgu.static.site.settings)} className='Clean-link' alt='settings' height='28px'
                    onClick={() => setEditSettings(true)}
                ></img>*/}
                <span className='material-icon'
                    style={{cursor:'pointer', fontSize:'28px', marginTop:'2px'}}
                    onClick={() => setEditSettings(true)}>settings</span>
            </div>
            <SettingsEdit settings={settings} saveSettings={saveSettings} editSettings={editSettings} setEditSettings={setEditSettings}/>
        </div>
    )
}

const _tbmodel = [
    {id:'tb-home', name:'Home', icon:imgu.static.items, linkTo:'/'},
    {id:'tb-items', name:'Items', icon:imgu.static.items, linkTo:'/items'},
    {id:'tb-currency', name:'Currency', icon:null, linkTo:'/items/currency'},
    {id:'tb-gather', name:'Gathering', icon:imgu.static.gathering, linkTo:'/gatherspots'},
    {id:'tb-craft', name:'Crafting', icon:imgu.static.crafting, linkTo:'/recipes'},
    //{id:'tb-zone', name:'Zones', icon:imgu.static.zones, linkTo:'/zones'},
    {id:'tb-world', name:'World', entries:[
        {id:'tb-npc', name:'NPCs', linkTo:'/npcs'},
        {id:'tb-quest', name:'Quests', linkTo:'/quests'},
        {id:'tb-zone', name:'Zones', linkTo:'/zones'},
        {id:'tb-housing', name:'Housing', linkTo:'/housing'}
    ]},
    {id:'tb-patch', name:`Patch ${client_config.ui.game_version}`, linkTo:'/patch/current'}
]

function Toolbar2() {
    const entries = _tbmodel.map(m => {
        return (m.entries == null) 
            ? (<SimpleTbLink m={m}/>)
            : (
                <ToolbarItem id={m.id} name={m.name} textColor='#79b' entries={m.entries.map((x) => (
                    <ToolbarItem id={x.id} name={x.name} icon={x.icon} linkTo={x.linkTo}/>
                ))}/>
            )

    })
    return (
        <div style={{display:'flex',gap:'10px'}}>
            {entries}
        </div>
    )
}

function SimpleTbLink({m}) {
    /*return (
        <div key={m.id} className='toolbar-btn' 
            style={{color:'#79b',fontSize:'16px',fontWeight:'500'}}>
            <Link className='Clean-link' to={m.linkTo}>
                {m.name}
            </Link>
        </div>
    )*/
    return (
        <Link className='Clean-link toolbar-btn' style={{fontSize:'16px',fontWeight:'500'}} to={m.linkTo}>
            {m.name}
        </Link>
    )
}

function ToolbarItem({id, name, icon, linkTo, entries, textColor}) {
    const nameSpacing = icon == null ? '0px' : '6px'
    const is_dd = entries != null && entries.length !== 0
    const content = (
        <div style={{display:'flex',alignItems:'center'}}>
            {icon == null ? null : (
                <img src={imgu.to_icon_path(icon)} style={{width:'24px',height:'24px'}}></img>
            )}
            {name == null ? null : (
                <div style={{marginLeft:nameSpacing,color:textColor == null ? 'inherit' : textColor}}>
                    {name == null ? null : name}
                </div>
            )}
            {is_dd !== true ? null : (
                <span className='material-icon icon16' style={{paddingLeft:'4px',
                    color:textColor == null ? 'inherit' : textColor}}>expand_more</span>
            )}
        </div>
    )
    if (is_dd) {
        const dd_button = (<div className='toolbar-btn'>{content}</div>)
        const dd_content = (
            <div style={{backgroundColor:'#111',padding:'0px 0px',minWidth:'100px'}}>
                {entries.map((e,i) => (<div key={i}>{e}</div>))}
            </div>
        )

        return (
            <Dropdown key={id} id={id} button={dd_button} content={dd_content}/>
        )
    }
    return linkTo == null ? (
        <span key={id} style={{display:'inline-flex',alignItems:'center'}}>{content}</span>
    ) : (
        <div key={id} style={{display:'flex'}}>
            <Link className='Clean-link toolmenu-btn' to={linkTo} style={{flexGrow:'1'}}>{content}</Link>
        </div>
    )
}

/*** SEARCH EXP ***/

function SearchHolder({show, setShow}) {
    console.log(`SearchHolder: show=${show}`)
    return show !== true ? null : (<SearchPopup show={show} setShow={setShow}/>)
}

export function SearchPopup({show, setShow}) {
    //const location = useLocation()
    const docClick = (e) => {
        if (show && !e.target.closest('#search-trigger') && !e.target.closest('#search-popup')) {
            console.log('docClick setShow(false)')
            setShow(false)
        }
    }
    React.useEffect(() => {
        document.addEventListener('click', docClick)
        return () => document.removeEventListener('click', docClick)
    })
    //React.useEffect(() => {
    //    setShow(false)
    //}, [location])
    React.useEffect(() => {
        //const e = document.getElementById('page.contents')
        //document.body.style.overflow = show === true ? 'hidden' : 'unset'
        
    }, [/*show*/])
    return (
        <div id='search-popup' style={{
            /*position:'fixed',top:'42px',left:'0px',width:'100%',height:'calc(100vh - 42px)',*/
            position:'fixed',inset:'42px 0 0 0',
            backgroundColor:'#00000000',zIndex:0,display:'flex',flexDirection:'column',alignItems:'center',pointerEvents:'none'
        }} onClick={null}>
            <div style={{pointerEvents:'auto'}}>
            <SearchPopupCore onClose={() => setShow(false)}/>
            </div>
        </div>
    )
}

class SearchPopupCore extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            search: '',
            results: null,
            _poke: 0
        }

        //this.data_loader = throttle.loadOnce(() => this.loadData())
    }

    componentDidMount() {
        //this.data_loader.load()
    }

    poke() {
        this.setState({_poke:this.state._poke === 0 ? 1 : 0})
    }

    /*loadData() {
        api.promise({
            path:'core/dcWorld', method:'GET'
        })
        .then((data) => {
            const repo = new WorldRepo(data.payload)
            repo.world_order = [...Object.values(repo.worldMap)]
            repo.world_order.sort((i, j) => cbase.strcmp(i.name, j.name))
            this.setState({worldRepo:repo})
        })
    }*/

    render() {
        //const {worldRepo, xsettings} = this.state
        //const {saveSettings} = this.props
        /*<div style={{position:'fixed',width:'100%',display:'flex', flexDirection:'column', alignItems:'center',borderTop:'1px solid #333'}}>*/
        /*</div>*/
        return (
            <div style={{display:'flex',flexDirection:'column',
                backgroundColor:'#121212',padding:'10px',width:'400px',maxWidth:'100vw',height:'calc(100vh - 42px)'}}>
                <div style={{/*marginTop: '14px'*/}}>
                    <input style={{backgroundColor:'#282828',borderRadius:'4px',height:'24px',border:'1px solid #00000000',
                        minWidth:'100%',maxWidth:'300px',color:'#aaa',margin:'0 auto',fontSize:'16px'}} autoFocus={true}></input>
                </div>
                {/*render_body(saveSettings, xsettings, worldRepo, this.props.onClose)*/}
                <div style={{display:'flex',gap:'8px',justifyContent:'center',paddingTop:'4px'}}>
                    <a href='#'>Items</a><a href='#'>Squirrels</a>
                </div>
                <div style={{flexGrow:1,display:'flex',flexDirection:'column'}}>
                    <div style={{flexGrow:1,minHeight:'100px',maxHeight:'200px',overflowY:'scroll',border:'1px solid red'}}>
                        {['ONE','TWO','THREE','FOUR','FIVE','SIX','SEVEN'].map((x) => (
                            <div key={x} style={{fontSize:'40px'}}>{x}</div>
                        ))}
                    </div>
                    <div style={{display:'flex',gap:'10px'}}>
                        <div style={{flexGrow:'1'}}></div>
                        <button className='xbtn' onClick={() => this.props.onClose()}>Close</button>
                    </div>
                </div>
            </div>
        )
    }
}
function preventDefault(e) {
    e.preventDefault()
}
/*
function render_body(saveSettings, xsettings, worldRepo, onClose) {
    const cancel = () => {
        onClose(false)
    }
    const save = () => {
        const changed = saveSettings(xsettings)
        onClose(changed)
    }
    return worldRepo == null ? null : (
        <div>
            <div style={{margin:'12px 0px'}}>
                <div style={{margin:'0px 0px 60px 0px'}}>
                    <table className='data-t noborder-t'><tbody>
                        <tr>
                            <td>Home World</td>
                            <td><SelectHomeWorld xsettings={xsettings} worldRepo={worldRepo}/></td>
                        </tr>
                    </tbody></table>
                </div>
                <div style={{display:'flex',gap:'10px'}}>
                    <div style={{flexGrow:'1'}}></div>
                    <button className='xbtn' onClick={save}>Save</button>
                    <button className='xbtn' onClick={cancel}>Cancel</button>
                </div>
            </div>
        </div>
    )
}*/

