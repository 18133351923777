import * as React from 'react';
import {Link, Navigate, useLocation} from "react-router-dom";

import api from '../common/api'
import client_util from '../common/util/client_util'
import imgu from '../common/imgu'
import throttle from '../common/util/throttle'
import cbase from '../common/cbase'
import {chcodes, diff_types} from '../common/patch'
import { DebugToggle } from '../components/viewbase'
import {Page} from '../components/Page'

class PatchPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            payload: null,
            _poke: 0,
            debug_mode: false
        }

        let { id } = props.params
        console.log(`id = ${id}`)
        this.patch_id = id
        this.data_loader = throttle.loadOnce(() => this.loadData())
    }

    componentDidMount() {
        console.log('componentDidMount')
        this.data_loader.load()
    }

    componentDidUpdate(prevProps) {
        let rlreq = false
        if (prevProps.params.id !== this.props.params.id) {
            this.patch_id = this.props.params.id
            this.setState({payload:null})
            this.loadData()
            rlreq = true
        }
        if (prevProps.qsearch !== this.props.qsearch) {
            if (!rlreq) {
                this.setState({_poke:this.state._poke === 0 ? 1 : 0})
            }
        }
    }

    loadData() {
        api.call({path:`hist/patch_report/${this.patch_id}`, handler:(result, err) => {
            if (result != null && result.payload != null) {
                this.setState({payload: result.payload})
            }
        }})
    }

    render() {
        const {payload} = this.state
        if (payload == null) {
            return (<></>)
        }
        document.title = `Patch ${payload.patch.patch}`
        return (
            <Page canon={`patch/${this.patch_id}`}>
            <div className='page-body'>
                {this.render_header(payload.patch)}
                {this.render_patch(payload)}
                {this.render_debug(payload)}
            </div>
            </Page>
        )
    }

    render_header(patch) {
        return (
            <div>
                <h1>Patch {patch.patch}</h1>
                <DebugToggle stateFn={(dbg) => this.setState({debug_mode: dbg})}/>
            </div>
        )
    }

    render_patch(payload) {
        const {debug_mode} = this.state
        return debug_mode === true ? (<></>) : (
            <div>
                {this.render_body(payload.patch)}
                {this.render_report(payload)}
            </div>
        )
    }

    render_body(patch) {
        return (
            <div style={{display:'flex'}}>
                <div style={{margin:'12px 0px',display:'flex',flexDirection:'column',alignItems:'stretch'}}>
                    <img src={patch.image} style={{width:'500px',maxWidth:'100%'}} alt='patchimage'></img>
                    <div style={{marginTop:'6px', display:'flex', flexWrap:'wrap', gap:'8px'}}>
                        {patch.notes != null && patch.notes.length !== 0 ?
                            (<a className='xbtn Clean-link' href={patch.notes} target='_blank'>Official Patch Notes</a>)
                            : (<button className='xbtn Clean-link' disabled={true}>Patch Notes</button>)
                        }
                        <a className='xbtn Clean-link' href={patch.site} target='_blank'>Promo Site</a>
                        <div style={{flexGrow:1}}></div>
                        <Link className='xbtn Clean-link off-btn' to='/patches'>Other Patches</Link>
                    </div>
                    <div style={{marginTop:'10px'}}>
                        <table className='data-t'>
                            <tbody>
                                <tr><td>Patch</td><td>{patch.patch}</td></tr>
                                <tr><td>Release Date</td><td>{patch.release}</td></tr>
                                <tr><td>Client Data Version</td><td>{patch.dversion}</td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    render_report(payload) {
        return payload.report == null ? null : (
            <div style={{margin:'12px 0px'}}>
                <PatchReport payload={payload}/>
            </div>
        )
    }

    render_debug(payload) {
        const {debug_mode} = this.state
        return debug_mode === false ? (<></>) : (
            <div style={{justifyContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                {client_util.renderInput(payload)}
            </div>
        )
    }
}

class PatchReport extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            diffs: this.props.payload.report.diff_summary
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.payload !== this.props.payload) {
            this.setState({diffs: this.props.payload.report.diff_summary})
        }
    }
    render() {
        const {diffs} = this.state
        const diff_bins = diffs == null ? [] : diff_types.filter((dt) => diffs[dt.rfield] != null).map((dt) => (
            <DiffBin diffs={diffs} dt={dt}/>
        ))
        return (
            <div style={{backgroundColor:'#202225',borderRadius:'8px',padding:'20px 10px',margin:'10px 0px',
                    display:'inline-block'
            }}>
                <div style={{fontWeight:'bold',marginBottom:'16px'}}>Changeset Summary</div>
                <div style={{display: 'flex', flexWrap:'wrap', gap:'20px'}}>
                    {diff_bins.length !== 0 ? diff_bins : (
                        <div style={{padding:'0px 20px',fontSize:'16px',color:'#888'}}>No Changes Detected</div>
                    )}
                </div>
            </div>
        )
    }
}

function DiffBin({diffs, dt}) {
    const location = useLocation()
    console.log(`DiffBin location: ${location}`)
    const xdiffs = diffs[dt.rfield]
    const chgtype_links = []
    chcodes._all.forEach((xch) => {
        const ct = xdiffs[xch.key]
        if (ct != null && ct > 0) {
            const path = `${cbase.url_append(location.pathname,xch.proper.toLowerCase())}-${dt.path_suffix}`
            const key = `${xch.key}-${dt.rfield}`
            chgtype_links.push((
                <Link key={key} className='Clean-link micro-btn' to={path}>{ct} {xch.proper}</Link>
            ))
        }
    })
    return (
        <div key={dt.rfield} style={{display:'flex',flexDirection:'column',gap:'6px',minWidth:'150px'}}>
            <div>{dt.name}</div>
            <div style={{display:'flex',gap:'10px',alignItems:'start'}}>
                <div>
                    <img src={imgu.to_icon_path(dt.icon)} height='48px' alt=' '></img>
                </div>
                <div style={{display:'flex',flexDirection:'column'}}>
                    {chgtype_links}
                </div>
            </div>
        </div>
    )
}

export default client_util.withUrlFeatures(PatchPage)
