import * as React from 'react'

import timeservice from '../common/util/timeservice'
import EorzeaTime from '../common/EorzeaTime'
import gathrare from '../common/gathrare'
import imgu from '../common/imgu'

export function SimpleNodeTimer({schedule, width}) {
    const [now, set_now] = React.useState(timeservice.now())
    const timer = calc_timerd(schedule, now.eorzea)
    //console.log(`timer_pct = ${timer.timer_pct}`)
    const twidth = `${Math.floor(timer.timer_pct*100)}%`
    const update_timer = (e) => set_now(e)
    React.useEffect(() => {
        timeservice.add_handler(update_timer)
        return () => timeservice.remove_handler(update_timer)
    })
    return (
        <div style={{width: width, margin: '8px 0px'}}>
            <div style={{backgroundColor: '#000', width:'100%', fontSize:'14px'}}>
                <span style={{position:'absolute'}}>{timer.timer_text}</span>
                <div style={{
                    backgroundColor: timer.timer_bg,
                    minWidth:twidth,
                    width:twidth,
                    maxWidth:twidth,
                    minHeight: '18px'}}>
                </div>
            </div>
        </div>
    )
}

export function SimpleNodeTimer2({schedule, jobabbrev, active_icon, width}) {
    const [now, set_now] = React.useState(timeservice.now())
    const timer = calc_timerd(schedule, now.eorzea)
    const twidth = `${Math.floor(timer.timer_pct*100)}%`
    const update_timer = (e) => set_now(e)
    React.useEffect(() => {
        timeservice.add_handler(update_timer)
        return () => timeservice.remove_handler(update_timer)
    })
    const icon = timer.is_active ? active_icon
        : (jobabbrev === 'BTN' ? imgu.static.gather_btn_rareinactive : imgu.static.gather_min_rareinactive)
    const text = timer.is_active ? `Active for ${timer.timer_text}` : `Next in ${timer.timer_text}`
    return (
        <div style={{width: width, margin: '8px 0px'}}>
            <div style={{display:'flex', alignItems:'center'}}>
                <img src={imgu.to_icon_path(icon)} style={{width:'30px',height:'30px'}}></img>
                <div style={{flexGrow:'1',display:'flex',flexDirection:'column'}}>
                    <div style={{fontSize:'14px'}}>{text}</div>
                    <div style={{backgroundColor: '#000', width:'100%'}}>
                        <div style={{
                            backgroundColor: timer.timer_bg,
                            minWidth:twidth,
                            width:twidth,
                            maxWidth:twidth,
                            minHeight: '12px'}}>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function MicroNodeTimer({schedule, jobabbrev, active_icon}) {
    const [now, set_now] = React.useState(timeservice.now())
    const timer = calc_timerd(schedule, now.eorzea)
    //console.log(`timer_pct = ${timer.timer_pct}`)
    const twidth = `${Math.floor(timer.timer_pct*100)}%`
    const update_timer = (e) => set_now(e)
    React.useEffect(() => {
        timeservice.add_handler(update_timer)
        return () => timeservice.remove_handler(update_timer)
    })
    const icon = timer.is_active ? active_icon
        : (jobabbrev === 'BTN' ? imgu.static.gather_btn_rareinactive : imgu.static.gather_min_rareinactive)
    return (
        <div style={{display:'flex', alignItems:'center', justifyContent:'right',width:'80px'}}>
            <div style={{marginRight:'2px'}}>{timer.timer_text}</div>
            <img src={imgu.to_icon_path(icon)} style={{width:'24px',height:'24px'}}></img>
        </div>
    )
}

function calc_timerd(schedule, etime) {
    const status = schedule.status(etime)
    const result = {is_active:status.is_active}
    if (status.is_active) {
        const end_epoch = status.end.to_real_epoch()
        result.secs_to_shift = end_epoch - etime.to_real_epoch()
        result.timer_pct = result.secs_to_shift / (end_epoch - status.start.to_real_epoch())
        result.timer_bg = '#30804A'
        result.timer_text = `${rdelta_str(etime.real_delta(status.end))}`
    }
    else {
        result.secs_to_shift = status.start.to_real_epoch() - etime.to_real_epoch()
        result.timer_pct = 1 - (result.secs_to_shift / (status.start.to_real_epoch() - status.prev_end.to_real_epoch()))
        result.timer_bg = '#80304A'
        result.timer_text = `${rdelta_str(etime.real_delta(status.start))}`
    }
    return result
}

function rdelta_str(rdelta) {
    const bits = rdelta.hours === 0 ? [] : [`${rdelta.hours}h`]
    if (rdelta.minutes !== 0 || bits.length !== 0) {
        bits.push(`${rdelta.minutes}m`)
    }
    if (rdelta.hours === 0 && rdelta.minutes < 5) {
        bits.push(`${rdelta.seconds}s`)
    }
    return bits.join(' ')
}

class AllTimersView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            etime: null,
            spawn_map: null
        }
    }

    componentDidMount() {
        console.log('AllTimersView:componentDidMount')
        this._deriveState()
        this._startTimer()
    }

    componentDidUpdate(prevProps) {
        console.log('AllTimersView:componentDidUpdate')
        if (this.props.node_data !== prevProps.node_data) {
            console.log('  - props_change')
            this._stopTimer()
            this._deriveState()
            this._startTimer()
        }
    }

    componentWillUnmount() {
        console.log('AllTimersView:componentWillUnmount')
        this._stopTimer()
    }

    _startTimer() {
        console.log('AllTimersView:_startTimer')
        this._stopTimer()
        this.etimer = setInterval(() => this._deriveState(), 5000)
    }

    _stopTimer() {
        console.log('AllTimersView:_stopTimer')
        if (this.etimer != null) {
            clearInterval(this.etimer)
            this.etimer = null
        }
    }

    _deriveState() {
        console.log('AllTimersView:_deriveState')
        const etime = new EorzeaTime()
        const spawn_map = this._mapSpawns(etime)
        this.setState({etime: etime, spawn_map: spawn_map})
        console.log('/END:AllTimersView:_deriveState')
    }

    _mapSpawns(etime) {
        const {node_data} = this.props
        if (node_data == null) {
            return {}
        }
        const nmap = {}
        for (const id in node_data.ext_map) {
            const gnode_d = node_data.ext_map[id]
            const sig = gnode_d.sig
            if (nmap[sig] === undefined) {
                nmap[sig] = this._calcTimerD(etime, gnode_d.gnode)
            }
        }
        console.log(`MapSpawns: map_entries=${Object.entries(nmap).length}`)
        return nmap
    }

    _calcTimerD(etime, gnode) {
        const sched = gathrare.get_spawn_info(gnode.transient_data, {eorzea_time:etime, num_results:2})
        const is_active = sched[0].is_active
        const result = {
            is_active: is_active,
            sched: sched,
        }
        if (is_active) {
            result.secs_to_shift = sched[0].end.to_real_epoch() - etime.to_real_epoch()
            result.timer_pct = result.secs_to_shift > 300 ? 1 : result.secs_to_shift / 300
            result.timer_bg = "#348854"
            const dur = etime.real_delta(sched[0].end)
            result.timer_text = `Active now for ${dur.hours}h ${dur.minutes}m ${dur.seconds}s`
        }
        else {
            result.secs_to_shift = sched[0].start.to_real_epoch() - etime.to_real_epoch()
            result.timer_pct = result.secs_to_shift > 300 ? 0 : 1 - result.secs_to_shift / 300
            result.timer_bg = "#322428"
            const dur = etime.real_delta(sched[0].start)
            result.timer_text = `Next active in ${dur.hours}h ${dur.minutes}m ${dur.seconds}s`
        }
        return result
    }

    render() {
        return this.renderNodes()
    }

    renderNodes() {
        const {node_data} = this.props
        return node_data == null ? (<div></div>) : (
            <div>
                {Object.keys(node_data.location_map).map(v => this.renderRegion(v, node_data.location_map[v]))}
            </div>
        )
    }

    renderRegion(name, data) {
        return (
            <div key={name}>
                <div style={{backgroundColor: '#383838', width: '100%'}}>{name}</div>
                {Object.keys(data).map(v => this.renderPlace(v, data[v]))}
            </div>
        )
    }
    
    renderPlace(name, gnodes) {
        return (
            <div key={name} style={{paddingLeft: '20px'}}>
                <div>{name}</div>
                <div style={{paddingLeft: '30px', fontSize: "14px"}}>
                    {gnodes.map(v => this.renderNodeByLocation(v))}
                </div>
            </div>
        )
    }
    
    renderNodeByLocation(gnode) {
        const {node_data} = this.props
        const {spawn_map} = this.state
        return (
            <div key={gnode.id}>
                <AllTimer gnode={gnode} ext_map={node_data.ext_map} spawn_map={spawn_map}/>
            </div>
        )
    }
}

const AllTimer = function({gnode, ext_map, spawn_map}) {
    if (spawn_map == null || Object.keys(spawn_map).length === 0) {
        return (<div></div>)
    }
    const sig = ext_map[gnode.id].sig
    const timer = spawn_map[sig]
    const width = `${Math.floor(timer.timer_pct*100)}%`
    return (
        <div style={{minWidth: '200px'}}>
            <div>{gathrare.to_name(gnode)}</div>
            <div style={{fontSize:"14px"}}>
                <span style={{paddingRight: '8px'}}>
                    X:{gnode.location.coords.x} Y:{gnode.location.coords.y}
                </span>
            </div>
            <div>{timer.timer_text}</div>
            <div style={{
                backgroundColor: timer.timer_bg,
                minWidth:width,
                width:width,
                maxWidth:width,
                minHeight: '16px'}}>
            </div>
        </div>
    )
}
