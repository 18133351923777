import imgu from '../common/imgu'

export const role_icon_map = {
    shop: imgu.static.shop,
    repair: imgu.static.repair,
    quest_start: imgu.static.quest
}

export function render_npc_roles(npc, {icon_sz='24px',gap='0px'}={}) {
    const role_icons = []
    if (npc.roles != null && npc.roles.length !== 0) {
        for (let i = 0; i < npc.roles.length; i++) {
            const icon = role_icon_map[npc.roles[i]]
            if (icon != null) {
                role_icons.push(
                    (<img key={npc.roles[i]} src={imgu.to_icon_path(icon)} style={{height:icon_sz,width:icon_sz}}></img>)
                )
            }
        }
    }
    // TODO add quests or whatever else that makes sense later
    return role_icons.length === 0 ? null : (
        <div style={{display:'flex',gap:gap}}>
            {role_icons}
        </div>
    )
}
