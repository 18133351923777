
import imgu from './imgu'

const sourcet = {
    gath_node: {id: 0, icon: 0, target_icon:true},
    fish_spot: {id: 1, icon: 0, target_icon:true},
    enemy: {id: 2, icon: imgu.static.enemy},
    duty: {id: 3, icon: imgu.static.duty},
    merchant: {id: 4, icon: imgu.static.shop},
    reduction: {id: 5, icon: imgu.static.reduction_basic, target_icon:true},
    coffer: {id: 6, icon: imgu.static.items, target_icon:true},
    achieve: {id: 7, icon: imgu.static.achieve, target_icon:true},
    quest: {id: 8, icon: imgu.static.quest, target_icon:true},
    rtask: {id: 9, icon: imgu.static.retainer, target_icon:false}
}

const source_icons = {
    gath_node: imgu.static.gathering,
    fish_spot: imgu.static.fishing_basic,
    enemy: imgu.static.enemy,
    duty: imgu.static.duty,
    merchant: imgu.static.shop,
    reduction: imgu.static.reduction_basic,
    coffer: imgu.static.items,
    quest: imgu.static.quest,
    achieve: imgu.static.achieve,
    rtask: imgu.static.retainer,
    from_string: (name) => {
        const x = source_icons[name]
        return x == null ? 0 : x
    }
}

function get_source_icon(s) {
    const stype = sourcet[s.source_type]
    if (stype == null) {
        return 0
    }
    if (stype.icon === 0) {
        return s.data[s.source_type].icon
    }
    return stype.icon
}

function get_target_icon(s) {
    const stype = sourcet[s.source_type]
    if (stype == null) {
        return 0
    }
    if (stype.target_icon === true) {
        return s.data[s.source_type].icon
    }
    return stype.icon
}

function get_data_field(s, field) {
    return s.data[s.source_type][field]
}

const sources = {
    sourcet: sourcet,
    icons: source_icons,
    get_source_icon: get_source_icon,
    get_target_icon: get_target_icon,
    get_data_field: get_data_field
}

export default sources
