import * as React from 'react';

import api from '../common/api'
import client_util from '../common/util/client_util'
import throttle from '../common/util/throttle'
import {recipe_colspec_default} from '../common/recipe'
import {DebugToggle} from '../components/viewbase'
import {XTable, XTableModel} from '../components/xtable'
import {XSearch, XSearchModel, XSelectFilter, XIntRangeFilter, XStrFilter} from '../components/xsearch'
import {Page} from '../components/Page'

class RecipeSearchPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            payload: null,
            debug_mode: false,
        }
        this.rpp = 50
        this.xsm = XSearchModel.parseUrl(recipe_search_spec, this.props.qsearch)
        this.xtm = XTableModel.parseUrl(this.props.qsearch)

        this.data_loader = throttle.loadOnce(() => this._run_query())
    }

    componentDidMount() {
        console.log('componentDidMount')
        this.data_loader.load()
        client_util.setTitle('Crafting Recipe Database')
    }

    componentDidUpdate(prevProps) {
        if (this.props.qsearch !== prevProps.qsearch) {
            this.xsm = XSearchModel.parseUrl(recipe_search_spec, this.props.qsearch)
            this.xtm = XTableModel.parseUrl(this.props.qsearch)
            this._run_query()
        }
    }

    render() {
        const {payload} = this.state
        return (
            <Page>
            <div className='page-body'>
                {this.render_header()}
                {this.render_search_controls()}
                {this.render_data(payload)}
                {this.render_debug_list(payload)}
            </div>
            </Page>
        )
    }

    render_header() {
        return (
            <div>
                <h1>Crafting Recipe Database</h1>
                <DebugToggle stateFn={(dbg) => this.setState({debug_mode: dbg})}/>
            </div>
        )
    }

    render_search_controls() {
        return (
            <RecipeSearch handler={(e) => this._search_updated(e)} xsmodel={this.xsm}/>
        )
    }

    _search_updated(e) {
        this.xsm = e
        this.xtm = new XTableModel()    // reset table settings for the new data
        this._update_history()
        this._run_query()
    }

    _table_updated(e) {
        this.xtm = e
        this._update_history()
    }

    _update_history() {
        const xsmParams = this.xsm.toUrlParams(recipe_search_spec)
        const xtmParams = this.xtm.toUrlParams()
        const qparams = (xsmParams != null && xtmParams != null)
            ? `${xsmParams}&${xtmParams}`
            : xsmParams != null ? xsmParams : xtmParams
        console.log(qparams)
        if (window.history.state != qparams) {
            window.history.replaceState(qparams, '', qparams == null ? '/recipes' : `/recipes?${qparams}`)
        }
    }

    _run_query() {
        api.recipe.search_recipes(this.xsm.search).then((result, err) => {
            this.setState({payload: result})
        })
    }

    render_data(payload) {
        const {debug_mode} = this.state
        return debug_mode === true ? (<></>) : (
            <div>
                <div style={{paddingTop: '8px'}}>
                    {payload == null ? '' : this.build_table(payload)}
                </div>
            </div>
        )
    }

    build_table(payload) {
        const reslen = payload.results.length
        const msg = payload.ttl_count > reslen
            ? `${reslen} of ${payload.ttl_count} recipes loaded`
            : `${reslen} recipes found`
        return (
            <XTable tableClass='data-t' cols={recipe_colspec_default} data={payload.results} 
                xtmodel={this.xtm} handler={(e) => this._table_updated(e)}
                message={msg} rpp={this.rpp} width='1000px'/>
        )
    }

    render_debug_list(payload) {
        const {debug_mode} = this.state
        return debug_mode === false ? (<></>) : (
            <div style={{justifyContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                {client_util.renderInput(payload)}
            </div>
        )
    }
}

async function load_recipe_options() {
    const data = await api.get_doc('core/opt/recipe.json')
    const options = {
        job: { choices: data.jobs.map((j) => {
            return {value: j.id, content: j.name}
        })}
    }
    const sortFn = (a,b) => a.content.localeCompare(b.content)
    options.job.choices.sort(sortFn)
    console.log(JSON.stringify(options))
    return options
}

const recipe_search_spec = {
    standard: [
        new XStrFilter('search', null, 'qstr', {placeholder:'Search for recipes'}),
        new XIntRangeFilter('level', 'Level', 'min_level', 'max_level'),
        new XSelectFilter('job', 'Job', 'job')
    ]
}

function RecipeSearch({handler, options, optionsLoader, xsmodel}) {
    if (options == null && optionsLoader == null) {
        optionsLoader = load_recipe_options
    }
    return (
        <XSearch handler={handler} spec={recipe_search_spec} 
            options={options} optionsLoader={optionsLoader}
            xsmodel={xsmodel}/>
    )
}


export default client_util.withQSearch(RecipeSearchPage)
