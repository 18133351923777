import * as React from 'react';
import {Link} from "react-router-dom";

import api from '../common/api'
import client_util from '../common/util/client_util'
import imgu from '../common/imgu'
import throttle from '../common/util/throttle'
import cbase from '../common/cbase'
import itemsource from '../common/itemsource'
import { ItemRef, JobRef, DebugToggle, render_source_header } from '../components/viewbase'
import { item_colspec_default, item_colspec, ItemRead, render_stats } from '../common/item'
import { XTable, XCol } from '../components/xtable'
import {Page} from '../components/Page'

export class PatchUpdEntities extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            payload: null,
            _poke: 0,
            debug_mode: false
        }

        let { id } = props.params
        console.log(`id = ${id}`)
        this.patch_id = id
        this.data_loader = throttle.loadOnce(() => this.loadData())
    }

    componentDidMount() {
        console.log('componentDidMount')
        this.data_loader.load()
    }

    componentDidUpdate(prevProps) {
        let rlreq = false
        if (prevProps.params.id !== this.props.params.id) {
            this.patch_id = this.props.params.id
            this.setState({payload:null})
            this.loadData()
            rlreq = true
        }
        if (prevProps.qsearch !== this.props.qsearch) {
            if (!rlreq) {
                this.setState({_poke:this.state._poke === 0 ? 1 : 0})
            }
        }
    }

    loadData() {
        api.call({path:this.getApiUrl(), handler:(result, err) => {
            if (result != null && result.payload != null) {
                const prop_changes = []
                result.payload.entries.forEach((e) => {
                    const local_props = []
                    for (let i = 0; i < e.detail.length; i++) {
                        if (e.detail[i].facet === 'prop') {
                            local_props.push(e.detail[i])
                        }
                    }
                    if (local_props.length != 0) {
                        prop_changes.push({entry:e,props:local_props})
                    }
                })
                this.setState({payload: result.payload, prop_changes: prop_changes})
                this.onPayload(result.payload)
            }
        }})
    }

    onPayload(payload) {

    }

    getApiUrl() {
        return null
    }

    getEntityDetail() {
        return {
            proper: 'Updated Unknowns',
            sub_path:'updated-unknowns'
        }
    }

    renderEntity(entry) {
        return null
    }

    renderExtra(payload) {
        return null
    }

    getPatch() {
        return this.state.payload == null ? null : this.state.payload.patch
    }

    getPropChangeBaseColspec() {
        return []
    }

    set_title() {
        document.title = `${this.getEntityDetail().proper}: Patch ${this.getPatch().patch}`
    }
    render() {
        const {payload} = this.state
        if (payload == null) {
            return (<></>)
        }
        this.set_title()
        return (
            <Page canon={`patch/${this.patch_id}/${this.getEntityDetail().sub_path}`}>
            <div className='page-body'>
                {this.render_header(payload.patch)}
                {this.render_content(payload)}
                {this.render_debug(payload)}
            </div>
            </Page>
        )
    }

    render_header(patch) {
        return (
            <div>
                <h1>
                    <Link className='Clean-link' to={`/patch/${this.patch_id}`}>
                        Patch {patch.patch}
                    </Link> 
                    <span style={{marginLeft:'6px'}}>&gt;&gt; {this.getEntityDetail().proper}</span>
                </h1>
                <DebugToggle stateFn={(dbg) => this.setState({debug_mode: dbg})}/>
            </div>
        )
    }

    render_content(payload) {
        const {debug_mode} = this.state
        return debug_mode === true ? (<></>)
            : this.render_updated_entities(payload)
    }

    render_updated_entities(payload) {
        return (
            <div>
                {this.render_prop_changes(this.state.prop_changes)}
                {this.renderExtra(payload)}
            </div>
        )
    }

    render_prop_changes(prop_changes) {
        if (prop_changes == null || prop_changes.length === 0) {
            return null
        }
        const rpp = prop_changes.length < 30 ? null : 30
        return (
            <div style={{backgroundColor:'#141414',borderRadius:'8px',padding:'10px',margin:'10px 0px',width:'1000px'}}>
                <div style={{fontSize:'18px'}}>Property Changes</div>
                <XTable tableClass='data-t vtop-t noborder-t' cols={this._propchg_colspec} data={prop_changes}
                    rpp={rpp} width='980px'/>                
            </div>
        )
    }

    render_debug(payload) {
        const {debug_mode} = this.state
        return debug_mode === false ? (<></>) : (
            <div style={{justifyContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                {client_util.renderInput(payload)}
            </div>
        )
    }

    _propchg_colspec = [
        ...this.getPropChangeBaseColspec(),
        new XCol({
            label:'', hstyle:{},
            compare: null,
            render: (i) => (<div style={{marginTop:'5px'}}>{render_itemprop_changes(i.props)}</div>)
        })
    ]
}

function render_itemprop_changes(props) {
    if (props.length === 0) {
        return null
    }
    return (
        <div>
            <table className='data-t small-t noborder-t vtop-t'><tbody>
                {props.map((p) => (
                    <tr key={p.name}>
                        <td>{p.name}</td>
                        <td>{p.val}</td>
                        <td style={{color:'#666'}}>{p.old}</td>
                    </tr>
                ))}
            </tbody></table>
        </div>
    )
}
