import React from 'react';
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet-async'

import client_config from '../common/client_config'
import imgu from '../common/imgu'

export function Page({children, canon}) {
    React.useEffect(() => {
        window.scrollTo(0,0)
    }, [])
    const helmTag = canon == null ? null : (
        <Helmet prioritizeSeoTags>
            <link rel='canonical' href={`https://xivnerd.com/${canon}`}/>
        </Helmet>
    )
    return (
        <div className='page-contents'>
            {helmTag}
            {children}
            <Footer2/>
        </div>
    )
}

export function Footer2() {
    return (
        <div className='footer' style={{display:'flex',flexDirection:'column',gap:'20px'}}>
            <div style={{display:'flex', gap:'60px', margin:'4px auto',alignItems:'center'}}>
                <div style={{display: 'flex', flexDirection:'column',alignItems: 'start', gap:'6px'}}>
                    <Link to="/">
                        <img src='/site-logo-stacked.png' className="footer-logo" alt="logo"/>
                    </Link>
                </div>
                <div style={{fontSize:'13px',display:'flex',flexDirection:'column',alignItems:'start'}}>
                    <div style={{color:'#999'}}>v{client_config.ui.version}</div>
                    <div style={{paddingTop:'4px'}}>© 2024 All Rights Reserved</div>
                </div>
            </div>
            <div style={{display:'flex',borderTop:'1px solid #444',borderBottom:'1px solid #444',backgroundColor:'#1c1c1c',
                    justifyContent:'center',alignItems:'center',gap:'12px',padding:'4px 10px'}}>
                <a className='Clean-link' rel="external nofollow noreferrer" target="_blank"
                        href={client_config.ui.discord_url}>
                    <div>
                        <img src={imgu.to_icon_path(imgu.static.site.discord)} alt='discord'
                            style={{height:'30px',width:'30px'}}/>
                    </div>
                </a>
                <a className='Clean-link' rel="external nofollow noreferrer" target="_blank"
                        href={client_config.ui.kofi_url}>
                    <img src={imgu.to_icon_path(imgu.static.site.kofi)} alt='kofi'
                        style={{height:'44px',width:'44px'}}/>
                </a>
                <div style={{flexGrow:1}}></div>
                <div style={{fontSize:'14px',display:'flex',gap:'4px'}}>
                    <Link className="Ext-link" to="/terms">Terms</Link>
                    <span>&#11049;</span>
                    <Link className="Ext-link" to="/privacy">Privacy</Link>
                    {/*<span>&#11049;</span>
                    <Link className="Ext-link" to="/contact">Contact</Link>*/}
                </div>
            </div>
            <StaticNav/>
            <div style={{padding:'4px 0',borderTop:'1px solid #444',display:'flex',flexDirection:'column'}}>
                <div style={{fontSize:'10px',margin:'10px auto'}}>
                    <div>FINAL FANTASY is a registered trademark of Square Enix Holdings Co., Ltd.</div>
                    <div>FINAL FANTASY XIV © 2010-2023 Square Enix Co., Ltd. All Rights Reserved.</div>
                    <div>This site is not endorsed by or associated with Square Enix</div>
                </div>
            </div>    
        </div>
    )
}

const nav_footer = {
    root: {id:'root', name:'Database', entries:[
        {id:'tb-patch', name:`Patch ${client_config.ui.game_version}`, linkTo:`/patch/${client_config.ui.patch_id}`},
    ]},
    'tb-items-cat': {id:'tb-items-cat', name:'Items', entries:[
        {id:'tb-items', name:'Items', icon:imgu.static.items, linkTo:'/items'},
        {id:'tb-currency', name:'Currency', icon:null, linkTo:'/items/currency'},
        {id:'tb-gather', name:'Gathering', icon:imgu.static.gathering, linkTo:'/gatherspots'},
        {id:'tb-craft', name:'Crafting', icon:imgu.static.crafting, linkTo:'/recipes'},
    ]},
    'tb-char': {id:'tb-char', name:'Character', entries:[
        {id:'tb-quest', name:'Quests', linkTo:'/quests'},
        {id:'tb-retainertask', name:'Retainer Tasks', linkTo:'/retainer-tasks'},
        {id:'tb-housing', name:'Housing', linkTo:'/housing'}
    ]},
    'tb-world': {id:'tb-world', name:'World', entries:[
        {id:'tb-npc', name:'NPCs', linkTo:'/npcs'},
        //{id:'tb-quest', name:'Quests', linkTo:'/quests'},
        {id:'tb-zone', name:'Zones', linkTo:'/zones'},
        //{id:'tb-housing', name:'Housing', linkTo:'/housing'}
    ]},
}

function StaticNav() {
    return (
        <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center',gap:'4rem',padding:'0.5rem 0'}}>
            <div className='static-nav-group'>
                <StaticNavSection tbcat={nav_footer.root}/>
                <StaticNavSection tbcat={nav_footer['tb-items-cat']}/>
            </div>
            <div className='static-nav-group'>
                <StaticNavSection tbcat={nav_footer['tb-char']}/>
                <StaticNavSection tbcat={nav_footer['tb-world']}/>
            </div>
        </div>
    )
}

function StaticNavSection({tbcat}) {
    return (
        <div style={{margin:'0.5rem 0'}}>
            <div style={{marginBottom:'10px',fontWeight:'bold'}}>{tbcat.name}</div>
            {tbcat.entries.map((e) => (
                <div style={{padding:'0.2rem 0'}}><Link to={e.linkTo}>{e.name}</Link></div>
            ))}
        </div>
    )
}

/*
function FooterOld() {
    return (
        <div className='footer'>
            <div style={{display:'flex', gap:'60px', margin:'4px 20px'}}>
                <div style={{paddingTop:'6px', fontSize:'12px'}}>
                    <div style={{display:'flex', gap:'8px', alignItems:'center'}}>
                        <a className='Clean-link' rel="external nofollow noreferrer" target="_blank"
                            href={client_config.ui.discord_url}>
                            <img src={imgu.to_icon_path(imgu.static.site.discord)} alt='discord'
                                style={{height:'20px',width:'20px'}}/>
                        </a>
                        <a className='Ext-link' rel="external nofollow noreferrer" target="_blank"
                            href={client_config.ui.discord_url}>Join Discord</a>
                    </div>
                    <div style={{display:'flex', gap:'8px', alignItems:'center'}}>
                        <a className='Clean-link' rel="external nofollow noreferrer" target="_blank"
                            href={client_config.ui.kofi_url} style={{paddingTop:'2px'}}>
                            <img src={imgu.to_icon_path(imgu.static.site.kofi)} alt='kofi'
                                style={{height:'20px',width:'20px'}}/>
                        </a>
                        <a className='Ext-link' rel="external nofollow noreferrer" target="_blank"
                            href={client_config.ui.kofi_url}>Support me on Ko-Fi</a>
                    </div>
                </div>
                <div style={{display:'flex', gap:'20px', margin:'0px 40px', justifyContent:'center'}}>
                    <div style={{display: 'flex', flexDirection:'column',alignItems: 'start', gap:'6px'}}>
                        <Link to="/">
                            <img src='/site-logo-stacked.png' className="footer-logo" alt="logo"/>
                        </Link>
                        {**<div style={{fontSize:'12px',color:'#999',paddingLeft:'4px'}}>{client_config.ui.version}</div>**}
                    </div>
                    <div style={{minWidth:'20px'}}></div>
                    <div style={{fontSize:'12px',display:'flex',flexDirection:'column',alignItems:'end'}}>
                        <div style={{color:'#999'}}>v{client_config.ui.version}</div>
                        <div style={{paddingTop:'8px'}}>© 2023 All Rights Reserved</div>
                        <div>
                            <Link className="Ext-link" to="/terms">Terms</Link>
                            <span style={{padding:'0px 4px'}}>&#11049;</span>
                            <Link className="Ext-link" to="/privacy">Privacy</Link>
                            <span style={{padding:'0px 4px'}}>&#11049;</span>
                            <Link className="Ext-link" to="/contact">Contact</Link>
                        </div>
                    </div>
                    <div style={{minHeight:'100%', minWidth:'1px', backgroundColor:'#343434'}}></div>
                    <div style={{fontSize:'10px'}}>
                        {**<div>FINAL FANTASY, FINAL FANTASY XIV, FFXIV, SQUARE ENIX, and the SQUARE ENIX logo are registered 
                            trademarks or trademarks of Square Enix Holdings Co., Ltd.</div>
                        <div>ENDWALKER, SHADOWBRINGERS, STORMBLOOD, HEAVENSWARD, and A REALM REBORN are registered 
                            trademarks or trademarks of Square Enix Co., Ltd.</div>**}
                        <div>FINAL FANTASY is a registered trademark of Square Enix Holdings Co., Ltd.</div>
                        <div>FINAL FANTASY XIV © 2010-2023 Square Enix Co., Ltd. All Rights Reserved.</div>
                        <div>This site is not endorsed by or associated with Square Enix</div>
                    </div>
                </div>
            </div>
    
        </div>
    )
}*/
