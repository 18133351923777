
const client_config = {
    api: {
        url: '/api' // process.env.FFS_API_URL does not work - need webpack
    },
    ui: {
        allow_debug: false,
        version: '2024.08.04-beta',
        game_version: '7.05',
        patch_id: 464,
        discord_url: 'https://discord.gg/FYB8st5Ee2',
        kofi_url: 'https://ko-fi.com/xivnerd'
    },
    game: {
        max_level: 100
    }
};

export default client_config
