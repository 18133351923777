import * as React from 'react';
import {Link} from "react-router-dom";

import api from '../common/api'
import client_util from '../common/util/client_util'
import throttle from '../common/util/throttle'
import cbase from '../common/cbase'
import imgu from '../common/imgu'
import { gather_item_colspec } from '../common/item'
import {XTable} from '../components/xtable'
import {to_simple_loc, render_location, DebugToggle} from '../components/viewbase'
import {MapPoint, BasicMapView} from '../components/mapview'
import { useLocalSettings } from '../common/user'
import {Page} from '../components/Page'

function FishingSpot(props) {
    const [settings] = useLocalSettings()

    return (<FishingSpotCore {...props} settings={settings}/>)
}

class FishingSpotCore extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fspot: null,
            debug_mode: false
        }
        let { id } = props.params
        console.log(`id = ${id}`)
        this.spot_id = id
        this.colspec = gather_item_colspec(this.props.settings.homeWorld().name)
        this.data_loader = throttle.loadOnce(() => this.loadData())
    }

    componentDidMount() {
        console.log('componentDidMount')
        this.data_loader.load()
    }

    componentDidUpdate(prevProps) {
        if (this.props.settings.homeWorld().id !== prevProps.settings.homeWorld().id) {
            this.colspec.update_world(this.props.settings.homeWorld().name)
            this.loadData()
        }
    }

    loadData() {
        const homeWorld = this.props.settings.homeWorld()
        api.call({path:`fishspot/${this.spot_id}?world=${homeWorld.id}`, handler:(result, err) => {
            const fspot = result
            if (fspot != null) {
                this.setState({fspot: fspot})
            }
        }})
    }

    render() {
        const {fspot} = this.state
        if (fspot == null) {
            return (<></>)
        }
        document.title = fspot.name
        return (
            <Page canon={`gatherspot/fish/${this.spot_id}`}>
            <div className='page-body'>
                {this.render_header(fspot)}
                {this.render_spot(fspot)}
                {this.render_debug_spot(fspot)}
            </div>
            </Page>
        )
    }

    render_header(fspot) {
        return (
            <div>
                <h1 className='small'>{fspot.name}</h1>
                <DebugToggle stateFn={(dbg) => this.setState({debug_mode: dbg})}/>
            </div>
        )
    }

    render_spot(fspot) {
        const {debug_mode} = this.state
        return debug_mode === true ? (<></>) : (
            <div style={{paddingTop: '8px'}}>
                <div style={{display:'flex'}}>
                    <img src={imgu.to_icon_path(fspot.icon)} style={{width: '60px', height: '60px'}}></img>
                    <div style={{margin:'0px 20px'}}>
                        <div>{fspot.name}</div>
                        <div>Level {fspot.level} {fspot.job_nm}</div>
                        <div>{fspot.typename} Spot</div>
                    </div>
                </div>
                {this.render_loc(fspot)}
                {this.render_items(fspot)}
            </div>
        )
    }

    render_loc(fspot) {
        //const zd = fspot._zdetail
        const loc = fspot.location
        const zd = loc.zd
        const vcoords = cbase.map_view_coords(loc.coords, zd.sizef)
        //console.log(`raw_coords: ${JSON.stringify(loc.coords)}`)
        //console.log(`sizef: ${zd.sizef} offx: ${zd.offx} offy: ${zd.offy}`)
        //console.log(`vcoords: ${JSON.stringify(vcoords)}`)
        return (
            <div className='x-section-clean'>
                <h2 className='small'>Location</h2>
                {render_location(to_simple_loc(loc))}
                {zd.map == null ? null : (
                    <div style={{marginTop:'10px'}}>
                        {/*<MapView vheight={340} vwidth={440} scale={0.5}
                            path={zd.map} points={[
                                new MapPoint(fspot.icon, vcoords.x, vcoords.y, {radius:loc.radius}),
                                //new MapPoint(fspot.icon, 0, 0)
                            ]}/>*/}
                        <BasicMapView sz={340} path={zd.map} points={[
                            new MapPoint(fspot.icon, vcoords.x, vcoords.y, {radius:loc.radius}),
                        ]}/>
                    </div>
                )}
            </div>
        )
    }

    render_items(fspot) {
        return (
            <div className='x-section-clean'>
                <h2 className='small'>Fishing Drops</h2>
                <XTable tableClass='data-t noborder-t'
                    simple={true} cols={this.colspec.cols} data={fspot.items}/>
            </div>
        )
    }

    render_debug_spot(fspot) {
        const {debug_mode} = this.state
        return debug_mode === false ? (<></>) : (
            <div style={{justifyContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                {client_util.renderInput(fspot)}
            </div>
        )
    }
}

export default client_util.withParams(FishingSpot)
