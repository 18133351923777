import * as React from 'react';
import {Link} from "react-router-dom";

import api from '../common/api'
import client_util from '../common/util/client_util'
import {Page} from '../components/Page'

function ContactPage() {
    const [email, setEmail] = React.useState('')
    const [emailError, setEmailError] = React.useState(true)
    const [subject, setSubject] = React.useState('')
    const [subjectError, setSubjectError] = React.useState(true)
    const [message, setMessage] = React.useState('')
    const [messageError, setMessageError] = React.useState(true)
    const [messageSending, setMessageSending] = React.useState(false)
    const [messageStatus, setMessageStatus] = React.useState(null)
    const disabledInput = messageSending === true
    const hasErrors = emailError || subjectError || messageError
    React.useEffect(() => client_util.setTitle('Contact'), [])
    return (
        <Page canon='contact'>
        <div className='page-body'>
            <h1>Contact</h1>
            {messageStatus === true ? (
                <div style={{height:'40px',width:'490px',display:'flex',gap:'12px',padding:'0px 8px',alignItems:'center',backgroundColor:'#454273',borderRadius:'6px'}}>
                    <div>Message sent.</div>
                </div>
            ) : messageStatus === false ? (
                <div style={{height:'40px',width:'490px',display:'flex',gap:'12px',padding:'0px 8px',alignItems:'center',backgroundColor:'#5552a3',borderRadius:'6px'}}>
                    <div>Message sending failed. Please try again.</div>
                </div>
            ) : messageSending === true ? (
                <div style={{height:'40px',width:'490px',display:'flex',gap:'12px',padding:'0px 8px',alignItems:'center',backgroundColor:'#5552a3',borderRadius:'6px'}}>
                    <div>Sending message...</div>
                </div>
            ) : null
            }
            {messageStatus === true ? (
                <div style={{marginTop:'12px'}}>
                    <button className='xbtn' onClick={() => {
                        setSubject('')
                        setSubjectError(true)
                        setMessage('')
                        setMessageError(true)
                        setMessageSending(false)
                        setMessageStatus(null)
                    }}>Send Another Message</button>
                </div>
            ) : (
                <table style={{marginTop:'8px'}}><tbody>
                    <tr>
                        <td><ContactLabel text='Email Address' req={true}/></td>
                        <td style={{paddingLeft:'8px'}}>
                            <input value={email} style={{width:'384px'}} disabled={disabledInput}
                                onChange={(e) => email_change(e, setEmail, setEmailError)}>
                            </input>
                        </td>
                        <td valign='top'>
                            {emailError !== true ? null : (
                                <span style={{padding:'0px 4px',color:'#a46983',fontWeight:'bold'}}>!</span>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td><ContactLabel text='Subject' req={true}/></td>
                        <td style={{paddingLeft:'8px'}}>
                            <input value={subject} style={{width:'384px'}} disabled={disabledInput}
                                onChange={(e) => subject_change(e, setSubject, setSubjectError)}>
                            </input>
                        </td>
                        <td valign='top'>
                            {subjectError !== true ? null : (
                                <span style={{padding:'0px 4px',color:'#a46983',fontWeight:'bold'}}>!</span>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td colspan='2'><ContactLabel text='Message' req={true}/></td>
                    </tr>
                    <tr>
                        <td colspan='2'>
                            <textarea value={message} style={{width:'500px'}} disabled={disabledInput} rows={20}
                                onChange={(e) => message_change(e, setMessage, setMessageError)}>
                            </textarea>
                        </td>
                        <td valign='top'>
                            {messageError !== true ? null : (
                                <span style={{padding:'0px 4px',color:'#a46983',fontWeight:'bold'}}>!</span>
                            )}
                        </td>
                    </tr>
                    {disabledInput === true ? null : (
                        <tr>
                            <td colspan='2'>
                                <div style={{display:'flex',paddingTop:'30px'}}>
                                    <div style={{flexGrow:'1',minWidth:'40px'}}></div>
                                    <button className='xbtn' disabled={hasErrors} onClick={() => {
                                        setMessageSending(true)
                                        push_message(email, subject, message, (result) => {
                                            setMessageSending(false)
                                            setMessageStatus(result)
                                        })
                                    }}>Send Message</button>
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody></table>
            )}
        </div>
        </Page>
    )
}

function ContactLabel({text} = {}) {
    return (
        <span>{text}</span>
    )
}

function email_change(e, setEmail, setEmailError) {
    const val = trim_value(e.target.value, 255)
    const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (val === '' || !val.match(pattern)) {
        setEmailError(true)
    }
    else {
        setEmailError(false)
    }
    setEmail(val)
}

function subject_change(e, setSubject, setSubjectError) {
    const val = trim_value(e.target.value, 255)
    setSubjectError(val === '')
    setSubject(val)
}

function message_change(e, setMessage, setMessageError) {
    const val = trim_value(e.target.value, 1600)
    setMessageError(val === '')
    setMessage(val)
}

function trim_value(value, length) {
    return value.length > length ? value.slice(0, length) : value
}

function push_message(email, subject, message, onResult) {
    api.promise({ 
        path: '/core/contactmsg', method: 'POST',
        params: {
            _em1: email,
            _sub1: subject,
            _ms1: message
        }
    }).then((result) => {
        onResult(result.error !== true)
    }).catch((err) => {
        console.log(err)
        onResult(false)
    })
}

export default ContactPage
