import * as React from 'react';
//import {Link} from "react-router-dom";

import api from '../common/api'
import cbase from '../common/cbase'
import client_util from '../common/util/client_util'
import {Page} from '../components/Page'

function HousingPage() {
    const [data, setData] = React.useState(null)

    React.useEffect(() => {
        client_util.setTitle('Housing')
        api.promise({path:'core/housing-summary'}).then((result) => {
            setData(result.payload)
        })
    }, [])

    return (
        <Page canon='housing'>
        <div className='page-body'>
            <h1>Housing</h1>
            <p style={{maxWidth:'800px'}}>
                Players can purchase a plot and house for their personal use or for their free company.
                Plots are limited and can be acquired through a lottery system. Open plots can be bid on, with all
                entrants bidding the same amount based on the value of the plot. One of the bidders will receive the plot and
                the others will receive a refund. The lottery works on a schedule with a 4 day Entry Phase and a 5 day Results Phase.
            </p>
            <h2>Schedule</h2>
            <p>Phase changes occur at the daily reset time.</p>
            {data == null ? null : (<HousingDetail data={data}/>)}
        </div>
        </Page>
    )
}

function HousingDetail({data}) {
    const cur = data.schedule[0]
    const bid_start = cur.bidding === 0 ? null : _simpledate(cur.bidding)
    const res_start = _simpledate(cur.results)
    const res_end = _simpledate(cur.end)
    const cur_rows = []
    if (cur.bidding !== 0) {
        cur_rows.push({
            phase:'Entry',active:true,start:bid_start,end:res_start
        })
    }
    cur_rows.push({
        phase:'Results',active:cur.bidding === 0,start:res_start,end:res_end
    })
    return (
        <div style={{display:'flex'}}>
            <div style={{backgroundColor:'#141414',padding:'8px 12px',borderRadius:'6px',display:'flex',gap:'1rem 2rem',flexWrap:'wrap'}}>
                <div>
                    <div style={{fontSize:'18px',fontWeight:'normal',marginBottom:'18px'}}>Current Lottery</div>
                    {sched_table(cur_rows)}
                </div>
                {_futureschedule(data.schedule)}
            </div>
            {/*<div style={{backgroundColor:'#181818',padding:'8px 12px',borderRadius:'6px'}}>
                <div style={{fontSize:'18px',fontWeight:'normal',marginBottom:'18px'}}>Demolition System</div>
                <table className='data-t noborder-t'>
                    <tbody>
                        <tr>
                            <td style={{width:'80px'}}>Status</td>
                            <td style={{fontWeight:'bold', fontSize:'14px'}}>{data.info.demolition_active === true ? 'Enabled' : 'Disabled'}</td>
                        </tr>
                        <tr>
                            <td style={{width:'80px'}}>Timer</td>
                            <td><span style={{fontWeight:'bold'}}>{data.info.demolition_window}</span> days</td>
                        </tr>
                    </tbody>
                </table>
            </div>*/}
        </div>
    )
}

function _futureschedule(schedule) {
    const entries = schedule.slice(1)
    const rows = []
    
    entries.forEach((e) => {
        const bid_start = _simpledate(e.bidding)
        const res_start = _simpledate(e.results)
        const res_end = _simpledate(e.end)
    
        rows.push({
            phase:'Entry',start:bid_start,end:res_start
        })
        rows.push({
            phase:'Results',start:res_start,end:res_end
        })
    })
    return (
        <div>
            <div style={{fontSize:'1.125rem',fontWeight:'normal',marginBottom:'1.125rem'}}>Upcoming Lotteries</div>
            {sched_table(rows)}
        </div>
    )
}

function _simpledate(x) {
    //const cv = cbase.format_utc_timestamp(x)
    //return cv.replace(':00:00 ', ' ')
    return cbase.format_utc_timestamp(x, false)
}

function sched_table(rows, inactive_greyed=true) {
    const trows = rows.map((r,i) => {
        const tcolor = r.active === true ? '#79b' : inactive_greyed === true ? '#888' : 'inherit'
        return (
            <tr key={i}>
                <td>
                    <div style={{fontSize:'1rem',color:tcolor,minWidth:'140px'}}>
                        {r.phase}
                    </div>
                </td>
                <td style={{color:tcolor}}>{r.start}</td>
                <td style={{color:tcolor}}>{r.end}</td>
            </tr>
        )
    })
    return (
        <div style={{paddingBottom:'6px'}}>
        <table className='data-t lightborder-t' style={{/*width:'404px'*/}}>
            <thead><tr>
                <th>Phase</th><th>Start</th><th>End</th>
            </tr></thead>
            <tbody>
                {trows}
            </tbody>
        </table>
        </div>
    )
}

export default HousingPage
