import * as React from 'react';
import {Link} from "react-router-dom";

import api from '../common/api'
import client_util from '../common/util/client_util'
import throttle from '../common/util/throttle'
import cbase from '../common/cbase'
import {XTable, XCol} from '../components/xtable'
import { DebugToggle} from '../components/viewbase'
import {Page} from '../components/Page'

class PatchList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            payload: null,
            debug_mode: false,
        }
        this.rpp = 50
        this.data_loader = throttle.loadOnce(() => this._run_query())
    }

    componentDidMount() {
        console.log('componentDidMount')
        this.data_loader.load()
        client_util.setTitle()
    }

    render() {
        const {payload} = this.state
        return payload == null ? null : (
            <Page>
            <div className='page-body'>
                {this.render_header()}
                {this.render_data(payload)}
                {this.render_debug_data(payload)}
            </div>
            </Page>
        )
    }

    render_header() {
        return (
            <div>
                <h1>Patch List</h1>
                <DebugToggle stateFn={(dbg) => this.setState({debug_mode: dbg})}/>
            </div>
        )
    }

    _run_query() {
        api.call({
            path:'core/patches', method:'GET',
            handler:(result, err) => this.setState({payload: result.payload})
        })
    }

    render_data(payload) {
        const {debug_mode} = this.state
        const rpp = payload.length > this.rpp ? this.rpp : null
        return debug_mode === true ? (<></>) : (
            <div>
                <div style={{paddingTop: '8px'}}>
                    {
                        payload == null ? ''
                            : <XTable tableClass='data-t' cols={patch_colspec} data={payload} rpp={rpp} />
                    }
                </div>
            </div>
        )
    }

    render_debug_data(payload) {
        const {debug_mode} = this.state
        return debug_mode === false ? (<></>) : (
            <div style={{justifyContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                {client_util.renderInput(payload)}
            </div>
        )
    }
}

const patch_colspec = [
    new XCol({
        label:'Patch',
        compare: (i,j) => cbase.numcmp(
            +i.patch, 
            +j.patch
        ),
        render: (i) => (<Link className='Game-link' to={`/patch/${i.id}`}>{i.patch}</Link>)
    }),
    new XCol({
        label:'Release',
        render: (i) => i.release
    }),
    new XCol({
        label:'Client Data',
        render: (i) => i.dversion
    })
]

export default client_util.withQSearch(PatchList)
