
function strcmp(i, j) {
    if (i == null && j == null) {
        return 0
    }
    if (i == null) {
        return -1
    }
    if (j == null) {
        return 1
    }
    return i.localeCompare(j)
}

function numcmp(i, j) {
    if (i == null && j == null) {
        return 0
    }
    if (i == null) {
        return -1
    }
    if (j == null) {
        return 1
    }
    const diff = i-j
    return diff === 0 ? 0 : diff > 0 ? 1 : -1
}

function url_append(url, path) {
    const urlEnds = url.endsWith('/')
    const pathStarts = path.startsWith('/')
    if (urlEnds && pathStarts) {
        return `${url}${path.slice(1)}`
    }
    if (urlEnds || pathStarts) {
        return `${url}${path}`
    }
    return `${url}/${path}`
}

function loccmp(i, j) {
    const res = loccmp_region(i, j)
    if (res != 0) {
        return res
    }
    return loccmp_zone(i, j)
}

function loccmp_region(i, j) {
    const ir = i == null ? null : loc_access.region_name(i)
    const jr = j == null ? null : loc_access.region_name(j)
    return strcmp(ir, jr)
}

function loccmp_zone(i, j) {
    const iz = i == null ? null : loc_access.zone_name(i)
    const jz = j == null ? null : loc_access.zone_name(j)
    return strcmp(iz,jz)
}

function loccmp_region_multi(i, j) {
    return loccmp_region(i == null || i.length === 0 ? null : i[0], j == null || j.length === 0 ? null : j[0])
}
function loccmp_zone_multi(i, j) {
    return loccmp_zone(i == null || i.length === 0 ? null : i[0], j == null || j.length === 0 ? null : j[0])
}

function loccmp_source(i, j) {
    const ix = i.data.locations == null || i.data.locations.length == 0 ? null : i.data.locations[0]
    const jx = j.data.locations == null || j.data.locations.length == 0 ? null : j.data.locations[0]
    return loccmp(ix, jx)
}

function gathlvlcmp(i_lvl, i_stars, j_lvl, j_stars) {
    return numcmp(
        i_lvl + (i_stars * 0.1),
        j_lvl + (j_stars * 0.1)
    )
}

const _defitemcolor = '#8aaaca'
const _raritycolor = {
    0: _defitemcolor,
    1: _defitemcolor,
    2: '#1c4',
    3: '#38f',
    4: '#af69ee',
    7: '#f795c3'
}
function to_rarity_color(rarity) {
    /*if (rarity === 2) {
        return '#1c4'
    }
    if (rarity === 3) {
        return '#38f'
    }
    if (rarity === 4) {
        return '#af69ee'
    }
    if (rarity === 7) {
        return '#F795C3' //'#F987C5' //'#FF66CC' //'#FF69B4'
    }
    */
    const color = _raritycolor[rarity]
    return (color != null ? color : _defitemcolor)
    //return '#79aaca'
}

function null_empty(x) {
    return x == null ? null : x.length === 0 ? null : x
}

const EMPTYSTR = ''
function str_notnull(s) {
    return s == null ? EMPTYSTR : s
}

const loc_access = {
    region_name: (loc) => loc.region == null ? null : loc.region.name,
    zone_name: (loc) => loc.zone.cfdname == null || loc.zone.cfdname.length === 0 ? loc.zone.name : loc.zone.cfdname,
    zsub_name: (loc) => loc.zsub == null ? null : loc.zsub.namesub,
}

// TODO - add this to cbase and use it instead of loc_access
// TODO - this could also hold methods dealing with normalization/coords variations
class LocationRead {
    static region_name(loc) {
        return loc.region == null ? null : loc.region.name
    }
    static zone_name(loc) {
        return loc.zone.cfdname == null || loc.zone.cfdname.length === 0 ? loc.zone.name : loc.zone.cfdname
    }
    static zsub_name(loc) {
        return loc.zsub == null ? null : loc.zsub.namesub
    }
}

function map_view_coords(coords, sizef, _offset_x=0, _offset_y=0) {
    /*
        const c = mapData.size_factor / 100;
        const x = ((+coords.x) + mapData.offset_x) * c; // x1 = (cx+ox) * c -> x1/c = cx+ox -> x1/c - ox = cx
        const y = ((+coords.y) + mapData.offset_y) * c;
        return {
            x: Math.floor(((41.0 / c) * ((+x + 1024.0) / 2048.0) + 1) * 100) / 100,
            y: Math.floor(((41.0 / c) * ((+y + 1024.0) / 2048.0) + 1) * 100) / 100,
            z: Math.floor((coords.z - mapData.offset_z)) / 100
        };

        s = 41/c * (1024+v)/2048) + 1
        s = (41*(1024+v)) / 2048c + 1
        s - 1 = (41*(1024+v)) / 2048c
        2048c * (s - 1) = 41 * (1024+v)
        (2048c * (s-1))/41 = 1024 + v
        (2048c * (s-1))/41 - 1024 = v

        v = (cv + ov) * c
        v / c = cv + ov
        cv = v / c - ov
    */
    // Note: offsets are not used to translate to view coordinates!
    const c = sizef / 100
    
    const x1 = (2048 * c * (coords.x-1))/41 - 1024
    const x = Math.floor(100 * x1) / 100

    const y1 = (2048 * c * (coords.y-1))/41 - 1024
    const y = Math.floor(100 * y1) / 100

    return {x: x, y: y}
}

function format_utc_timestamp(ptime, includeTime=true) {
    const d = new Date(Date.parse(ptime))
    const dstr = d.toLocaleDateString()
    return includeTime ? `${dstr} ${d.toLocaleTimeString()}` : dstr
}

const cbase = {
    strcmp: strcmp,
    numcmp: numcmp,
    url_append: url_append,
    loccmp: loccmp,
    loccmp_region: loccmp_region,
    loccmp_zone: loccmp_zone,
    loccmp_region_multi: loccmp_region_multi,
    loccmp_zone_multi: loccmp_zone_multi,
    loccmp_source: loccmp_source,
    gathlvlcmp: gathlvlcmp,
    null_empty: null_empty,
    EMPTYSTR: EMPTYSTR,
    str_notnull: str_notnull,
    to_rarity_color: to_rarity_color,
    map_view_coords: map_view_coords,
    format_utc_timestamp: format_utc_timestamp,
    loc_access: loc_access,
}

export default cbase
