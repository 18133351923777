import * as React from 'react';
import {Link} from "react-router-dom";

import api from '../common/api'
import client_util from '../common/util/client_util'
import { useLocalSettings } from '../common/user'
import imgu from '../common/imgu'
import throttle from '../common/util/throttle'
import cbase from '../common/cbase'
import {ItemRef, DebugToggle, vgap} from '../components/viewbase'
import {MapView} from '../components/mapview'
import {XTable, XCol, XTableModel} from '../components/xtable'
import { MarketHomeBanner, render_home_reward_value, market_prop, float_quantity } from '../common/item'
import {Page} from '../components/Page'

const job_info = {
    combat: {id:'combat', title: 'Field Exploration', job: 'Combat'},
    botanist: {id:'botanist', title: 'Woodland Exploration', job: 'Botanist'},
    fisher: {id:'fisher', title: 'Waterside Exploration', job: 'Fisher'},
    miner: {id:'miner', title: 'Highland Exploration', job: 'Miner'}
}

/*
 * retainerJob = combat | botanist | fisher | miner
 */
function RetainerTasksExplore(props) {
    const [settings] = useLocalSettings()

    return (<RetainerTasksExploreCore {...props} settings={settings}/>)
}

class RetainerTasksExploreCore extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            payload: null,
            poke: 0
        }
        this.jobd = job_info[this.props.retainerJob]
        this.colspec = this.mk_colspec()
        this.xtm = XTableModel.parseUrl(this.props.qsearch)
        this.data_loader = throttle.loadOnce(() => this._run_query())
    }

    componentDidMount() {
        this.data_loader.load()
        client_util.setTitle(this.jobd.title)
    }

    componentDidUpdate(prevProps) {
        if (this.props.qsearch !== prevProps.qsearch) {
            this.xtm = XTableModel.parseUrl(this.props.qsearch)
            this.setState((prev) => ({poke:prev.poke === 0 ? 1 : 0}))
        }
        if (this.props.settings.homeWorld().id !== prevProps.settings.homeWorld().id) {
            this.colspec.update_world(this.props.settings.homeWorld().name)
            this._run_query()
        }
    }

    _table_updated(e) {
        console.log(`table_update: ${JSON.stringify(e, 2)}`)
        this.xtm = e
        this._update_history()
    }

    _update_history() {
        const qparams = this.xtm.toUrlParams()
        if (window.history.state != qparams) {
            const base_path = `/retainer-tasks/exploration/${this.jobd.id}`
            window.history.replaceState(qparams, '', qparams == null ? base_path : `${base_path}?${qparams}`)
        }
    }

    _run_query() {
        readPagePayload(this.jobd.id, this.props.settings, (x) => this.setState({payload:x}))
    }

    render() {
        const {payload} = this.state
        return (
            <Page canon={`retainer-tasks/exploration/${this.jobd.id}`}>
                <div className='page-body'>
                    <h1>{this.jobd.title}</h1>
                    <div className='x-titled-section'>
                        <div className='x-title'>Retainer Tasks</div>
                        <table className='basic-t'><tbody>
                            <tr><td>Retainer Level</td><td>1</td></tr>
                            <tr><td>Retainer Class</td><td>{this.jobd.job}</td></tr>
                            <tr><td>Duration</td><td>18 hours</td></tr>
                            <tr>
                                <td>Cost</td>
                                <td><ItemRef id={21072} icon={65049} quantity={2}/></td>
                            </tr>
                        </tbody></table>
                    </div>
                    <div style={{margin:'1rem 0', maxWidth:'800px'}}>
                        <p>These long duration tasks each have their own pool of potential reward items. In addition to the random reward your
                        retainer will also earn a number of Allagan Pieces which can be traded directly for Gil. Exploration tasks provide
                        a large amount of experience and are excellent for levelling your retainer.</p>
                    </div>
                    {payload == null ? null : this.render_tasks(payload)}
                </div>
            </Page>
        )
    }

    render_tasks(payload) {
        const tasks = payload == null ? [] : payload.tasks
        const rpp = tasks.length > 50 ? 50 : null
        const msg = `${tasks.length} tasks found`
        return (
            <div>
                <h2>Tasks</h2>
                <p></p>
                <XTable tableClass='data-t vtop-t' cols={this.colspec.cols} data={tasks} 
                    xtmodel={this.xtm} handler={(e) => this._table_updated(e)}
                    message={msg} rpp={rpp} width='1020px'/>
            </div>
        )
    }

    mk_colspec() {
        const mvcols = task_reward_columns(this.props.settings.homeWorld().name)
        const req_special_col = this.jobd.id === 'combat'
            ? new XCol({
                label:'ILvl',hstyle:{minWidth:'60px',width:'70px',maxWidth:'80px'},
                compare: (i,j) => cbase.numcmp(i.req_ilvl, j.req_ilvl),
                render: (i) => vgap(3, i.req_ilvl)
            })
            : new XCol({
                label:'Gathering',hstyle:{minWidth:'60px',width:'70px',maxWidth:'80px'},
                compare: (i,j) => cbase.numcmp(i.req_gather, j.req_gather),
                render: (i) => vgap(3, i.req_gather)
            })
        return {
            cols: [
                new XCol({
                    label:'Task',hstyle:{minWidth:'182px',width:'184px',maxWidth:'188px'},
                    compare: (i,j) => cbase.strcmp(i.name, j.name),
                    render: (i) => vgap(3, i.name),
                }),
                new XCol({
                    label:'Level',hstyle:{minWidth:'60px',width:'70px',maxWidth:'80px'},
                    compare: (i,j) => cbase.numcmp(i.level, j.level),
                    render: (i) => vgap(3, i.level)
                }),
                req_special_col,
                ...mvcols.cols
            ],
            update_world: (name) => mvcols.update_world(name)
        }
    }
}

function task_reward_columns(homeWorldName) {
    const cols = [
        new XCol({
            label:'Value', 
            hstyle:{minWidth:'242px',width:'244px',maxWidth:'258px'},
            compare: (i,j) => {
                const rw_i = i.rand_reward == null || i.rand_reward.length === 0 ? null : i.rand_reward[0]
                const rw_j = j.rand_reward == null || j.rand_reward.length === 0 ? null : j.rand_reward[0]
                let price_i = rw_i == null ? 0 : market_prop(rw_i, rw_i.hq === true ? 'mprice_hq' : 'mprice_nq')
                let price_j = rw_j == null ? 0 : market_prop(rw_j, rw_j.hq === true ? 'mprice_hq' : 'mprice_nq')
                return cbase.numcmp(price_i, price_j)
            },
            render: (i) => render_task_rewards(i),
            span: {
                size:2,
                /*sep:'default',*/
                justify:'right',
                header: () => (<MarketHomeBanner homeWorldName={homeWorldName}/>)
            }
        }),
        new XCol({
            label:(<span className="material-icon icon18">acute</span>)/*'Act'*/,
        }),
        new XCol({
            label:'Median',hstyle:{minWidth:'80px',width:'80px',maxWidth:'80px'},
            compare: (i,j) => cbase.numcmp(i.rw_median, j.rw_median),
            render: (i) => vgap(3, (
                <div style={{textAlign:'right'}}>{i.rw_median == null ? null : float_quantity(i.rw_median).toLocaleString()}</div>
            )),
        })
    ]
    const mvcol = cols[0]
    return {
        cols: cols,
        update_world: (name) => {
            mvcol.span.header = () => (<MarketHomeBanner homeWorldName={name}/>)
        }
    }
}

function render_task_rewards(t) {
    const entries = t.rand_reward == null ? null : t.rand_reward.map((i) => (
        <div key={i.id} style={{display:'flex',justifyContent:'space-between',width:'100%',padding:'2px 0'}}>
            <ItemRef id={i.id} icon={i.icon} name={i.name} rarity={i.rarity}/>
            {render_home_reward_value(i, 1, {minWidth:'142px'})}
        </div>
    ))
    return (
        <div>{entries}</div>
    )
}

function readPagePayload(jobpathkey, settings, callback) {
    const worldId = settings.homeWorld().id
    api.call({
        path:`retainertask/exploration/${jobpathkey}?world=${worldId}`, method:'GET',
        handler:(result, err) => processPayload(result, callback)
    })
}

function processPayload(payload, callback) {
    const tasks = []
    if (payload != null && payload.tasks != null && payload.tasks.length !== 0) {
        payload.tasks.forEach((t) => {
            if (t.rand_reward != null && payload.market != null) {
                t.rand_reward.forEach((r) => {
                    const mkt = payload.market[r.id]
                    if (mkt != null) {
                        r.market = mkt
                    }
                })
                processTask(t)
            }
            tasks.push(t)
        })
    }
    callback({tasks: tasks})
}

function processTask(task) {
    // sort by mprice (desc)
    // pull out median
    task.rand_reward.sort((i,j) => {
        const ip = market_prop(i, i.hq === true ? 'mprice_hq' : 'mprice_nq')
        const jp = market_prop(j, j.hq === true ? 'mprice_hq' : 'mprice_nq')
        return cbase.numcmp(jp, ip) // reverse sort
    })
    const len = task.rand_reward.length
    const index = len === 0 ? null
        : len % 2 === 0 ? len/2 - 1
        : Math.floor(len/2)
    if (len % 2 === 0) {
        const base_idx = len/2 - 1
        const a = task.rand_reward[base_idx]
        const aprice = market_prop(a, a.hq === true ? 'mprice_hq' : 'mprice_nq')
        const b = task.rand_reward[base_idx+1]
        const bprice = market_prop(b, b.hq === true ? 'mprice_hq' : 'mprice_nq')
        task.rw_median = Math.floor((aprice + bprice)*100/2)/100
    }
    else {
        const a = task.rand_reward[Math.floor(len/2)]
        const aprice = market_prop(a, a.hq === true ? 'mprice_hq' : 'mprice_nq')
        task.rw_median = aprice
    }
}

export default client_util.withQSearch(RetainerTasksExplore)
