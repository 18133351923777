
/*
def icon_path(val):
if isinstance(val, str) and len(val) > 6:
    return val.replace('.tex', '_hr1.png')
val_str = str(val)
while (len(val_str) < 6):
    val_str = f'0{val_str}'
dir_name = f'{val_str[0:3]}000'
return f'ui/icon/{dir_name}/{val_str}_hr1.png'
*/

function to_icon_path(val, hq=false) {
    if (typeof val === "string" || val instanceof String) {
        if (val.startsWith('ui/')) {
            return `/${val}`
        }
        const asint = parseInt(val)
        if (isNaN(asint)) {
            return null
        }
    }
    if (Number.isInteger(val)) {
        let asstr = `${val}`
        while(asstr.length < 6) {
            asstr = `0${asstr}`
        }
        const dir_name = `${asstr.substring(0,3)}000`
        const join = hq === true ? '/hq/' : '/'
        return `/ui/icon/${dir_name}${join}${asstr}_hr1.png`
    }
    return null
}

const imgu = {
    to_icon_path: to_icon_path,
    job_icon: (job) => 62000 + job,
    job_icon_framed: (job) => 62100 + job,
    gc_icon: (gc) => 95000 + gc,
    static: {
        exp: 65001,
        gil: 65002,
        hq16: 840100,
        shop: 60412,
        repair: 60434,
        gather_btn_logging: [60433,60462],  // TODO these gathering regular/rare shouldnt be needed (icon w/ node data)
        gather_btn_harvesting: [60432,60461],
        gather_min_mining: [60438,60464],
        gather_min_quarrying: [60437,60463],
        gather_fsh_fishing: [60465,60466],
        gather_fsh_spearfishing: [60929,60930],
        gather_min_rareinactive: 62016,
        gather_btn_rareinactive: 62017,
        enemy: 840001, //60459, //60558,
        npc: 61661, // zhloe
        //recipe: 60434,
        duty: 60431,
        items: 26509,
        gathering: 60859, //61815 is higher res 64x64 if needed, //62201,
        gathering_nofish: 61755,
        fishing: 61756,
        fishing_basic: 60465,
        crafting: 61816,
        reduction_basic: 840005,
        zones: 63181,
        achieve: 6,
        quest: 71221,
        retainer: 60426,
        map_marker: {
            circle_green: 60403,
            circle_red: 60422,
            circle_orange: 60424,
            circle_white: 60444,
            circle_iconsz: 64,
            pin_green: 60408,
            pin_orange: 60442,
            pin_iconsz: 46,
        },
        site: {
            discord: 880200,
            kofi: 880201
        }
    }
}

export default imgu;
