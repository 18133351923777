import * as React from 'react';

import throttle from '../common/util/throttle'
import cbase from '../common/cbase'
import {Dropdown} from './dropdown'

const e_update = 0
const e_invoke = 1

class FilterBase extends React.Component {
    constructor(props) {
        super(props)
    }

    render() { return (<></>) }

    _keydown(e) {
        if (e.key === 'Enter') {
            e.preventDefault()
            e.stopPropagation()
            this._notify({type:e_invoke})
        }
    }

    _notify(e) {
        if (this.props.handler != null) {
            this.props.handler(e)
        }
    }
}

class XFilterSpec {
    constructor(name) {
        this.name = name
    }

    param_names() {
        if (this.param !== undefined) {
            return [this.param]
        }
        return []
    }

    cleanup_state(search) {
        this.param_names().forEach((p) => search[p] = null)
    }

    get_component(xsearch, fopt, search) {
        return null
    }
}

export class XStrFilter extends XFilterSpec {
    constructor(name, label, param, {inputClass, style, placeholder}={}) {
        super(name)
        this.label = label
        this.param = param
        this.inputClass = inputClass
        this.style = style
        this.placeholder = placeholder
    }

    get_component(xsearch, fopt, search) {
        return (
            <StringFilter key={this.param} label={this.label} param={this.param} value={search[this.param]} placeholder={this.placeholder}
                inputClass={this.inputClass} style={this.style} handler={(e) => xsearch.on_filter_event(e)}/>
        )
    }
}

// label
// param: name of param to store value in search
// value
// placeholder:
// inputClass: class name to apply to input
// style: style to apply to input
// key
// handler
class StringFilter extends FilterBase {
    constructor(props) {
        super(props)
        this.state = {
            value: cbase.str_notnull(this.props.value)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({value:cbase.str_notnull(this.props.value)})
        }
    }

    render() {
        const lbl = this.props.label == null ? null : (
            <span style={{marginRight: '6px'}}>{this.props.label}</span>
        )
        return (
            <div>
                {lbl}
                <input
                    className={this.props.inputClass}
                    style={this.props.style}
                    type="text"
                    placeholder={this.props.placeholder}
                    value={this.state.value}
                    onChange={(e) => this._on_change(e)}
                    onKeyDown={(e) => this._keydown(e)}
                />
            </div>
        )
    }

    _on_change(e) {
        this.setState({value:e.target.value})
        const search = {[this.props.param]:cbase.null_empty(e.target.value)}
        this._notify({type:e_update,search:search})
    }
}

// options are {value: xxx, content: yyy}
// TODO - optionResolver function. SelectFilter takes it and uses it to load options into its own state.
export class XSelectFilter extends XFilterSpec {
    constructor(name, label, param, {inputClass, style}={}) {
        super(name)
        this.label = label
        this.param = param
        this.inputClass = inputClass
        this.style = style
    }

    get_component(xsearch, fopt, search) {
        return (
            <SelectFilter key={this.param} label={this.label} param={this.param} value={search[this.param]}
                choices={fopt == null ? null : fopt.choices}
                inputClass={this.inputClass} style={this.style} handler={(e) => xsearch.on_filter_event(e)}/>
        )
    }
}

function _filter_notify(handler, e) {
    if (handler != null) {
        handler(e)
    }
}
function SelectFilter({label, param, value, choices, inputClass, style, handler}) {
    const [val, setVal] = React.useState(cbase.str_notnull(value))

    const _on_change = (e) => {
        setVal(e.target.value)
        const search = {[param]:cbase.null_empty(e.target.value)}
        _filter_notify(handler, {type:e_update,search:search})
    }
    const lbl = label == null ? null : (
        <span style={{marginRight: '6px'}}>{label}</span>
    )
    return (
        <div>
            {lbl}
            <select
                className={inputClass}
                style={style}
                value={val}
                onChange={_on_change}
            >
                <option key={0} value={cbase.EMPTYSTR}></option>
                {choices == null ? null : choices.map((o) => (
                    <option key={o.value} value={o.value}>{o.content}</option>
                ))}
            </select>
        </div>
    )
}

export class XBooleanFilter extends XFilterSpec {
    constructor(name, label, param, {inputClass, style}={}) {
        super(name)
        this.label = label
        this.param = param
        this.inputClass = inputClass
        this.style = style
    }

    get_component(xsearch, fopt, search) {
       const choices = [{value:1, content:'Yes'}, {value:0, content:'No'}]
       return (
            <SelectFilter key={this.param} label={this.label} param={this.param} value={search[this.param]} choices={choices}
                inputClass={this.inputClass} style={this.style} handler={(e) => xsearch.on_filter_event(e)}/>
       )
    }
}

export class XIntRangeFilter extends XFilterSpec {
    constructor(name, label, param_min, param_max, {inputClass, style, placeholder_min, placeholder_max}={}) {
        super(name)
        this.label = label
        this.param_min = param_min
        this.param_max = param_max
        this.inputClass = inputClass
        this.style = style
        this.placeholder_min = placeholder_min
        this.placeholder_max = placeholder_max
    }

    param_names() {
        return [this.param_min, this.param_max]
    }

    get_component(xsearch, fopt, search) {
        return (
            <IntRangeFilter key={this.param_min} label={this.label} param_min={this.param_min} value_min={search[this.param_min]}
                param_max={this.param_max} value_max={search[this.param_max]}
                placeholder={this.placeholder}
                inputClass={this.inputClass} style={this.style} handler={(e) => xsearch.on_filter_event(e)}/>
        )
    }
}

// label:
// param_min:
// value_min
// param_max:
// value_max
// placeholder_min:
// placeholder_max:
// inputClass: class name to apply to each input
// style: style to apply to each input
// key
// handler
class IntRangeFilter extends FilterBase {
    constructor(props) {
        super(props)
        this.state = {
            min: cbase.str_notnull(this.props.value_min),
            max: cbase.str_notnull(this.props.value_max)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value_min !== this.props.value_min) {
            this.setState({min:cbase.str_notnull(this.props.value_min)})
        }
        if (prevProps.value_max !== this.props.value_max) {
            this.setState({max:cbase.str_notnull(this.props.value_max)})
        }
    }

    render() {
        const lbl = this.props.label == null ? null : (
            <span style={{marginRight: '6px'}}>{this.props.label}</span>
        )
        const instyle = this.props.inputClass == null && this.props.style == null ?
            {width:'50px'} : this.props.style
        return (
            <div>
                {lbl}
                <input className={this.props.inputClass} type='text' placeholder={this.props.placeholder_min}
                    value={this.state.min} 
                    style={instyle}
                    onChange={(e) => this._on_change_min(e)}
                    onKeyDown={(e) => this._keydown(e)}
                />
                <span> - </span>
                <input className={this.props.inputClass} type='text' placeholder={this.props.placeholder_max}
                    value={this.state.max}
                    style={instyle}
                    onChange={(e) => this._on_change_max(e)}
                    onKeyDown={(e) => this._keydown(e)}
                />
            </div>
        )
    }

    _on_change_min(e) {
        this.setState({min:e.target.value})
        const search = {[this.props.param_min]:cbase.null_empty(e.target.value)}
        this._notify({type:e_update,search:search})
    }

    _on_change_max(e) {
        this.setState({max:e.target.value})
        const search = {[this.props.param_max]:cbase.null_empty(e.target.value)}
        this._notify({type:e_update,search:search})
    }
}

export function cleanSearch(search) {
    const x = {}
    for (const key in search) {
        if (search[key] != null) {
            x[key] = search[key]
        }
    }
    return x
}

function buildSearchArgs(spec, xsdata, prefix=null) {
    const params = []
    if (xsdata.addf != null && xsdata.addf.length !== 0) {
        const pn = prefix == null ? 'addf' : `${prefix}addf`
        params.push(`${pn}=${xsdata.addf.join(',')}`)
    }
    if (spec.standard != null) {
        spec.standard.forEach((f) => {
            f.param_names().forEach((p) => {
                if (xsdata.search[p] != null) {
                    const pn = prefix == null ? p : `${prefix}${p}`
                    params.push(`${pn}=${encodeURIComponent(xsdata.search[p])}`)
                }
            })
        })
    }
    if (spec.other != null) {
        spec.other.forEach((f) => {
            f.param_names().forEach((p) => {
                if (xsdata.search[p] != null) {
                    const pn = prefix == null ? p : `${prefix}${p}`
                    params.push(`${pn}=${encodeURIComponent(xsdata.search[p])}`)
                }
            })
        })
    }
    return params.length === 0 ? null : params.join('&')
}

function parseSearchArgs(spec, qsearch, prefix=null) {
    const res = {
        addf: null,
        search: {}
    }
    if (qsearch == null) {
        res.addf = []
        return res
    }
    if (spec.standard != null) {
        spec.standard.forEach((f) => {
            f.param_names().forEach((p) => {
                const pn = prefix == null ? p : `${prefix}${p}`
                res.search[p] = qsearch.get(pn)
            })
        })
    }
    if (spec.other != null) {
        spec.other.forEach((f) => {
            f.param_names().forEach((p) => {
                const pn = prefix == null ? p : `${prefix}${p}`
                res.search[p] = qsearch.get(pn)
            })
        })
    }
    const pn = prefix == null ? 'addf' : `${prefix}addf`
    const addf = qsearch.get(pn)
    res.addf = addf == null ? [] : addf.split(',')
    return res
}

export class XSearchModel {
    constructor(addf=null, search=null) {
        this.addf = addf == null ? [] : addf
        this.search = search == null ? {} : search
    }

    toUrlParams(spec, prefix=null) {
        return buildSearchArgs(spec, this, prefix)
    }

    static parseUrl(spec, qsearch, prefix=null) {
        const x = parseSearchArgs(spec, qsearch, prefix)
        return new XSearchModel(x.addf, x.search)
    }
}

export class XSearch extends React.Component {
    // props:
    //  handler -> receives event when it's time to search, with search object
    //  spec -> {standard:[XFilterSpec], other:[XFilterSpec]}
    //  options -> {filtername:filteropts, ...}
    //  optionsLoader -> Promise<options> () =>
    //  xsmodel -> XSearchModel= addf:[opt-filternames],search:{params} initial state
    constructor(props) {
        super(props)
        let filters = this.props.spec.standard == null ? [] : this.props.spec.standard.map((x) => {
            return {spec:x, show:true, optional:false}
        })
        const stdFilterCount = filters.length
        if (this.props.spec.other != null) {
            filters = filters.concat(this.props.spec.other.map((x) => {
                return {spec:x, show:false, optional:true}
            }))
        }
        this.state = {
            options: this.props.optionsLoader == null ? this.props.options : null,
            stdFilterCount: stdFilterCount,
            _poke: 0
        }
        this.filters = filters
        this.xsm = null
        this.loadXsModel()
        this.data_loader = this.props.optionsLoader == null ? null
            : throttle.loadOnce(() => {
                this.props.optionsLoader().then((options) => this.setState({options:options}))
            })
    }

    loadXsModel() {
        if (this.added != null) this.added.forEach((f) => {
            if (f.optional) f.show = false
        })
        this.added = []
        const xsmodel = this.props.xsmodel
        this.xsm = xsmodel != null ? xsmodel : new XSearchModel()
        if (this.xsm.addf.length !== 0) {
            this.initOptionalFilters()
        }
    }
    initOptionalFilters() {
        const {stdFilterCount} = this.state
        this.xsm.addf.forEach((name) => {
            for (let i = stdFilterCount; i < this.filters.length; i++) {
                const f = this.filters[i]
                if (f.spec.name === name) {
                    f.show = true
                    this.added.push(f)
                    break
                }
            }
        })
    }

    componentDidMount() {
        if (this.data_loader != null) {
            this.data_loader.load()
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.optionsLoader != this.props.optionsLoader) {
            if (this.props.optionsLoader != null) {
                this.props.optionsLoader().then((options) => this.setState({options:options}))
            }
        }
        else if (prevProps.optionsLoader == null && prevProps.options !== this.props.options) {
            this.setState({options: this.props.options})
        }
        if (prevProps.xsmodel !== this.props.xsmodel) {
            this.loadXsModel()
            this._poke()
        }        
    }

    _poke() {
        this.setState({_poke:this.state._poke === 0 ? 1 : 0})
    }

    render() {
        const std_comps = this.filters.filter((f) => f.optional === false).map((f) => this._mkfilter(f))
        const opt_comps = this.added.map((f) => this._mkfilter(f))
        return (
            <div style={{width:'fit-content'}}>
                {std_comps}
                {opt_comps}
                <div style={{display:'flex', marginTop:'8px'}}>
                    {this._mkaddfilter()}
                    <div style={{flexGrow: '1'}}></div>
                    <button className='xbtn' onClick={e => this._notify()}>Search</button>
                </div>
            </div>
        )
    }

    _mkaddfilter() {
        const available = this.filters.filter((f) => f.show === false)
        return available.length === 0 ? (<></>) : (
            <Dropdown
                id='add_filter'
                button={(
                    <button className='xbtn' style={{marginRight: '10px'}}>
                        <div style={{display:'flex',alignItems:'center'}}>
                            <div style={{paddingRight:'2px'}}>Add Filter</div>
                            <div className='material-icon icon18' style={{/*color:'#999'*/}}>expand_more</div>
                        </div>
                    </button>
                )}
                content={(
                    <div className='xmenu-body' style={{
                        display:'flex', flexDirection:'column', alignItems:'stretch', minWidth:'160px'
                    }}>
                        {available.map((f) => (
                            <div key={f.spec.name} className='xmenu-item' onClick={(e) => this._addfilter(f)}>{f.spec.label}</div>
                        ))}
                    </div>
                )}
            />
        )
    }

    _addfilter(f) {
        f.show = true
        this.added.push(f)
        //this.xsm.addf.push(f.spec.name)
        this._poke()
    }

    _rmfilter(f) {
        f.show = false
        f.spec.cleanup_state(this.xsm.search)
        this.added = this.added.filter((x) => x !== f)
        //this.xsm.addf = this.xsm.addf.filter((x) => x !== f.spec.name)
        this._poke()
    }

    _mkfilter(f) {
        const {options} = this.state
        return (
            <div key={f.spec.name} style={{marginTop: '6px', display: 'flex'}}>
                {f.spec.get_component(this, options == null ? null : options[f.spec.name], this.xsm.search)}
                {f.optional === false ? null :
                    <span style={{
                            padding:'0px 10px',marginLeft:'12px',
                            color:'#c23',border:'1px solid #111',borderRadius:'4px',
                            cursor:'pointer'
                        }}
                        onClick={(e) => this._rmfilter(f)}>x</span>
                }
            </div>
        )
    }

    on_filter_event(e) {
        if (e.type == e_update) {
            this.xsm.search = {...this.xsm.search, ...e.search}
        }
        else if (e.type == e_invoke) {
            this._notify()
        }
    }

    _notify() {
        if (this.props.handler != null) {
            this.xsm.addf = this.added.map((f) => f.spec.name)
            this.props.handler(this.xsm)
        }
    }
}
