import * as React from 'react'

import cbase from '../common/cbase'
import imgu from '../common/imgu'
import {ItemRef, RecipeRef} from '../components/viewbase'
import {XCol} from '../components/xtable'
import {ItemRead} from '../common/item'

export const recipe_colspec = ({
    extra_cols=null, read_fn=null, excludeLinks=false
} = {}) => {
    const base_cols = [
        new XCol({
            label:'Name', hstyle:{minWidth:'120px',maxWidth:'280px'},
            compare: (i, j) => cbase.strcmp(
                (read_fn == null ? i : read_fn(i)).name, 
                (read_fn == null ? j : read_fn(j)).name
            ),
            render: (i) => {
                const x = (read_fn == null ? i : read_fn(i))
                return (<RecipeRef id={x.id} name={x.name} icon={imgu.job_icon(x.job)} nolink={excludeLinks}/>)
            }
        }),
        new XCol({
            label:'Job', hstyle:{},
            compare: (i, j) => cbase.strcmp(
                (read_fn == null ? i : read_fn(i)).job_abbrev, 
                (read_fn == null ? j : read_fn(j)).job_abbrev
            ),
            render: (i) => (read_fn == null ? i : read_fn(i)).job_abbrev
        }),
        new XCol({
            label:'Level', hstyle:{},
            compare: (i, j) => cbase.numcmp(
                (read_fn == null ? i : read_fn(i)).recipe_lt.joblevel, 
                (read_fn == null ? j : read_fn(j)).recipe_lt.joblevel
            ),
            render: (i) => (read_fn == null ? i : read_fn(i)).recipe_lt.joblevel
        }),
        new XCol({
            label:'Result', hstyle:{},
            compare: (i,j) => cbase.strcmp(
                (read_fn == null ? i : read_fn(i)).name, 
                (read_fn == null ? j : read_fn(j)).name
            ),
            render: (i) => {
                const x = (read_fn == null ? i : read_fn(i))
                return (<ItemRef id={x.result.id} icon={x.result.icon} rarity={x.result.rarity} quantity={x.result.amount}/>)
            }
        }),
        new XCol({
            label:'ILvl', hstyle:{},
            compare: (i,j) => cbase.numcmp(
                ItemRead.ilvl((read_fn == null ? i : read_fn(i)).result), 
                ItemRead.ilvl((read_fn == null ? j : read_fn(j)).result)
            ),
            render: (i) => ItemRead.ilvl((read_fn == null ? i : read_fn(i)).result)
        }),
        new XCol({
            label:'Type', hstyle:{},
            compare: (i,j) => cbase.strcmp(
                (read_fn == null ? i : read_fn(i)).result.uicat_nm, 
                (read_fn == null ? j : read_fn(j)).result.uicat_nm
            ),
            render: (i) => (read_fn == null ? i : read_fn(i)).result.uicat_nm
        })
    ]

    return extra_cols == null ? base_cols : [...base_cols, ...extra_cols]
}

export const recipe_colspec_default = recipe_colspec()
