import * as React from 'react';
//import {Link} from "react-router-dom";

import api from '../common/api'
import imgu from '../common/imgu'
import config from '../common/client_config'
import gathrare from '../common/gathrare'
import throttle from '../common/util/throttle'
import cbase from '../common/cbase'
import { XCol, XTable, XTableModel } from '../components/xtable'
import { XSearchModel } from '../components/xsearch'
import { 
    item_search_spec, render_stats,
    market_value_currency_columns, calc_per_currency_price, MarketHomeBanner
} from '../common/item'
import {MicroNodeTimer} from '../components/gnode_timer'
import {
    ItemRef, GathNodeRef, FishSpotRef, NpcRef, RecipeRef,
    EnemyRef, DutyRef, GameRef,
    ListingCost, render_source_locations,
    render_gath_level, vgap
} from '../components/viewbase'
import {ItemSearch, ItemRead, Quality} from '../common/item'
import itemsource from '../common/itemsource'
import { 
    duty_source_colspec, enemy_source_colspec, 
    gather_source_colspec, reduction_source_colspec,
    coffer_source_colspec, achieve_source_colspec, quest_source_colspec
} from '../common/common_colspec'
import { retainertask_source_colspec } from '../common/retainertask'

const src_gath_node = itemsource.sourcet.gath_node.id
const src_fish_spot = itemsource.sourcet.fish_spot.id
const src_enemy = itemsource.sourcet.enemy.id
const src_duty = itemsource.sourcet.duty.id
const src_shop = itemsource.sourcet.merchant.id
const src_reduce = itemsource.sourcet.reduction.id
const src_coffer = itemsource.sourcet.coffer.id
const src_achieve = itemsource.sourcet.achieve.id
const src_quest = itemsource.sourcet.quest.id
const src_rtask = itemsource.sourcet.rtask.id

function source_type_id(source_type) {
    const source_def = itemsource.sourcet[source_type]
    return (source_def == null ? -1 : source_def.id)
}

const tab_monster = 'monsters'
const tab_duty = 'duties'
const tab_shops = 'shops'
const tab_gather = 'gather'
const tab_reduce_from = 'reduce_from'
const tab_coffer = 'coffer'
const tab_quest = 'quest'
const tab_retainertask = 'rtask'
const tab_achieve = 'achieve'
const tab_craft = 'craft'
const tab_reduction = 'reduction'
const tab_coffer_items = 'coffer_items'
const tab_recipebook = 'recipebook'
const tab_material = 'material'
const tab_currency = 'currency'
const tabset = [
    tab_shops, tab_monster, tab_duty, tab_gather, tab_quest, tab_retainertask, tab_achieve, tab_reduce_from, tab_coffer,
    tab_craft, tab_reduction, tab_coffer_items, tab_recipebook, tab_material, tab_currency
]
/*
                    {_mktab(tab_monster, 'Monsters', this.tabinfo, active, cb)}
                    {_mktab(tab_duty, 'Duties', this.tabinfo, active, cb)}
                    {_mktab(tab_shops, 'Shops', this.tabinfo, active, cb)}
                    {_mktab(tab_gather, 'Gather', this.tabinfo, active, cb)}
                    {_mktab(tab_reduce_from, 'Reduced From', this.tabinfo, active, cb)}
                    {_mktab(tab_coffer, 'Coffer', this.tabinfo, active, cb)}
                    {_mktab(tab_quest, 'Quest Rewards', this.tabinfo, active, cb)}
                    {_mktab(tab_retainertask, 'Retainer Task Rewards', this.tabinfo, active, cb)}
                    {_mktab(tab_achieve, 'Achievement', this.tabinfo, active, cb)}
                    {_mktab(tab_craft, 'Craft', this.tabinfo, active, cb)}
                    {_mktab(tab_reduction, 'Reduction', this.tabinfo, active, cb)}
                    {_mktab(tab_coffer_items, 'Coffer Contents', this.tabinfo, active, cb)}
                    {_mktab(tab_recipebook, 'Learn Recipes', this.tabinfo, active, cb)}
                    {_mktab(tab_material, 'Material', this.tabinfo, active, cb)}
                    {_mktab(tab_currency, 'Currency', this.tabinfo, active, cb)}
*/
const tabdefs = [
    {id: tab_shops, name:'Shops'},
    {id: tab_monster, name:'Monsters'},
    {id: tab_duty, name:'Duties'},
    {id: tab_gather, name:'Gather'},
    {id: tab_quest, name:'Quest Reward'},
    {id: tab_retainertask, name:'Retainer Task Reward'},
    {id: tab_achieve, name:'Achievement'},
    {id: tab_reduce_from, name:'Reduced From'},
    {id: tab_coffer, name:'Coffer'},
    {id: tab_craft, name:'Craft'},
    {id: tab_reduction, name:'Reduction'},
    {id: tab_coffer_items, name:'Coffer Contents'},
    {id: tab_recipebook, name:'Learn Recipes'},
    {id: tab_material, name:'Material'},
    {id: tab_currency, name:'Currency'}
]
function build_tabinfo(payload) {
    if (payload == null) {
        return {}
    }
    const nSources = payload.sources == null ? 0 : payload.sources.length
    const stype = {}
    if (nSources !== 0) {
        payload.sources.forEach((s) => {
            const entry = stype[s.source_type]
            if (entry == null) {
                stype[s.source_type] = 1
            }
            else {
                stype[s.source_type] = entry+1
            }
        })
    }
    const all_tabs = {
        [tab_monster]: new EnemyTab(stype),
        [tab_duty]: new DutyTab(stype),
        [tab_shops]: new ShopTab(stype),
        [tab_gather]: new GatherTab(stype),
        [tab_quest]: new QuestRewardTab(stype),
        [tab_retainertask]: new RetainerTaskRewardTab(stype),
        [tab_reduce_from]: new ReduceFromTab(stype),
        [tab_coffer]: new CofferSrcTab(stype),
        [tab_achieve]: new AchieveSrcTab(stype),
        [tab_craft]: new CraftTab(payload),
        [tab_reduction]: new ReductionTab(payload),
        [tab_coffer_items]: new CofferItemsTab(payload),
        [tab_recipebook]: new RecipeBookTab(payload),
        [tab_material]: new MaterialTab(payload),
        [tab_currency]: new CurrencyTab(payload)
    }
    const res = {}
    Object.keys(all_tabs).forEach((k) => {
        if (all_tabs[k].count > 0) {
            res[k] = all_tabs[k]
        }
    })
    return res
}

function choose_default_tab(tabinfo) {
    for (let i = 0; i < tabdefs.length; i++) {
        const n = tabdefs[i]
        if (tabinfo[n.id] != null) {
            return n.id
        }
    }
    return null
}

class XSearchAndTableModel {
    constructor(xsm=null, xtm=null) {
        this.xsm = xsm != null ? xsm : new XSearchModel()
        this.xtm = xtm != null ? xtm : new XTableModel()
    }

    toUrlParams(search_spec) {
        const sp = this.xsm.toUrlParams(search_spec)
        const tp = this.xtm.toUrlParams()
        return sp != null && tp != null ? `${sp}&${tp}`
            : sp != null ? sp
            : tp
    }

    static parseUrl(search_spec, qsearch) {
        return new XSearchAndTableModel(
            XSearchModel.parseUrl(search_spec, qsearch),
            XTableModel.parseUrl(qsearch)
        )
    }
}

class ItemRefs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: null,
            _poke: 0
        }
        this.tabinfo = null
        this.deftab = null
        this._proc_payload()
        this._load_params(true)
        console.log('IREF CTOR')
    }

    componentDidUpdate(prevProps) {
        if (this.props.payload !== prevProps.payload) {
            console.log('payload-update')
            this._proc_payload(false)
            //this._load_params()
            this._poke();
        }
        /*if (this.props.homeWorld.id !== prevProps.homeWorld.id) {
            console.log('settings-update')
            //this._load_params()
            //this._poke()
        }*/
        if (this.props.qsearch !== prevProps.qsearch) {
            console.log('qsearch-update')
            this._load_params()
        }
    }

    _proc_payload(build_tabs=true) {
        if (this.props.payload != null && this.props.payload.iref_proc !== true) {
            for (let i = 0; i < this.props.payload.sources.length; i++) {
                const s = this.props.payload.sources[i]
                s.stype_id = source_type_id(s.source_type)
            }
            this.props.payload.iref_proc = true
        }
        if (build_tabs === true) {
            this.tabinfo = build_tabinfo(this.props.payload)
            this.deftab = choose_default_tab(this.tabinfo)
        }
    }

    _load_params(initialLoad=false) {
        if (this.props.qsearch != null) {
            let target = null
            const tab = this.props.qsearch.get('tab')
            console.log(`load_tab = ${tab}`)
            if (tab != null && this.tabinfo[tab] != null) {
                if (initialLoad) {
                    // eslint-disable-next-line
                    this.state.active = tab
                }
                else this.setState({active:tab})
                target = tab
            }
            else target = this.deftab
            if (this.tabinfo[target] != null) {
                this.tabinfo[target].loadUrlParams(this.props.qsearch)
            }
        }
        else {
            console.log('null-qsearch')
        }
    }

    _poke() {
        this.setState({_poke:!this.state._poke})
    }

    _update_history(active=null) {
        if (active == null) {
            active = this.state.active
        }
        const target = active != null ? active : this.deftab
        const tabParams = target === this.deftab ? null : `tab=${encodeURIComponent(target)}`
        const conParams = this.tabinfo[target].toUrlParams()
        const params = tabParams != null && conParams != null 
            ? `${tabParams}&${conParams}`
            : tabParams != null ? tabParams : conParams
        const bpath = `/item/${this.props.payload.item.id}`
        const path = params == null ? bpath : `${bpath}?${params}`
        if (window.history.state !== params) {
            window.history.replaceState(params, '', path)
        }
    }

    _settab(x) {
        this._update_history(x)
        this.setState({active:x})
    }

    _tabmodel_update() {
        this._update_history()
    }

    render() {
        const {payload} = this.props
        if (payload == null) {
            return (<></>)
        }
        const active = this.state.active != null ? this.state.active : this.deftab
        const cb = (x) => this._settab(x)
        return (
            <div>
                {this.deftab == null ? '' 
                    : <h2>Sources & Uses</h2>}
                <div style={{margin:'10px 0px 4px 0px'}}>
                    {_mktabs(this.tabinfo, active, cb)}
                </div>
                {active === null ? '' 
                    : _mkcontent(active, this.tabinfo, payload, this.props.settings,
                        () => this._tabmodel_update())
                }
            </div>
        )
    }
}

function _mktabs(tabinfo, active, handler) {
    const tabs = []
    tabdefs.forEach((tdef) => {
        const tab = _mktab(tdef.id, tdef.name, tabinfo, active, handler)
        if (tab != null) {
            tabs.push(tab)
        }
    })
    return tabs
}

function _mktab(id, label, tabinfo, active, handler) {
    const tabd = tabinfo[id]
    if (tabd == null || tabd.count === 0) {
        return null
    }
    const tabClass = active === id ? 'tabbtnactive' : 'tabbtn'
    const displayText = `${label} (${tabd.count})`
    return (
        <span key={`tab-${id}`}
            className={tabClass} 
            style={{fontWeight:'bold',fontSize:'14px',borderRadius:'4px', padding:'2px 8px'}} 
            onClick={() => handler(id)}>{displayText}</span>
    )
}

function _mkcontent(id, tabinfo, payload, settings, handler) {
    console.log(`mkcontent: ${id}`)
    return (
        <div>
            {id === tab_currency ? null
                : tabinfo[id].render(payload, settings, true, handler)
            }
            {/*id === tab_gather ? <GatherSource payload={payload}/>
                : id === tab_craft ? <CraftSource payload={payload}/>
                : id === tab_shops ? <ShopSource payload={payload}/>
                : id === tab_monster ? <EnemySource payload={payload}/>
                : id === tab_duty ? <DutySource payload={payload}/>
                : id === tab_recipebook ? <RecipeBook payload={payload}/>
                : id === tab_material ? <MaterialDetails payload={payload}/>
                : null
            */}
            {tabinfo[tab_currency] == null ? null
                : tabinfo[tab_currency].render(payload, settings, id === tab_currency, handler)
            }
        </div>
    )
}

class ShopTab {
    constructor(stype) {
        this.count = stype == null || stype.merchant == null ? 0 : stype.merchant
        this.model = null
    }
    toUrlParams() {
        return this.model == null ? null : this.model.toUrlParams()
    }
    loadUrlParams(qsearch) {
        this.model = XTableModel.parseUrl(qsearch)
    }
    render(payload, settings, visible, handler=null) {
        return visible !== true ? '' : (
            <ShopContent payload={payload}
                model={this.model} handler={(e) => {
                    this.model = e
                    handler && handler(this.model)
                }}/>
        )
    }
}
function ShopContent({payload, model, handler}) {
    return (
        <XTable tableClass='data-t vtop-t' cols={_shop_colspec}
            idResolver={(x) => x.data.merchant.id}
            data={payload.sources.filter((x) => x.stype_id === src_shop)} 
            xtmodel={model} handler={handler}/>
    )
}

const _shop_colspec = [
    new XCol({
        label:'Name', hstyle:{},
        compare: (i,j) => cbase.strcmp(i.data.merchant.name, j.data.merchant.name),
        render: (i) => {
            return (
                <NpcRef id={i.data.merchant.id} name={i.data.merchant.name} icon={imgu.static.shop}/>
            )
        }
    }),
    new XCol({
        label:'Location', hstyle:{},
        compare: cbase.loccmp_source,
        render: (i) => vgap(4, render_source_locations(i.data.locations))
    }),
    new XCol({
        label:'Detail', hstyle:{},
        //compare: (i,j) => cbase.strcmp(i.d.source_type, j.d.source_type),
        render: (i) => vgap(4, render_shop_source_listings(i.data))
    })
]
function render_shop_source_listings(shop) {
    const nlistings = shop.merchant.multi_listing != null ? shop.merchant.multi_listing
        : [shop.merchant.listing]
    return (<ListingCost listings={nlistings}/>)
}

class EnemyTab {
    constructor(stype) {
        this.count = stype == null || stype.enemy == null ? 0 : stype.enemy
        this.model = null
    }
    toUrlParams() {
        return this.model == null ? null : this.model.toUrlParams()
    }
    loadUrlParams(qsearch) {
        this.model = XTableModel.parseUrl(qsearch)
    }
    render(payload, settings, visible, handler=null) {
        return visible !== true ? '' : (
            <XTable key='enemycontent' tableClass='data-t' cols={enemy_source_colspec}
                idResolver={(x) => `${x.data.enemy.id}-${x.data.enemy.vid}`}
                data={payload.sources.filter((x) => x.stype_id === src_enemy)} 
                xtmodel={this.model} handler={(e) => {
                    this.model = e
                    handler && handler(this.model)
                }}/>
        )
    }
}

class DutyTab {
    constructor(stype) {
        this.count = stype == null || stype.duty == null ? 0 : stype.duty
        this.model = null
    }
    toUrlParams() {
        return this.model == null ? null : this.model.toUrlParams()
    }
    loadUrlParams(qsearch) {
        this.model = XTableModel.parseUrl(qsearch)
    }
    render(payload, settings, visible, handler) {
        return visible !== true ? '' : (
            <XTable key='dutycontent' tableClass='data-t' cols={duty_source_colspec}
                idResolver={(x) => `${x.data.duty.id}-${x.data.duty.ds_name}-${x.data.duty.bin}`}
                data={payload.sources.filter((x) => x.stype_id === src_duty)}
                xtmodel={this.model} handler={(e) => {
                    this.model = e
                    handler && handler(this.model)
                }}/>
        )
    }
}

class GatherTab {
    constructor(stype) {
        this.count = stype == null ? 0
            : (stype.gath_node == null ? 0 : stype.gath_node) 
            + (stype.fish_spot == null ? 0 : stype.fish_spot)
        this.model = null
    }
    toUrlParams() {
        return this.model == null ? null : this.model.toUrlParams()
    }
    loadUrlParams(qsearch) {
        this.model = XTableModel.parseUrl(qsearch)
    }
    render(payload, settings, visible, handler=null) {
        return visible !== true ? '' : (
            <XTable key='gathercontent' tableClass='data-t' cols={gather_source_colspec}
                idResolver={(x) => `${x.source_type}_${x.data[x.source_type].id}`}
                data={payload.sources.filter((x) => x.stype_id === src_gath_node || x.stype_id === src_fish_spot)}
                xtmodel={this.model} handler={(e) => {
                    this.model = e
                    handler && handler(this.model)
                }}/>
        )
    }
}

class ReduceFromTab {
    constructor(stype) {
        this.count = stype == null ? 0
            : (stype.reduction == null ? 0 : stype.reduction)
        this.model = null
    }
    toUrlParams() {
        return this.model == null ? null : this.model.toUrlParams()
    }
    loadUrlParams(qsearch) {
        this.model = XTableModel.parseUrl(qsearch)
    }
    render(payload, settings, visible, handler=null) {
        return visible !== true ? '' : (
            <div>
                <div style={{display:'flex', alignItems:'center', fontSize:'14px', margin:'6px 0px 4px 0px'}}>
                    <div style={{marginRight:'6px'}}>Aetherial Reduction can be used on the following to extract</div>
                    <ItemRef id={payload.item.id} name={payload.item.name} icon={payload.item.icon}
                        rarity={payload.item.rarity} nolink={true}/>
                </div>
                <XTable key='reducefromcontent' tableClass='data-t' cols={reduction_source_colspec} 
                    idResolver={(x) => x.data.reduction.id}
                    data={payload.sources.filter((x) => x.stype_id === src_reduce)}
                    xtmodel={this.model} handler={(e) => {
                        this.model = e
                        handler && handler(this.model)
                    }}/>
            </div>
        )
    }
}

class ReductionTab {
    constructor(payload) {
        this.count = payload == null ? 0
            : (payload.item.ext.reduce == null ? 0 : payload.item.ext.reduce.length)
        this.model = null
    }
    toUrlParams() {
        return this.model == null ? null : this.model.toUrlParams()
    }
    loadUrlParams(qsearch) {
        this.model = XTableModel.parseUrl(qsearch)
    }
    render(payload, settings, visible, handler=null) {
        return visible !== true ? '' : (
            <div>
                <div style={{display:'flex', alignItems:'center', fontSize:'14px', margin:'6px 0px 4px 0px'}}>
                    <div style={{marginRight:'6px'}}>Aetherial Reduction can be used on</div>
                    <ItemRef id={payload.item.id} name={payload.item.name} icon={payload.item.icon}
                        rarity={payload.item.rarity} nolink={true}/>
                    <div style={{marginLeft:'6px'}}>to extract the following</div>
                    {/*<div style={{fontWeight:'normal'}}>Aetherial Reduction Results</div>*/}
                </div>
                <XTable key='reductioncontent' tableClass='data-t' cols={_reduction_colspec} 
                    idResolver={(x) => x.id}
                    data={payload.item.ext.reduce}
                    xtmodel={this.model} handler={(e) => {
                        this.model = e
                        handler && handler(this.model)
                    }}/>
            </div>
        )
    }
}

const _reduction_colspec = [
    new XCol({
        label:'Name', hstyle:{/*minWidth:'180px'*/},
        compare: (i,j) => cbase.strcmp(i.name, j.name),
        render: (i) => {
            return (<ItemRef id={i.id} name={i.name} icon={i.icon} rarity={i.rarity}/>)
        }
    }),
    new XCol({
        label:'ILvl', hstyle:{maxWidth:'80px', width:'80px',minWidth:'80px'},
        compare: (i,j) => cbase.numcmp(i.level, j.level),
        render: (i) => i.ilvl
    }),
    new XCol({
        label:'Type', hstyle:{},
        compare: (i,j) => cbase.strcmp(i.uicat_nm, j.uicat_nm),
        render: (i) => i.uicat_nm
    })
]

class CofferSrcTab {
    constructor(stype) {
        this.count = stype == null ? 0
            : (stype.coffer == null ? 0 : stype.coffer)
        this.model = null
    }
    toUrlParams() {
        return this.model == null ? null : this.model.toUrlParams()
    }
    loadUrlParams(qsearch) {
        this.model = XTableModel.parseUrl(qsearch)
    }
    render(payload, settings, visible, handler=null) {
        return visible !== true ? '' : (
            <div>
                <XTable key='coffersrccontent' tableClass='data-t' cols={coffer_source_colspec} 
                    idResolver={(x) => x.data.coffer.id}
                    data={payload.sources.filter((x) => x.stype_id === src_coffer)}
                    xtmodel={this.model} handler={(e) => {
                        this.model = e
                        handler && handler(this.model)
                    }}/>
            </div>
        )
    }
}

class CofferItemsTab {
    constructor(payload) {
        this.count = (
            payload == null
            || payload.item.ext.coffer == null
            || payload.item.ext.coffer.results == null
        ) ? 0 : payload.item.ext.coffer.results.length
        this.model = null
    }
    toUrlParams() {
        return this.model == null ? null : this.model.toUrlParams()
    }
    loadUrlParams(qsearch) {
        this.model = XTableModel.parseUrl(qsearch)
    }
    render(payload, settings, visible, handler=null) {
        return visible !== true ? '' : (
            <div>
                <div style={{display:'flex', alignItems:'center', fontSize:'14px', margin:'6px 0px 4px 0px'}}>
                    {payload.item.ext.coffer.type === 'all' ? 'All of ' : 'One of '} the following items awarded on use
                </div>
                <XTable key='cofferitemscontent' tableClass='data-t' cols={_cofferitems_colspec} 
                    idResolver={(x) => x.id}
                    data={payload.item.ext.coffer.results}
                    xtmodel={this.model} handler={(e) => {
                        this.model = e
                        handler && handler(this.model)
                    }}/>
            </div>
        )
    }
}

const _cofferitems_colspec = [
    new XCol({
        label:'Name', hstyle:{/*minWidth:'180px'*/},
        compare: (i,j) => cbase.strcmp(i.name, j.name),
        render: (i) => {
            return (<ItemRef id={i.id} name={i.name} icon={i.icon} rarity={i.rarity}/>)
        }
    }),
    new XCol({
        label:'ILvl', hstyle:{/*maxWidth:'80px', width:'80px',minWidth:'80px'*/},
        compare: (i,j) => cbase.numcmp(i.level, j.level),
        render: (i) => i.ilvl
    }),
    new XCol({
        label:'Req', hstyle:{},
        compare: (i,j) => cbase.numcmp(i.ulvl, j.ulvl),
        render: (i) => i.ulvl
    }),
    new XCol({
        label:'Stats', hstyle:{},
        compare: null,
        render: (i) => render_stats(i)
    }),
    new XCol({
        label:'Type', hstyle:{},
        compare: (i,j) => cbase.strcmp(i.uicat_nm, j.uicat_nm),
        //render: (i) => i.uicat_nm
        render: (i) => (<div style={{fontSize:'12px'}}>{i.uicat_nm}</div>)
    }),
    new XCol({
        label:'Equip By', hstyle:{},
        compare: (i,j) => cbase.strcmp(i.jobcat_nm, j.jobcat_nm),
        //render: (i) => i.jobcat_nm
        render: (i) => (<div style={{fontSize:'12px'}}>{i.jobcat_nm}</div>)
    })
]

class QuestRewardTab {
    constructor(stype) {
        this.count = stype == null || stype.quest == null ? 0 : stype.quest
        this.model = null
    }
    toUrlParams() {
        return this.model == null ? null : this.model.toUrlParams()
    }
    loadUrlParams(qsearch) {
        this.model = XTableModel.parseUrl(qsearch)
    }
    render(payload, settings, visible, handler) {
        const rpp = this.count > 50 ? 50 : null
        return visible !== true ? '' : (
            <XTable key='questrewardcontent' tableClass='data-t' cols={quest_source_colspec} width='920px' rpp={rpp}
                idResolver={(x) => x.data.quest.id}
                data={payload.sources.filter((x) => x.stype_id === src_quest)}
                xtmodel={this.model} handler={(e) => {
                    this.model = e
                    handler && handler(this.model)
                }}/>
        )
    }
}

class RetainerTaskRewardTab {
    constructor(stype) {
        this.count = stype == null || stype.rtask == null ? 0 : stype.rtask
        this.model = null
    }
    toUrlParams() {
        return this.model == null ? null : this.model.toUrlParams()
    }
    loadUrlParams(qsearch) {
        this.model = XTableModel.parseUrl(qsearch)
    }
    render(payload, settings, visible, handler) {
        return visible !== true ? null : (
            <XTable key='rtaskrewardcontent' tableClass='data-t' cols={retainertask_source_colspec} width='800px'
                idResolver={(x) => x.data.rtask.id}
                data={payload.sources.filter((x) => x.stype_id === src_rtask)}
                xtmodel={this.model} handler={(e) => {
                    this.model = e
                    handler && handler(this.model)
                }}/>
        )
    }
}

class AchieveSrcTab {
    constructor(stype) {
        this.count = stype == null ? 0
            : (stype.achieve == null ? 0 : stype.achieve)
        this.model = null
    }
    toUrlParams() {
        return this.model == null ? null : this.model.toUrlParams()
    }
    loadUrlParams(qsearch) {
        this.model = XTableModel.parseUrl(qsearch)
    }
    render(payload, settings, visible, handler=null) {
        return visible !== true ? '' : (
            <div>
                <XTable key='achievesrccontent' tableClass='data-t' cols={achieve_source_colspec}
                    idResolver={(x) => x.data.achieve.id}
                    data={payload.sources.filter((x) => x.stype_id === src_achieve)}
                    xtmodel={this.model} handler={(e) => {
                        this.model = e
                        handler && handler(this.model)
                    }}/>
            </div>
        )
    }
}

class CraftTab {
    constructor(payload) {
        this.count = payload == null || payload.recipes == null ? 0 : payload.recipes.length
        this.model = null
    }
    toUrlParams() {
        return this.model == null ? null : this.model.toUrlParams()
    }
    loadUrlParams(qsearch) {
        this.model = XTableModel.parseUrl(qsearch)
    }
    render(payload, settings, visible, handler=null) {
        return visible !== true ? '' : (
            <XTable key='craftcontent' tableClass='data-t vtop-t' cols={_craft_colspec} 
                idResolver={(r) => r.id}
                data={payload.recipes}
                xtmodel={this.model} handler={(e) => {
                    this.model = e
                    handler && handler(this.model)
                }}/>
        )
    }
}

const _craft_colspec = [
    new XCol({
        label:'Name', hstyle:{/*minWidth:'180px'*/},
        compare: (i,j) => cbase.strcmp(i.name, j.name),
        render: (i) => {
            return (<RecipeRef id={i.id} name={i.name} icon={imgu.job_icon(i.job)}/>)
        }
    }),
    new XCol({
        label:'Job', hstyle:{},
        compare: (i,j) => cbase.strcmp(i.job_abbrev, j.job_abbrev),
        render: (i) => vgap(4, i.job_abbrev)
    }),
    new XCol({
        label:'Level', hstyle:{maxWidth:'80px', width:'80px',minWidth:'80px'},
        compare: (i,j) => cbase.gathlvlcmp(i.level, i.stars, j.level, j.stars),
        render: (i) => vgap(4, render_gath_level(i.level, i.stars))
    }),
    new XCol({
        label:'Yield', hstyle:{},
        compare: (i,j) => cbase.numcmp(i.yield, j.yield),
        render: (i) => vgap(4, i.yield)
    }),
    new XCol({
        label:'Materials', hstyle:{maxWidth:'300px'},
        render: (i) => render_mats(i.materials, 1)
    }),
    new XCol({
        label:'Crystals', hstyle:{minWidth:'100px'},
        render: (i) => render_mats(i.materials, 2)
    })
]

// mode = 0:all 1:non-crystals 2:crystals
function render_mats(mats, mode=0) {
    const entries = []
    for (let i = 0; i < mats.length; i++) {
        const c = mats[i]
        if (mode === 0
                || (mode === 1 && c.uicat_nm !== 'Crystal')
                || (mode === 2 && c.uicat_nm === 'Crystal')) {
            entries.push((
                <div key={i} style={{margin:'0px 0px 2px 0px'}}>
                    <ItemRef id={c.id} name={c.name} icon={c.icon} rarity={c.rarity} quantity={c.amount}/>
                </div>
            ))
        }
    }
    return (<div>{entries}</div>)
}

class MaterialTab {
    constructor(payload) {
        this.count = payload == null || payload.material == null ? 0 : payload.material.stats.DOH.count
        this.model = null
    }
    toUrlParams() {
        return this.model == null ? null : this.model.toUrlParams()
    }
    loadUrlParams(qsearch) {
        this.model = XTableModel.parseUrl(qsearch)
    }
    render(payload, settings, visible, handler=null) {
        return visible !== true ? '' : (
            <MaterialDetails payload={payload} model={this.model}
                handler={(e) => {
                    this.model = e
                    handler && handler(this.model)
                }}
            />
        )
    }
}

function MaterialDetails({payload, model, handler}) {
    const job_rows = build_job_material_rows(payload.material)
    return (
        <div>
            <span style={{fontStyle:'italic',fontSize:'14px',margin:'12px 8px'}}>
                Material data includes both direct and indirect uses.</span>
            <div style={{fontSize:'14px',fontWeight:'bold',margin:'12px 0px'}}>Material Use Summary</div>
            <table className='data-t'>
                <thead><tr>
                    <th>Job</th><th>Levels</th><th></th><th>Recipes</th>
                </tr></thead>
                <tbody>
                    {job_rows}
                </tbody>
            </table>
            <div style={{fontSize:'14px',fontWeight:'bold',margin:'12px 0px 0px 0px'}}>Used In Recipes</div>
            <XTable tableClass='data-t' cols={_matrecipe_colspec} width='900px'
                idResolver={(r) => r.recipe.id}
                data={payload.useInRecipes} rpp={payload.useInRecipes.length > 50 ? 50 : null}
                xtmodel={model} handler={handler}/>
        </div>
    )
}

function build_job_material_rows(material) {
    const raw_rows = Object.keys(material.stats).map((x) => {
        return {job:x, stats:material.stats[x]}
    })
    raw_rows.sort((i,j) => j.stats.count - i.stats.count)
    return raw_rows.map((x) => {
        return (
            <tr key={x.job}>
                <td>{x.job}</td>
                <td>{x.stats.minlvl} - {x.stats.maxlvl}</td>
                <td><JobLevelRange min={x.stats.minlvl} max={x.stats.maxlvl} width={80}/></td>
                <td>{x.stats.count}</td>
            </tr>
        )
    })
}

function JobLevelRange({min, max, width}) {
    const pos_min = Math.round((min/config.game.max_level)*width)
    const pos_max = Math.round((max/config.game.max_level)*width)
    return (
        <div style={{width:`${width}px`,height:'14px',backgroundColor:'#282a32'}}>
            <div style={{width:`${pos_max-pos_min}px`,height:'14px',backgroundColor:'#469',
                marginLeft:`${pos_min}px`,paddingLeft:'2px',display:'inline-block'}}
            >{/*<span style={{position:'absolute',left:'30'}}>{min} - {max}</span>*/}</div>
        </div>
    )
}

const _matrecipe_colspec = [
    new XCol({
        label:'Name', hstyle:{minWidth:'120px',maxWidth:'280px'},
        compare: (i,j) => cbase.strcmp(i.recipe.result.name, j.recipe.result.name),
        render: (i) => {
            return (
                <div style={{display:'flex'}}>
                    <RecipeRef id={i.recipe.id} name={i.recipe.name} icon={62000 + i.recipe.job} 
                        textColor={`${i.direct ? 'inherit' : '#888'}`}/>
                </div>
            )
        }
    }),
    new XCol({
        label:'Job', hstyle:{},
        compare: (i,j) => cbase.strcmp(i.recipe.job_abbrev, j.recipe.job_abbrev),
        render: (i) => i.recipe.job_abbrev
    }),
    new XCol({
        label:'Level', hstyle:{maxWidth:'80px', width:'80px',minWidth:'80px'},
        compare: (i,j) => cbase.gathlvlcmp(i.recipe.level, i.recipe.stars, j.recipe.level, j.recipe.stars),
        render: (i) => render_gath_level(i.recipe.level, i.recipe.stars)
    }),
    new XCol({
        label:'Result', hstyle:{},
        compare: (i,j) =>  cbase.strcmp(i.recipe.result.name, j.recipe.result.name),
        render: (i) => {
            const item = i.recipe.result
            return (<ItemRef id={item.id} icon={item.icon} rarity={item.rarity} quantity={item.amount}/>)
        }
    }),
    new XCol({
        label:'ILvl', hstyle:{},
        compare: (i,j) => cbase.numcmp(i.recipe.result.ilvl, j.recipe.result.ilvl),
        render: (i) => i.recipe.result.ilvl
    }),
    new XCol({
        label:'Type', hstyle:{},
        compare: (i,j) => cbase.strcmp(i.recipe.result.uicat_nm, j.recipe.result.uicat_nm),
        render: (i) => i.recipe.result.uicat_nm
    })
]

class RecipeBookTab {
    constructor(payload) {
        this.count = payload == null || payload.learnRecipes == null ? 0 : payload.learnRecipes.length
        this.model = null
    }
    toUrlParams() {
        return this.model == null ? null : this.model.toUrlParams()
    }
    loadUrlParams(qsearch) {
        this.model = XTableModel.parseUrl(qsearch)
    }
    render(payload, settings, visible, handler=null) {
        if (!visible) return null
        const data = payload.learnRecipes.map((x) => {
            return {direct:true,recipe:x}
        })
        return (
            <XTable key='recipebookcontent' tableClass='data-t' cols={_matrecipe_colspec} width='900px'
                idResolver={(r) => r.recipe.id}
                data={data}
                xtmodel={this.model} handler={(e) => {
                    this.model = e
                    handler && handler(this.model)
                }}/>
        )
    }
}

class CurrencyTab {
    constructor(payload) {
        this.count = payload == null || payload.item == null ? 0 : payload.item.ext.curct
        this.model = null
    }
    toUrlParams() {
        return this.model == null ? null : this.model.toUrlParams(item_search_spec)
    }
    loadUrlParams(qsearch) {
        this.model = XSearchAndTableModel.parseUrl(item_search_spec, qsearch)
    }
    render(payload, settings, visible, handler=null) {
        console.log('currency-tab-render')
        return (
            <CurrencyContent item={payload.item} settings={settings}
                visible={visible} model={this.model}
                handler={(e) => {
                    this.model = e
                    handler && handler(this.model)
                }}/>
        )
    }
}

const _cur_colspec = (settings) => {
    const mvcols = market_value_currency_columns({
        homeWorldName:settings == null ? null : settings.homeWorld().name
    })
    const cols = [
        new XCol({
            label:'Name', hstyle:{minWidth:'200px',maxWidth:'400px'},
            compare: (i,j) => cbase.strcmp(i.item.name, j.item.name),
            render: (i) => (
                <ItemRef id={i.item.id} name={i.item.name} icon={i.item.icon} rarity={i.item.rarity}/>
            )
        }),
        new XCol({
            label:'ILvl', hstyle:{},
            compare: (i,j) => cbase.numcmp(ItemRead.ilvl(i.item), ItemRead.ilvl(j.item)),
            render: (i) => vgap(4, ItemRead.ilvl(i.item))
        }),
        new XCol({
            label:'Req', hstyle:{},
            compare: (i,j) => cbase.numcmp(ItemRead.ulvl(i.item), ItemRead.ulvl(j.item)),
            render: (i) => vgap(4, ItemRead.ulvl(i.item))
        }),
        new XCol({
            label:'Detail', hstyle:{},
            compare: (i,j) => cbase.strcmp(i.item.uicat_nm, j.item.uicat_nm),
            render: (i) => vgap(4, (
                <div>
                    <div style={{marginBottom:'2px'}}>{i.item.uicat_nm}</div>
                    {render_stats(i.item)}
                </div>
            ))
        }),
        new XCol({
            label: 'Listings', hstyle:{minWidth:'100px',maxWidth:'300px'},
            render: (i) => (<ListingCost listings={i.l}/>)
        }),
        ...mvcols.cols
    ]
    return {
        cols: cols,
        update_world: (name) => mvcols.update_world(name)
    }
}

class CurrencyContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            payload: null,
            visible: this.props.visible,
            _poke: 0
        }
        this.colspec = _cur_colspec(this.props.settings)
        this.model = this.props.model != null ? this.props.model : new XSearchAndTableModel()
        this.data_loader = throttle.loadOnce(() => this._run_query())
    }

    componentDidMount() {
        this.data_loader.load()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.visible !== this.props.visible) {
            this.setState({visible: this.props.visible})
        }
        if (prevProps.model !== this.props.model) {
            this.model = this.props.model != null ? this.props.model : new XSearchAndTableModel()
            this.setState({_poke:this.state._poke === 0 ? 1 : 0})
        }
        if (prevProps.settings.homeWorld().id !== this.props.settings.homeWorld().id) {
            this.colspec.update_world(this.props.settings.homeWorld().name)
            this._run_query()
        }
        //return true
    }

    _notify() {
        if (this.props.handler != null) {
            this.props.handler(this.model)
        }
    }

    _search_updated(e) {
        console.log(`_search_updated: ${JSON.stringify(e)}`)
        this.model.xsm = e
        this.model.xtm = new XTableModel()
        this._run_query()
        this._notify()
    }

    _table_updated(e) {
        console.log(`_table_updated: ${JSON.stringify(e)}`)
        this.model.xtm = e
        this._notify()
    }

    _run_query() {
        console.time('item_currency_query')
        const cur_item = this.props.item
        const search = {
            cur_id:cur_item.id, 
            ...this.model.xsm.search,
            home_world: this.props.settings.homeWorld().id
        }
        api.item.search_currency_items(search).then((result, err) => {
            if (result != null) {
                result.results.forEach((row) => {
                    const raw_listings = row.l
                    row.l = []
                    const quals = {}
                    for (let i = 0; i < raw_listings.length; i++) {
                        const listing = _listing_fill(raw_listings[i], cur_item, row.item)
                        row.l.push(listing)
                        listing.cost.filter((x) => x.id === cur_item.id).forEach((x) => {
                            const xqt = x.qt != null ? x.qt : 1
                            //console.log(`xqt = ${xqt}`)
                            quals[x.hq === true ? 'hq' : 'nq'] = xqt
                        })
                    }
                    const keys = Object.keys(quals)
                    row.market_qual = keys.length == 2 ? undefined
                        : keys[0] === 'hq' ? Quality.hq : Quality.nq

                    if (row.l.length !== 1) {
                        row.per_cur = 0
                    }
                    else {
                        let bqt = 1
                        row.l[0].buy.filter((x) => x.id === row.item.id).forEach((x) => {
                            const xqt = x.qt != null ? x.qt : 1
                            bqt = xqt
                        })
                        row.per_cur = row.l.length !== 1 ? 0 
                            : calc_per_currency_price(row.item, bqt, row.market_qual, quals[keys[0]])
                    }
                })
            }
            console.timeEnd('item_currency_query')
            this.setState({payload:result})
        })
    }

    render() {
        console.log('currency-content:render')
        const {visible, payload} = this.state
        if (payload == null)
            return (<></>)
        console.log(`payload-count: ${payload.results.length}`)
        const rootStyle = visible ? {} : {display:'none'}/*{visibility:'hidden',height:'0px',maxHeight:'0px'}*/
        return (
            <div style={rootStyle}>
                {this._render_search()}
                {this._render_table(payload)}
            </div>
        )
    }

    _render_search() {
        return this.props.item.ext.curct < 40 ? '' : (
            <div style={{margin:'10px 0px 6px 0px'}}>
                <ItemSearch handler={(e) => this._search_updated(e)} xsmodel={this.model.xsm}/>
            </div>
        )
    }

    _render_table(payload) {
        const reslen = payload.results.length
        const rpp = reslen > 50 ? 50 : null
        const msg = payload.ttl_count > reslen
            ? `${reslen} of ${payload.ttl_count} items loaded`
            : `${reslen} items found`
        console.log(`render-table: reslen = ${reslen} rpp = ${rpp}`)
        return (
            <XTable tableClass='data-t vtop-t' cols={this.colspec.cols} data={payload.results} 
                message={msg} rpp={rpp} width='1160px' idResolver={(x) => x.item.id}
                xtmodel={this.model.xtm} handler={(e) => this._table_updated(e)}/>
        )
    }
}

function _listing_fill(listing, citem, bitem) {
    return {
        'buy':listing.buy.map((b) => {
            return (b.id !== bitem.id) ? b : {
                ...b, name:bitem.name, icon:bitem.icon, rarity:bitem.rarity
            }
        }),
        'cost':listing.cost.map((c) => {
            return (c.id !== citem.id) ? c : {
                ...c, name:citem.name, icon:citem.icon, rarity:citem.rarity
            }
        }),
        'req':listing.req
    }
}

export default ItemRefs
