import * as React from 'react'
import {Link, useLocation, useNavigate} from "react-router-dom";

import api from '../common/api'
import client_util from '../common/util/client_util'
import imgu from '../common/imgu'
import throttle from '../common/util/throttle'
import cbase from '../common/cbase'
import {ClientSettings, useLocalSettings, WorldRepo} from '../common/user'
import { DebugToggle } from '../components/viewbase'

export function SettingsEdit({settings, saveSettings, editSettings, setEditSettings}) {
    /*React.useEffect(() => {
        //const e = document.getElementById('page.contents')
        document.body.style.overflow = editSettings === true ? 'hidden' : 'unset'
    }, [editSettings])*/
    return editSettings === false ? null : (
        <div style={{
            position:'absolute',top:'0px',left:'0px',width:'100%',height:'100vh',
            backgroundColor:'#00000070',zIndex:'2000'
        }} onClick={null}>
            <div className='header-dropui'>
            <SettingsPageCore settings={settings} saveSettings={saveSettings} onClose={() => setEditSettings(false)}/>
            </div>
        </div>
    )
}

class SettingsPageCore extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            xsettings: ClientSettings.from_json(props.settings.json()),
            worldRepo: null,
            _poke: 0,
            debug_mode: false
        }

        this.data_loader = throttle.loadOnce(() => this.loadData())
    }

    componentDidMount() {
        this.data_loader.load()
    }

    poke() {
        this.setState({_poke:this.state._poke === 0 ? 1 : 0})
    }

    loadData() {
        api.promise({
            path:'core/dcWorld', method:'GET'
        })
        .then((data) => {
            const repo = new WorldRepo(data.payload)
            repo.world_order = [...Object.values(repo.worldMap)]
            repo.world_order.sort((i, j) => cbase.strcmp(i.name, j.name))
            this.setState({worldRepo:repo})
        })
    }

    render() {
        const {worldRepo, xsettings} = this.state
        const {saveSettings} = this.props
        return worldRepo == null ? null : (
            <div style={{display:'flex', flexDirection:'column', alignItems:'end'}}>
                <div className='ui-element' style={{maxWidth:'420px',width:'100%',padding:'20px',borderBottomLeftRadius:'6px',
                    borderTop:'1px solid #444'}}>
                    <div style={{marginBottom: '14px'}}>
                        <span style={{fontSize: '20px', fontWeight: 'bold'}}>
                            Settings
                        </span>
                        <DebugToggle stateFn={(dbg) => this.setState({debug_mode: dbg})}/>
                    </div>
                    {render_body(saveSettings, xsettings, worldRepo, this.props.onClose)}
                </div>
            </div>
        )
    }
}

function render_body(saveSettings, xsettings, worldRepo, onClose) {
    const cancel = () => {
        onClose(false)
    }
    const save = () => {
        const changed = saveSettings(xsettings)
        onClose(changed)
    }
    return worldRepo == null ? null : (
        <div style={{paddingTop:'12px', borderTop:'1px solid #666'}}>
            <div style={{margin:'0px 0px 60px 0px',display:'flex',flexDirection:'column',gap:'0.5rem'}}>
                <div>Home World</div>
                <div><SelectHomeWorld xsettings={xsettings} worldRepo={worldRepo}/></div>
            </div>
            <div style={{display:'grid',gridTemplateColumns:'repeat(2,1fr)',gap:'8px'}}>
                <button className='xbtn' onClick={save}>Save</button>
                <button className='xbtn' onClick={cancel}>Cancel</button>
            </div>
        </div>
    )
}

function SelectHomeWorld({xsettings, worldRepo}) {
    const [worldId, setWorldId] = React.useState(xsettings.homeWorld().id)

    const choices = worldRepo.world_order.map((w) => {
        return (
            <option key={w.id} value={w.id}>{w.name} - {w.dc_name}</option>
        )
    })
    const _on_change = (e) => {
        xsettings.setHomeWorldId(+e.target.value, worldRepo)
        setWorldId(+e.target.value)
    }
    console.log('SelectHomeWorld/')
    return (
        <select
            className={null}
            style={{width:'100%',backgroundColor:'#3a3c3e',color:'#bbb',fontSize:'1rem',border:'1px solid #111',borderRadius:'4px',padding:'4px 8px'}}
            value={worldId}
            onChange={_on_change}
        >
            {choices}
        </select>
    )
}

