
export const qkind_map = {
    0: 'Quest',
    1: 'Main Scenario Quest',
    2: 'Feature Quest',
    3: 'Job Quest',
    4: 'Leve Quest'
}

export function qkind_display(qkind) {
    const dname = qkind_map[qkind]
    return dname == null ? qkind_map[0] : dname
}
