import EorzeaTime from '../EorzeaTime'

function start() {
    if (ts_data.interval === null) {
        ts_data.interval = setInterval(() => pulse(), 1000)
    }
}
function stop() {
    if (ts_data.interval !== null) {
        clearInterval(ts_data.interval)
        ts_data.interval = null
    }
}
function pulse() {
    const event = now()
    ts_data.handlers.forEach((h) => h(event))
}
function now() {
    const date_now = new Date()
    const epoch_time = Math.floor(date_now.getTime() / 1000)
    return {
        date: date_now,
        epoch: epoch_time,
        eorzea: new EorzeaTime(epoch_time)
    }
}
const ts_data = {
    interval: null,
    handlers: []
}

const timeservice = {
    now: now,
    add_handler: (handler) => {
        if (handler != null) {
            ts_data.handlers.push(handler)
            start()
        }
    },
    remove_handler: (handler) => {
        if (handler != null) {
            const i = ts_data.handlers.indexOf(handler)
            if (i != -1) {
                ts_data.handlers.splice(i, 1)
                if (ts_data.handlers.length === 0) {
                    stop()
                }
            }
        }
    }
}
export default timeservice
