import * as React from 'react';

import api from '../common/api'
import client_util from '../common/util/client_util'
import throttle from '../common/util/throttle'
import cbase from '../common/cbase'
import imgu from '../common/imgu'
import {market_value_columns, render_stats, ItemRead} from '../common/item'
import { render_npc_roles } from '../common/npc'
import {XTable, XCol} from '../components/xtable'
import {to_simple_loc, render_location, ItemRef, vgap, ListingCost, DebugToggle} from '../components/viewbase'
import {MapPoint, BasicMapView} from '../components/mapview'
import { quest_ref_colspec } from '../common/common_colspec'
import {Page} from '../components/Page'

class NpcPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            npc: null,
            debug_mode: false
        }
        let { id } = props.params
        console.log(`id = ${id}`)
        this.npc_id = id
        this.colspec = _mk_colspec()
        this.data_loader = throttle.loadOnce(() => this.loadData())
    }

    componentDidMount() {
        console.log('componentDidMount')
        this.data_loader.load()
    }

    componentDidUpdate(prevProps) {
        if (this.props.params.id !== prevProps.params.id) {
            this.npc_id = this.props.params.id
            this.loadData()
        }
    }

    loadData() {
        api.call({path:`npc/${this.npc_id}`, handler:(result, err) => {
            if (err) {
                console.log(err)
                return
            }
            const npc = _process_npc(result.payload)
            this.setState({npc: npc})
            //this.processPayload(npc)
        }})
    }

    render() {
        const {npc} = this.state
        if (npc == null) {
            return (<></>)
        }
        document.title = npc.name
        return (
            <Page canon={`npc/${this.npc_id}`}>
            <div className='page-body'>
                {this.render_header(npc)}
                {this.render_body(npc)}
                {this.render_debug(npc)}
            </div>
            </Page>
        )
    }

    render_header(npc) {
        return (
            <div>
                <h1>{npc.name}</h1>
                <DebugToggle stateFn={(dbg) => this.setState({debug_mode: dbg})}/>
            </div>
        )
    }

    /*render_body(npc) {
        const {debug_mode} = this.state
        return debug_mode === true ? (<></>) : (
            <div style={{paddingTop: '8px'}}>
                <div style={{marginBottom:'10px'}}>
                    <div style={{marginLeft:'10px'}}>NPC: {npc.name}</div>
                    {_render_npc_roles(npc)}
                </div>
                {this.render_locs(npc)}
            </div>
        )
    }*/
    // #0078d4
    render_body(npc) {
        const {debug_mode} = this.state
        if (debug_mode === true) {
            return (<></>)
        }
        const roles = render_npc_roles(npc, {icon_sz:'30px'})
        return (
            <div style={{paddingTop:'8px'}}>
                <div style={{marginBottom:'10px'}}>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <div style={{padding:'0px 8px',backgroundColor:'#2258a4',borderRadius:'4px',
                            display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <div style={{fontSize:'18px'}}>NPC</div>
                        </div>
                        <div style={{margin:'0px 12px'}}>{npc.name}</div>
                        {/*_render_npc_roles(npc)*/}
                    </div>
                    {roles == null ? null : (
                        <div style={{marginTop:'6px'}}>{roles}</div>
                    )}
                </div>
                {this.render_locs(npc)}
                {this.render_quests(npc)}
                {this.render_shop_data(npc)}
            </div>
        )
    }

    render_locs(npc) {
        return npc.levels == null || npc.levels.length === 0 ? null : (
            <div>
                <h2>Location</h2>
                <div style={{margin: '8px 0px 0px 0px'}}>
                    <LocationSection locs={npc.levels}/>
                </div>
            </div>
        )
    }

    render_quests(npc) {
        return npc.quest_start == null || npc.quest_start.length === 0 ? null : (
            <QuestSection quests={npc.quest_start}/>
        )
    }

    render_shop_data(npc) {
        return npc.shopd == null || npc.shopd.length === 0 ? null : (
            <ShopSection shopd={npc.shopd} colspec={this.colspec}/>
        )
    }

    render_debug(npc) {
        const {debug_mode} = this.state
        return debug_mode === false ? (<></>) : (
            <div style={{justifyContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                {client_util.renderInput(npc)}
            </div>
        )
    }
}

function _loc_tabstr(loc) {
    const zname = cbase.loc_access.zone_name(loc)
    const subname = cbase.loc_access.zsub_name(loc)
    if (subname != null) {
        return `${zname} - ${subname}`
    }
    return zname
}

function LocationSection({locs}) {
    if (locs == null || locs.length === 0) {
        return null
    }
    return locs.length > 1 ? (<LocationsView locs={locs}/>) : _loc_body(locs[0], 0)
}

function LocationsView({locs}) {
    const [selected, setSelected] = React.useState(0)

    if (locs == null || locs.length === 0) {
        return null
    }
    const tabs = locs.map((loc, i) => (
        <span key={i} className={`${i === selected ? 'tabbtnactive' : 'tabbtn'}`} 
            style={{padding:'2px 8px',borderRadius:'4px'}}
            onClick={i === selected ? null : (e) => setSelected(i)}>
                {_loc_tabstr(loc)}
        </span>
    ))
    return (
        <div>
            <div style={{display:'flex'}}>{tabs}</div>
            {_loc_body(locs[selected], selected)}
        </div>
    )
}

function _loc_body(loc, key) {
    const zd = loc.zd
    const vcoords = loc.coords == null ? null : cbase.map_view_coords(loc.coords, zd.sizef)
    const points = vcoords == null ? null 
        : [new MapPoint(imgu.static.map_marker.circle_green, vcoords.x, vcoords.y, {icon_size:imgu.static.map_marker.circle_iconsz})]
    console.log(`coords = ${JSON.stringify(loc.coords, null, 2)} sizef = ${zd.sizef}`)
    console.log(`points = ${JSON.stringify(points, null, 2)}`)
    // mapsize 340 440 on gnode
    return (
        <div style={{margin:'12px 0px'}}>
            {render_location(to_simple_loc(loc))}
            {zd.map == null ? null : (
                <div style={{marginTop:'10px'}}>
                    {/*<MapView key={key} vheight={340} vwidth={440} scale={0.5}
                        path={zd.map} points={points}/>*/}
                    <BasicMapView key={key} sz={340} path={zd.map} points={points}/>
                </div>
            )}
        </div>
    )
}

function QuestSection({quests}) {
    const rpp = quests.length > 20 ? 20 : null
    return (
        <div style={{marginTop:'32px'}}>
            <h2>Starts Quests</h2>
            <div style={{margin: '0px 0px 0px 0px'}}>
                <XTable tableClass='data-t' cols={quest_ref_colspec} data={quests}
                    rpp={rpp} width='900px' idResolver={(x) => x.id}/>
            </div>
        </div>
    )
}

function _mk_colspec() {
    /*const mvcols = market_value_columns({
        homeWorldName:settings == null ? null : settings.homeWorld().name
    })*/
    const cols = [
        new XCol({
            label:'Name', hstyle:{minWidth:'200px',maxWidth:'400px'},
            compare: (i,j) => cbase.strcmp(i.item.name, j.item.name),
            render: (i) => (
                <ItemRef id={i.item.id} name={i.item.name} icon={i.item.icon} rarity={i.item.rarity}/>
            )
        }),
        new XCol({
            label:'ILvl', hstyle:{},
            compare: (i,j) => cbase.numcmp(ItemRead.ilvl(i.item), ItemRead.ilvl(j.item)),
            render: (i) => vgap(4, ItemRead.ilvl(i.item))
        }),
        new XCol({
            label:'Req', hstyle:{},
            compare: (i,j) => cbase.numcmp(ItemRead.ulvl(i.item), ItemRead.ulvl(j.item)),
            render: (i) => vgap(4, ItemRead.ulvl(i.item))
        }),
        new XCol({
            label:'Detail', hstyle:{},
            compare: (i,j) => cbase.strcmp(i.item.uicat_nm, j.item.uicat_nm),
            render: (i) => vgap(4, (
                <div>
                    <div style={{marginBottom:'2px'}}>{i.item.uicat_nm}</div>
                    {render_stats(i.item)}
                </div>
            ))
        }),
        new XCol({
            label: 'Listings', hstyle:{minWidth:'100px',maxWidth:'300px'},
            render: (i) => (<ListingCost listings={[i.listing]}/>)
        }),
        //...mvcols.cols
    ]
    /*return {
        cols: cols,
        update_world: (name) => mvcols.update_world(name)
    }*/
    return cols
}

function ShopSection({shopd, colspec}) {
    const reslen = shopd.length
    const rpp = reslen > 50 ? 50 : null
    return (
        <div style={{marginTop:'32px'}}>
            <h2>Shop Listings</h2>
            <XTable tableClass='data-t vtop-t' cols={colspec} data={shopd}
                rpp={rpp} width='1100px' idResolver={null/*(x) => x.id*/}/>
        </div>
    )
}

function _process_npc(npc) {
    if (npc != null) {
        if (npc.shops != null && npc.shops.length !== 0) {
            const shopd = []
            npc.shops.forEach((s) => {
                _collect_shopd(s, shopd)
            })
            npc.shopd = shopd
            delete npc.shops
        }
    }
    return npc
}

function _collect_shopd(s, shopd) {
    if (s.data.type === 'gcshop') {
        s.data.cats.forEach((cat) => {
            _shopcat_listings(shopd, s.data.name, cat.name, cat.listings, s.data['std_cur'])
        })
    }
    else {
        const std_cur = s.data.std_cur == null ? null : s.data.std_cur
        _shopcat_listings(shopd, s.context, s.data.name, s.data.listings, std_cur)
    }
}

function _shopcat_listings(shopd, context, name, listings, std_cur) {
    const cparts = []
    if (context != null) { cparts.push(context) }
    if (name != null) { cparts.push(name) }
    const context_p = cparts.join('/')
    listings.forEach((l) => {
        const nl = _norm_listing(l, std_cur)
        const sl = {'shop_cat':context_p,...nl}
        nl.buy.forEach((buy) => {
            shopd.push({
                item: buy,
                listing: sl
            })
        })
    })
}

function _norm_listing(l, std_cur) {
    return {
        buy: Array.isArray(l.buy) ? l.buy : [l.buy],
        cost: std_cur == null ? l.cost : [{...std_cur, qt: l.cost}],
        req: l.req
    }
}

export default client_util.withParams(NpcPage)
