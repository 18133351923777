import * as React from 'react';
//import {Link} from "react-router-dom";

import api from '../common/api'
import client_util from '../common/util/client_util'
import { gather_colspec_default } from '../common/gathnode'
import throttle from '../common/util/throttle'
import {XTable, XTableModel} from '../components/xtable'
import {XStrFilter, XIntRangeFilter, XSelectFilter, XBooleanFilter, XSearch, XSearchModel} from '../components/xsearch'
import { DebugToggle} from '../components/viewbase'
import {Page} from '../components/Page'

class GatherSpotSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            payload: null,
            options: null,
            debug_mode: false,
        }
        this.rpp = 50
        this.xsm = XSearchModel.parseUrl(_gs_spec, this.props.qsearch)
        this.xtm = XTableModel.parseUrl(this.props.qsearch)

        this.data_loader = throttle.loadOnce(() => this._run_query())
    }

    componentDidMount() {
        console.log('componentDidMount')
        this.data_loader.load()
        client_util.setTitle('Gathering Spot Database')
    }

    componentDidUpdate(prevProps) {
        if (this.props.qsearch !== prevProps.qsearch) {
            this.xsm = XSearchModel.parseUrl(_gs_spec, this.props.qsearch)
            this.xtm = XTableModel.parseUrl(this.props.qsearch)
            this._run_query()
        }
    }

    render() {
        const {payload} = this.state
        return (
            <Page>
            <div className='page-body'>
                {this.render_header()}
                {this.render_search_controls()}
                {this.render_data(payload)}
                {this.render_debug_data(payload)}
            </div>
            </Page>
        )
    }

    render_header() {
        return (
            <div>
                <h1>Gathering Spot Database</h1>
                <DebugToggle stateFn={(dbg) => this.setState({debug_mode: dbg})}/>
            </div>
        )
    }

    render_search_controls() {
        return (
            <GatherSearch 
                handler={(e) => this._search_updated(e)} 
                optionsLoader={load_gather_options}
                xsmodel={this.xsm}/>
        )
    }

    _search_updated(e) {
        this.xsm = e
        this.xtm = new XTableModel()
        this._update_history()
        this._run_query()
    }

    _table_updated(e) {
        //console.log(`table_update: ${JSON.stringify(e, 2)}`)
        this.xtm = e
        this._update_history()
    }

    _update_history() {
        const xsmParams = this.xsm.toUrlParams(_gs_spec)
        const xtmParams = this.xtm.toUrlParams()
        const qparams = (xsmParams != null && xtmParams != null)
            ? `${xsmParams}&${xtmParams}`
            : xsmParams != null ? xsmParams : xtmParams
        console.log(qparams)
        if (window.history.state != qparams) {
            window.history.replaceState(qparams, '', qparams == null ? '/gatherspots' : `/gatherspots?${qparams}`)
        }
    }

    _run_query() {
        api.call({
            path:'gathspot', method:'POST',
            params:{
                search: this.xsm.search,
                search_method: 'search'
            },
            handler:(result, err) => this.setState({payload: result})
        })
    }

    render_data(payload) {
        const {debug_mode} = this.state
        return debug_mode === true ? (<></>) : (
            <div>
                <div style={{paddingTop: '8px'}}>
                    {
                        payload == null ? ''
                            : <XTable tableClass='data-t' cols={gather_colspec_default} data={payload.results}
                                width='900px' rpp={this.rpp} 
                                handler={(e) => this._table_updated(e)} xtmodel={this.xtm}/>
                    }
                </div>
            </div>
        )
    }

    render_debug_data(payload) {
        const {debug_mode} = this.state
        return debug_mode === false ? (<></>) : (
            <div style={{justifyContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                {client_util.renderInput(payload)}
            </div>
        )
    }
}

async function load_gather_options() {
    const data = await api.get_doc('core/opt/gather.json')
    const options = {
        region: { choices: data.regions.map((r) => {
            return {value: r.id, content: r.name}
        })},
        zone: { choices: data.zones.map((z) => {
            return {value: z.id, content: z.name}
        })},
        job: { choices: data.jobs.map((j) => {
            return {value: j.id, content: j.name}
        })}
    }
    const sortFn = (a,b) => a.content.localeCompare(b.content)
    options.region.choices.sort(sortFn)
    options.zone.choices.sort(sortFn)
    options.job.choices.sort(sortFn)
    console.log(JSON.stringify(options))
    return options
}

const _gs_spec = {
    standard: [
        new XStrFilter('search', null, 'qstr', {placeholder:'Search for spots'}),
        new XIntRangeFilter('level', 'Level', 'min_level', 'max_level')
    ],
    other: [
        new XSelectFilter('job', 'Job', 'job_id'),
        new XBooleanFilter('rare', 'Rare Spawn', 'is_rare'),
        new XSelectFilter('region', 'Region', 'region_id'),
        new XSelectFilter('zone', 'Zone', 'zone_id')
    ]
}
function GatherSearch({handler, options, optionsLoader, xsmodel}) {
    return (<XSearch handler={handler} spec={_gs_spec} options={options} optionsLoader={optionsLoader} xsmodel={xsmodel}/>)
}

export default client_util.withQSearch(GatherSpotSearch)
