import React from "react";
import { useParams, useLocation } from "react-router-dom";

const useQuery = function() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const HasHistoryContext = React.createContext(false)
const HasHistoryProvider = function({children}) {
    const { pathname } = useLocation()
    const [hasHistory, setHasHistory] = React.useState(false)
    const [setupInvoked, setSetupInvoked] = React.useState(false)
    React.useEffect(() => {
        if (!setupInvoked) {
            setSetupInvoked(true)
        }
        else if (!hasHistory) {
            setHasHistory(true)
        }
    }, [pathname])
    return (
        <HasHistoryContext.Provider value={hasHistory}>
            {children}
        </HasHistoryContext.Provider>
    )
}
const withParams = function(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const withQSearch = function(Component) {
    return props => <Component {...props} qsearch={useQuery()} />;
}

const withUrlFeatures = function(Component) {
    return props => (<Component {...props} params={useParams()} qsearch={useQuery()}/>)
}

const renderJson = function(obj, styleClass) {
    return renderCode(obj === null ? "" : JSON.stringify(obj, undefined, 2), styleClass);
}
const renderInput = function(obj) {
    return renderJson(obj, 'light-green')
}
/*
const renderInputJson = function(obj) {
    return renderJson(obj, "green");
}
const renderInternalJson = function(obj) {
    return renderJson(obj, "bright-blue");
}
const renderOutputJson = function(obj) {
    return renderJson(obj, "light-green");
}
*/

const renderCode = function(code, styleClass) {
    const cn = styleClass ? `code-render ${styleClass}` : 'code-render';
    if (code != null) {
        return (<div style={{marginTop:'-20px'}}>
            <div style={{display:'flex',justifyContent:'right'}}>
                <i className="material-icons" style={{fontSize:'18px',cursor:'pointer'}} onClick={(e) => copyToClipboard(code)}>content_copy</i>
            </div>
            <pre className={cn}>{code}</pre>
        </div>);
    }
    return (<div/>);
}
const renderErrors = function(errors) {
    return renderCode(errors, "violet");
}

const copyToClipboard = str => {
    const el = document.createElement('textarea');  // Create a <textarea> element
    el.value = str;                                 // Set its value to the string that you want copied
    el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
    el.style.position = 'absolute';                 
    el.style.left = '-9999px';                      // Move outside the screen to make it invisible
    document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
    const selected =            
        document.getSelection().rangeCount > 0        // Check if there is any content selected previously
        ? document.getSelection().getRangeAt(0)     // Store selection if found
        : false;                                    // Mark as false to know no selection existed before
    el.select();                                    // Select the <textarea> content
    document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el);                  // Remove the <textarea> element
    if (selected) {                                 // If a selection existed before copying
        document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
        document.getSelection().addRange(selected);   // Restore the original selection
    }
};

function setTitle(t=null) {
    document.title = t == null ? 'XIV Nerd' : t
}

const client_util = {
    useQuery: useQuery,
    withParams: withParams,
    withQSearch: withQSearch,
    withUrlFeatures: withUrlFeatures,
    renderJson: renderJson,
    renderInput: renderInput,
    renderCode: renderCode,
    renderErrors: renderErrors,
    copyToClipboard: copyToClipboard,
    setTitle: setTitle,
}

export default client_util
