import * as React from 'react';

import api from '../common/api'
import client_util from '../common/util/client_util'
import throttle from '../common/util/throttle'
import {DebugToggle} from '../components/viewbase'
import {XTable, XTableModel} from '../components/xtable'
import {XSearchModel} from '../components/xsearch'
import {ItemSearch, item_search_spec, item_colspec_default, item_colspec_mkt} from '../common/item'
import { useLocalSettings, useUserData } from '../common/user'
import {Page} from '../components/Page'

function ItemHomePage(props) {
    const [settings] = useLocalSettings()
    //const { userData } = useUserData()
    //console.log(`IHP: world=${userData.settings.homeWorld().id}`)
    return (
        <ItemHomePageCore {...props} settings={settings}/>
    )
}

class ItemHomePageCore extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            payload: null,
            debug_mode: false,
        }
        this.rpp = 50
        this.colspec = this.mk_colspec()
        this.xsm = XSearchModel.parseUrl(item_search_spec, this.props.qsearch)
        this.xtm = XTableModel.parseUrl(this.props.qsearch)

        this.data_loader = throttle.loadOnce(() => this._run_query())
        console.log('IHP ctor')
    }

    componentDidMount() {
        console.log('componentDidMount')
        this.data_loader.load()
        client_util.setTitle('Item Database')
    }

    componentDidUpdate(prevProps) {
        let rq = false
        if (this.props.qsearch !== prevProps.qsearch) {
            //this.colspec = this.mk_colspec()
            this.xsm = XSearchModel.parseUrl(item_search_spec, this.props.qsearch)
            this.xtm = XTableModel.parseUrl(this.props.qsearch)
            rq = true
        }
        if (this.props.settings.homeWorld().id !== prevProps.settings.homeWorld().id) {
            this.colspec.update_world(this.props.settings.homeWorld().name)
            rq = true
        }
        if (rq) {
            this._run_query()
        }
    }

    render() {
        const {payload} = this.state
        return (
            <Page>
            <div className='page-body'>
                {this.render_header()}
                {this.render_search_controls()}
                {this.render_data(payload)}
                {this.render_debug_list(payload)}
            </div>
            </Page>
        )
    }

    render_header() {
        return (
            <div>
                <h1>Item Database</h1>
                <DebugToggle stateFn={(dbg) => this.setState({debug_mode: dbg})}/>
            </div>
        )
    }

    render_search_controls() {
        return (
            <ItemSearch handler={(e) => this._search_updated(e)} xsmodel={this.xsm}/>
        )
    }

    _search_updated(e) {
        console.log(`search_update: ${JSON.stringify(e, 2)}`)
        this.xsm = e
        this.xtm = new XTableModel()    // reset table settings for the new data
        this._update_history()
        this._run_query()
    }

    _table_updated(e) {
        console.log(`table_update: ${JSON.stringify(e, 2)}`)
        this.xtm = e
        this._update_history()
    }

    _update_history() {
        const xsmParams = this.xsm.toUrlParams(item_search_spec)
        const xtmParams = this.xtm.toUrlParams()
        const qparams = (xsmParams != null && xtmParams != null)
            ? `${xsmParams}&${xtmParams}`
            : xsmParams != null ? xsmParams : xtmParams
        console.log(qparams)
        if (window.history.state != qparams) {
            window.history.replaceState(qparams, '', qparams == null ? '/items' : `/items?${qparams}`)
        }
    }

    _run_query() {
        console.log('itemhomepage::_run_query()')
        api.item.search_items(
            {...this.xsm.search, home_world: this.props.settings.homeWorld().id}
        ).then((result, err) => {
            console.log('itemhomepage::_run_query()->handler')
            this.setState({payload: result})
        })
    }

    render_data(payload) {
        const {debug_mode} = this.state
        return debug_mode === true ? (<></>) : (
            <div>
                <div style={{paddingTop: '8px'}}>
                    {payload == null ? '' : this.build_table(payload)}
                </div>
            </div>
        )
    }

    build_table(payload) {
        const reslen = payload.results.length
        const msg = payload.ttl_count > reslen
            ? `${reslen} of ${payload.ttl_count} items loaded`
            : `${reslen} items found`
        return (
            <XTable tableClass='data-t' cols={this.colspec.cols} data={payload.results} 
                xtmodel={this.xtm} handler={(e) => this._table_updated(e)}
                message={msg} rpp={this.rpp} width='1020px'/>
        )
    }

    render_debug_list(payload) {
        const {debug_mode} = this.state
        return debug_mode === false ? (<></>) : (
            <div style={{justifyContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                {client_util.renderInput(payload)}
            </div>
        )
    }

    mk_colspec() {
        if (this.props.settings.homeWorld() == null) {
            return item_colspec_mkt('Faerie')
        }
        return item_colspec_mkt(this.props.settings.homeWorld().name)
    }
}

//const _colspec = item_colspec_mkt('Faerie')

export default client_util.withQSearch(ItemHomePage)
