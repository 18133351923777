import * as React from 'react';

import cbase from '../common/cbase'
import gathrare from '../common/gathrare'
import imgu from '../common/imgu'
import { 
    GameRef, ItemRef, GathSpotRef, DutyRef, EnemyRef, QuestRef,
    render_gath_level, render_source_locations, render_jobcat
} from '../components/viewbase'
import {XCol} from '../components/xtable'
import {MicroNodeTimer} from '../components/gnode_timer'

export const gather_source_colspec = [
    new XCol({
        label:'Name', hstyle:{},
        compare: (i,j) => cbase.strcmp(i.data[i.source_type].name, j.data[j.source_type].name),
        render: (i) => {
            const gspot = i.data[i.source_type]
            const stype = gspot.stype != null ? gspot.stype 
                : i.source_type === 'gath_node' ? 'node' : 'fish'
            const ref = (<GathSpotRef id={gspot.id} name={gspot.name} icon={gspot.icon} stype={stype} nolink={false}/>)
            return (
                <div style={{display:'flex'}}>
                    {ref}
                    <div style={{flexGrow:'1',minWidth:'14px'}}></div>
                    {gspot.transient == null ? null
                        : <MicroNodeTimer schedule={new gathrare.RareNodeSchedule(gspot.transient)} 
                            jobabbrev={i.data.gath_node.job_abbrev} active_icon={i.data.gath_node.icon}/>
                    }
                </div>
            )
        }
    }),
    new XCol({
        label:'Job', hstyle:{},
        compare: (i,j) => cbase.strcmp(i.data[i.source_type].job_abbrev, j.data[j.source_type].job_abbrev),
        render: (i) => i.data[i.source_type].job_abbrev
    }),
    new XCol({
        label:'Lvl', hstyle:{},
        compare: (i,j) => cbase.numcmp(i.data[i.source_type].level, j.data[j.source_type].level),
        render: (i) => i.data[i.source_type].level
    }),
    new XCol({
        label:'GLvl', hstyle:{},
        compare: (i,j) => cbase.gathlvlcmp(
            i.data[i.source_type].glevel, i.data[i.source_type].gstars,
            j.data[j.source_type].glevel, j.data[j.source_type].gstars
        ),
        render: (i) => render_gath_level(i.data[i.source_type].glevel, i.data[i.source_type].gstars)
    }),
    new XCol({
        label:'Location', hstyle:{},
        compare: cbase.loccmp_source,
        render: (i) => render_source_locations(i.data.locations)
    })
]

export const enemy_source_colspec = [
    new XCol({
        label:'Name', hstyle:{},
        compare: (i,j) => cbase.strcmp(i.data.enemy.name, j.data.enemy.name),
        render: (i) => {
            return (<EnemyRef id={i.data.enemy.id} name={i.data.enemy.name} icon={imgu.static.enemy}/>)
        }
    }),
    new XCol({
        label:'Level', hstyle:{},
        compare: (i,j) => {
            const iL = i.data.enemy.level == null ? 0 : i.data.enemy.level.min
            const jL = j.data.enemy.level == null ? 0 : j.data.enemy.level.min
            return iL - jL
        },
        render: (i) => {
            return i.data.enemy.level == null ? ''
                : i.data.enemy.level.min === i.data.enemy.level.max ? i.data.enemy.level.min
                : `${i.data.enemy.level.min}-${i.data.enemy.level.max}`
        }
    }),
    new XCol({
        label:'Location', hstyle:{},
        compare: cbase.loccmp_source,
        render: (i) => render_source_locations(i.data.locations)
    })
]

export const duty_source_colspec = [
    new XCol({
        label:'Name', hstyle:{},
        compare: (i,j) => cbase.strcmp(i.data.duty.name, j.data.duty.name),
        render: (i) => {
            return (<DutyRef zone_id={i.data.locations[0].zone.id} name={i.data.duty.name} icon={imgu.static.duty}/>)
        }
    }),
    new XCol({
        label:'Level', hstyle:{},
        compare: (i,j) => cbase.numcmp(i.data.duty.level, j.data.duty.level),
        render: (i) => i.data.duty.level === 0 ? null : i.data.duty.level
    }),
    new XCol({
        label:'ILvl', hstyle:{},
        compare: (i,j) => cbase.numcmp(i.data.duty.itemlevel, j.data.duty.itemlevel),
        render: (i) => i.data.duty.itemlevel === 0 ? null : i.data.duty.itemlevel
    }),
    new XCol({
        label:'Location', hstyle:{},
        compare: (i,j) => {
            const scomp = cbase.strcmp(i.data.duty.ds_name, j.data.duty.ds_name)
            if (scomp !== 0) {
                return scomp
            }
            return cbase.strcmp(i.data.duty.bin, j.data.duty.bin)
        },
        render: (i) => get_duty_loc(i.data.duty)
    })
]
function get_duty_loc(duty) {
    let text = duty.bin
    if (text === '(Blue Mage Spell Acquisition)' || text === '(Fixed Rate Drop)') {
        text = null
    }
    if (duty.ds_name === 'Additional Coffers') {
        return text
    }
    return text === null ? duty.ds_name : `${duty.ds_name} - ${text}`
}

export const reduction_source_colspec = [
    new XCol({
        label:'Name', hstyle:{/*minWidth:'180px'*/},
        compare: (i,j) => cbase.strcmp(i.data.reduction.name, j.data.reduction.name),
        render: (i) => {
            return (<ItemRef id={i.data.reduction.id} name={i.data.reduction.name} icon={i.data.reduction.icon}
                rarity={i.data.reduction.rarity}/>)
        }
    }),
    new XCol({
        label:'ILvl', hstyle:{maxWidth:'80px', width:'80px',minWidth:'80px'},
        compare: (i,j) => cbase.numcmp(i.data.reduction.level, j.data.reduction.level),
        render: (i) => i.data.reduction.level
    }),
    new XCol({
        label:'Type', hstyle:{},
        compare: (i,j) => cbase.strcmp(i.data.reduction.uicat_nm, j.data.reduction.uicat_nm),
        render: (i) => i.data.reduction.uicat_nm
    })
]

export const coffer_source_colspec = [
    new XCol({
        label:'Name', hstyle:{/*minWidth:'180px'*/},
        compare: (i,j) => cbase.strcmp(i.data.coffer.name, j.data.coffer.name),
        render: (i) => {
            return (<ItemRef id={i.data.coffer.id} name={i.data.coffer.name} icon={i.data.coffer.icon}
                rarity={i.data.coffer.rarity}/>)
        }
    }),
    new XCol({
        label:'ILvl', hstyle:{maxWidth:'80px', width:'80px',minWidth:'80px'},
        compare: (i,j) => cbase.numcmp(i.data.coffer.level, j.data.coffer.level),
        render: (i) => i.data.coffer.level
    }),
    new XCol({
        label:'Type', hstyle:{},
        compare: (i,j) => cbase.strcmp(i.data.coffer.uicat_nm, j.data.coffer.uicat_nm),
        render: (i) => i.data.coffer.uicat_nm
    })
]

const _questref_common_cols = [
    new XCol({
        label:'Type', hstyle:{},
        compare: (i,j) => cbase.strcmp(i.qkind_nm, j.qkind_nm),
        render: (i) => i.qkind_nm
    }),
    new XCol({
        label:'Repeat', hstyle:{},
        compare: (i,j) => cbase.numcmp(i.repeat, j.repeat),
        render: (i) => render_repeat(i.repeat)
    }),
    new XCol({
        label:'Level', hstyle:{maxWidth:'80px', width:'80px',minWidth:'80px'},
        compare: (i,j) => cbase.numcmp(i.req.level, j.req.level),
        render: (i) => i.req.level
    }),
    new XCol({
        label:'Reqs', hstyle:{minWidth:'100px',maxWidth:'220px'},
        compare: (i,j) => cbase.strcmp(i.req.jobcat_nm, j.req.jobcat_nm),
        render: (i) => render_quest_reqs(i.req)
    }),
]
export const quest_ref_colspec = [
    new XCol({
        label:'Name', hstyle:{minWidth:'100px'},
        compare: (i,j) => cbase.strcmp(i.name, j.name),
        render: (i) => (<QuestRef id={i.id} name={i.name} icon={i.icon}/>)
    }),
    ..._questref_common_cols
]

export const quest_ref_colspec_nolinks = [
    new XCol({
        label:'Name', hstyle:{minWidth:'100px'},
        compare: (i,j) => cbase.strcmp(i.name, j.name),
        render: (i) => (<QuestRef name={i.name} icon={i.icon}/>)
    }),
    ..._questref_common_cols
]

export const quest_source_colspec = [
    new XCol({
        label:'Name', hstyle:{minWidth:'100px'},
        compare: (i,j) => cbase.strcmp(i.data.quest.name, j.data.quest.name),
        render: (i) => (<QuestRef id={i.data.quest.id} name={i.data.quest.name} icon={i.data.quest.icon}/>)
    }),
    new XCol({
        label:'Type', hstyle:{},
        compare: (i,j) => cbase.strcmp(i.data.quest.qkind_nm, j.data.quest.qkind_nm),
        render: (i) => i.data.quest.qkind_nm
    }),
    new XCol({
        label:'Repeat', hstyle:{},
        compare: (i,j) => cbase.numcmp(i.data.quest.repeat, j.data.quest.repeat),
        render: (i) => render_repeat(i.data.quest.repeat)
    }),
    new XCol({
        label:'Level', hstyle:{maxWidth:'80px', width:'80px',minWidth:'80px'},
        compare: (i,j) => cbase.numcmp(i.data.quest.req.level, j.data.quest.req.level),
        render: (i) => i.data.quest.req.level
    }),
    new XCol({
        label:'Reqs', hstyle:{minWidth:'100px',maxWidth:'220px'},
        compare: (i,j) => cbase.strcmp(i.data.quest.req.jobcat_nm, j.data.quest.req.jobcat_nm),
        render: (i) => render_quest_reqs(i.data.quest.req)
    }),
    new XCol({
        label:'Detail', hstyle:{},
        render: (i) => render_reward_detail(i.data.quest.rw)
    }),
    /*new XCol({
        label:'Start Loc', hstyle:{minWidth:'220px'},
        compare: cbase.loccmp_source,
        render: (i) => render_source_locations(i.data.locations)
    })*/
]

function render_repeat(r) {
    if (r == 1) { return (<div style={{fontSize:'12px',color:'#999'}}>daily</div>) }
    if (r == 2) { return (<div style={{fontSize:'12px',color:'#999'}}>weekly</div>) }
    if (r == 51) { return (<div style={{fontSize:'12px',color:'#999'}}>yes</div>) }
    return null
}

function render_quest_reqs(r) {
    /*const entries = [
        (<div key='lvl'>Level <span style={{color:'#999'}}>{r.level}</span></div>)
    ]*/
    const entries = []
    if (r.jobcat != 0) {
        entries.push((<div key='jobcat' style={{color:'#999',fontSize:'12px'}}>{render_jobcat(r.jobcat_nm)}</div>))
    }
    return (<div>{entries}</div>)
}

function render_reward_detail(rw) {
    const rows = rw.defs.map((d,i) => (
        <tr key={i}>
            <td><ItemRef id={rw.id} icon={rw.icon} quantity={d.qt == null ? null : d.qt}/></td>
            <td style={{fontSize:'12px',color:'#999'}}>{
                d.rpt === 'chance' ? `${d.chance}%`
                    : d.rpt === 'option' ? 'option'
                    : d.rpt === 'jobcat' ? d.jobcat_nm
                    : null
            }</td>
        </tr>
    ))
    return (
        <table className='data-t clean-t'>
            <tbody>
                {rows}
            </tbody>
        </table>
    )
}

export const achieve_source_colspec = [
    new XCol({
        label:'Name', hstyle:{},
        compare: (i,j) => cbase.strcmp(i.data.achieve.name, j.data.achieve.name),
        render: (i) => (<GameRef name={i.data.achieve.name} icon={i.data.achieve.icon}/>)
    }),
    new XCol({
        label:'Description', hstyle:{},
        compare: (i,j) => cbase.numcmp(i.data.achieve.desc, j.data.achieve.desc),
        render: (i) => (
            <div style={{maxWidth:'500px'}}>{i.data.achieve.desc}</div>
        )
    }),
]

