import * as React from 'react';

import gathrare from '../common/gathrare'
import api from '../common/api'
import client_util from '../common/util/client_util'
import throttle from '../common/util/throttle'
import cbase from '../common/cbase'
import imgu from '../common/imgu'
import {SimpleNodeTimer2} from '../components/gnode_timer'
import { gather_item_colspec } from '../common/item'
import {XTable} from '../components/xtable'
import {to_simple_loc, render_location, DebugToggle} from '../components/viewbase'
import {MapPoint, BasicMapView} from '../components/mapview'
import { useLocalSettings } from '../common/user'
import {Page} from '../components/Page'

function GatheringNode(props) {
    const [settings] = useLocalSettings()

    return (<GatheringNodeCore {...props} settings={settings}/>)
}

class GatheringNodeCore extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            eorzea_time: null,
            gnode: null,
            schedule: null,
            debug_mode: false
        }
        let { id } = props.params
        console.log(`id = ${id}`)
        this.node_id = id
        this.colspec = gather_item_colspec(this.props.settings.homeWorld().name)
        this.data_loader = throttle.loadOnce(() => this.loadData())
    }

    componentDidMount() {
        console.log('componentDidMount')
        this.data_loader.load()
    }

    componentDidUpdate(prevProps) {
        if (this.props.settings.homeWorld().id !== prevProps.settings.homeWorld().id) {
            this.colspec.update_world(this.props.settings.homeWorld().name)
            this.loadData()
        }
    }

    loadData() {
        const homeWorld = this.props.settings.homeWorld()
        api.call({path:`gathnode/${this.node_id}?world=${homeWorld.id}`, handler:(result, err) => {
            const gnode = result
            if (gnode != null) {
                const schedule = gnode.transient == null ? null : new gathrare.RareNodeSchedule(gnode.transient)
                this.setState({gnode: gnode, schedule: schedule})
            }
        }})
    }

    render() {
        const {gnode} = this.state
        if (gnode == null) {
            return (<></>)
        }
        document.title = gnode.name
        return (
            <Page canon={`gatherspot/node/${this.node_id}`}>
            <div className='page-body'>
                {this.render_header(gnode)}
                {this.render_node(gnode)}
                {this.render_debug_node(gnode)}
            </div>
            </Page>
        )
    }

    render_header(gnode) {
        return (
            <div>
                <h1 className='small'>{gnode.name}</h1>
                <DebugToggle stateFn={(dbg) => this.setState({debug_mode: dbg})}/>
            </div>
        )
    }

    render_node(gnode) {
        const {debug_mode, schedule} = this.state
        return debug_mode === true ? (<></>) : (
            <div style={{paddingTop: '8px'}}>
                <div style={{display:'flex'}}>
                    <img src={imgu.to_icon_path(gnode.icon)} style={{width: '60px', height: '60px'}}></img>
                    <div style={{margin:'0px 20px'}}>
                        <div>{gnode.name}</div>
                        <div>Level {gnode.level} {gnode.job_nm}</div>
                        <div>{gnode.typename} Node</div>
                    </div>
                </div>
                {gnode.attempts > 1 ? <div style={{fontSize:'14px'}}>Attempts: {gnode.attempts}</div> : ''}
                {this.render_loc(gnode)}
                {schedule == null ? <></> : this.render_rare_spawns(gnode, schedule)}
                {this.render_items(gnode)}
                {this.render_variations(gnode)}
            </div>
        )
    }

    render_loc(gnode) {
        //const zd = gnode._zdetail
        const loc = gnode.location
        const zd = loc.zd
        const vcoords = cbase.map_view_coords(loc.coords, zd.sizef)
        return (
            <div className='x-section-clean'>
                <h2 className='small'>Location</h2>
                {render_location(to_simple_loc(loc))}
                {zd.map == null ? null : (
                    <div style={{marginTop:'10px'}}>
                        {/*<MapView vheight={340} vwidth={440} scale={0.5}
                            path={zd.map} points={[new MapPoint(gnode.icon, vcoords.x, vcoords.y, {radius:loc.radius})]}/>*/}
                        <BasicMapView sz={340} path={zd.map} points={[
                            new MapPoint(gnode.icon, vcoords.x, vcoords.y, {radius:loc.radius})
                        ]}/>
                    </div>
                )}
            </div>
        )
    }

    render_variations(gnode) {
        const variants = gnode.variants.map((v) => this.render_variant(v))
        return (
            <div className='x-section-clean'>
                <h2>Variations</h2>
                <table className="rarenode-result-t">
                    <thead>
                        <tr><th>Id</th><th>Bonus</th><th>Requirement</th></tr>
                    </thead>
                    <tbody>{variants}</tbody>
                </table>
            </div>
        )
    }

    render_variant(v) {
        const bonus_items = v.bonuses.map((b) => (<div>{b.bonus}</div>))
        const req_items = v.bonuses.map((b) => (<div>{b.cond}</div>))
        return (
            <tr>
                <td>{v.id}</td><td>{bonus_items}</td><td>{req_items}</td>
            </tr>
        )
    }

    render_rare_spawns(gnode, schedule) {
        const vspawns = []
        if (schedule != null) {
            for (let i = 0; i < schedule.spawns.length; i++) {
                vspawns.push(this.render_spawn(schedule.spawns[i]))
            }
        }
        return (
            <div className='x-section-clean'>
                <h2>Rare Spawn</h2>
                <SimpleNodeTimer2 schedule={schedule} jobabbrev={gnode.job_abbrev} active_icon={gnode.icon} width='200px'/>
                <table className="rarenode-result-t">
                    <thead>
                        <tr><th>Eorzea</th><th>Earth</th></tr>
                    </thead>
                    <tbody>{vspawns}</tbody>
                </table>
            </div>
        )
    }

    render_spawn(spawn) {
        const cur_duration = show_duration(spawn.real_dur)
        const next_duration = show_duration(spawn.real_dur_tonext)
        return (
            <tr key={spawn.start.bell}>
                <td>
                    {/*<span>
                        {spawn_time(cur.start)} - {spawn_time(cur.end)}
                    </span>*/}
                    <span>
                        {spawn_time_ampm(spawn.start)} - {spawn_time_ampm(spawn.end)}
                    </span>
                </td>
                <td>
                    <span>up for {cur_duration}, next in {next_duration}</span>
                </td>
            </tr>
        )
    }

    render_items(gnode) {
        return (
            <div className='x-section-clean'>
                <h2>Gathered Items</h2>
                <XTable tableClass='data-t noborder-t'
                    simple={true} cols={this.colspec.cols} data={gnode.items}/>
            </div>
        )
    }

    render_debug_node(gnode) {
        const {debug_mode} = this.state
        return debug_mode === false ? (<></>) : (
            <div style={{justifyContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                {client_util.renderInput(gnode)}
                {this.render_debug_spawns(gnode)}
            </div>
        )
    }

    render_debug_spawns(gnode) {
        const {schedule} = this.state
        if (schedule != null) {
        //if (gnode.transient_data != null) {
        //    const etime = new EorzeaTime()
        //    const sched = gathrare.get_spawn_info(gnode.transient_data, {eorzea_time:etime})
            return (
                <div>
                    {client_util.renderInput(schedule.spawns)}
                </div>
            )
        }
        return (<></>)
    }
}

function pad_time(val) {
    return (val < 10 ? `0${val}` : `${val}`)
}

function spawn_time(etime) {
    return (
        <span style={{marginRight: '2px'}}>
            {etime.bell}:{pad_time(etime.minute)}
        </span>
    )
}

function spawn_time_ampm(etime) {
    const b_ampm = bell_ampm(etime.bell)
    return (
        <span style={{marginRight: '2px'}}>
            {b_ampm.bell}:{pad_time(etime.minute)}{b_ampm.ampm}
        </span>
    )
}

function bell_ampm(bell) {
    if (bell === 12) {
        return {bell: 12, ampm: 'pm'}
    }
    if (bell < 12) {
        return {bell: bell === 0 ? 12 : bell, ampm: 'am'}
    }
    return {bell: bell-12, ampm: 'pm'}
}

function show_duration(dur) {
    if (dur.hours > 0) {
        return `${dur.hours}h ${dur.minutes}m ${dur.seconds}s`
    }
    if (dur.minutes > 0) {
        return `${dur.minutes}m ${dur.seconds}s`
    }
    return `${dur.seconds}s`
}

export default client_util.withParams(GatheringNode)
