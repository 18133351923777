import * as React from 'react'

import cbase from '../common/cbase'
import imgu from '../common/imgu'
import {ItemRef, RecipeRef} from '../components/viewbase'
import {XCol} from '../components/xtable'
import {ItemRead} from '../common/item'

import {ZoneRef} from '../components/viewbase'

export const ZoneAccess = {
    region: (z) => {
        return z.parent_path == null || z.parent_path.length === 0 ? null : z.parent_path[z.parent_path.length-1]
    },
    region_name: (z) => {
        const r = ZoneAccess.region(z)
        return r == null ? null : r.name
    },
    name: (z) => {
        return z.cfdname != null ? z.cfdname : z.name
    }
}

export const zone_regcmp = (i, j) => {
    return cbase.strcmp(ZoneAccess.region_name(i), ZoneAccess.region_name(j))
}

export const zone_colspec = ({
    extra_cols=null, read_fn=null, excludeLinks=false
} = {}) => {
    const base_cols = [
        new XCol({
            label:'Name', hstyle:{},
            compare: (i,j) => cbase.strcmp(
                ZoneAccess.name(read_fn == null ? i : read_fn(i)), 
                ZoneAccess.name(read_fn == null ? j : read_fn(j))
            ),
            render: (i) => {
                const x = (read_fn == null ? i : read_fn(i))
                return (<ZoneRef id={x.id} name={ZoneAccess.name(x)} nolink={excludeLinks}/>)
            }
        }),
        new XCol({
            label:'Level', hstyle:{},
            compare: (i,j) => cbase.numcmp(
                (read_fn == null ? i : read_fn(i)).cfdlevel, 
                (read_fn == null ? j : read_fn(j)).cfdlevel
            ),
            render: (i) => {
                const x = (read_fn == null ? i : read_fn(i))
                return x.cfdlevel === 0 ? null : x.cfdlevel
            }
        }),
        new XCol({
            label:'ILvl', hstyle:{},
            compare: (i,j) => cbase.numcmp(
                (read_fn == null ? i : read_fn(i)).cfdilvl, 
                (read_fn == null ? j : read_fn(j)).cfdilvl
            ),
            render: (i) => {
                const x = (read_fn == null ? i : read_fn(i))
                return x.cfdilvl === 0 ? null : x.cfdilvl
            }
        }),
        new XCol({
            label:'Use', hstyle:{},
            compare: (i,j) => cbase.strcmp(
                (read_fn == null ? i : read_fn(i)).role, 
                (read_fn == null ? j : read_fn(j)).role
            ),
            render: (i) => (read_fn == null ? i : read_fn(i)).role
        }),
        new XCol({
            label:'Region', hstyle:{},
            compare: (i,j) => zone_regcmp(
                (read_fn == null ? i : read_fn(i)),
                (read_fn == null ? j : read_fn(j))
            ),
            render: (i) => ZoneAccess.region_name(read_fn == null ? i : read_fn(i))
        })
    ]

    return extra_cols == null ? base_cols : [...base_cols, ...extra_cols]
}

export const zone_colspec_default = zone_colspec()
