import * as React from 'react';
import {Link} from "react-router-dom";

import imgu from '../common/imgu'
import cbase from '../common/cbase'
import itemsource from '../common/itemsource'
import gathrare from '../common/gathrare'
import client_config from '../common/client_config'
import { MicroNodeTimer } from './gnode_timer'

export function GameRef({name, icon, hq, quantity, textColor, fontSize, linkTo}) {
    const qt_content = quantity == null || quantity === 1 ? null : (
        <span><span style={{fontSize: '10px', marginLeft: '4px', marginRight: '4px'}}>x</span>{quantity.toLocaleString()}</span>
    )
    const nameSpacing = icon == null ? '0px' : '6px'
    const content = (
        <div style={{display:'flex',alignItems:'center',fontSize:fontSize == null ? 'inherit' : fontSize}}>
            {icon == null ? null : (
                <img src={imgu.to_icon_path(icon, hq)} style={{width:'24px',height:'24px'}}></img>
            )}
            {name == null ? null : (
                <div style={{marginLeft:nameSpacing,color:textColor == null ? 'inherit' : textColor}}>
                    {name == null ? null : name}
                </div>
            )}
        </div>
    )
    return linkTo == null ? (
        <span style={{display:'inline-flex',alignItems:'center'}}>{content}{qt_content}</span>
    ) : (
        <span style={{display:'inline-flex',alignItems:'center'}}><Link className='Game-link' to={linkTo}>{content}</Link>{qt_content}</span>
    )
}

export function ItemRef({id, name, icon, rarity, hq, quantity, fontSize, nolink}) {
    return (
        <GameRef name={name} icon={icon} hq={hq} quantity={quantity} 
            textColor={rarity == null ? cbase.to_rarity_color(0) : cbase.to_rarity_color(rarity)}
            fontSize={fontSize}
            linkTo={nolink === true || id == null ? null : `/item/${id}`}/>
    )
}

export function FishSpotRef({id, name, icon, nolink}) {
    return (
        <GameRef name={name} icon={icon} 
            linkTo={nolink === true || id == null ? null : `/gatherspot/fish/${id}`}/>
    )
}

export function GathNodeRef({id, name, icon, nolink}) {
    return (
        <GameRef name={name} icon={icon} 
            linkTo={nolink === true || id == null ? null : `/gatherspot/node/${id}`}/>
    )
}

export function GathSpotRef({id, name, icon, stype, nolink}) {
    return stype === 'node' 
        ? (<GathNodeRef id={id} name={name} icon={icon} nolink={nolink}/>)
        : (<FishSpotRef id={id} name={name} icon={icon} nolink={nolink}/>)
}

export function NpcRef({id, name, icon}) {
    return (
        <GameRef name={name} icon={icon}
            linkTo={id == null ? null : `/npc/${id}`}/>
    )
}

export function QuestRef({id, name, icon}) {
    return (
        <GameRef name={name} icon={icon}
            linkTo={id == null ? null : `/quest/${id}`}/>
    )
}

export function RecipeRef({id, name, icon, textColor, nolink}) {
    return (
        <GameRef name={name} icon={icon} textColor={textColor} 
            linkTo={nolink === true || id == null ? null : `/recipe/${id}`}/>
    )
}

export function ZoneRef({id, idsub, name, icon, textColor, nolink}) {
    return (
        <GameRef name={name} icon={icon} textColor={textColor}
            linkTo={
                nolink === true || id == null ? null 
                    : idsub == null ? `/zone/${id}`
                    : `/zone/${id}?sub=${idsub}`
            }
        />
    )
}

export function EnemyRef({id, name, icon}) {
    return (<GameRef name={name} icon={icon}/>)
}

export function DutyRef({zone_id, name, icon, textColor}) {
    return (<ZoneRef id={zone_id} name={name} icon={icon} textColor={textColor}/>)
}

export function JobRef({id, name, framed, textColor}) {
    const icon = framed === true ? imgu.job_icon_framed(id) : imgu.job_icon(id)
    return (<GameRef name={name} icon={icon} textColor={textColor}/>)
}

export function DebugToggle({stateFn}) {
    return client_config.ui.allow_debug === false ? null : (
        <span>
            <button className="xbtn" style={{marginLeft: '8px'}} 
                onClick={stateFn == null ? null : () => stateFn(true)}>Debug</button>
            <button className="xbtn" style={{marginLeft: '8px'}} 
                onClick={stateFn == null ? null : () => stateFn(false)}>Live</button>
        </span>
    )
}

export function vgap(gap, content) {
    return (<div style={{marginTop:`${gap}px`}}>{content}</div>)
}

export function render_gath_level(level, stars) {
    return (
        <span>{level} {
            stars == null || stars === 0 ? '' 
            //: stars === 1 ? '(1)' 
            : `(${stars})`
        }</span>
    )
}

export function render_location(loc, key=null, skip_region=false) {
    return (
        <div key={key}>
            <div>{loc_str(loc, false)}</div>
            {loc.region == null || skip_region ? null : (
                <div style={{marginLeft:'0px'}} className='disabled-text2'>
                    {loc.region.name}
                </div>
            )}
        </div>
    )
}
export function loc_str_new(loc, includeRegion=true) {
    // Eastern Thanalan - Dock Storehouse
    // 9.5,12.3
    // Thanalan
    const np = loc.zone.cfdname == null || loc.zone.cfdname.length === 0 ? loc.zone.name : loc.zone.cfdname
    const nr = !includeRegion || loc.region == null ? null : loc.region.name
    const ns = loc.zsub == null || loc.zsub.namesub == null || loc.zsub.namesub.length === 0 ? null : loc.zsub.namesub
    let extra = ''
    if (ns != null) {
        extra += `- ${ns}`
    }
    //if (nr != null) {
    //    extra += `, ${nr}`
    //}
    const coords = loc.coords == null || loc.coords.length === 0 ? null
        : loc.coords.split(' ').map((c,i) => (
            <div key={i} className='coord-box' style={{/*backgroundColor:'#202228',color:'#89b',padding:'0px 4px',borderRadius:'4px'*/}}>
                {c}
            </div>
        ))
    return (
        <div style={{display:'flex',flexDirection:'column'}}>
            <div>
                <ZoneRef id={loc.zone.id} name={np}/>
                {extra.length === 0 ? null : (
                    <span style={{marginLeft:'6px'}}>{extra}</span>
                )}
            </div>
            <div style={{display:'flex',flexWrap:'wrap',gap:'4px'}}>
                {coords}
            </div>
            {includeRegion !== true ? null : (
                <div className='disabled-text2'>
                    {loc.region.name}
                </div>
            )}
        </div>
    )
}
export function loc_str(loc, includeRegion=true) {
    // Eastern Thanalan (Dock Storehouse), Thanalan  9.5,12.3
    const np = loc.zone.cfdname == null || loc.zone.cfdname.length === 0 ? loc.zone.name : loc.zone.cfdname
    const nr = !includeRegion || loc.region == null ? null : loc.region.name
    const ns = loc.zsub == null || loc.zsub.namesub == null || loc.zsub.namesub.length === 0 ? null : loc.zsub.namesub
    let extra = ''
    if (ns != null) {
        extra += `- ${ns}`
    }
    if (nr != null) {
        extra += `, ${nr}`
    }
    const coords = loc.coords == null || loc.coords.length === 0 ? null
        : loc.coords.split(' ').map((c,i) => (
            <div key={i} className='coord-box' style={{/*backgroundColor:'#202228',color:'#89b',padding:'0px 4px',borderRadius:'4px'*/}}>
                {c}
            </div>
        ))
    return (
        <div style={{display:'flex'}}>
            <span style={{marginRight: '8px'}}>
                <ZoneRef id={loc.zone.id} name={np}/>
                {extra.length === 0 ? null : (
                    <span style={{marginLeft:'6px'}}>{extra}</span>
                )}
            </span>
            {/*<span>{loc.coords}</span>*/}
            <div style={{display:'flex',gap:'4px'}}>
                {coords}
            </div>
        </div>
    )
}
export function to_locx(loc) {
    const x = {
        region: loc.region == null ? null : loc.region.name,
        zone_id: loc.zone.id,
        zone: loc.zone.cfdname == null || loc.zone.cfdname.length === 0 ? loc.zone.name : loc.zone.cfdname,
        sub_id: loc.zsub == null ? null : loc.zsub.id,
        sub: loc.zsub == null ? null : loc.zsub.namesub
    }
    x.base_nm = x.sub == null ? x.zone : `${x.zone} - ${x.sub}`
    return x
}
export function to_simple_loc(loc) {
    return {
        ...loc,
        coords: to_simple_coords(loc.coords)
    }
}
function to_simple_coords(c) {
    if (c == null) return null
    if (typeof(c) == 'string' || (c instanceof String)) {
        return c
    }
    if (Array.isArray(c)) {
        const bits = c.map((x) => `${x.x},${x.y}`)
        return bits.join(' ')
    }
    return `${c.x},${c.y}`
}

export function ListingCost({listings, suppressShopCat=false}) {
    const [open, setOpen] = React.useState(false)
    const entries = []
    const toggleOpenState = () => setOpen(!open)
    const btnText = listings.length < 4 ? null : open ? 'view less' : `view all ${listings.length}`
    for (let i = 0; i < listings.length; i++) {
        if (i > 2 && !open) {
            break
        }
        const l = listings[i]   // #282c34
        const tieColor = '#464898'
        entries.push((
            <ListingCostEntry1 i={i} l={l} tieColor={tieColor} suppressShopCat={suppressShopCat}/>
        ))
    }
    return (
        <div>
            {entries}
            {btnText == null ? null : (
                <button className='micro-btn' onClick={toggleOpenState}>{btnText}</button>
            )}
        </div>
    )
}

function ListingCostEntry2({i, l, tieColor}) {
    return (
        <div key={i} style={{display:'flex', alignItems:'stretch', marginBottom:'6px'/*, borderRadius:'6px', backgroundColor:'#262628', padding:'2px 6px'*/}}>
            <div style={{width:'4px', backgroundColor:tieColor, margin:'0px 6px 2px 0px'}}></div>
            <div style={{display:'flex', flexGrow:'1',flexDirection:'column', alignItems:'stretch'}}>
                <div style={{display:'flex'}}>
                    <div>
                        {l.buy.map((b,j) => (
                            <div key={j} style={{margin:'0px 0px 2px 0px',minWidth:'96px'}}>
                                <ItemRef id={b.id} icon={b.icon} quantity={b.qt}/>
                            </div>
                        ))}
                    </div>
                    <div style={{flexGrow:'0',minWidth:'10px'}}></div>
                    <div>
                        {l.cost.map((c,j) => (
                            <div key={j} style={{margin:'0px 0px 2px 0px'}}>
                                <ItemRef id={c.id} name={c.name} icon={c.icon} rarity={c.rarity} quantity={c.qt}/>
                            </div>
                        ))}
                    </div>
                </div>
                <div style={{fontSize:'12px',color:'#777'}}>{l.shop_cat}</div>
            </div>
        </div>
    )
}
function ListingCostEntry1({i, l, tieColor, suppressShopCat=false}) {
    return (
        <div key={i} style={{display:'flex', alignItems:'stretch', marginBottom:'6px'/*, borderRadius:'6px', backgroundColor:'#262628', padding:'2px 6px'*/}}>
            <div style={{width:'4px', backgroundColor:tieColor, margin:'0px 6px 2px 0px'}}></div>
            <div style={{display:'flex', flexGrow:'1',flexDirection:'column', alignItems:'stretch'}}>
                <div style={{display:'flex', /*flexGrow:'1',*/alignItems:'stretch'}}>
                    <div style={{}}>
                        {l.cost.map((c,j) => (
                            <div key={j} style={{margin:'0px 0px 2px 0px'}}>
                                <ItemRef id={c.id} name={c.name} icon={c.icon} rarity={c.rarity} hq={c.hq} quantity={c.qt} fontSize='12px'/>
                            </div>
                        ))}
                    </div>
                    <div style={{flexGrow:'1',minWidth:'24px'}}></div>
                    {/*<div style={{width:'4px', backgroundColor:'#222224', margin:'0px 6px 0px 0px'}}></div>*/}
                    <div>
                        {l.buy.map((b,j) => (
                            <div key={j} style={{margin:'0px 0px 2px 0px',minWidth:'80px'}}>
                                <ItemRef id={b.id} icon={b.icon} hq={b.hq} quantity={b.qt}/>
                            </div>
                        ))}
                    </div>
                </div>
                {suppressShopCat ? null : (
                    <div style={{fontSize:'12px',color:'#777'}}>{l.shop_cat}</div>
                )}
            </div>
        </div>
    )
}

export function ItemSourceRef({source_rec}) {
    const s = source_rec
    const stype = itemsource.sourcet[s.source_type]
    const name = itemsource.get_data_field(s, 'name')

    if (stype == null) {
        return (<GameRef name={name}/>)
    }

    const id = itemsource.get_data_field(s, 'id')
    const icon = itemsource.get_target_icon(s)
    switch (stype.id) {
        case itemsource.sourcet.gath_node.id:
            return (<GathNodeRef id={id} icon={icon} name={name}/>)
        case itemsource.sourcet.fish_spot.id:
            return (<FishSpotRef id={id} icon={icon} name={name}/>)
        case itemsource.sourcet.enemy.id:
            return (<EnemyRef id={id} icon={icon} name={name}/>)
        case itemsource.sourcet.duty.id:
            const zid = s.data.locations[0].zone.id
            return (<DutyRef zone_id={zid} icon={icon} name={name}/>)
        case itemsource.sourcet.merchant.id:
            return (<NpcRef id={id} icon={icon} name={name}/>)
        case itemsource.sourcet.reduction.id:
            const rarity1 = itemsource.get_data_field(s, 'rarity')
            return (<ItemRef id={id} icon={icon} name={name} rarity={rarity1}/>)
        case itemsource.sourcet.coffer.id:
            const rarity2 = itemsource.get_data_field(s, 'rarity')
            return (<ItemRef id={id} icon={icon} name={name} rarity={rarity2}/>)
        case itemsource.sourcet.achieve.id:
        default:
            return (<GameRef icon={icon} name={name}/>)
    }
}

export function render_source_header(s) {
    if (s == null) {
        return null
    }
    const name = s.data[s.source_type].name
    const locs = s.data.locations
    return (
        <div style={{display:'flex',alignItems:'start',gap:'20px'}}>
            <ItemSourceRef source_rec={s}/>
            <div style={{paddingTop:'3px'}}>
                <div style={{fontSize:'12px',color:'#89b'}}>{locs == null || locs.length === 0 ? null
                    : render_location(locs[0], null, false)
                }</div>
            </div>
        </div>
    )
}

export function render_mini_source(s) {
    if (s == null) {
        return null
    }
    const name = s.data[s.source_type].name
    const locs = s.data.locations
    return (
        <div style={{display:'flex',alignItems:'top',gap:'8px'}}>
            <div>
                <div style={{display:'flex',alignItems:'center'}}>
                    <img src={imgu.to_icon_path(itemsource.get_source_icon(s))} width='24px' height='24px'></img>
                    <div>{name}</div>
                </div>
                <div style={{paddingLeft:'24px'}}>                
                    <div style={{fontSize:'12px',color:'#89b'}}>{locs == null || locs.length === 0 ? null
                        : render_location(locs[0], null, false)
                    }</div>
                </div>
            </div>
            {/*<div style={{paddingTop:'4px'}}>{mini_source_extra(s)}</div>*/}
        </div>
    )
}

function mini_source_extra(s) {
    if (s.source_type === 'gath_node') {
        return s.data.gath_node.transient == null ? null : (
            <MicroNodeTimer schedule={new gathrare.RareNodeSchedule(s.data.gath_node.transient)} 
                jobabbrev={s.data.gath_node.job_abbrev} active_icon={s.data.gath_node.icon}/>
        )
    }
    if (s.source_type === 'merchant') {
        const tieColor = '#464898'
        return (
            <div style={{fontSize:'12px'}}>
            <ListingCostEntry1 i={0} l={s.data.merchant.listing} tieColor={tieColor} suppressShopCat={true}/>
            </div>
        )
    }
    if (s.source_type === 'duty') {
        // place
        return
    }
}

export function render_source_locations(locs) {
    const entries = []
    for (let i = 0; i < locs.length; i++) {
        const x = locs[i]
        entries.push(render_location(x, i))
    }
    return (<div>{entries}</div>)
}

export function render_locations(locs, {simple_loc=true,skip_region=false}={}) {
    const entries = []
    for (let i = 0; i < locs.length; i++) {
        const x = simple_loc === true ? locs[i] : to_simple_loc(locs[i])
        entries.push(render_location(x, i, skip_region))
    }
    return (<div>{entries}</div>)
}

// 282px?
export function render_jobcat(jobcat) {
    return (<div style={{maxWidth:'262px',fontSize:'12px'}}>{jobcat}</div>)
}

export function render_jobcat_lg(jobcat) {
    return (<div style={{maxWidth:'340px',fontSize:'0.875rem'}}>{jobcat}</div>)
}
