import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useLocation,
    Navigate
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async'

import './App.css';

import client_config from './common/client_config'
import imgu from './common/imgu'
import {userService} from './common/user'

import Header from './components/Header'

import ContactPage from './pages/ContactPage'
import {ContentViewer, ContentPath} from './components/ContentViewer'
import CurrencyPage from "./pages/CurrencyPage"
import FishingSpot from './pages/FishingSpot'
import GatheringNode from './pages/GatheringNode'
import GatherSpotSearch from './pages/GatherSpotSearch'
import Home from './pages/Home'
import HousingPage from './pages/HousingPage'
import ItemPage from './pages/ItemPage'
import ItemHomePage from './pages/ItemHomePage'
import NpcPage from './pages/NpcPage'
import NpcSearchPage from './pages/NpcSearchPage'
import PatchPage from './pages/PatchPage'
import PatchList from './pages/PatchList'
import {
    PatchItemsNew, PatchItemsRemoved,
    PatchGnodesNew, PatchGnodesRemoved,
    PatchFspotsNew, PatchFspotsRemoved,
    PatchRecipesNew, PatchRecipesRemoved,
    PatchZonesNew, PatchZonesRemoved,
    PatchQuestsNew, PatchQuestsRemoved,
} from './pages/PatchBaseEntities'
import PatchItemsUpdated from './pages/PatchItemsUpdated'
import QuestPage from './pages/QuestPage'
import QuestSearchPage from "./pages/QuestSearchPage"
import RecipeSearchPage from './pages/RecipeSearchPage'
import RecipePage from './pages/RecipePage'
import RetainerTasksExplore from './pages/RetainerTasksExplore'
import RetainerTasksPage from './pages/RetainerTasksPage'
import RetainerTasksQuick from './pages/RetainerTasksQuick'
import RetainerTasksTargeted from './pages/RetainerTasksTargeted'
import ZoneSearchPage from "./pages/ZoneSearchPage"
import ZonePage from "./pages/ZonePage"

import MapTestPage from "./pages/MapTestPage"

function App() {
    const [ready, setReady] = React.useState(false)

    React.useEffect(() => {
        userService.setup()
        userService.server_sync({version_check:false}).then((x) => {
            console.log('server-sync-setReady')
            setReady(true)
        })
        .catch((e) => {
            console.log('server-sync-catch')
            console.log(e)
        })
        return () => userService.teardown()
    }, [])

    return !ready ? null : (
        <HelmetProvider>
        <Router>
            <div className="App">
                <div className="header-wrapper">
                    <header className="header">
                        <Header/>
                    </header>
                </div>
                <div className="page-wrapper">
                    <div className="page-precontents"><PageTracker/></div>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/contact" element={<ContactPage/>}/>
                        <Route path="/privacy" element={<ContentViewer path={ContentPath.Privacy} title='Privacy Policy' canon='privacy'/>}/>
                        <Route path="/terms" element={<ContentViewer path={ContentPath.Terms} title='Terms and Conditions' canon='terms'/>}/>

                        <Route path="/gatherspots" element={<GatherSpotSearch/>}/>
                        <Route path="/gatherspot/fish/:id" element={<FishingSpot/>}/>
                        <Route path="/gatherspot/node/:id" element={<GatheringNode/>}/>
                        <Route path="/housing" element={<HousingPage/>}/>
                        <Route path="/items" element={<ItemHomePage/>}/>
                        <Route path="/items/currency" element={<CurrencyPage/>}/>
                        <Route path="/item/:id" element={<ItemPage/>}/>
                        <Route path="/npcs" element={<NpcSearchPage/>}/>
                        <Route path="/npc/:id" element={<NpcPage/>}/>
                        <Route path="/quests" element={<QuestSearchPage/>}/>
                        <Route path="/quest/:id" element={<QuestPage/>}/>
                        <Route path="/recipes" element={<RecipeSearchPage/>}/>
                        <Route path="/recipe/:id" element={<RecipePage/>}/>
                        <Route path="/retainer-tasks" element={<RetainerTasksPage/>}/>
                        <Route path="/retainer-tasks/quick" element={<RetainerTasksQuick/>}/>
                        <Route path="/retainer-tasks/targeted/combat" element={<RetainerTasksTargeted retainerJob='combat'/>}/>
                        <Route path="/retainer-tasks/targeted/botanist" element={<RetainerTasksTargeted retainerJob='botanist'/>}/>
                        <Route path="/retainer-tasks/targeted/fisher" element={<RetainerTasksTargeted retainerJob='fisher'/>}/>
                        <Route path="/retainer-tasks/targeted/miner" element={<RetainerTasksTargeted retainerJob='miner'/>}/>
                        <Route path="/retainer-tasks/exploration/combat" element={<RetainerTasksExplore retainerJob='combat'/>}/>
                        <Route path="/retainer-tasks/exploration/botanist" element={<RetainerTasksExplore retainerJob='botanist'/>}/>
                        <Route path="/retainer-tasks/exploration/fisher" element={<RetainerTasksExplore retainerJob='fisher'/>}/>
                        <Route path="/retainer-tasks/exploration/miner" element={<RetainerTasksExplore retainerJob='miner'/>}/>
                        <Route path="/zones" element={<ZoneSearchPage/>}/>
                        <Route path="/zone/:id" element={<ZonePage/>}/>

                        <Route path="/patches" element={<PatchList/>}/>
                        {patchCurrentRedirects()}
                        <Route path="/patch/:id" element={<PatchPage/>}/>
                        <Route path="/patch/:id/new-items" element={<PatchItemsNew/>}/>
                        <Route path="/patch/:id/updated-items" element={<PatchItemsUpdated/>}/>
                        <Route path="/patch/:id/removed-items" element={<PatchItemsRemoved/>}/>
                        <Route path="/patch/:id/new-gnodes" element={<PatchGnodesNew/>}/>
                        <Route path="/patch/:id/removed-gnodes" element={<PatchGnodesRemoved/>}/>
                        <Route path="/patch/:id/new-fspots" element={<PatchFspotsNew/>}/>
                        <Route path="/patch/:id/removed-fspots" element={<PatchFspotsRemoved/>}/>
                        <Route path="/patch/:id/new-recipes" element={<PatchRecipesNew/>}/>
                        <Route path="/patch/:id/removed-recipes" element={<PatchRecipesRemoved/>}/>
                        <Route path="/patch/:id/new-zones" element={<PatchZonesNew/>}/>
                        <Route path="/patch/:id/removed-zones" element={<PatchZonesRemoved/>}/>
                        <Route path="/patch/:id/new-quests" element={<PatchQuestsNew/>}/>
                        <Route path="/patch/:id/removed-quests" element={<PatchQuestsRemoved/>}/>

                        {/* Redirects */}
                        <Route path="/gatherspot" element={<Navigate to="/gatherspots" replace={true}/>}/>
                        <Route path="/item" element={<Navigate to="/items" replace={true}/>}/>
                        <Route path="/currency" element={<Navigate to="/items/currency" replace={true}/>}/>
                        <Route path="/npc" element={<Navigate to="/npcs" replace={true}/>}/>
                        <Route path="/recipe" element={<Navigate to="/recipes" replace={true}/>}/>
                        <Route path="/zone" element={<Navigate to="/zones" replace={true}/>}/>

                        {/* Test pages */}
                        <Route path="/test/map" element={<MapTestPage/>}/>
                    </Routes>
                </div>
            </div>
        </Router>
        </HelmetProvider>
    );
}

function patchCurrentRedirects() {
    const patch_id = client_config.ui.patch_id
    const paths = [
        '/patch/:id',
        '/patch/:id/new-items', '/patch/:id/updated-items', '/patch/:id/removed-items',
        '/patch/:id/new-gnodes', '/patch/:id/removed-gnodes',
        '/patch/:id/new-fspots', '/patch/:id/removed-fspots',
        '/patch/:id/new-recipes', '/patch/:id/removed-recipes',
        '/patch/:id/new-zones', '/patch/:id/removed-zones',
        '/patch/:id/new-quests', '/patch/:id/removed-quests',
    ]
    const routes = paths.map((p) => {
        const from = p.replace(':id', 'current')
        const to = p.replace(':id', `${patch_id}`)
        console.log(`redirect: ${from} -> ${to}`)
        return (
            <Route path={from} element={<Navigate to={to}/>}/>
        )
    })
    return (
        <React.Fragment>{routes}</React.Fragment>
    )
}

function PageTracker(props) {
    usePageTracking()
    return (<></>)
}

function usePageTracking() {
    const location = useLocation();
  
    React.useEffect(() => {
        window.gtag("event", "page_view", {
            page_path: location.pathname + location.search + location.hash,
            page_search: location.search,
            page_hash: location.hash,
        })
    }, [location])
}

export default App;
