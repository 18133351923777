import * as React from 'react';
import {Link} from "react-router-dom";

import api from '../common/api'
import client_util from '../common/util/client_util'
import { useLocalSettings } from '../common/user'
import imgu from '../common/imgu'
import throttle from '../common/util/throttle'
import cbase from '../common/cbase'
import {ItemRef, DebugToggle, vgap} from '../components/viewbase'
import {MapView} from '../components/mapview'
import {XTable, XCol, XTableModel} from '../components/xtable'
import {ItemRead, render_stats, reward_market_value_columns, set_home_reward_value} from '../common/item'
import {ItemSearch, item_search_spec, item_colspec_default, item_colspec_mkt} from '../common/item'
import {Page} from '../components/Page'

const job_info = {
    combat: {id:'combat', title: 'Hunting Tasks - Targeted', job: 'Combat'},
    botanist: {id:'botanist', title: 'Botany Tasks - Targeted', job: 'Botanist'},
    fisher: {id:'fisher', title: 'Fishing Tasks - Targeted', job: 'Fisher'},
    miner: {id:'miner', title: 'Mining Tasks - Targeted', job: 'Miner'}
}

/*
 * retainerJob = combat | botanist | fisher | miner
 */
function RetainerTasksTargeted(props) {
    const [settings] = useLocalSettings()

    return (<RetainerTasksTargetedCore {...props} settings={settings}/>)
}

class RetainerTasksTargetedCore extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            payload: null,
            poke: 0
        }
        this.jobd = job_info[this.props.retainerJob]
        this.colspec = this.mk_colspec()
        this.xtm = XTableModel.parseUrl(this.props.qsearch)
        this.data_loader = throttle.loadOnce(() => this._run_query())
    }

    componentDidMount() {
        this.data_loader.load()
        client_util.setTitle(this.jobd.title)
    }

    componentDidUpdate(prevProps) {
        if (this.props.qsearch !== prevProps.qsearch) {
            this.xtm = XTableModel.parseUrl(this.props.qsearch)
            this.setState((prev) => ({poke:prev.poke === 0 ? 1 : 0}))
        }
        if (this.props.settings.homeWorld().id !== prevProps.settings.homeWorld().id) {
            this.colspec.update_world(this.props.settings.homeWorld().name)
            this._run_query()
        }
    }

    _table_updated(e) {
        console.log(`table_update: ${JSON.stringify(e, 2)}`)
        this.xtm = e
        this._update_history()
    }

    _update_history() {
        const qparams = this.xtm.toUrlParams()
        if (window.history.state != qparams) {
            const base_path = `/retainer-tasks/targeted/${this.jobd.id}`
            window.history.replaceState(qparams, '', qparams == null ? base_path : `${base_path}?${qparams}`)
        }
    }

    _run_query() {
        readPagePayload(this.jobd.id, this.props.settings, (x) => this.setState({payload:x}))
    }

    render() {
        const {payload} = this.state
        const is_combat = this.jobd.id === 'combat'
        return (
            <Page canon={`retainer-tasks/targeted/${this.jobd.id}`}>
                <div className='page-body'>
                    <h1>{this.jobd.title}</h1>
                    <div className='x-titled-section'>
                        <div className='x-title'>Retainer Tasks</div>
                        <table className='basic-t'><tbody>
                            <tr><td>Retainer Level</td><td>1</td></tr>
                            <tr><td>Retainer Class</td><td>{this.jobd.job}</td></tr>
                            <tr><td>Duration</td><td>40m - 1h</td></tr>
                            <tr>
                                <td>Cost</td>
                                <td><ItemRef id={21072} icon={65049} quantity={1}/></td>
                            </tr>
                        </tbody></table>
                    </div>
                    <div style={{margin:'1rem 0', maxWidth:'800px'}}>
                        <p>These tasks allow you to instruct your {this.jobd.job} retainer to collect a specific item. The duration of the task
                        varies depending on the retainer level vs. the task level as follows:</p>
                        <div className='x-section-mini'>
                            <table className='basic-t'><tbody>
                                <tr><td>Baseline</td><td>1 hour</td></tr>
                                <tr><td>Retainer Level +11</td><td>50 minutes</td></tr>
                                <tr><td>Retainer Level +21</td><td>40 minutes</td></tr>
                            </tbody></table>
                        </div>
                        <p>Each task requires a minimum retainer level and {is_combat ? 'item-level' : 'gathering'}.
                        The quantity of items received will increase as your retainer's {is_combat ? 'item-level' : 'perception'} increases.</p>
                        {is_combat ? null :
                            <div style={{marginTop:'0.5rem'}}>
                                <span style={{fontWeight:'bold'}}>Note:</span> to unlock a task you must have gathered the item yourself.
                            </div>
                        }
                    </div>
                    {payload == null ? null : this.render_tasks(payload)}
                </div>
            </Page>
        )
    }

    render_tasks(payload) {
        const tasks = payload == null ? [] : payload.tasks
        const rpp = tasks.length > 50 ? 50 : null
        const msg = `${tasks.length} tasks found`
        return (
            <div>
                <h2>Tasks</h2>
                <p></p>
                <XTable tableClass='data-t' cols={this.colspec.cols} data={tasks} 
                    xtmodel={this.xtm} handler={(e) => this._table_updated(e)}
                    message={msg} rpp={rpp} width='1020px'/>
            </div>
        )
    }

    mk_colspec() {
        const mvcols = reward_market_value_columns({
            homeWorldName:this.props.settings.homeWorld().name,
            item_fn: (t) => t.reward.item,
            qt_fn: (t) => t.reward.max_qt
        })
        const req_special_col = this.jobd.id === 'combat'
            ? new XCol({
                label:'ILvl',hstyle:{minWidth:'60px',width:'70px',maxWidth:'80px'},
                compare: (i,j) => cbase.numcmp(i.req_ilvl, j.req_ilvl),
                render: (i) => i.req_ilvl
            })
            : new XCol({
                label:'Gathering',hstyle:{minWidth:'60px',width:'70px',maxWidth:'80px'},
                compare: (i,j) => cbase.numcmp(i.req_gather, j.req_gather),
                render: (i) => i.req_gather
            })
        return {
            cols: [
                new XCol({
                    label:'Task',hstyle:{minWidth:'182px',width:'184px',maxWidth:'188px'},
                    compare: (i,j) => cbase.strcmp(i.name, j.name),
                    render: (i) => i.name,
                }),
                new XCol({
                    label:'Level',hstyle:{minWidth:'60px',width:'70px',maxWidth:'80px'},
                    compare: (i,j) => cbase.numcmp(i.level, j.level),
                    render: (i) => i.level
                }),
                req_special_col,
                new XCol({
                    label:'Item',hstyle:{minWidth:'40px',width:'40px',maxWidth:'40px'},
                    render: (i) => <ItemRef id={i.reward.item.id} icon={i.reward.item.icon} hq={i.reward.item.hq}/>
                }),
                new XCol({
                    label:'Max Quantity',hstyle:{minWidth:'140px',width:'144px',maxWidth:'160px'},
                    render: (i) => (
                        <div>
                            <span style={{fontSize:'1rem'}}>{i.reward.max_qt}</span>
                            <span style={{marginLeft:'0.75rem',color:'#777'}}>
                                {i.reward.qt_table[i.reward.qt_table.length-1].cond}
                            </span>
                        </div>
                    )
                }),
                ...mvcols.cols
            ],
            update_world: (name) => mvcols.update_world(name)
        }
    }
}

function readPagePayload(jobpathkey, settings, callback) {
    const worldId = settings.homeWorld().id
    api.call({
        path:`retainertask/targeted/${jobpathkey}?world=${worldId}`, method:'GET',
        handler:(result, err) => processPayload(result, callback)
    })
}

function processPayload(payload, callback) {
    const tasks = []
    if (payload != null && payload.tasks != null && payload.tasks.length !== 0) {
        payload.tasks.forEach((t) => {
            if (t.reward != null && t.reward.item != null) {
                if (payload.market != null) {
                    const mkt = payload.market[t.reward.item.id]
                    if (mkt != null) {
                        t.reward.item.market = mkt
                        set_home_reward_value(t.reward.item, t.reward.max_qt)
                    }
                }
            }
            tasks.push(t)
        })
    }
    callback({tasks: tasks})
}

export default client_util.withQSearch(RetainerTasksTargeted)
