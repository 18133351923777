import * as React from 'react';
//import {Link} from 'react-router-dom';

import api from '../common/api'
import {Page} from './Page'

export function ContentViewer({path, title, canon}) {
    const [content, setContent] = React.useState(null)
    const [contentPath, setContentPath] = React.useState(path)
    if (path !== contentPath) {
        setContentPath(path)
        window.scrollTo(0,0)
    }

    React.useEffect(() => {
        api.get_text_doc(contentPath).then((doc_content) => {
            setContent(doc_content)
        })
        document.title = title == null ? 'XIV Nerd' : title
    }, [contentPath])

    return content == null ? null : (
        <Page canon={canon}>
            <div className='page-body resp' dangerouslySetInnerHTML={{__html:content}}
            />
        </Page>
    )
}

export const ContentPath = {
    Privacy: 'legal/privacy.html',
    Terms: 'legal/terms.html',
}
