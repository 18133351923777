
const REAL_TO_EORZEA = (60.0 * 24.0) / 70.0

// Note:
//
// Everything is 0 based currently.
// moon/sun values should be incremented by 1 to get actual eorzea month/day
export default class EorzeaTime {
    constructor(epoch_time) {
        this.eorzea_epoch = 0
        this.year = 0
        this.moon = 0
        this.sun = 0
        this.bell = 0
        this.minute = 0
        if (epoch_time == null) {
            epoch_time = Math.floor(new Date().getTime() / 1000)
        }
        if (epoch_time !== 0) {
            this._init_from_epoch(epoch_time)
        }
    }

    _init_from_epoch(epoch_time) {
        this.eorzea_epoch = Math.floor(epoch_time * REAL_TO_EORZEA)
        const minutes = Math.floor(this.eorzea_epoch / 60)
        const bells = Math.floor(minutes / 60)
        const suns = Math.floor(bells / 24)
        const moons = Math.floor(suns / 32)
        const years = Math.floor(moons / 12)

        this.year = Math.floor(years)
        this.moon = Math.floor(moons % 12)
        this.sun = Math.floor(suns % 32)
        this.bell = Math.floor(bells % 24)
        this.minute = Math.floor(minutes % 60)
    }

    static from_parts({ year, moon, sun, bell, minute } = {}) {
        const res = new EorzeaTime(0)
        res.year = year == null ? 0 : Math.floor(year)
        res.moon = moon == null ? 0 : Math.floor(moon)
        res.sun = sun == null ? 0 : Math.floor(sun)
        res.bell = bell == null ? 0 : Math.floor(bell)
        res.minute = minute == null ? 0 : Math.floor(minute)
        res._normalize_components()
        res._calc_eorzea_epoch()
        return res
    }

    static parse_eis_time(eorzea_int_time) {
        const raw_bells = eorzea_int_time / 100
        const bells = Math.floor(raw_bells)
        const minutes = (raw_bells - bells) * 100
        return {bells: bells, minutes: minutes}
    }

    _normalize_components() {
        while (this.minute < 0) {
            this.minute += 60
            this.bell--
        }
        while (this.minute > 59) {
            this.minute -= 60
            this.bell++
        }

        while (this.bell < 0) {
            this.bell += 24
            this.sun--
        }
        while (this.bell > 23) {
            this.bell -= 24
            this.sun++
        }

        while (this.sun < 0) {
            this.sun += 32
            this.moon--
        }
        while (this.sun > 31) {
            this.sun -= 32
            this.moon++
        }

        while (this.moon < 0) {
            this.moon += 12
            this.year--
        }
        while (this.moon > 11) {
            this.moon -= 12
            this.year++
        }
    }

    _calc_eorzea_epoch() {
        const years = this.year
        const moons = (years * 12.0) + this.moon
        const suns = (moons * 32.0) + this.sun
        const bells = (suns * 24.0) + this.bell
        const minutes = (bells * 60.0) + this.minute
        const seconds = minutes * 60.0
        this.eorzea_epoch = Math.floor(seconds)
    }

    format() {
        return `${this.moon+1}/${this.sun+1}/${this.year} ${this.bell}:${this.minute}`
    }

    to_date() {
        return new Date(this.to_real_epoch() * 1000)
    }

    to_real_epoch() {
        return this.eorzea_epoch / REAL_TO_EORZEA
    }

    /*
        Calculate and return the 'next time' matching the provided criteria.

        Components that are 'below' the lowest specified component will be zeroed out.
        Eg. If a sun of 23 is provided, the returned time will have 0 bells and 0 minutes.
        
        If this time already matches the provided criteria, it will NOT be returned, instead
        the next time greater than this time which matches will be provided.
    */
    next_time({ moon, sun, bell, minute } = {}) {
        let tgt_year = this.year
        let tgt_moon = this.moon
        let tgt_sun = this.sun
        let tgt_bell = this.bell
        let tgt_minute = this.minute
        let any_found = false
        if (minute === undefined)
            tgt_minute = 0
        else {
            any_found = true
            tgt_minute = minute
            if (this.minute >= minute)
                tgt_bell++
        }
        if (bell === undefined)
            if (!any_found)
                tgt_bell = 0
        else {
            any_found = true
            tgt_bell = bell
            if (this.bell >= bell)
                tgt_sun++
        }
        if (sun === undefined)
            if (!any_found)
                tgt_sun = 0
        else {
            any_found = true
            tgt_sun = sun
            if (this.sun >= sun)
                tgt_moon++
        }
        if (moon === undefined)
            if (!any_found)
                tgt_moon = 0
        else {
            tgt_moon = moon
            if (this.moon >= moon)
                tgt_year += 1
        }
        return EorzeaTime.from_parts({
            year: tgt_year, moon: tgt_moon, sun: tgt_sun, bell: tgt_bell, minute: tgt_minute
        })
    }

    offset(other) {
        const epoch_delta = other.to_real_epoch() - this.to_real_epoch()
        const etime = new EorzeaTime(epoch_delta)
        return {
            years: etime.year,
            moons: etime.moon,
            suns: etime.sun,
            bells: etime.bell,
            minutes: etime.minute
        }
    }

    offset_time({ mod_year, mod_moon, mod_sun, mod_bell, mod_minute } = {}) {
        return EorzeaTime.from_parts({
            year: mod_year === undefined ? this.year : this.year + mod_year,
            moon: mod_moon === undefined ? this.moon : this.moon + mod_moon,
            sun: mod_sun === undefined ? this.sun : this.sun + mod_sun,
            bell: mod_bell === undefined ? this.bell : this.bell + mod_bell,
            minute: mod_minute === undefined ? this.minute : this.minute + mod_minute
        })
    }

    real_delta(etime_other) {
        return ms_to_duration(etime_other.to_real_epoch()*1000 - this.to_real_epoch()*1000)
    }
}

// Note: Only supports up to 24 hours of duration presently
function ms_to_duration(ms) {
    const isoDateStr = new Date(ms).toISOString()
    //console.log(`isoDate = ${isoDateStr}`)
    const temp = isoDateStr.substring(11, 19)
    //console.log(temp)
    const parts = temp.split(':')
    return {
        hours: parseInt(parts[0]),
        minutes: parseInt(parts[1]),
        seconds: parseInt(parts[2]),
    }
}
