
import config from './client_config'

const call = function({ path, method='GET', params, handler } = {}) {
    const url = mkapiurl(path)
    let init_obj = {
        method: method
    }
    if (params !== undefined) {
        init_obj.headers = {
            'Content-Type': 'application/json',            
        }
        init_obj.body = JSON.stringify(params)
    }
    fetch(url, init_obj)
        .then(response => {
            console.log(`API:${path} status=${response.status}`)
            return response.json()
        })
        .then(result_obj => {
            console.log('handler(result_obj)')
            handler(result_obj);
        })
        .catch(err => {
            console.log('handler(err)')
            handler(null, err);
        });
}

const promise = async function({ path, method='GET', params } = {}) {
    const url = mkapiurl(path)
    let init_obj = {
        method: method
    }
    if (params !== undefined) {
        init_obj.headers = {
            'Content-Type': 'application/json',            
        }
        init_obj.body = JSON.stringify(params)
    }
    const response = await fetch(url, init_obj)
    if (response.ok) {
        return await response.json()
    }
    return null
}

const promise_text = async function({ path, method='GET', params } = {}) {
    const url = mkapiurl(path)
    let init_obj = {
        method: method
    }
    if (params !== undefined) {
        init_obj.headers = {
            'Content-Type': 'application/json',            
        }
        init_obj.body = JSON.stringify(params)
    }
    const response = await fetch(url, init_obj)
    if (response.ok) {
        return await response.text()
    }
    return null
}

function mkapiurl(path) {
    return `${config.api.url}${path.startsWith('/')?'':'/'}${path}`
}

class CoreCaller {
    async get_jobs() {
        return await promise({path:'core/job'})
    }
    
    async get_uicats() {
        return await promise({path:'core/itemUiCategory'})
    }
}

class ItemCaller {
    async search_items(search) {
        return await search_base('item', search)
    }

    async search_currency_items(search) {
        return await search_base('item', search, 'currency_search')
    }
}

class RecipeCaller {
    async search_recipes(search) {
        return await search_base('recipe', search)
    }
}

async function search_base(path, search, search_method='search') {
    return await promise({
        path:path, method:'POST',
        params:{
            search: cleanSearch(search),
            search_method: search_method
        }
    })
}

function cleanSearch(search) {
    const x = {}
    for (const key in search) {
        if (search[key] != null) {
            x[key] = search[key]
        }
    }
    return x
}

async function get_doc(path) {
    return await promise({
        path:`doc/${path}`, method:'GET'
    })
}

async function get_text_doc(path) {
    return await promise_text({
        path:`doc/${path}`, method:'GET'
    })
}

const api = {
    call: call,
    promise: promise,
    promise_text: promise_text,
    get_doc: get_doc,
    get_text_doc: get_text_doc,
    core: new CoreCaller(),
    item: new ItemCaller(),
    recipe: new RecipeCaller()
};

export default api;
