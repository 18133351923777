import * as React from 'react';
import {useLocation, useNavigate} from 'react-router-dom'

import cbase from '../common/cbase'
import imgu from '../common/imgu'
import {MapPoint, MapView} from '../components/mapview'
import {Page} from '../components/Page'

function MapTestPage({}) {
    const vwidth = 640
    const vheight = 400
    const iwidth = 2048
    const iheight = 2048
    const scale = 0.68
    const path = 'l1f/l1f1 - Mor Dhona'

    const gcoords = {x:32.1, y:13.55}
    const vcoords = cbase.map_view_coords(gcoords, 100)

    const nav = useNavigate()
    const loc = useLocation()
    console.log(`loc.pathname = ${loc.pathname}`)

    const _add_params = () => {
        const params = '?foo=bar'
        const path = `/maptest${params}`
        console.log(`loc.state: ${loc.state}`)
        if (loc.state !== params) {
            nav(path, {replace:true,state:params})
            //window.history.replaceState(params, '', path)
        }
    }
    return (
        <Page>
        <div className='page-body'>
            <div>
                <div>Expect: 529.727, -397.057</div>
                <div>Game Coords: {JSON.stringify(gcoords)}</div>
                <div>Result: {JSON.stringify(vcoords)}</div>
            </div>
            <button onClick={() => _add_params()}>Set Params</button>
            <MapView vwidth={vwidth} vheight={vheight}
                iwidth={iwidth} iheight={iheight} scale={scale}
                path={path} points={[new MapPoint(imgu.static.gather_btn_logging[1], 529.727, -397.057)]}
            />
        </div>
        </Page>
    )
}
/*
function MapOrig({}) {
    const vwidth = 640
    const vheight = 400
    const iwidth = 2048
    const iheight = 2048
    const scale = 0.8
    const iws = iwidth * scale
    const ihs = iheight * scale
    const icenter = {x:iws/2,y:ihs/2}

    // icon placement
    const egpx = 529.727
    const egpy = -397.057
    const xpos = icenter.x + (egpx*scale) - 16
    const ypos = icenter.y + (egpy*scale) - 16

    // center-boundaries
    const imin = {x:vwidth/2, y:vheight/2}
    const imax = {x:iws - vwidth/2, y: ihs - vheight/2}
    const [mapCenter, setMapCenter] = React.useState({
        x: clamp(xpos, imin.x, imax.x),
        y: clamp(ypos, imin.y, imax.y)
    })
    console.log(`center: ${mapCenter.x} ${mapCenter.y}`)
    const dragInfo = React.useRef(null)
    const drag = () => {
        const dragd = dragInfo.current
        if (dragd != null) {
            const cx = window.event.clientX;
            const cy = window.event.clientY;
            const dx = cx - dragd.click.x
            const dy = cy - dragd.click.y
            //console.log(`mouse: ${cx} ${cy}`)
            console.log(`deltas: ${dx} ${dy}`)
            const newCenter = {
                x:clamp(dragd.center.x - dx, imin.x, imax.x), 
                y:clamp(dragd.center.y - dy, imin.y, imax.y)
            }
            console.log(JSON.stringify(newCenter))
            if (newCenter.x !== dragd.center.x || newCenter.y !== dragd.center.y) {
                setMapCenter(newCenter)
            }
        }
        window.event.stopPropagation()
        window.event.preventDefault()
        //return true
    }
    const mouseup = () => {
        dragInfo.current = null
        console.log('big-unclick')
        window.removeEventListener("mousemove", drag)
        window.removeEventListener("mouseup", mouseup)
    }
    const mapOff = {
        x: 0 - mapCenter.x + vwidth/2,
        y: 0 - mapCenter.y + vheight/2
    }
    const canvas = document.createElement('canvas')
    canvas.width = 0
    canvas.height = 0
    return (
        <div style={{height:`${vheight}px`}}>
            <div style={{position:'absolute', height:`${vheight}px`,width:`${vwidth}px`, overflow:'hidden', border:'2px solid black'}}>
                <div style={{position:'absolute', left:`${mapOff.x}px`, top:`${mapOff.y}px`}}
                    draggable='false'
                    onMouseDown={(e) => {
                        dragInfo.current = {
                            click:{x:e.clientX, y:e.clientY},
                            center:{x:mapCenter.x, y:mapCenter.y}
                        }
                        console.log(`click: ${dragInfo.current.click.x} ${dragInfo.current.click.y}`)
                        window.event.stopPropagation()
                        window.event.preventDefault()
                        window.addEventListener("mousemove", drag)
                        window.addEventListener("mouseup", mouseup)
                        return false
                    }}
                    onDragStart={() => {
                        return false
                    }}
                >
                    <img src='/ui/map2/l1f/l1f1 - Mor Dhona.webp' style={{width:`${iws}px`, height:`${ihs}px`}}></img>
                    <img style={{position:'absolute', left:`${xpos}px`, top:`${ypos}px`, width:'32px', height:'32px'}}
                        src={imgu.to_icon_path(imgu.static.gather_btn_logging[1])}>
                    </img>
                </div>
            </div>
            <div style={{marginLeft:`${vwidth+12}px`}}>
                <div style={{position:'absolute'}}>
                    <img src='/ui/map2/l1f/l1f1 - Mor Dhona.webp' style={{width:'80px', height:'80px', position:'absolute'}}></img>
                    {mapViewToThumb(80, iws, ihs, vwidth, vheight, mapCenter)}
                </div>
            </div>
        </div>
    )
}*/

export default MapTestPage
