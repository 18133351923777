import * as React from 'react';
//import {Link} from "react-router-dom";

import api from '../common/api'
import client_util from '../common/util/client_util'
import cbase from '../common/cbase'
import imgu from '../common/imgu'
import throttle from '../common/util/throttle'
import { render_velocity } from '../common/item'
import { ItemRef, JobRef, GameRef, DebugToggle } from '../components/viewbase'
import { XTable, XCol } from '../components/xtable'
import { useLocalSettings } from '../common/user'
import ItemRefs from './ItemRefs'
import {Page} from '../components/Page'

function ItemPage(props) {
    const [settings] = useLocalSettings()

    return (<ItemPageCore {...props} settings={settings}/>)
}

class ItemPageCore extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            payload: null,
            homeWorld: null,
            _poke: 0,
            debug_mode: false
        }

        let { id } = props.params
        console.log(`id = ${id}`)
        this.item_id = id
        this.data_loader = throttle.loadOnce(() => this.loadData())
    }

    componentDidMount() {
        console.log('componentDidMount')
        this.data_loader.load()
    }

    componentDidUpdate(prevProps) {
        let rlreq = false
        if (prevProps.params.id !== this.props.params.id) {
            this.item_id = this.props.params.id
            this.setState({payload:null})
            this.loadData()
            rlreq = true
        }
        if (prevProps.settings.homeWorld().id !== this.props.settings.homeWorld().id) {
            if (!rlreq) {
                //this.setState({payload:null})
                this.loadData()
                rlreq = true
            }
        }
        if (prevProps.qsearch !== this.props.qsearch) {
            if (!rlreq) {
                this.setState({_poke:this.state._poke === 0 ? 1 : 0})
            }
        }
    }

    loadData() {
        const homeWorld = this.props.settings.homeWorld()
        api.call({path:`item/${this.item_id}?world=${homeWorld.id}`, handler:(result, err) => {
            if (result != null && result.payload != null) {
                this.setState({payload: result.payload, homeWorld:homeWorld})
            }
        }})
    }

    render() {
        const {payload} = this.state
        if (payload == null) {
            return (<></>)
        }
        document.title = payload.item.name
        return (
            <Page canon={`item/${this.item_id}`}>
            <div className='page-body'>
                {this.render_header(payload.item)}
                {this.render_item(payload)}
                {this.render_debug_item(payload)}
            </div>
            </Page>
        )
    }

    render_header(item) {
        return (
            <div>
                <h1>{item.name}</h1>
                <DebugToggle stateFn={(dbg) => this.setState({debug_mode: dbg})}/>
            </div>
        )
    }

    render_item(payload) {
        const {debug_mode} = this.state
        const item = payload.item
        return debug_mode === true ? (<></>) : (
            <div>
                <div style={{paddingTop: '8px'}}>
                    {item.item_st === 1 ? this.render_simple_body(item) : this.render_body(item)}
                    {payload.marketDetail == null ? null : (
                        <MarketDetail item={item} report={payload.marketDetail}/>
                    )}
                </div>
                <ItemRefs payload={payload} qsearch={this.props.qsearch} settings={this.props.settings}/>
            </div>
        )
    }

    render_simple_body(item) {
        return (
            <div>
                {/*<img src={imgu.to_icon_path(item.icon)} style={{width: '60px', height: '60px'}}></img>
                <div>{item.uicat_nm}</div>
                {item.stacksize > 1 ? <div>Stack Count: {item.stacksize}</div> : ''}*/}
                <div style={{display:'flex',marginBottom:'10px'}}>
                    <img src={imgu.to_icon_path(item.icon)} style={{width: '60px', height: '60px'}}></img>
                    <div style={{margin:'0px 20px'}}>
                        <div>{item.name}</div>
                        <div style={{marginTop:'2px'}}>{item.uicat_nm}</div>
                    </div>
                </div>
                {item.stacksize > 1 ? <div style={{fontSize:'14px'}}>Stack: {item.stacksize}</div> : ''}
            </div>
        )
    }

    render_body(item) {
        const icon_url = imgu.to_icon_path(item.icon)
        console.log(`icon = ${item.icon}`)
        console.log(`icon_url = ${icon_url}`)
        return (
            <div>
                <div style={{display:'flex',marginBottom:'10px'}}>
                    <img src={icon_url} style={{width: '60px', height: '60px'}}></img>
                    <div style={{margin:'0px 20px'}}>
                        <div style={{color:cbase.to_rarity_color(item.rarity)}}>{item.name}</div>
                        <div style={{margin:'2px 0px'}}>
                            <span style={{marginLeft:'2px'}}>Item Level {item.ilvl}</span>
                        </div>
                        <div>{item.uicat_nm}</div>
                    </div>
                </div>
                {item.stacksize > 1 ? <div style={{fontSize:'14px'}}>Stack: {item.stacksize}</div> : ''}
                {this.render_flags(item)}
                {this.render_action(item)}
                {this.render_materia(item)}
                {this.render_equip(item)}
                {/*this.render_flags(item)*/}
            </div>
        )
    }

    render_flags(item) {
        const flags = []
        const fconf = [
            {'f': 'isunique', 'd': 'Unique'},
            {'f': 'isuntradable', 'd': 'Untradable'},
            {'f': 'isindisposable', 'd': 'Indisposable'},
            {'f': 'iscollectable', 'd': 'Collectable'},
            {'f': 'alwayscollectable', 'd': 'Always-Collectable'},
            {'f': 'desynth', 'd': 'Can-Desynth', 'fn': (v) => v !== 0},
            {'f': 'aetherialreduce', 'd': 'Can-Aetherial-Reduce', 'fn': (v) => v !== 0},
            {'f': 'canbehq', 'd': 'Can-Be-HQ'}
        ]
        for (let i = 0; i < fconf.length; i++) {
            const fc = fconf[i]
            const fn = fc.fn !== undefined ? fc.fn : (v) => v
            const test_result = fn(item[fc.f])
            if (test_result === true) {
                flags.push((
                    <div key={i} style={{fontSize:'14px',backgroundColor:'#202222',borderRadius:'4px',padding:'2px 6px'}}>
                        {fc['d']}
                    </div>
                ))
            }
        }
        return flags.length === 0 ? (<></>) : (
            <div style={{marginTop:'6px'}}>
                <div style={{display:'flex',flexWrap:'wrap',gap:'6px', width:'500px'}}>{flags}</div>
            </div>
        )
    }

    render_action(item) {
        let content = null
        if (item.action != undefined && item.action != null) {
            if (item.action.type == 'Food') {
                content = this.render_food_action(item)
            }
            else if (item.action.type == 'Enhancement') {
                content = this.render_enhancement_action(item)
            }
        }
        return content
    }

    render_food_action(item) {
        const xtras = [
            (<tr key='0'>
                <td>Duration</td>
                <td>{item.action.dur}</td>
            </tr>),
            (<tr key='1'>
                <td>Experience Gain</td>
                <td>3%</td>
            </tr>)
        ]
        return (
            <div style={{marginTop: '20px'}}>
                <div style={{fontWeight: 'bold', fontSize: '16px'}}>Food Bonuses</div>
                <div style={{marginLeft: '20px'}}>
                    {this.render_action_effects(item, {xtras: xtras})}
                </div>
            </div>
        )
    }

    render_enhancement_action(item) {
        const xtras = [
            (<tr key='0'>
                <td>Duration</td>
                <td>{item.action.dur}</td>
            </tr>),
            (<tr key='1'>
                <td>Cooldown</td>
                <td>{item.action.cd}</td>
            </tr>)
        ]
        return (
            <div style={{marginTop: '20px'}}>
                <div style={{fontWeight: 'bold', fontSize: '16px'}}>Enhancements</div>
                <div style={{marginLeft: '20px'}}>
                    {this.render_action_effects(item, {xtras: xtras})}
                </div>
            </div>
        )
    }

    render_action_effects(item, {xtras} = {}) {
        const rows = xtras != undefined ? [...xtras] : []
        const xk = rows.length
        for (let i = 0; i < item.action.effects.length; i++) {
            const e = item.action.effects[i]
            rows.push(this.render_action_effect(e, item.canbehq, i+xk))
        }
        return (
            <table className='rarenode-result-t'><tbody>
                {rows}
            </tbody></table>
        )
    }

    render_action_effect(e, canbehq, key) {
        return (
            <tr key={key}>
                <td>{e.p}</td>
                <td>{e.rel ? `${e.v}% (max ${e.vx})` : e.v}</td>
                <td>{canbehq === false ? '' : e.rel ? `${e.hq}% (max ${e.hqx})` : e.hq}</td>
            </tr>
        )
    }

    render_materia(item) {
        return item.uicat_nm != 'Materia' ? (<></>) : (
            <div style={{marginTop: '20px'}}>
                <div style={{fontWeight: 'bold', fontSize: '16px'}}>Materia Bonus</div>
                <div style={{marginLeft: '20px'}}>
                    <table className='rarenode-result-t'><tbody>
                        <tr>
                            <td>{item.materia.p}</td>
                            <td>{item.materia.v}</td>
                        </tr>
                    </tbody></table>
                </div>
            </div>
        )
    }
    render_equip(item) {
        return item.equip == null ? (<></>) : (
            <div style={{marginTop: '12px'}}>
                {/*<div style={{fontWeight: 'bold', fontSize: '16px'}}>Equipment Details</div>*/}
                <div style={{marginTop: '6px',/*border:'1px solid #111'backgroundColor:'#202024',borderRadius:'8px'*/}}>
                    {this.render_equip_reqs(item)}
                    {this.render_equip_type(item)}
                    {this.render_equip_detail(item)}
                </div>
            </div>
        )
    }

    render_equip_reqs(item) {
        return (
            <div style={{fontSize:'14px'}}>
                <div>{item.equip.jobcat_nm}</div>
                <div>Level {item.ulvl}</div>
                {sep()}
            </div>
        )
    }

    render_equip_type(item) {
        const equip_stats = (item.equip.eqtype == 'weapon' || item.equip.eqtype == 'prtool')
            ? this.render_weapon_stats(item)
            : (item.equip.eqtype == 'armor' || item.equip.eqtype == 'acc')
            ? this.render_armor_stats(item)
            : (item.equip.eqtype == 'shield')
            ? this.render_shield_stats(item)
            : null
        return equip_stats == null ? (<></>) : (
            <div style={{margin:'6px 0px 10px 0px'}}>
                {/*<div style={{marginLeft: '12px'}}>Stats - {item.equip.eqtype}</div>
                <div style={{marginLeft: '20px'}}>{equip_stats}</div>*/}
                {equip_stats}
            </div>
        )
    }

    render_main_stat(name, val, val_hq) {
        return (
            <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
                <div style={{fontWeight:'bold',marginBottom:'2px'}}>{name}</div>
                <div style={{display:'flex',justifyContent:'left',alignItems:'flex-end'}}>
                    <span className={val_hq != null ? 'disabled-text1' : ''} 
                        style={{fontSize:val_hq != null ? '14px' : '20px'}}
                    >
                        {val}
                    </span>
                    {val_hq == null ? '' : 
                        <span style={{marginLeft:'8px',fontSize:'20px'}}>
                            {val_hq}
                        </span>
                    }
                </div>
            </div>
        )
    }
    render_weapon_stats(item) {
        return (
            <div>
                <div style={{display:'flex',fontSize:'14px'}}>
                    {this.render_main_stat('Damage', item.equip.base.dmgp, item.canbehq ? item.equip.base_hq.dmgp : null)}
                    <div style={{margin:'0px 12px'}}></div>
                    {this.render_main_stat('Magic Damage', item.equip.base.dmgm, item.canbehq ? item.equip.base_hq.dmgm : null)}
                    <div style={{margin:'0px 12px'}}></div>
                    {this.render_main_stat('Delay', `${item.equip.base.delay/1000}s`, null)}
                </div>
            </div>
        )
    }

    render_armor_stats(item) {
        return (
            <div>
                <div style={{display:'flex',fontSize:'14px'}}>
                    {this.render_main_stat('Defense', item.equip.base.defp, item.canbehq ? item.equip.base_hq.defp : null)}
                    <div style={{margin:'0px 12px'}}></div>
                    {this.render_main_stat('Magic Defense', item.equip.base.defm, item.canbehq ? item.equip.base_hq.defm : null)}
                </div>
            </div>
        )
    }

    render_shield_stats(item) {
        return (
            <div>
                <div style={{display:'flex',fontSize:'14px'}}>
                    {this.render_main_stat('Block', item.equip.base.block, item.canbehq ? item.equip.base_hq.block : null)}
                    <div style={{margin:'0px 12px'}}></div>
                    {this.render_main_stat('Block Rate', item.equip.base.blockrt, item.canbehq ? item.equip.base_hq.blockrt : null)}
                </div>
            </div>
        )
    }

    render_equip_detail(item) {
        const entries = []
        for (let i = 0; i < item.equip.bonus.length; i++) {
            const b = item.equip.bonus[i]
            entries.push((
                <tr key={i}>
                    <td>{b.p}</td>
                    <td className={item.canbehq ? 'disabled-text1' : ''}>{b.v}</td>
                    <td>{item.canbehq ? b.hq : ''}</td>
                </tr>
            ))
        }
        return (
            <div style={{/*display:'flex',gap:'16px'*/}}>
                <div>
                <div style={{fontSize:'16px',fontWeight:'bold'}}>Bonuses</div>
                <div style={{margin: '0px 0px 8px 6px'}}>
                    <table className='rarenode-result-t'><tbody>
                        {entries}
                    </tbody></table>
                </div>
                </div>
                <div>
                <div style={{fontSize:'16px',fontWeight:'bold'}}>Materia</div>
                <div style={{margin: '0px 0px 8px 6px'}}>
                    <table className='rarenode-result-t'><tbody>
                        <tr><td>Materia Slots</td><td>{item.equip.materiaslotcount}</td></tr>
                        <tr><td>Advanced Melding</td><td>{item.equip.isadvancedmeldingpermitted ? 'Yes' : 'No'}</td></tr>
                    </tbody></table>
                </div>
                </div>
                <div>
                <div style={{fontSize:'16px',fontWeight:'bold'}}>Repairs</div>
                <div style={{margin: '0px 0px 8px 6px'}}>
                    <table className='data-t noborder-t'><tbody>
                        <tr>
                            <td>Job</td>
                            <td><div style={{display:'flex',alignItems:'center'}}>
                                <JobRef id={item.equip.jobrepair} name={item.equip.jobrepair_nm}/>
                                <div style={{marginLeft:'6px'}}>Level {item.ulvl < 12 ? 1 : item.ulvl-10}</div>
                            </div></td>
                        </tr>
                        <tr>
                            <td>Material</td>
                            <td>
                                <ItemRef id={item.repairMaterial.id} icon={item.repairMaterial.icon} 
                                    name={item.repairMaterial.name}/>
                            </td>
                        </tr>
                    </tbody></table>
                </div>
                </div>
            </div>
        )
    }

    render_debug_item(payload) {
        const {debug_mode} = this.state
        return debug_mode === false ? (<></>) : (
            <div style={{justifyContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                {client_util.renderInput(payload)}
            </div>
        )
    }
}

const sep = () => {
    return (
        <div style={{/*width:'300px',height:'1px',backgroundColor:'#151515',*/margin:'4px 0px'}}>
        </div>
    )
}

function MarketDetail({item, report}) {
    const recs = report.worlds
    recs.sort((i,j) => {
        const homec = cbase.numcmp(i.ishome ? 0 : 1, j.ishome ? 0 : 1)
        if (homec !== 0) {
            return homec
        }
        return cbase.strcmp(i.name, j.name)
    })
    const widthx = item.canbehq ? 560 : 400
    const width = `${widthx}px`
    return (
        <div>
            <h2>Market</h2>
            <div style={{display:'flex',flexDirection:'column',alignItems:'stretch',width:width}}>
                <div style={{display:'flex',alignItems:'end'}}>
                    <div style={{fontSize:'12px',color:'#999'}}>{`${cbase.format_utc_timestamp(report.ctime)}`}</div>
                    <div style={{flexGrow:'1',minWidth:'20px'}}></div>
                    <a className='xbtn Clean-link' href={`https://universalis.app/market/${item.id}`}
                        target='_blank'>View on Universalis</a>
                </div>
                
                <XTable key='market' tableClass='data-t' 
                    cols={item.canbehq ? _mkt_hq_colspec : _mkt_nq_colspec}
                    idResolver={(x) => x.id} data={recs} width={widthx}
                    />
            </div>
        </div>
    )
}

const _mcol_name = new XCol({
    label:'Name', hstyle:{minWidth:'160px'},
    compare: (i,j) => cbase.strcmp(i.name, j.name),
    render: (i) => {
        const fWeight = i.ishome ? 'bold' : 'normal'
        return (<div style={{fontWeight:fWeight}}>{i.name}</div>)
    }
})
const _mcol_stat = (name,prop,isgil=false) => new XCol({
    label:name, hstyle:{minWidth:'80px'},
    compare: (i,j) => cbase.numcmp(i[prop], j[prop]),
    render: (i) => {
        if (isgil === false) {
            return (
                <div style={{color:'#888',display:'flex',alignItems:'center'}}>
                    {render_velocity(i[prop])}
                    <div style={{paddingLeft:'6px'}}>{i[prop]}</div>
                </div>
            )
        }
        return (
            <div style={{display:'flex'}}>
                <div style={{flexGrow:'1'}}></div>
                <GameRef icon={/*imgu.static.gil*/null} name={i[prop].toLocaleString()}/>
                <div style={{minWidth:'40px'}}></div>
            </div>
        )
    }
})

const _mkt_hq_colspec = [
    _mcol_name,
    _mcol_stat((<HqHeader label='Value'/>),'mprice_hq',true),
    _mcol_stat((<HqHeader label={_act_label()}/>),'vel_hq'),
    _mcol_stat('Value','mprice_nq',true),
    _mcol_stat(_act_label(),'vel_nq'),
]
const _mkt_nq_colspec = [
    _mcol_name,
    _mcol_stat('Value','mprice_nq',true),
    _mcol_stat(_act_label(),'vel_nq'),
]

function HqHeader({label}) {
    return (
        <div style={{display:'flex',alignItems:'center'}}>
            <div>{label}</div>
            <div style={{width:'6px'}}></div>
            <img src={imgu.to_icon_path(imgu.static.hq16)} alt='hq' height='12px'></img>
        </div>
    )
}

function _act_label() {
    return (
        <div style={{display:'flex',gap:'4px'}}>
            <div className='material-icon icon18'>acute</div>
            <div>Activity</div>
        </div>
    )
}

export default client_util.withUrlFeatures(ItemPage)
